import { useContext } from "react";
import { LayoutStateContext } from "../../contexts/layout_state";

export default function Desktop({children}:any) {
    const { mobileDetect: { widthCutoff: mobileCutoff } } = useContext(LayoutStateContext);
    return (
        <>
            <div className="desktop">{children}</div>
            <style jsx>{`                
            @media screen and (max-width: ${mobileCutoff}px) {
                .desktop {
                    display: none;
                }
            }
            `}</style>
        </>
    );
}