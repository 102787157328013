import { useContext, useEffect, useRef } from "react";
import { LayoutStateContext } from "../../contexts/layout_state";
import { WindowSizeContext } from "../../contexts/window_size";
import { useSmartState } from "../../hooks/use_smart_state";
import { keypress } from "../../lib/keypress";
import { misc } from "../../lib/misc";
import { Text } from "../core/text";
import PromoSpace from "./promo_space"

type TProp = {
    aspectRatio: number;
    iconName: string;
    text: string;
    url?: string;
};

export class SearchBarComm {
    private blurDelayed = false;
    private endBlurDelayTimeoutHandler = 0;
    private blurWasBlocked = false;

    isBlurDelayed() {
        return this.blurDelayed;
    }

    openBlurDelay() {
        this.blurWasBlocked = false;
        this.blurDelayed = true;
        this.endBlurDelayTimeoutHandler = setTimeout(() => {
            this.endBlurDelayTimeoutHandler = 0;
            if (this.blurWasBlocked) {
                this.endBlurDelay();
            }                         
        },750) as unknown as number;
    }

    declareBlurWasBlocked() {
        this.blurWasBlocked = true;
    }

    endBlurDelay() {
        if (this.endBlurDelayTimeoutHandler!==0) {
            clearTimeout(this.endBlurDelayTimeoutHandler);
            this.endBlurDelayTimeoutHandler = 0;
        }
        this.blurDelayed = false;
        this.notifyMeOnBlurDelayEnd();
    }

    blur() {
        this.notifyMeOnBlurRequest();
    }

    notifyMeOnBlurDelayEnd() {}
    notifyMeOnBlurRequest() {}
    notifyMeOnArrowUpPressed() {}
    notifyMeOnArrowDownPressed() {}
    notifyMeOnEnterPressed() {}
    notifyMeOnPlaceholderAnimationStart() {}
    notifyMeOnPlaceholderAnimationEnd() {}    
}

export default function PromoLeft({aspectRatio,iconName,text,url}: TProp)
{
    const searchSideSizePercentage = 12;

    const inputRef = useRef<HTMLInputElement>();
    const searchInputText = useSmartState("");

    const windowSize = useContext(WindowSizeContext);
    const { searchBarOffsetLeft,scalePixelToRem,searchInputTextPointer,searchOpen,searchBarCommRef,sideCartOpenRef,openSearch } = useContext(LayoutStateContext);
    const { current: searchBarComm } = searchBarCommRef;

    // useEffect(() => {
    //     // searchInputTextPointer.value = searchInputText;
    //     // searchBarComm.notifyMeOnBlurDelayEnd = () => {
    //     //     blurHandler();
    //     // };    
    //     // searchBarComm.notifyMeOnBlurRequest = () => {
    //     //     if (inputRef.current) inputRef.current.blur();
    //     // };

    //     // const unregisterCtrlS = keypress.onKey(() => {
    //     //     if ((inputRef.current) && (!sideCartOpenRef.current)) {
    //     //         inputRef.current.focus();
    //     //     }
    //     // },"s",{ctrl: true});

    //     // return () => {
    //     //     unregisterCtrlS();
    //     // };
    // },[]);

    useEffect(() => {
        if (inputRef.current) {
            searchBarOffsetLeft.value = scalePixelToRem(misc.getOffsetRelativeToParent(inputRef.current,".page")?.left || 0);
        }        
    },[windowSize.width]);

    const focusHandler = () => {
        openSearch();
    };
    const blurHandler = () => {
        if (!searchBarComm.isBlurDelayed()) {
            searchOpen.value = false;
            searchInputText.value = "";
        }
    };

    const handleInputKeyDown = (key: string,preventDefault: () => void) => {
        if (key==="Escape") {
            if (inputRef.current) inputRef.current.blur();
        };
        if (key==="ArrowUp") {
            preventDefault();
            searchBarComm.notifyMeOnArrowUpPressed();
        }
        if (key==="ArrowDown") {
            preventDefault();
            searchBarComm.notifyMeOnArrowDownPressed();
        }
        if (key==="Enter") {
            preventDefault();
            searchBarComm.notifyMeOnEnterPressed();
        }
    };

    return (
        <>
            <PromoSpace {...{aspectRatio,iconName,text,url}}>
                <div></div>
            </PromoSpace>
            <style jsx>{`
            `}</style>
        </>
    );
}