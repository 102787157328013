import { CSSProperties, useContext, useEffect, useRef } from "react";
import { LayoutStateContext } from "../../contexts/layout_state";
import { useCssStateManager } from "../../hooks/use_css_state_manager";
import { useResizeObserver } from "../../hooks/use_resize_observer";
import { SmartState, useSmartState } from "../../hooks/use_smart_state";
import { DiscountBreakdown, prebuildData } from "../../lib/prebuild-data";
import PriceDisplay from "../common/price-display";
import { EventPane } from "../core/event_pane";

type ComponentEntryProps = {
    caption: string;
    fromQuantity: number;
    discount: number;
    isLast?: boolean;
};

function ComponentEntry({caption,fromQuantity,discount,isLast = false}:ComponentEntryProps)
{
    const cssStateManager = useCssStateManager(["component-entry"]);
    cssStateManager.useProperty(!isLast,"is-not-last");
    return (
        <>
            <div className={cssStateManager.getClassNames()}>
                <div className="caption">{caption}</div>
                <div className="info">
                    <div className="items">{fromQuantity} item{(fromQuantity!==1)?"s":""}</div>
                    <div className="price"><span className="saved">saved</span><PriceDisplay price={discount} size={18} /></div>
                </div>
            </div>
            <style jsx>{`
            .component-entry {                
                font-size: 14rem;
            }

            .caption {
                margin-bottom: 3rem;
                color: #555;
            }

            .info {
                display: flex;
                justify-content: space-between;
                align-items: end;
                padding-left: 10rem;                
            }

            .items {
                color: #777;
            }

            .price {
                color: #666;
            }

            .is-not-last {
                margin-bottom: 15rem;
            }

            .saved {
                border: 1rem solid #aaa;
                border-radius: 10rem;
                font-size: 10rem;
                padding: 2rem 7rem;
                position: relative;
                top: 5rem;
                left: -7rem;
                color: #777;
            }
            `}</style>
        </>
    );
}


export type SalesBreakdownProps = {
    discountBreakdown: DiscountBreakdown;
    open: boolean;
    onClose?: () => void;
};

export default function SalesBreakdown({discountBreakdown,open,onClose = () => {}}:SalesBreakdownProps) {
    const refOuter = useRef<HTMLDivElement>();
    const innerRef = useResizeObserver(() => {
        if (height.value>0) {
            if (innerRef.current) {
                const { current: innerElem } = innerRef;
                height.value = scalePixelToRem(innerElem.offsetHeight);
            }
        }
    });
    const cssStateManager = useCssStateManager(["sales-breakdown"]);
    const openRef = useRef(open);
    openRef.current = open;
    
    const { scalePixelToRem } = useContext(LayoutStateContext);
    
    const { sales,salesComponents } = prebuildData.getGlobalConfig();

    const height = useSmartState(0);

    const styles: CSSProperties = {
        height: (height.value===-1)?"auto":`${height.value}rem`
    };
    

    const activeSale = sales.filter(({name}) => name===prebuildData.salesName)[0];
    
    const components = activeSale.components.filter((name) => !!discountBreakdown[name]).map((name) => salesComponents.filter(({name: _name}) => _name===name)[0]);

    useEffect(() => {
        if (open) {
            if (innerRef.current) {
                const { current: innerElem } = innerRef;
                height.value = scalePixelToRem(innerElem.offsetHeight);
            }
        }
        else {
            height.value = 0;
        }
    },[open]);

    // const closeHandler = () => {
    //     open.value = false;
    // };

    return (
        <>
            <div ref={refOuter} className={cssStateManager.getClassNames()} style={styles}>
                <div className="inner" ref={innerRef}>
                    {components.map(({name,caption},index) => <ComponentEntry key={index} {...{
                            caption,
                            fromQuantity: discountBreakdown[name].fromQuantity,
                            discount: discountBreakdown[name].discount,
                            isLast: index===components.length-1
                        }} />)}
                    <div className="close">
                        <span className="icon-navbar-left-arrow icon" />
                    </div>
                    <EventPane onClick={onClose} />
                </div>    
            </div>
            <style jsx>{`
            .sales-breakdown {
                /* height: 0rem; */
                overflow: hidden;
                transition: height 0.5s;
            }

            .inner {
                padding: 20rem 20rem;
                padding-bottom: 35rem;
                /* height: 100rem; */
                background-color: rgba(0,0,0,0.05);
                /* background-color: white; */
                --border-color: #ccc;
                border-top: 1rem solid var(--border-color);
                border-bottom: 1rem solid var(--border-color);
                box-shadow: 0 1rem 5rem#0000000f inset;          
                position: relative;      
            }
            
            .close {
                position: absolute;
                --width: 50rem;
                width: var(--width);
                height: 40rem;
                bottom: 0rem;
                left: calc(50% - (var(--width) / 2));
                display: flex;
                justify-content: center;
                align-items: center;
                color: #aaa;
            }
            .close .icon {
                transform: scaleX(1.4) rotate(90deg);
                display: inline-block;
            }
            `}</style>
        </>
    );
}