import { HTMLAttributes, useEffect, useRef, useState } from "react";
import { useSmartState } from "./use_smart_state";

type ScrollbarAnalysis = {
    scrollbarWidth: number;
    overlaySupported: boolean;
    isStyleable: boolean;
};

let _cache_analyzeScrollbarCapabilities: ScrollbarAnalysis = {
    scrollbarWidth: 0,
    overlaySupported: false,
    isStyleable: false
};
let _is_analyzeScrollbarCapabilities = false;

const analyzeScrollbarCapabilities = () => {
    if (_is_analyzeScrollbarCapabilities) return _cache_analyzeScrollbarCapabilities;

    const div = document.createElement("div");
    const divChild = document.createElement("div");
    divChild.style.width = "100%";
    divChild.style.height = "200rem";
    divChild.style.backgroundColor = "blue";
    div.style.position = "absolute";
    div.style.left = "0rem";
    div.style.top = "0rem";
    div.style.width = "500rem";
    div.style.height = "100rem";
    div.style.overflow = "auto";
    div.style.visibility = "hidden";
    div.style.backgroundColor = "red";
    div.appendChild(divChild);
    document.body.appendChild(div);
    const scrollbarWidth = div.offsetWidth-divChild.offsetWidth;
    let overlaySupported = false;
    let isStyleable = false;
    
    if (scrollbarWidth>0) {
        div.style.overflow = "overlay";
        overlaySupported = ((div.offsetWidth-divChild.offsetWidth)===0);
        div.style.overflow = "auto";

        const targetScrollbarWidth = scrollbarWidth*2;
        const className = "__scrollbar_stylable_test__";
        const css = `
            .${className} {
                scrollbar-width: ${targetScrollbarWidth}rem;
            }
    
            .${className}::-webkit-scrollbar {
                width: ${targetScrollbarWidth}rem;
            }
        `;
    
        const style = document.createElement("style");
    
        if ((style as any).styleSheet) {
            (style as any).styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }
        
        document.getElementsByTagName('head')[0].appendChild(style);
        div.className = className;
        const scrollbarWidthAfter = div.offsetWidth - divChild.offsetWidth;
        isStyleable = (scrollbarWidthAfter>scrollbarWidth);

        document.getElementsByTagName('head')[0].removeChild(style);
    }

    document.body.removeChild(div);    

    const ret: ScrollbarAnalysis = {
        scrollbarWidth,
        overlaySupported,
        isStyleable
    };

    _is_analyzeScrollbarCapabilities = true;
    _cache_analyzeScrollbarCapabilities = ret;

    return ret;
};

export const useDesktopScrollbar = <T extends Element = HTMLDivElement>() => {
    const ref = useRef<T>(null);   
    const [show,setShow] = useState(false); 
    const hideOverlayScrollbar = useSmartState(false);
    const stylesRef = useRef<HTMLAttributes<T>["style"]>({});
    const classNameRef = useRef("");
    const overlayClassName = "-desktop-scrollbar-overlay-scrollbar";
    const hideOverlayClassName = "-desktop-scrollbar-hide-overlay-scrollbar";
    const normalClassName = "-desktop-scrollbar-normal-scrollbar";
    const overlayScrollbarWidth = 10+2;
    const normalScrollbarWidth = 6+2;
    let injectedStyles = `

    .${overlayClassName} {
        scrollbar-color: rgba(0,0,0,1) rgba(0,0,0,0);
        scrollbar-width: ${overlayScrollbarWidth}rem;
        scrollbar-height: ${overlayScrollbarWidth}rem;
    }

    .${overlayClassName}::-webkit-scrollbar {
        width: ${overlayScrollbarWidth}rem;
        height: ${overlayScrollbarWidth}rem;
    }

    .${overlayClassName}::-webkit-scrollbar-thumb {
        background-color: rgba(0,0,0,0.25);
        border-radius: ${overlayScrollbarWidth*2}rem;
        border: 3rem solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }

    .${overlayClassName}::-webkit-scrollbar-track {
        background-color: transparent;
    } 

    .${overlayClassName}.${hideOverlayClassName}::-webkit-scrollbar-thumb {
        background-color: rgba(0,0,0,0);
    }

    .${normalClassName} {
        scrollbar-color: rgba(0,0,0,1) rgba(0,0,0,0);
        scrollbar-width: ${normalScrollbarWidth}rem;
        scrollbar-height: ${normalScrollbarWidth}rem;
    }

    .${normalClassName}::-webkit-scrollbar {
        width: ${normalScrollbarWidth}rem;
        height: ${normalScrollbarWidth}rem;
    }

    .${normalClassName}::-webkit-scrollbar-thumb {
        background-color: rgba(0,0,0,0.2);
        border-radius: ${normalScrollbarWidth*2}rem;
    }

    .${normalClassName}::-webkit-scrollbar-track {
        background-color: rgb(230,230,230);
    } 
    `;

    useEffect(() => {        
        const { isStyleable,overlaySupported,scrollbarWidth } = analyzeScrollbarCapabilities();
        if (scrollbarWidth>0) {
            if (isStyleable) {
                if (overlaySupported) {
                    stylesRef.current = {
                        overflow: "overlay"
                    };
                    classNameRef.current = overlayClassName;
                }
                else {
                    classNameRef.current = normalClassName;
                }
            }
        }
        setShow(true);
    },[]);
    const styles: HTMLAttributes<HTMLDivElement>["style"] = {
        ...stylesRef.current,
        opacity: show?1:0
    };
    return { ref,styles,className: `${classNameRef.current} ${hideOverlayScrollbar.value?hideOverlayClassName:""}`,injectedStyles,hideOverlayScrollbar };
};