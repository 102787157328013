import React, { MutableRefObject } from 'react'
import { SearchBarComm } from '../components/navbar/promo_left';
import { BoughtTogetherClient } from '../hooks/use_bought_together_client';
import { ReviewsHeader } from '../hooks/use_reviews_header';
import { SmartState } from '../hooks/use_smart_state';
import { Account } from '../hooks/use_account';

export type TCollectionItem = {
    id: string; 
    title: string;
    caption: string;
    subCaption: string;
    banner: {
        captionLeft: number;
        subCaptionLeft: number;
    };
    route: string;
};
export type TCollectionItems = TCollectionItem[];

export enum TManagedScrollTopAnimationDuration {
    Up,
    Down,
    Either
}

export type MobileDetectUseEffectCallback = (isMobile: boolean) => void;
export type ScaleFactorDetectUseEffectCallback = (scalePixelToRem: (value: number) => number,scaleRemToPixel: (value: number) => number) => void;

export type SearchOpenType = "site-width" | "collection";

export type SmileState = "none" | "iframe-normal" | "login-confirmation"

export type MobileDetectType = "main" | "small" | "mini" | "small_desktop";

export type TLayoutState = {    
    routerIsLocked: SmartState<boolean>;
    account: SmartState<Account | null | false>;
    linearTopWorkingIndicatorRunning: SmartState<boolean>;
    registerError: string;
    loginPreloadedEmail: string;
    loginPreloadedType: string;
    clearLoginPreloaded: () => void;
    openSalesNotice: (message: string,fromCart?: boolean) => void;
    navbarHeaderRef: MutableRefObject<HTMLDivElement>;
    openBugReporter: () => void;
    resetBugReporter: () => void;
    creatingCheckoutCaptionRef: MutableRefObject<string>;
    productPageLoadCounter: SmartState<number>;
    smileState: SmartState<SmileState>;
    smileAccessToken: string;
    openSmileIframe: (accessToken?: string) => void;
    pageRef: MutableRefObject<HTMLDivElement>;
    collectionSearchBarOpen: SmartState<boolean>;
    searchCollectionId: string;
    openSearch: (type?: SearchOpenType,collectionId?: string) => void;
    configuredBannerHeight: { height: number; };
    pageMinWidth: number;
    sideCartOpenRef: MutableRefObject<boolean>;
    searchBarCommRef: MutableRefObject<SearchBarComm>;
    searchOpen: SmartState<boolean>;
    searchOpenType: SmartState<SearchOpenType>;
    searchInputTextPointer: SmartState<SmartState<string>>;
    searchBarOffsetLeft: SmartState<number>;
    reviewsHeader: ReviewsHeader;
    boughtTogetherClient: BoughtTogetherClient;
    mobileDetect: {
        widthCutoff: number;
        widthCutoffStyleSection: (content: string) => string;
        smallWidthCutoff: number;
        miniWidthCutoff: number;
        smallDesktopWidthCutoff: number;
        isActive: MutableRefObject<boolean>;
        isSmallScreen: MutableRefObject<boolean>;
        isMiniScreen: MutableRefObject<boolean>;
        isSmallDesktopScreen: MutableRefObject<boolean>;
        mobileSmallScreenCutoff: number;
    };
    mobileDetectUseEffect: (cb: MobileDetectUseEffectCallback,listenToChanges?: React.DependencyList,type?: MobileDetectType) => void;
    scaleFactorDetectUseEffect: (cb: ScaleFactorDetectUseEffectCallback,listenToChanges?: React.DependencyList) => void;
    scaleFactor: number;
    screenRatio: number;
    scalePixelToRem: (pixelValue: number) => number;
    scaleRemToPixel: (RemValue: number) => number;
    sideMenu: {
        openId: number;
        show: boolean;
        setShow: (value: boolean) => void;
    };
    collectionItems: TCollectionItems;
    page: {
        addScrollEventListener: (handler: (scroll: {top: number,left: number,topMax: number,leftMax: number}) => void) => (() => void);
        hideOverlayScrollbar: () => void;
        showOverlayScrollbar: () => void;
        scrollTop: () => number;
        setScrollTop: (value: number) => void;
        animateScrollTop: (target: number,durationMs: number,cb?: () => void) => void;
        animateScrollTopManaged: (target: number,options?: { durationMs?: number; threshold?: number; direction?: TManagedScrollTopAnimationDuration },cb?: () => void) => void;
        scrollLeft: () => number;
        setScrollLeft: (value: number) => void;
        scrollTopMax: () => number;
        scrollLeftMax: () => number;
        scrollTo: (options: ScrollToOptions) => void;
        scrollToTopWithCallback: (cb: () => void,target?: number) => void;
        scrollWidth: () => number;
        scrollHeight: () => number;
        clientWidth: () => number;
        clientHeight: () => number;
        addEventListener: HTMLDivElement["addEventListener"];
        removeEventListener: HTMLDivElement["removeEventListener"];
        scrollBarWidth: () => number;
        lockScroll: () => void;
        unlockScroll: () => void;
        isScrollLocked: boolean;
        on: (eventName: "before-router-scroll-up" | "after-router-scroll-up",handler: () => void) => void;
        off: (eventName: "before-router-scroll-up" | "after-router-scroll-up",handler: () => void) => void;
    };
    font: {
        ready: boolean;
        setReady: (value: boolean) => void;
    };
    misc: {
        filterBarItemClassNameIdentifier: string;
    };
    sideCart: {
        open: (options?: {highlightIds?: string[];flashIds?: string[]}) => void;
    }
};

export const LayoutStateContext = React.createContext<TLayoutState>(null);