import { useCssStateManager } from "../../../hooks/use_css_state_manager";
import { useSmartState } from "../../../hooks/use_smart_state";
import GridItem, { GridItemData } from "./grid_item";

export type GridRowProps = {
    items: GridItemData[];
    productIds: string[];
    onClick?: (productId: string) => void;
    onProductPage?: boolean;
};

export type TitleHeight = {
    value: number;
    max: number;
    itemCount: number;
    knownCount: number
};

export default function GridRow({items,productIds,onClick = () => {},onProductPage = false}:GridRowProps)
{        
    const cssStateManager = useCssStateManager(["grid-row"]);
    cssStateManager.useProperty(onProductPage,"on-product-page");

    const titleHeight = useSmartState<TitleHeight>({
        value: -1,
        max: 0,
        itemCount: items.length,
        knownCount: 0
    });    
    const firstItem: React.ReactNode = items[0]?<GridItem key={items[0].productId} data={items[0]} titleHeight={titleHeight} clickHandler={() => onClick(productIds[0])} onProductPage={onProductPage} />:"";
    const secondItem: React.ReactNode = items[1]?<GridItem key={items[1].productId} data={items[1]} titleHeight={titleHeight} clickHandler={() => onClick(productIds[1])} onProductPage={onProductPage} />:"";
    return (
        <>
            <div className={cssStateManager.getClassNames()}>
                <div className="side">{firstItem}</div>
                <div className="spacer"></div>
                <div className="side">{secondItem}</div>
            </div>
            <style jsx>{`
            .grid-row {
                display: flex;
                flex-direction: row;
                margin-top: 25rem;                
            }
            .grid-row.on-product-page {
                margin-top: 30rem;
            }
            .side {
                flex: 1 0;
            }
            .spacer {
                flex: 0 0 30rem;
            }
            `}</style>
        </>
    );
}