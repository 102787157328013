import { useContext, useEffect } from "react";
import { useSmartState } from "./use_smart_state";
import serverRefs from "../server-refs.json";
import { isSimpleObject } from "../lib/shared/options/snapshot/misc";
import { LayoutStateContext, TLayoutState } from "../contexts/layout_state";
import { NextRequest } from "next/server";
import { infiniteFetch } from "../lib/infinite-fetch";
import { libMockReviews } from "../lib/mock-reviews";

export type ReviewsHeaderProduct = {
    reviewsCount: number;
    starRating: number;
};

export type ReviewsHeaderProductRaw = {
    totalReviews: number;
    totalRating: number;
};

export type ReviewsHeaderRaw = {
    products: {[id: string]: ReviewsHeaderProductRaw};
};

export type ReviewsHeader = {
    products: {[id: string]: ReviewsHeaderProduct};
};

const rawReviewsHeaderProductToReal = ({totalRating,totalReviews}: ReviewsHeaderProductRaw) => {
    const ret: ReviewsHeaderProduct = {
        reviewsCount: totalReviews,
        starRating: (totalReviews>0)?(totalRating/totalReviews):0
    };
    return ret;
};

export const useReviewsHeader = () => {
    const reviewsHeader = useSmartState<ReviewsHeader>(null);

    useEffect(() => {
        (async () => {            
            const url = `${serverRefs.buckets.public}/reviewsummaries/header.json`;
            // const response = await infiniteFetch(url);
            // const header: ReviewsHeaderRaw = (await response.json());
            const header = libMockReviews.genReviewsHeaderRaw();
            // const header: ReviewsHeaderRaw = {
            //     products: {}
            // };
            if (header?.products) {
                const value: ReviewsHeader = {
                    products: {}
                };
                Object.keys(header.products).forEach((productId) => {
                    value.products[productId] = rawReviewsHeaderProductToReal(header.products[productId]);
                });
                reviewsHeader.value = value;
            }
        })();
    },[]);

    return reviewsHeader.value;
};

export const consumeReviewsHeader = (productId: number | string,layout?: TLayoutState) => {
    const { reviewsHeader } = layout?layout:useContext(LayoutStateContext);
    if (reviewsHeader) {
        return reviewsHeader.products[productId]?reviewsHeader.products[productId]:{reviewsCount: 0,starRating: 0};
    }
    else {
        return {reviewsCount: -1,starRating: 0};
    }
};

export const consumeAllReviewsHeader = (layout?: TLayoutState) => {
    const { reviewsHeader } = layout?layout:useContext(LayoutStateContext);
    return reviewsHeader;
};