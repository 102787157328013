export type WindowMessageEvent = {
    type: string;
};

type Subscription = {
    types: string[];
    cb: (event: WindowMessageEvent) => void;
};

class WindowMessages {
    private initialized = false;
    private subscriptions: Subscription[] = []

    initialize() {
        if (this.initialized) return;
        window.onmessage = (e: MessageEvent<WindowMessageEvent>) => {            
            this.subscriptions.forEach(({types,cb}) => {
                if (types.indexOf(e.data?.type)!==-1) {
                    cb(e.data);
                }
            });
        };
        this.initialized = true;
    }

    subscribe<T extends WindowMessageEvent = WindowMessageEvent>(types: string[],cb: (event: T) => void) {
        const subObj = { 
            types,
            cb
        };
        this.subscriptions.push(subObj);
        
        return () => {
            const tmp = this.subscriptions.filter((sub) => sub!==subObj);
            this.subscriptions.length = 0;
            this.subscriptions.push(...tmp);
        };
    }
}

export const libWindowMessages = new WindowMessages();