import { useContext, useEffect, useRef } from "react";
import { useSmartState } from "./use_smart_state";
import serverRefs from "../server-refs.json";
import { isSimpleObject } from "../lib/shared/options/snapshot/misc";
import { LayoutStateContext, TLayoutState } from "../contexts/layout_state";
import { misc } from "../lib/misc";
import { infiniteFetch } from "../lib/infinite-fetch";
import { libMockReviews } from "../lib/mock-reviews";

export type BoughtTogetherClientEntry = {
    id: string;
    weight: number;    
};

export type BoughtTogetherClient = {
    table: {
        [id: string]: BoughtTogetherClientEntry[]
    };
    popular: string[];
};

export const useBoughtTogetherClient = () => {
    const boughtTogether = useSmartState<BoughtTogetherClient>(null);

    useEffect(() => {
        (async () => {            
            // const url = `${serverRefs.buckets.public}/bought_together/client.json`;
            // const response = await infiniteFetch(url);
            // boughtTogether.value = await response.json();
            boughtTogether.value = libMockReviews.genBoughtTogetherClient();
        })();
    },[]);

    return boughtTogether.value;
};

type CachedResult = {
    digest: string;
    result: string[];
};

export const consumeBoughtTogetherClient = (productIds: (number | string)[],layout?: TLayoutState) => {
    productIds = misc.removeDuplicates(productIds);

    const productsTotal = 10;

    const { boughtTogetherClient } = layout?layout:useContext(LayoutStateContext);
    const cachedRef = useRef<CachedResult>(null);

    if (boughtTogetherClient) {
        const digest = productIds.join(",");

        const process = () => {
            const usedHash: {[id: string]: true} = {};
            const weightedHash: {[id: string]: number} = {};
            productIds.forEach((productId) => usedHash[productId] = true);
            productIds.forEach((productId) => {
                if (boughtTogetherClient.table[productId]) {
                    boughtTogetherClient.table[productId].forEach(({id,weight}) => {
                        if (!usedHash[id]) {
                            weightedHash[id] = weightedHash[id] || 0;
                            weightedHash[id]+= weight;
                        }
                    });
                }
            });
            const result = Object.keys(weightedHash);
            result.sort((_a,_b) => {
                const a = weightedHash[_a];
                const b = weightedHash[_b];
                if (a<b) return 1;
                if (a>b) return -1;
                return 0;
            });
            let pnt = 0;
            while ((result.length<productsTotal) && (pnt<boughtTogetherClient.popular.length)) {
                const candidate = boughtTogetherClient.popular[pnt]; pnt++;
                if ((!usedHash[candidate]) && (!weightedHash[candidate])) {
                    result.push(candidate);
                }
            }
            result.length = Math.min(result.length,productsTotal);
    
            const ret: CachedResult = {
                digest,
                result
            };

            return ret;    
        };

        return (cachedRef.current && (cachedRef.current.digest===digest))?cachedRef.current.result:(cachedRef.current = process()).result;
    }
    else {
        return null;
    }
};