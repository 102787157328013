import { useContext, useEffect, useRef } from "react";
import { SmartState, useSmartState } from "./use_smart_state";
import { LayoutStateContext } from "../contexts/layout_state";
import { lstore } from "../lib/localstorage";

export type AccountUserAddress = {
    id: string;
    firstName: string;
    lastName: string;
    address1?: string;
    address2?: string;
    city?: string;
    company?: string;
    country?: string;
    countryCodeV2?: string;
    phone?: string;
    province?: string;
    provinceCode?: string;
    zip?: string;
};

export type AccountUser = {
    addresses: AccountUserAddress[];
    accountNumber: string;
    email: string;
    firstName?: string;
    lastName?: string;    
    phone?: string;
    addressSetupSkipped?: boolean;
};
export type AccountSession = {
    id: string;
    renewKey: string;
};

export type Account = {
    user: AccountUser;
    session: AccountSession;
};

const lstoreKey = "account";
const lstorelastLoginEmailKey = "account.lastLoginEmail";

export const getLastLoginEmail = () => lstore.get(lstorelastLoginEmailKey);

let globalAccountState: SmartState<Account | null | false>;

export const getAccountValue = () => globalAccountState?globalAccountState.value:null;
export const setAccountValue = (value: Account) => {
    if (globalAccountState) {
        globalAccountState.value = {...value};
    }
};

export const useAccount = () => {
    const account = globalAccountState = useSmartState<Account | null | false>(false,{
        onSet: (value) => {
            lstore.set(lstoreKey,value);
            if (value) {
                lstore.set(lstorelastLoginEmailKey,value.user.email);
            }
        }
    });

    useEffect(() => {
        account.value = lstore.get(lstoreKey);
        // console.log("account",lstore.get(lstoreKey));
    },[]);

    return account;
};

export const consumeAccount = (onReady: ((accountValue: Account | null) => void) = () => {}) => {
    const { account } = useContext(LayoutStateContext);
    const isReadyRef = useRef(false);

    useEffect(() => {        
        if ((!isReadyRef.current) && (account.value!==false)) {
            isReadyRef.current = true;
            onReady(account.value);
        }
    },[account.value]);

    return account;
};