import { HTMLAttributes, useContext, useEffect, useRef, useState } from "react";
import { LayoutStateContext } from "../contexts/layout_state";
import { consumeBoughtTogetherClient } from "../hooks/use_bought_together_client";
import { useCssStateManager } from "../hooks/use_css_state_manager";
import { useDesktopScrollbar } from "../hooks/use_desktop_scrollbar";
import { SmartState, useSmartState } from "../hooks/use_smart_state";
import { apiEditOrder } from "../lib/api/editorder";
import { apiSalesSignature } from "../lib/api/sales_signature";
import { misc } from "../lib/misc";
import { prebuildData } from "../lib/prebuild-data";
import { cart, genPackslipAttributeString, infoAttributesToDisplayString, useCart } from "../lib/services/cart/cart";
import { trackTouchMoveDelta } from "../lib/touch_move_tracker";
import BackToTop from "./common/back_to_top";
import CenteredSpinner from "./common/centered-spinner";
import PriceDisplay from "./common/price-display";
import { Image } from "./common/scaled-next-image";
import { EventPane } from "./core/event_pane";
import { Icon } from "./core/icon";
import { PriceSavedDisplay } from "./pages/product/price-update";
import AlsoBought from "./side_cart/also_bought/also_bought";
import ClearCart from "./side_cart/clear_cart";
import SideCartItem from "./side_cart/item";
import SalesBreakdown from "./side_cart/sales_breakdown";
import { consumeAccount } from "../hooks/use_account";
import { miscShared } from "../lib/shared/lib/misc";
import { lstore } from "../lib/localstorage";

type Props = {
    open: boolean;
    closeHandler?: () => void;
    cartController: ReturnType<typeof useCart>;
    onMobile: boolean;
    creatingCheckoutOpen: SmartState<boolean>;
    highlightIds?: string[];
    flashIds?: string[];
};

export default function SideCart({open,closeHandler = () => {},cartController,onMobile,creatingCheckoutOpen,highlightIds = [],flashIds = []}: Props) {            

    const { creatingCheckoutCaptionRef,openSalesNotice } = useContext(LayoutStateContext);
    const emptyCartSvg = misc.createImageDimensions(256,240,110);
    const scrollToTopSvg = misc.createImageDimensions(266,320,20);
    const editNoteSvg = misc.createImageDimensions(256,255,20);

    const account = consumeAccount();

    const cssStateManager = useCssStateManager(["container"]);
    cssStateManager.useProperty(open,"open");    
    cssStateManager.useProperty(onMobile,"mobile");  
    cssStateManager.useProperty(!!cart.editOrder,"is-editing");  
    cssStateManager.useProperty(cart.editOrder?.orderType==="draft","is-editing-draft");  
    const salesInfoOpen = cssStateManager.useState(false,"sales-info-open");
    const saveDisabled = cssStateManager.useState(false,"save-disabled");

    const showSubtotalToast = cssStateManager.useState(false,"show-subtotal-toast",{timedMilliseconds: 4000});
    const showNoteSavedToast = cssStateManager.useState(false,"show-notesaved-toast",{timedMilliseconds: 3000});    
    const forceHideToasts = cssStateManager.useState(false,"force-hide-toasts");    
    const showScrollToTop = cssStateManager.useState(false,"show-scroll-to-top");
    const isCheckoutSticky = cssStateManager.useState(false,"is-checkout-sticky");    
    const noteOpened = cssStateManager.useState(false,"note-opened");        
    const mobileHideScrollbar = cssStateManager.useState(true,"mobile-hide-scrollbar");        

    const confirmClearCart = useSmartState(false);

    const clearSalesInfo = () => {
        salesInfoOpen.value = false;
    };

    const clearConfirmClearCart = () => {
        confirmClearCart.value = false;
    };

    const clearConfirmClearCartAndSalesInfo = () => {
        clearConfirmClearCart();
        clearSalesInfo();
        return true;
    };

    const mobileCloseMechanism = {        
        openTopGap: 240, 
        closedtopGap: 0,
        initialVisibleTopGap: 60,        
        closeThreshold: 10
    };

    mobileCloseMechanism.closedtopGap = mobileCloseMechanism.openTopGap-mobileCloseMechanism.initialVisibleTopGap;

    const { 
        items,
        version,
        note,
        updateItemQuantity,
        minusItemQuantity,
        plusItemQuantity,
        removeItem,
        itemQuantityBlur,
        getItemCount,
        clearCart,
        getNonDeletedRealItemCount,
        getSubtotal,
        getUndiscountedSubtotal,
        updateNote,
        checkout,
        getCheckoutData } = cartController;

    const itemsWrapperHeight = useSmartState(-1);
    const clearingCartRef = useRef(false);
    const clearingCart = useSmartState(false);

    const finalizeClearCartAnimation = () => {
        clearingCart.value = true;
    };

    const startClearCartAnimation = () => {
        if (getScrollTop()>0) {
            clearingCartRef.current = true;
            scrollerRef.current?.scrollTo({top: 0,behavior: "smooth"});
        }
        else {
            finalizeClearCartAnimation();
        }        
    };

    const editPriceDifference = useSmartState(0);
    const editNotifyCustomer = useSmartState(true);

    useEffect(() => {        
        if (cart.editOrder) {            
            saveDisabled.value = (cart.items.length===0) || !cart.itemsDifferFromEditOrder();
            editPriceDifference.value = getSubtotal().subTotal-cart.editOrder.priceCents;            
        }
    },[version]);

    useEffect(() => {
        if (itemsWrapperHeight.value>0) {
            setTimeout(() => {
                itemsWrapperHeight.value = 0;
            },20);
        }
    },[itemsWrapperHeight.value]);

    const itemsRef = useRef<HTMLDivElement>();

    const itemsTransitionEndHandler = (target: HTMLDivElement,propertyName: string) => {
        if ((target===itemsRef.current) && (propertyName==="opacity") && (clearingCart.value)) {
            clearCart();
            clearingCart.value = false;
        }
    };

    // cssStateManager.useProperty(itemsWrapperHeight.value===0,"items-wrapper-fade-out");

    // const itemCount = (itemsWrapperHeight.value===0)?0:getItemCount();
    // const nonDeletedRealItemCount = (itemsWrapperHeight.value===0)?0:getNonDeletedRealItemCount();
    // const subTotal = (itemsWrapperHeight.value===0)?0:getSubtotal().subTotal;

    const itemCount = (clearingCart.value)?0:getItemCount();
    const nonDeletedRealItemCount = (clearingCart.value)?0:getNonDeletedRealItemCount();
    const subTotalRes = getSubtotal();
    const subTotal = (clearingCart.value)?0:subTotalRes.subTotal;
    const { discountBreakdown } = subTotalRes;
    const undiscountedSubTotal = (clearingCart.value)?0:getUndiscountedSubtotal();
    
    cssStateManager.useProperty(nonDeletedRealItemCount>0,"cart-has-items");    
    cssStateManager.useProperty(nonDeletedRealItemCount>=2,"at-least-two-real-items");
    const lastKnownSubTotalRef = useRef(subTotal);
    const sliderRef = useRef<HTMLDivElement>();
    const { ref: scrollerRef,styles: sliderInnerStyles,className: sliderInnerClassName,injectedStyles: sliderInnerInjectedStyles } = useDesktopScrollbar();
    const checkoutWrapperRef = useRef<HTMLDivElement>();
    const { ref: noteTextAreaRef,className: noteTextClassName,styles: noteTextStyles,injectedStyles: noteTextInjectedStyles } = useDesktopScrollbar<HTMLTextAreaElement>();
    const noteBoxRealOpenedRef = useRef(false);
    const mobileTopGapRef = useRef<HTMLDivElement>();

    const checkoutClickHandler = async () => {
        alert("Demo: this feature has been disabled");
        return;
        if (!lstore.get("bypass-checkout-block")?.set) {
            alert("Checkout on the beta site is currently disabled - we are working on some crucial bug fixes. Please visit www.tbdliquids.com to use the live site.");
            return;    
        }
        
        if (!backIsReadyRef.current) return;
        creatingCheckoutOpen.value = true;
        closeHandler();
        const timestamp = (new Date()).getTime();
        const signatureState = await apiSalesSignature.verifySignature()
        if (signatureState==="matches") {
            const url = await checkout();
            cart.accountShopifyIntegrationStore(url,account.value);
            const timespan = (new Date()).getTime()-timestamp;
            const atLeast = 400;
            // console.log("url",url);
            setTimeout(() => {
                if (url) {
                    window.location.href = url;
                }
                else {
                    console.log("error occurred");
                    alert("An error occurred while attempting to checkout - make sure you are connected to the internet, refresh the page and try again.");
                }    
            },Math.max(0,atLeast-timespan));    
        }
        else {
            const ending = "Note that the contents/subtotal of your cart may or may not have changed, so please make sure to review them before checking out again. To proceed click the 'Continue' button below.";
            const messageTable = {
                "new-snapshot": `A new version of our website is available - it must be reloaded before you can checkout. ${ending}`,
                "new-sale": `A new sale has gone live while you were shopping! The page must be reloaded before you can checkout. ${ending}`,
                "sale-ended": `Unfortunately the current sale ended while you were shopping. The page must be reloaded before you can checkout. ${ending}`,
            };  
            openSalesNotice(messageTable[signatureState],true);
        }
    };    

    const isTouchAvailable = () => (("ontouchstart" in window) || (navigator.maxTouchPoints>0) || ((navigator as any).msMaxTouchPoints>0));

    const checkoutDisabled = (itemCount===0);
    const { dollar,cents } = misc.splitPriceForDisplay(subTotal);
    const { dollar: udollar,cents: ucents } = misc.splitPriceForDisplay(undiscountedSubTotal);    

    const subtotalToastScrollThreshold = 47;
    const scrollToTopScrollThreshold = 300;

    const getScrollTop = () => (scrollerRef.current)?scrollerRef.current.scrollTop:0;    
    const setScrollTop = (value: number) => {
        if (scrollerRef.current) {
            scrollerRef.current.scrollTop = value;
        }
    }    
    const scrollToTop = () => {
        if (scrollerRef.current) {
            scrollerRef.current.scrollTo({top: 0,behavior: "smooth"});
        }
    };

    const mobileTopGapOpenedRef = useRef(false);

    const mobileScrollbarJumpingRef = useRef(false);

    const openMobileTopGap = () => {
        const { current: mobileTopGap } = mobileTopGapRef;
        const { current: scroller } = scrollerRef;
        if ((!mobileTopGapOpenedRef.current) &&  (mobileTopGap) && (scroller)) {
            mobileTopGapOpenedRef.current = true;
            mobileHideScrollbar.value = true;
            mobileScrollbarJumpingRef.current = true;
            setTimeout(() => {
                mobileScrollbarJumpingRef.current = false;
            },50);
            const scrollTopBefore = scroller.scrollTop;
            mobileTopGap.style.height = `${mobileCloseMechanism.openTopGap}rem`;
            scroller.scrollTop = scrollTopBefore+mobileCloseMechanism.closedtopGap;
        }
    };

    const closeMobileTopGap = () => {
        const { current: mobileTopGap } = mobileTopGapRef;
        const { current: scroller } = scrollerRef;
        if ((mobileTopGapOpenedRef.current) &&  (mobileTopGap) && (scroller)) {
            mobileTopGapOpenedRef.current = false;
            mobileHideScrollbar.value = true;
            mobileScrollbarJumpingRef.current = true;
            setTimeout(() => {
                mobileScrollbarJumpingRef.current = false;
            },50);
            const scrollTopBefore = scroller.scrollTop;
            mobileTopGap.style.height = `${mobileCloseMechanism.initialVisibleTopGap}rem`;
            scroller.scrollTop = scrollTopBefore-(mobileCloseMechanism.closedtopGap);            
        }
    };

    useEffect(() => {
        if (lastKnownSubTotalRef.current!==subTotal) {
            if (getScrollTop()>=subtotalToastScrollThreshold) {
                showSubtotalToast.value = true;
                showNoteSavedToast.value = false;
            }
        }
        lastKnownSubTotalRef.current = subTotal;
    },[subTotal]);

    useEffect(() => {
        if (open) {                    
            hideAllToasts();
            if (onMobile) {
                if (isTouchAvailable()) {                    
                    closeMobileTopGap();
                    // setScrollTop(0);                    
                    if ((flashIds.length===1) && (highlightIds.length===0)) {
                        const flashId = flashIds[0];
                        if (sliderRef.current) {                            
                            const itemElem = sliderRef.current.querySelector(`.${flashId}`) as HTMLDivElement;
                            if (itemElem) {
                                const isFirst = cart.items[0].uuid===flashId;
                                const midPoint = itemElem.offsetTop+(itemElem.offsetHeight/2);
                                const delta = midPoint-(sliderRef.current.offsetHeight/2);
                                setScrollTop(isFirst?0:delta);
                            }
                        }
                    }
                    else {
                        setScrollTop(0);
                    }                    
                }
                else {
                    openMobileTopGap();
                    setScrollTop(mobileCloseMechanism.closedtopGap);
                }
                document.body.classList.add("disable-pull-to-refresh");
            }
            else {
                if ((flashIds.length===1) && (highlightIds.length===0)) {
                    const flashId = flashIds[0];
                    if (sliderRef.current) {
                        const itemElem = sliderRef.current.querySelector(`.${flashId}`) as HTMLDivElement;
                        if (itemElem) {
                            const midPoint = itemElem.offsetTop+(itemElem.offsetHeight/2);
                            const delta = midPoint-(sliderRef.current.offsetHeight/2);
                            setScrollTop(delta);
                        }
                    }
                }
                else {
                    setScrollTop(0);
                }                
            }            
        }
        else {
            clearConfirmClearCartAndSalesInfo();
            if (onMobile) {
                document.body.classList.remove("disable-pull-to-refresh");
            }            
        }
    },[open]);

    useEffect(() => {
    },[mobileHideScrollbar.value]);

    useEffect(() => {
        (window as unknown as any).globalCart = cart;
        if ((onMobile) && (scrollerRef.current)) {
            return trackTouchMoveDelta(scrollerRef.current,() => {
            },{
                onStart: () => {
                    openMobileTopGap();
                },onEnd: () => {
                    const limit = mobileCloseMechanism.closedtopGap;
                    if (Math.round(scrollerRef.current.scrollTop)<limit) {
                        closeHandler();
                    }                
                    else {
                        closeMobileTopGap();
                    }
                }
            });
        }
        // if ((onMobile) && (!isTouchAvailable()))
        // {
        //     noTouch.value = !isTouchAvailable();
        // }
    },[]);

    useEffect(() => {
        if ((sliderRef.current) && (checkoutWrapperRef.current) && (IntersectionObserver)) {
            const observer = new IntersectionObserver( 
                ([e]) => isCheckoutSticky.value = e.intersectionRatio<1,
                {
                    root: sliderRef.current,
                    threshold: [1]
                }
            );
            observer.observe(checkoutWrapperRef.current);
            return () => observer.disconnect();
        }
    },[]);

    const handleScrollToTop = () => {
        scrollToTop();
    };

    const handleEditNote = () => {
        if (!noteBoxRealOpenedRef.current) {
            clearConfirmClearCartAndSalesInfo();
            noteOpened.value = true;
        }
    };

    const [noteSaveSwitch,setNoteSaveSwitch] = useState(0);
    const noteSaveSwitchRef = useRef(noteSaveSwitch);
    noteSaveSwitchRef.current = noteSaveSwitch;

    const noteBoxTransitionEndHandler = (target: EventTarget,propertyName: string) => {
        if (propertyName==="height") {
            if (noteOpened.value) {
                if (noteTextAreaRef.current) {
                    noteTextAreaRef.current.focus();
                }            
            }    
            noteBoxRealOpenedRef.current = noteOpened.value;
        }
    };

    useEffect(() => {
        if (noteOpened.value) {
            if (noteTextAreaRef.current) {
                noteTextAreaRef.current.setSelectionRange(0,0);
                noteTextAreaRef.current.scrollTop = 0;
            }            
            setNoteText(note);
        }
    },[noteOpened.value]);

    const [noteText,setNoteText] = useState(note);

    const hideAllToasts = () => {
        forceHideToasts.value = true;
        showNoteSavedToast.value = false;
        showSubtotalToast.value = false;
        setTimeout(() => {
            forceHideToasts.value = false;
        },500);
    };

    const saveNoteHandler = () => {
        noteOpened.value = false;
        if (updateNote(noteText)) {            
            showNoteSavedToast.value = true;
            showSubtotalToast.value = false;
        }
    };

    const scrollHandler = () => {
        if ((scrollerRef.current.scrollTop===0) && (clearingCartRef.current)) {
            clearingCartRef.current = false;
            finalizeClearCartAnimation();
        }
        if (scrollerRef.current) {
            if ((!onMobile) || (!mobileScrollbarJumpingRef.current)) {
                showScrollToTop.value = scrollerRef.current.scrollTop>=scrollToTopScrollThreshold;
            }
        }           
        if ((showNoteSavedToast.value) && ((noteOpened.value) || (!noteBoxRealOpenedRef.current))) {
            showNoteSavedToast.value = false;
        }        
        if (!mobileScrollbarJumpingRef.current) {
            showSubtotalToast.value = false;
        }
    };

    const sliderWheelHandler: HTMLAttributes<HTMLDivElement>["onWheel"] = (e) => {
        if ((scrollerRef.current) && (onMobile) && (!isTouchAvailable())) {
            const newScrollTop = Math.round(scrollerRef.current.scrollTop+e.deltaY);
            const limit = mobileCloseMechanism.closedtopGap-55;
            if (newScrollTop<limit) {
                closeHandler();
            }                
        }        
    };

    const alsoBoughtExists = useSmartState(false);
    const alsoBoughtShows = useSmartState(false);

    const lastNonDeletedRealItemCountCountRef = useRef(nonDeletedRealItemCount);

    const alsoBoughtHiddenHandler = () => {
        alsoBoughtExists.value = false;
    };

    useEffect(() => {
        if (nonDeletedRealItemCount!==lastNonDeletedRealItemCountCountRef.current) {
            if ((lastNonDeletedRealItemCountCountRef.current===0) && (nonDeletedRealItemCount>0)) {
                alsoBoughtShows.value = true;
                alsoBoughtExists.value = true;
            }
            else if ((lastNonDeletedRealItemCountCountRef.current>0) && (nonDeletedRealItemCount===0)) {
                alsoBoughtShows.value = false;
            }
            lastNonDeletedRealItemCountCountRef.current = nonDeletedRealItemCount;
        }
    },[nonDeletedRealItemCount]);    

    const clearCartHandler = () => {
        startClearCartAnimation();
    };    

    const backIsReadyRef = useRef(false);

    const backClickHandler = () => {
        if (backIsReadyRef.current) closeHandler();
    };

    const backRef = useRef<HTMLDivElement>();

    const backTransitionEndHandler = (target: HTMLDivElement,propertyName: string) => {
        if ((target===backRef.current) && (propertyName==="opacity")) {
            backIsReadyRef.current = open;
        }
    };

    const editCancelClickHandler = () => {
        if (cart.editOrder) {
            top.location = cart.editOrder.orderReturnUrl;
        }        
    };
    const editSaveClickHandler = async () => {
        if (!backIsReadyRef.current) return;
        if (!cart.editOrder) return;
        creatingCheckoutCaptionRef.current = "Saving Order";
        creatingCheckoutOpen.value = true;
        closeHandler();
        const centVariantId = prebuildData.getCentVariantId();
        const checkoutData = getCheckoutData();
        const display = infoAttributesToDisplayString(checkoutData.infoAttributes);
        const ps = genPackslipAttributeString(cart.items);        
        try {
            await apiEditOrder.saveOrder(cart.editOrder.id,checkoutData,display,ps,cart.editOrder.snapshotId,centVariantId,editNotifyCustomer.value,cart.note);
            top.location = cart.editOrder.orderReturnUrl;
        }
        catch(err) {
            alert(`error: ${err.message}`);
        }        
    };

    const frequentlyBoughtTogetherProductIds = consumeBoughtTogetherClient(items.map(({productId}) => productId));

    const salesInfoClickHandler = () => {
        if (salesInfoOpen.value) {
            clearConfirmClearCartAndSalesInfo();
        }
        else {
            clearConfirmClearCart();
            salesInfoOpen.value = true;    
        }
    };

    const salesBreakdownCloseHandler = () => {
        clearConfirmClearCartAndSalesInfo();
    };

    const { 
        cart: {
            lineColor,
            checkoutButtonColor,
            checkoutButtonTextColor,
            checkoutButtonDisabledColor,
            isEmptyTextColor,
            itemBackgroundColor,
            infoToastColor,
            infoToastTextColor,
            editNoteButtonTextIconColor,
            editNoteButtonTextIconHoverColor
        }
    } = prebuildData.getActiveTheme();

    return (
        <>
            <div className={cssStateManager.getClassNames()}>
                <div ref={backRef} className="back" onTransitionEnd={(e) => backTransitionEndHandler(e.target as HTMLDivElement,e.propertyName)}>
                    <div className="cross">
                        <Icon name="navbar-cross" size={20} />
                    </div>
                    <EventPane zIndex={0} onClick={backClickHandler} />
                </div>
                <div className="slider" ref={sliderRef} >
                    <div 
                        className={`slider-inner ${sliderInnerClassName}`} 
                        ref={scrollerRef} 
                        onScroll={scrollHandler} 
                        onWheel={sliderWheelHandler}
                        style={sliderInnerStyles}
                    >
                        <div className="mobile-top-gap" ref={mobileTopGapRef} onClick={backClickHandler}></div>
                        <div className="slider-back">
                            <div className="subtotal">
                                <div className="notch"></div>
                                <span className="subtotal-components">
                                    Subtotal <span className="price"><span className="superscript">$</span>{dollar}<span className="superscript">{cents}</span></span>
                                </span>
                                {(subTotal!==undiscountedSubTotal)?(<>
                                    <span className="subtotal-discount-amount">
                                        <PriceSavedDisplay priceDiff={subTotal-undiscountedSubTotal} />
                                    </span>
                                    <span className="subtotal-discount-info">
                                        <i className="icon-info" />
                                        <EventPane onClick={salesInfoClickHandler} hoverOnTouchDown />
                                    </span>
                                </>):""}
                            </div>
                            {(subTotal!==undiscountedSubTotal)?(
                                <div className="sales-breakdown">
                                    <SalesBreakdown discountBreakdown={discountBreakdown} open={salesInfoOpen.value} onClose={salesBreakdownCloseHandler} />
                                </div>
                            ):""}                            
                            <div className="checkout-wrapper" ref={checkoutWrapperRef}>
                                <div className={`checkout-button ${checkoutDisabled?"disabled":""}`}>
                                    Proceed to checkout ({itemCount} item{(itemCount!==1)?"s":""})
                                    <EventPane zIndex={0} onClick={() => checkoutClickHandler()} disabled={checkoutDisabled} />
                                </div>
                                <div className="editing-wrapper">
                                    <div className="button cancel">
                                        Cancel
                                        <EventPane zIndex={0} onClick={() => editCancelClickHandler()} />
                                    </div>
                                    <div className="spacer"></div>
                                    <div className="button save">
                                        {cart.editOrderIsCreate?"Create Order":`Save${(cart.editOrder?.orderType==="draft")?" Draft":""}`}
                                        <EventPane zIndex={0} onClick={() => editSaveClickHandler()} disabled={saveDisabled.value} />
                                    </div>
                                </div>
                                <div className="checkout-flap"></div>
                            </div>
                            <div className="empty-cart-wrapper">
                                <div className="empty-cart">
                                    <div className="image-wrapper">
                                        <Image src={prebuildData.bustCache("/images/side_cart/empty_cart.svg")} width={emptyCartSvg.width} height={emptyCartSvg.height} />
                                    </div>
                                    <div className="cart-is-empty">
                                        <span>Your cart is empty</span>
                                    </div>
                                </div>
                            </div>
                            <div className="edit-options-wrapper">
                                <div className="amount-wrapper">
                                    Difference: 
                                    <PriceDisplay price={editPriceDifference.value} size={23} />
                                </div>
                                <div className="checkbox-wrapper">
                                    <input type="checkbox" checked={editNotifyCustomer.value} onChange={() => {}} />
                                    <span>Send notification to customer</span>
                                    <EventPane  onClick={() => editNotifyCustomer.value = !editNotifyCustomer.value} />
                                </div>
                            </div>
                            <div className="note-group">
                                <div className="edit-note">
                                    <div className="edit-note-button">
                                        <div className="edit-note-image-wrapper" style={{
                                            width: `${editNoteSvg.width}rem`,
                                            height: `${editNoteSvg.height}rem`
                                        }}>
                                            <Image src={prebuildData.bustCache("/images/side_cart/edit_note.svg")} layout="fill" />
                                            <Image className="highlighted" src={prebuildData.bustCache("/images/side_cart/edit_note.svg")} layout="fill" />
                                        </div>
                                        <div className="include-a-note">
                                            {noteOpened.value?(cart.editOrder?"Hide staff note":"Hide note"):((note!=="")?(cart.editOrder?"Edit staff note":"Edit note"):(cart.editOrder?"Include a staff note":"Include a note"))}
                                        </div>
                                        <EventPane onClick={handleEditNote} />
                                    </div>
                                </div>
                                <div className="note-box" onTransitionEnd={(e) => noteBoxTransitionEndHandler(e.target,e.propertyName)}>
                                    <div className="note-container">
                                        <textarea 
                                            className={`note-text ${noteTextClassName}`} 
                                            ref={noteTextAreaRef} 
                                            onBlur={saveNoteHandler}
                                            value={noteText}
                                            onChange={(e) => setNoteText(e.target.value)}
                                            style={noteTextStyles}
                                        >
                                        </textarea>
                                    </div>
                                    <div className="note-top-line"></div>
                                </div>
                            </div>
                            <div className="items-wrapper">
                                <div className="items"
                                    ref={itemsRef}
                                    onTransitionEnd={(e) => itemsTransitionEndHandler(e.target as HTMLDivElement,e.propertyName)}
                                >                                
                                    {items.map((item) => <SideCartItem
                                        key={item.id}
                                        item={item}
                                        onMobile={onMobile}
                                        updateQuantity={(quantityDisplay) => clearConfirmClearCartAndSalesInfo() && updateItemQuantity(item.id,quantityDisplay)} 
                                        minusQuantity={() => {
                                            clearConfirmClearCartAndSalesInfo() && minusItemQuantity(item.id);
                                        }} 
                                        plusQuantity={() => clearConfirmClearCartAndSalesInfo() && plusItemQuantity(item.id)} 
                                        remove={() => clearConfirmClearCartAndSalesInfo() && removeItem(item.id)}
                                        quantityBlur={() => clearConfirmClearCartAndSalesInfo() && itemQuantityBlur(item.id)}
                                        hidden={highlightIds.includes(item.id)}
                                        flashit={flashIds.includes(item.id)}
                                        deleted={!!item.deleted}
                                        closeSideCartHandler={closeHandler}
                                    />)}
                                </div>
                            </div>
                            <div className="clear_cart">
                                <ClearCart 
                                    onClear={clearCartHandler} 
                                    confirm={confirmClearCart.value}
                                    setConfirm={(value) => {
                                        clearSalesInfo();
                                        confirmClearCart.value = value;
                                    }}
                                />
                            </div>
                            {/*}
                            <div className="add-more-items">
                                <div className="add-more-items-button">                                
                                    Add More Items
                                    <EventPane touchDownOnMouseDown />
                                </div>
                            </div>
                            {*/}
                            
                            {alsoBoughtExists.value?(
                                frequentlyBoughtTogetherProductIds?(
                                    <div className="also-bought">
                                        <AlsoBought closeSideCartHandler={closeHandler} itemCount={items.length} show={alsoBoughtShows.value} onHidden={alsoBoughtHiddenHandler} productIds={frequentlyBoughtTogetherProductIds} />
                                    </div>
                                ):<CenteredSpinner />
                            ):""}                            
                        </div>
                    </div>
                    <div className="subtotal-toast">Subtotal <span className="price"><span className="superscript">$</span>{dollar}<span className="superscript">{cents}</span></span></div>
                    <div className="notesaved-toast">Note Saved</div>
                    <BackToTop 
                        show={showScrollToTop.value}
                        handleScrollToTop={handleScrollToTop}
                    />
                </div>
            </div>
            <style jsx>{sliderInnerInjectedStyles}</style>
            <style jsx>{noteTextInjectedStyles}</style>
            <style jsx>{`
                .container {
                    --line-color: ${lineColor};
                    position: fixed;
                    left: 0rem;
                    top: 0rem;
                    right: 0rem;
                    bottom: 0rem;
                    z-index: 1000;
                    visibility: hidden;
                    --duration: 0.3s;
                    transition: visibility var(--duration);
                    --extra-desktop-padding-hori: 5rem;
                }
                .sales-breakdown {
                    position: relative;
                    top: 12rem;
                    display: block;
                    z-index: 100;
                }
                .container.mobile {
                    --duration: 0.6s;
                    --extra-desktop-padding-hori: 0rem;
                }

                .edit-options-wrapper {
                    border-top: 1rem solid #ddd;
                    border-bottom: 1rem solid #ddd;
                    margin-top: 15rem;
                    margin-bottom: 0rem;
                    padding: 20rem 20rem;
                    padding-bottom: 25rem;
                    font-size: 16rem;
                    color: #444;
                    display: none;
                }

                .is-editing .edit-options-wrapper {
                    display: block;
                }

                .is-editing.is-editing-draft .edit-options-wrapper {
                    display: none;
                }

                .edit-options-wrapper .amount-wrapper {
                    display: flex;
                    justify-content: space-between;
                    line-height: 30rem;
                }

                .edit-options-wrapper .checkbox-wrapper {
                    margin-top: 10rem;
                    position: relative;
                }

                .edit-options-wrapper .checkbox-wrapper span {
                    padding-left: 5rem;
                    font-size: 15rem;
                    position: relative;
                    top: -1rem;
                }

                .also-bought {
                    padding: 0rem var(--extra-desktop-padding-hori);
                }

                .clear_cart {
                    --duration: 0.3s;
                    position: relative;
                    top: -20rem;
                    height: 0rem;
                    opacity: 0;
                    overflow: hidden;
                    transition: opacity var(--duration),height var(--duration);
                }

                .at-least-two-real-items .clear_cart {
                    height: 45rem;
                    opacity: 1;
                    transition-duration: 0s;
                }

                .mobile .mobile-top-gap {
                    width: 100%;
                    height: ${mobileCloseMechanism.initialVisibleTopGap}rem;
                }

                .container.open {
                    visibility: visible;
                }

                .back {                    
                    position: absolute;
                    left: 0rem;
                    top: 0rem;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0,0,0,.5);
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity var(--duration),visibility var(--duration);
                }

                .back .cross {
                    position: absolute;
                    left: 29rem;
                    top: 45rem;
                    color: #FFFFFF;                
                    display: none; /* removed for now */
                }
    
                .container.open .back {
                    opacity: 1;
                    visibility: visible;
                }

                .slider {
                    position: absolute;
                    right: 0rem;
                    width: 480rem;
                    top: 0rem;
                    bottom: 0rem;                    
                    transform: translateX(100%);                    
                    transition: transform var(--duration);
                    --standard-hori-padding: calc(15rem + var(--extra-desktop-padding-hori));
                }
                .slider-back {
                    /* background-color: var(--color-collection-menu); */
                    background-color: var(--body-background-color);
                    padding-bottom: 1rem;
                    min-height: 100%;                    
                }
                .mobile .slider {
                    width: 100%;
                    transform: translateY(100%);
                }
                .slider-inner {
                    position: absolute;
                    left: 0rem;
                    right: 0rem;
                    top: 0rem;
                    bottom: 0rem;
                    overflow-y: auto;                    
                }

                .container.open .slider {
                    transform: translateX(0rem);
                }
                
                .container.mobile.open .slider {
                    transform: translateY(0rem);
                }

                .slider .subtotal {        
                    position: relative;
                    padding: 0rem var(--standard-hori-padding);
                    font-size: 22rem;
                    padding-top: 20rem;
                    margin-bottom: -7rem;                    
                }

                .slider .subtotal .price {
                    font-weight: 600;
                    font-size: 25rem;
                }

                .slider .subtotal .superscript {
                    font-size: 0.6em;
                    position: relative;
                    top: -0.4666em;
                }

                .checkout-wrapper {
                    padding: 0rem var(--standard-hori-padding);
                    padding-top: 18rem;
                    padding-bottom: 0rem;
                    position: sticky;
                    top: -1rem;
                    z-index: 10;
                    /* background-color: var(--color-collection-menu); */
                    background-color: var(--body-background-color);
                }
                .container.is-checkout-sticky .checkout-wrapper {
                    /* border-bottom: 1rem solid var(--line-color); */
                }
                .checkout-flap {
                    position: absolute;
                    height: 17rem;
                    bottom: -17rem;
                    left: 0rem;
                    width: 100%;
                    border-bottom: 1rem solid var(--line-color);
                    background-color: var(--body-background-color);
                    display: none;
                }
                .container.is-checkout-sticky .checkout-flap {
                    display: block;
                }

                .is-editing .checkout-button {
                    display: none;
                }

                .checkout-button,.add-more-items-button,.button {
                    height: 48rem;
                    line-height: 48rem;                    
                    color: ${checkoutButtonTextColor};
                    text-align: center;
                    border-radius: 4rem;
                    position: relative;
                    transition: opacity 0.3s;
                }
                .editing-wrapper {
                    display: none;
                    width: 100%;
                }

                .editing-wrapper .button {
                    flex: 1;
                }

                .is-editing .editing-wrapper {
                    display: flex;
                }

                .editing-wrapper .spacer {
                    flex: 0 0 8rem;
                }

                .button {
                    background-color: #3387db;
                }
                .button.cancel {
                    background-color: #404040;
                }
                .checkout-button {
                    background-color: ${checkoutButtonColor};
                }                

                .checkout-button.hover,.checkout-button.touchdown,.button.hover,.button.touchdown {
                    opacity: 0.8;
                }

                .subtotal-discount-amount {
                    position: relative;
                    top: -3rem;
                    left: 10rem;
                    z-index: 100;
                }

                .subtotal-discount-info {
                    position: relative;
                    top: 0rem;
                    left: 12rem;
                    z-index: 100;
                    color: #999;
                    padding: 5rem 10rem;
                    transition: color 0.3s;
                }

                .subtotal-discount-info.hover {
                    color: #777;
                }

                .sales-info-open .subtotal-discount-info {
                    color: #555;
                }

                .sales-info-open .subtotal-discount-info.hover {
                    color: #777;
                }

                .checkout-button.disabled,.save-disabled .button.save {
                    background-color: ${checkoutButtonDisabledColor};
                }

                .empty-cart-wrapper {
                    position: relative;
                    width: 100%;
                    height: 0rem;
                }

                .empty-cart {          
                    position: absolute;          
                    left: 0rem;
                    top: 0rem;
                    width: 100%;
                    padding-top: 45rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;                
                    opacity: 1;
                    transition: opacity 1.7s;
                }     
                .is-editing .empty-cart {
                    top: 125rem;
                }
                .is-editing.is-editing-draft .empty-cart {
                    top: 0rem;
                }
                .cart-has-items .empty-cart {
                    opacity: 0;              
                    transition-duration: 0s;      
                }

                .empty-cart .image-wrapper {

                }

                .cart-is-empty {
                    padding-top: 18rem;                
                }

                .cart-is-empty span {
                    font-weight: 600;
                    font-size: 19rem;
                    color: ${isEmptyTextColor};
                }

                div.container .hide {
                    display: none;
                }
                .items-wrapper {
                    --duration: 0.3s;
                    overflow: hidden;                    
                    opacity: 1;
                }
                .items-wrapper-fade-out .items-wrapper {
                    opacity: 0;
                    transition: height var(--duration),opacity var(--duration);
                }
                .items {
                    border-bottom: 1rem solid var(--line-color);
                    padding-top: 2rem;
                    padding-bottom: 2rem;
                    border-top: 1rem solid var(--line-color);
                    margin-bottom: 20rem;                
                    opacity: 0;
                    transition: opacity 0.3s;
                    background-color: ${itemBackgroundColor};
                }
                .cart-has-items .items {
                    opacity: 1;
                    transition-duration: 0s;
                }
                .subtotal-toast,.notesaved-toast {
                    position: absolute;
                    bottom: 31rem;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: ${infoToastColor};
                    border-radius: 20rem;
                    color: ${infoToastTextColor};
                    padding: 9rem 25rem;
                    font-size: 18rem;
                    padding-bottom: 11rem;            
                    --duration: 0.5s;    
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity var(--duration),visibility var(--duration);
                    white-space: nowrap;
                }
                .subtotal-toast .price {
                    font-weight: 600;
                }
                .subtotal-toast .price .superscript {                    
                    font-size: 10rem;
                    position: relative;
                    top: -5rem;
                }
                .container.show-subtotal-toast .subtotal-toast,.container.show-notesaved-toast .notesaved-toast {
                    opacity: 1;
                    visibility: visible;
                }

                .container.force-hide-toasts .subtotal-toast,.container.force-hide-toasts .notesaved-toast {
                    transition: opacity 0s,visibility 0s;
                }

                .scroll-to-top {
                    --size: 40rem;
                    --duration: 0.3s;
                    position: absolute;
                    bottom: 33rem;
                    right: 31rem;
                    width: var(--size);
                    height: var(--size);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: var(--size);
                    background-color: rgba(0,0,0,0.25);              
                    opacity: 0;
                    visibility: hidden;
                    transition: background-color var(--duration),opacity var(--duration),visibility var(--duration);
                }

                .scroll-to-top.hover,.scroll-to-top.touchdown {
                    background-color: rgba(0,0,0,0.4);                
                }
                .container.show-scroll-to-top .scroll-to-top {
                    opacity: 1;
                    visibility: visible;                                    
                }

                .note-group {
                    opacity: 0;
                    transition: opacity 0.5s;
                }
                .cart-has-items .note-group {
                    opacity: 1;
                    transition-duration: 0s;
                }

                .is-editing.is-editing-draft .note-group {
                    height: 0rem;
                    overflow: hidden;
                    margin-bottom: 27rem;
                }

                .edit-note {                    
                    margin-top: -5rem;
                    padding: 0rem 15rem;
                    padding-bottom: 4rem;
                    padding-top: 9rem;
                }
                .edit-note-button {
                    --duration: 0.3s;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    padding: 7rem 0rem;
                    border-radius: 3rem;
                }
                .edit-note-button.hover .include-a-note,.edit-note-button.touchdown .include-a-note {
                    color: ${editNoteButtonTextIconHoverColor};
                }                

                .edit-note-button img:global(.highlighted) {
                    filter: invert(78%) sepia(38%) saturate(4474%) hue-rotate(182deg) brightness(99%) contrast(106%);
                    opacity: 0;
                    transition: opacity var(--duration);
                }

                .edit-note-button.hover img:global(.highlighted),.edit-note-button.touchdown img:global(.highlighted) {
                    opacity: 1;
                }
                
                .edit-note-image-wrapper {
                    position: relative;
                }
                .include-a-note {
                    padding-left: 8rem;
                    color: ${editNoteButtonTextIconColor};
                    padding-right: 25rem;
                    transition: color var(--duration);
                }
                .note-box {
                    --textbox-height: 143rem;
                    height: 0rem;
                    transition: height 0.3s;
                    height: 0rem;
                    background-color: #fff;    
                    transition: height 0.5s;
                    overflow: hidden;                    
                    position: relative;
                }

                .container.note-opened .note-box {
                    height: var(--textbox-height);                    
                }
                .note-container {
                    padding: 0rem;
                }
                .note-text {
                    width: 100%;
                    height: var(--textbox-height);
                    resize: none;
                    padding: 24rem;                    
                    outline: none;
                    color: #666;
                    line-height: 19rem;
                    border-radius: 3rem;
                    border: none;                    
                    background-color: transparent;
                    box-shadow: 0 1rem 4rem #0000000f inset;
                }
                .note-top-line {
                    position: absolute;
                    left: 0rem;
                    top: 0rem;
                    width: 100%;
                    height: 1rem;
                    background-color: var(--line-color);                    
                }

                .add-more-items {
                    padding: 0rem 40rem;
                }
                .container .add-more-items-button {
                    background-color: #242424;
                    height: 44rem;
                    line-height: 44rem;
                }
                .add-more-items-button.hover {}
                .add-more-items-button.touchdown {}
                .mobile .notch {
                    position: absolute;
                    left: 50%;
                    top: 6rem;
                    transform: translateX(-50%);
                    width: 36rem;
                    height: 5rem;
                    background-color: #cfcfcf;
                    border-radius: 5rem;                    
                }
                .mobile .slider-back {
                    --border-radius: 6rem;
                    border-top-left-radius: var(--border-radius);
                    border-top-right-radius: var(--border-radius);
                }
                .mobile.mobile-hide-scrollbar .slider-inner {
                    right: -50rem;
                }

                .mobile.mobile-hide-scrollbar .slider-back {
                    width: calc(100% - 50rem);
                }

                .subtotal-components {
                    position: relative;
                    z-index: 100;
                }

                @media screen and (max-width: 350px) {
                    .slider .subtotal {        
                        font-size: 18rem;
                    }                   

                    .slider .subtotal .price {
                        font-size: 22rem;
                    }
                }

                @media screen and (max-width: 290px) {
                    .slider .subtotal {        
                        font-size: 16rem;
                    }                   

                    .slider .subtotal .price {
                        font-size: 20rem;
                    }
                }
            `}</style>
        </>
    );
}