import Image from "next/image";
import { useContext } from "react";
import { LayoutStateContext } from "../../contexts/layout_state";
import { useCustomRouter } from "../../hooks/use_custom_router";
import { useImageLoadEffect } from "../../hooks/use_image_load_effect";
import { prebuildData } from "../../lib/prebuild-data";
import { EventPane } from "../core/event_pane";
import serverRefs from "./../../server-refs.json";

type TProp = {
    scale?: number;
    size: number;
    shift?: number;
    centered?: boolean;
    paddingLeft?: number;
    paddingRight?: number;
    paddingTop?: number;
    paddingBottom?: number;
};

export default function Logo({
    scale = 1,
    size,shift,centered,
    paddingLeft = 0,
    paddingRight = 0,
    paddingTop = 0,
    paddingBottom = 0
    }: TProp)
{
    shift = shift || 0;     

    const router = useCustomRouter();
    const { scaleRemToPixel } = useContext(LayoutStateContext);

    const handleClick = () => 
    {
        router.push("/",true);
    };

    const logoLoadEffect = useImageLoadEffect(0.5);

    const pixelSize = scaleRemToPixel(size);

    const { logo: { src: logoSrc } } = prebuildData.getActiveTheme();

    return (
        <>
            <div className="container" 
                    style={{
                        ...(centered?{left: `calc(50% - ${size/2}rem - ${paddingLeft}rem)`,position: "absolute",top: "0rem"}:{}),
                        paddingLeft,
                        paddingRight,
                        paddingTop,
                        paddingBottom
                    }}
                >
                <div className="holder" style={{top: `${shift}rem`,...logoLoadEffect.wrapperStyles,transform: `scale(${scale})`}}>
                    <Image src={prebuildData.bustCache(logoSrc)} alt="Logo" width={pixelSize} height={pixelSize} onLoad={logoLoadEffect.imageLoadHandler} />
                </div>
                <EventPane onClick={handleClick} seoLinkUrl="/" />
            </div>   
            <style jsx>{`
            .container {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                cursor: pointer;   
                position: relative;     
                transition: background-color var(--touch-duration);
            }

            .container.hover {
                opacity: 0.85;
            }

            .container.touchdown {
                background-color: rgba(255,255,255,0.1);
            }

            .holder {
                position: relative;
            }
            `}</style>
        </>
    );   
}