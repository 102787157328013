import { useContext, useRef } from "react";
import { LayoutStateContext } from "../contexts/layout_state";
import { useCssStateManager } from "../hooks/use_css_state_manager";
import { useErrorSlideShow } from "../hooks/use_error_slide_show";
import { SmartState, useSmartState } from "../hooks/use_smart_state";
import { apiBugReporter } from "../lib/api/bugreporter";
import CenteredSpinner from "./common/centered-spinner";
import { EventPane } from "./core/event_pane";

export type DialogSingleButtonProps = {
    caption: string;
    onClick?: () => void;
};

function DialogSingleButton({caption,onClick = () => {}}:DialogSingleButtonProps)
{
    const cssStateManager = useCssStateManager(["dialog-single-button"],{horiVertiCentered: true});
    return (
        <>
            <div className={cssStateManager.getClassNames()}>
                <div className="button">
                    {caption}
                    <EventPane onClick={onClick} hoverOnTouchDown />
                </div>
            </div>
            <style jsx>{`
            .dialog-single-button {
                margin-top: 10rem;
            }

            .button {
                background-color: #607afd;
                color: #fff;
                padding: 8rem 26rem;
                border-radius: 6rem;
                position: relative;
                opacity: 0.8;
                font-size: 15rem;
            }

            .button.hover {
                opacity: 1;
                transition: opacity 0.3s;
            }
            `}</style>
        </>
    );
}


export type DialogProps = {
    onClose?: () => void;
    show: boolean;
    message: string;
    buttonCaption: string;
};

export function Dialog({onClose = () => {},show,message,buttonCaption}:DialogProps) {
    const cssStateManager = useCssStateManager(["dialog"],{horiVertiCentered: true});
    cssStateManager.useProperty(show,"show");

    return (
        <>
            <div className={cssStateManager.getClassNames()}>
                <div className="form">
                    <div className="text">
                        {message}
                    </div>
                    <DialogSingleButton caption={buttonCaption} onClick={onClose} />
                </div>
            </div>
            <style jsx>{`
            .dialog {
                --duration: 0.4s;
                position: absolute;
                left: 0rem;
                right: 0rem;
                top: 0rem;
                bottom: 0rem;
                opacity: 0;
                visibility: hidden;
                transition: opacity var(--duration),visibility var(--duration);
                pointer-events: none;
                padding: 0rem 10rem;
            }

            .dialog.show {
                opacity: 1;
                visibility: visible;
            }

            .form {
                position: relative;
                width: 100%;
                max-width: 500rem;
                /* height: 100rem; */
                background-color: white;
                border-radius: 10rem;
                padding: 30rem 40rem;
                padding-bottom: 25rem;
            }
            .text {
                font-size: 15rem;
                padding-bottom: 15rem;
                text-align: center;
            }
            `}</style>
        </>
    );
}

export type SuccessDialogProps = {
    onClose?: () => void;
    show: boolean;
};

function SuccessDialog({onClose = () => {},show}:SuccessDialogProps) {
    return <Dialog {...{onClose,show}} message="Thank you for helping us improve the site. We will be looking into your bug report very soon." buttonCaption="Close" />;
    // const cssStateManager = useCssStateManager(["success-dialog"],{horiVertiCentered: true});
    // cssStateManager.useProperty(show,"show");

    // return (
    //     <>
    //         <div className={cssStateManager.getClassNames()}>
    //             <div className="form">
    //                 <div className="text">
    //                     Thank you for helping us improve the site. We will be looking into your bug report very soon.
    //                 </div>
    //                 <DialogSingleButton caption="Close" onClick={onClose} />
    //             </div>
    //         </div>
    //         <style jsx>{`
    //         .success-dialog {
    //             --duration: 0.4s;
    //             position: absolute;
    //             left: 0rem;
    //             right: 0rem;
    //             top: 0rem;
    //             bottom: 0rem;
    //             opacity: 0;
    //             visibility: hidden;
    //             transition: opacity var(--duration),visibility var(--duration);
    //             pointer-events: none;
    //             padding: 0rem 10rem;
    //         }

    //         .success-dialog.show {
    //             opacity: 1;
    //             visibility: visible;
    //         }

    //         .form {
    //             position: relative;
    //             width: 100%;
    //             max-width: 500rem;
    //             /* height: 100rem; */
    //             background-color: white;
    //             border-radius: 10rem;
    //             padding: 30rem 40rem;
    //             padding-bottom: 25rem;
    //         }
    //         .text {
    //             font-size: 15rem;
    //             padding-bottom: 15rem;
    //             text-align: center;
    //         }
    //         `}</style>
    //     </>
    // );
}


export type BugReporterProps = {
    open: SmartState<boolean>;
};

export default function BugReporter({open}:BugReporterProps)
{
    const cssStateManager = useCssStateManager(["bug-reporter"],{horiVertiCentered: true});
    cssStateManager.useProperty(open.value,"open");
    const working = cssStateManager.useState(false,"working");
    const showForm = useSmartState(true);
    cssStateManager.useProperty(showForm.value && open.value,"show-form");
    const { resetBugReporter } = useContext(LayoutStateContext);

    const email = useSmartState("");
    const name = useSmartState("");
    const description = useSmartState("");

    const errorSlideShow = useErrorSlideShow();

    const closeHandler = () => {
        open.value = false;
    };

    const submitClickHandler = async () => {
        let { value: emailValue } = email;
        let { value: nameValue } = name;
        let { value: descriptionValue } = description;
        const errorMessage = (() => {
            emailValue = emailValue.trim();
            nameValue = nameValue.trim();
            descriptionValue = descriptionValue.trim();
            if (!emailValue) return "Your email address is required";
            if (!/.+@.+/.test(emailValue)) return "The email address you provided is not valid";
            if (descriptionValue.length<20) return "The bug description must be at least 20 characters long";
            return null;    
        })();
        if (errorMessage) {
            errorSlideShow.actions.show(errorMessage);
        }
        else {
            errorSlideShow.actions.close();

            working.value = true;
            try {
                await apiBugReporter.submitBugReport(emailValue,nameValue,descriptionValue);
                showSuccessDialog.value = true;
                showForm.value = false;
                // closeHandler();
            }
            catch (err) {
                setTimeout(() => {
                    errorSlideShow.actions.show(err.message);
                    working.value = false;
                },100);
            }
            
        }        
    };

    const showSuccessDialog = useSmartState(false);

    const containerRef = useRef<HTMLDivElement>();

    const transitionEndHandler = (target: HTMLDivElement,propertyName: string) => {
        if ((target===containerRef.current) && (propertyName==="opacity")) {
            if (open.value) {
                const emailInputElem = target.querySelector("input.email") as HTMLInputElement;
                if (emailInputElem) {
                    emailInputElem.focus();
                }    
            }
            else {
                resetBugReporter();
            }
        }
    };

    return (
        <>
            <div ref={containerRef} className={cssStateManager.getClassNames()} onTransitionEnd={(e) => transitionEndHandler(e.target as HTMLDivElement,e.propertyName)}>
                <div className="overlay">
                    <EventPane onClick={closeHandler} />
                </div>
                <SuccessDialog onClose={closeHandler} show={showSuccessDialog.value && open.value} />
                <div className="form">
                    <div className="close-wrapper">
                        <div className="close-frame">
                            <i className="icon-navbar-cross" />
                            <EventPane onClick={closeHandler} />
                        </div>                        
                    </div>
                    <div className="title">Report a bug</div>
                    <div className="controls">                        
                        <input className="email" type="text" placeholder="Email" value={email.value} onChange={(e) => {errorSlideShow.actions.close();email.value = e.target.value}} />
                        <input type="text" placeholder="Name (optional)" value={name.value} onChange={(e) => {errorSlideShow.actions.close(); name.value = e.target.value}} />
                        <textarea 
                            placeholder="Description" 
                            value={description.value}
                            onChange={(e) => {errorSlideShow.actions.close();description.value = e.target.value}}
                        />
                    </div>
                    {errorSlideShow.component}
                    <div className="spinner-wrapper">
                        <div className="spinner-frame">
                            <CenteredSpinner scale={0.6} />
                        </div>
                    </div>
                    <div className="button-wrapper hori-verti-centered">
                        <div className="button">
                            Submit
                            <EventPane onClick={submitClickHandler} hoverOnTouchDown disabled={working.value} />
                        </div>
                    </div>
                </div>
            </div>
            <style jsx>{`
            .bug-reporter {
                --duration: 0.4s;
                position: fixed;
                left: 0rem;
                right: 0rem;
                top: 0rem;
                bottom: 0rem;
                z-index: 1500;
                opacity: 0;
                visibility: hidden;
                transition: opacity var(--duration),visibility var(--duration);
                padding: 0rem 10rem;
            }

            .close-wrapper {
                position: relative;
                height: 0rem;
            }

            .close-frame {
                position: absolute;
                right: -21rem;
                top: -6rem;
                width: 34rem;
                height: 34rem;
                text-align: center;
                padding-top: 8rem;
                font-size: 13rem;
                color: #aaa;
            }

            .close-frame.touchdown {
                background-color: #eee;
            }

            .spinner-wrapper {
                position: relative;
                height: 0rem;
                display: none;
            }

            .working .spinner-wrapper {
                display: block;
            }

            .spinner-frame {
                position: absolute;
                right: 0rem;
                top: 15rem;
                width: 34rem;
                height: 50rem;
            }

            .open {
                opacity: 1;
                visibility: visible;                
            }

            .controls {
                padding-top: 0rem;
            }

            .button-wrapper {

            }

            .controls input,.controls textarea {
                width: 100%;
                border: 1rem solid #aaa;
                border-radius: 3rem;
                padding: 10rem 10rem;
                margin-top: 15rem;
                font-family: Arial;           
                outline-color: #999;     
            }

            .controls input::placeholder,.controls textarea::placeholder {
                color: #aaa;
            }   

            .controls textarea {
                height: 150rem;
                resize: vertical;
            }                    

            .form {
                --duration: 0.4s;
                position: relative;
                width: 100%;
                max-width: 500rem;
                /* height: 100rem; */
                background-color: white;
                border-radius: 10rem;
                padding: 15rem 30rem;
                opacity: 0;
                visibility: hidden;
                transition: opacity var(--duration),visibility var(--duration);
            }

            .show-form .form {
                opacity: 1;
                visibility: visible;
            }

            .title {
                text-align: center;
                color: #555;
                font-weight: 400;
            }

            .overlay {
                position: absolute;
                left: 0rem;
                right: 0rem;
                top: 0rem;
                bottom: 0rem;
                background-color: rgba(0,0,0,0.45);
            }

            .button-wrapper {
                margin-top: 10rem;
            }

            .button {
                background-color: #607afd;
                color: #fff;
                padding: 8rem 26rem;
                border-radius: 6rem;
                position: relative;
                opacity: 0.8;
                font-size: 15rem;
            }

            .working .button {
                background-color: #aaa;
            }

            .button.hover {
                opacity: 1;
                transition: opacity 0.3s;
            }
            `}</style>
        </>
    );
}