import { CSSProperties } from "react";
import { useCssStateManager } from "../../hooks/use_css_state_manager";
import { EventPane } from "../core/event_pane";
import { useCustomRouter } from "../../hooks/use_custom_router";
import { misc } from "../../lib/misc";

export type ButtonProps = {
    caption: string;    
    onClick?: () => void;
    redirectToPath?: string;
    disabled?: boolean;
    fixedWidth?: number;
};

export const styledButton = (styles: CSSProperties = {},disabledStyles: CSSProperties = {},{
        overlapHori = 0,
        overlapVert = 0,
        hoverOpacity = 0.85,
        backgroundColorCssVarName,
        colorCssVarName        
    }: {
        overlapHori?: number;
        overlapVert?: number;
        hoverOpacity?: number;
        backgroundColorCssVarName?: string;
        colorCssVarName?: string;
    } = {}) => {
    return function Button({ caption,onClick = () => {},disabled,fixedWidth,redirectToPath }:ButtonProps) {
        const cssStateManager = useCssStateManager(["button"]);
        cssStateManager.useProperty(disabled,"disabled");

        const backgroundColor = backgroundColorCssVarName?`var(${backgroundColorCssVarName})`:"#076bd1";
        const color = colorCssVarName?`var(${colorCssVarName})`:"#fff";

        const internalStyles: CSSProperties = {
            ...((fixedWidth!==undefined)?{width: `${fixedWidth}rem`}:{})
        };

        const router = useCustomRouter();

        const handleClick = () => {
            if (redirectToPath) {
                if (misc.isExternalPath(redirectToPath)) {
                    location.href = redirectToPath;
                }
                else {
                    router.push(redirectToPath);
                }                
            }
            else {
                onClick();
            }
        };
        
        return (
            <>
                <div className={cssStateManager.getClassNames()} style={{...styles,...internalStyles,...(disabled?disabledStyles:{})}}>
                    {caption}
                    <EventPane 
                        onClick={handleClick} 
                        hoverOnTouchDown 
                        disabled={disabled} 
                        overlapHori={overlapHori} 
                        overlapVert={overlapVert}                         
                    />
                </div>
                <style jsx>{`
                .button {
                    background-color: ${backgroundColor};
                    color: ${color};
                    padding: 11rem 25rem;
                    border-radius: 5rem;
                    font-size: 15rem;
                    position: relative;
                    transition: opacity 0.3s;
                    text-align: center;
                }

                .button.hover {
                    opacity: ${hoverOpacity};
                }

                .disabled {
                    opacity: 1;
                    background-color: #999;
                }
                `}</style>
            </>
        );    
    };
};

export const InlineButton = styledButton({display: "inline-block"});