export type DeltaDirection = "None" | "Vertical" | "Horizontal";

export const trackTouchMoveDelta = (elem: HTMLDivElement,onDelta: (delta: {x: number;y: number;wx: number;wy: number}) => void,{
        onStart = () => {},
        onEnd = () => {},
        onFirstDelta = () => {},
        deltaDirection = "None",
        preventDefaultOnDelta = false
    }: {
        onStart?: () => void;
        onEnd?: (delta: {wx: number;wy: number}) => void;
        onFirstDelta?: () => void;
        deltaDirection?: DeltaDirection;
        preventDefaultOnDelta?: boolean;
    } = {}) => {    
    let tsHandler: (e: TouchEvent) => void;
    let tmHandler: (e: TouchEvent) => void;
    let teHandler: (e: TouchEvent) => void;

    const activeTouches: {[identifier: string]: true} = {};
    let startPoint: {x: number,y: number} = null;
    let lastPoint: {x: number,y: number} = null;
    let isFirstDelta = true;
    let inDeadZone = false;
    let blocked = false;
    let deltaIsActive = false;

    const clearTouchDown = () => 
    {        
        if (startPoint) onEnd({wx: lastPoint.x-startPoint.x,wy: lastPoint.y-startPoint.y});
        deltaIsActive = false;
        startPoint = null;
        lastPoint = null;
        isFirstDelta = true;
    };

    const enterDeadZone = () => 
    {
        if ((Object.keys(activeTouches).length>0) && (!inDeadZone))
        {
            clearTouchDown();
            inDeadZone = true;
        }
    };

    elem.addEventListener("touchstart",tsHandler=(event) => {
        for(let i=0;i<event.changedTouches.length;i++)
        {
            const touch = event.changedTouches[i];
            const beforeTouchCount = Object.keys(activeTouches).length;
            activeTouches[touch.identifier.toString()] = true;
            const afterTouchCount = Object.keys(activeTouches).length;
            if ((beforeTouchCount===0) && (afterTouchCount===1))
            {
                blocked = false;
                deltaIsActive = false;
                startPoint = {
                    x: touch.screenX,
                    y: touch.screenY
                };
                lastPoint = {
                    x: touch.screenX,
                    y: touch.screenY
                };
                onStart();
            }
            else if (afterTouchCount>1)
            {
                enterDeadZone();
            }
        }
    });
    elem.addEventListener("touchmove",tmHandler=(event) => {
        if ((preventDefaultOnDelta) && (deltaIsActive)) event.preventDefault();
        for(let i=0;i<event.changedTouches.length;i++)
        {
            const touch = event.changedTouches[i];            
            if ((Object.keys(activeTouches).length==1) && activeTouches[touch.identifier.toString()] && (!inDeadZone))
            {
                const x = touch.screenX-lastPoint.x;
                const y = touch.screenY-lastPoint.y;
                lastPoint.x = touch.screenX;
                lastPoint.y = touch.screenY;
                const wx = touch.screenX-startPoint.x;
                const wy = touch.screenY-startPoint.y;
                if ((isFirstDelta) && (deltaDirection!=="None")) {
                    if (((deltaDirection==="Vertical") && (Math.abs(wx)>Math.abs(wy))) || ((deltaDirection==="Horizontal") && (Math.abs(wy)>Math.abs(wx)))) {
                        blocked = true;
                    }
                }
                if (!blocked) {
                    deltaIsActive = true;
                    onDelta({x,y,wx,wy});
                }
                if (isFirstDelta) {
                    isFirstDelta = false;
                    onFirstDelta();
                }
            }
        }
    });
    elem.addEventListener("touchend",teHandler=(event) => {
        for(let i=0;i<event.changedTouches.length;i++)
        {
            const touch = event.changedTouches[i];
            const beforeTouchCount = Object.keys(activeTouches).length;
            delete activeTouches[touch.identifier.toString()];
            const afterTouchCount = Object.keys(activeTouches).length;
            if ((beforeTouchCount===1) && (afterTouchCount===0))
            {
                clearTouchDown();
                inDeadZone = false;
            }
        }
    });
    return () => {
        elem.removeEventListener("touchstart",tsHandler);
        elem.removeEventListener("touchmove",tmHandler);
        elem.removeEventListener("touchend",teHandler);
    };
};