import { TransitionEvent, useContext, useEffect, useRef, useState } from "react";
import { LayoutStateContext } from "../contexts/layout_state";
import { Icon } from "./core/icon";
import { SideMenuItem } from "./side_menu/menu_item";
import { SideMenuSeparator } from "./side_menu/separator";
import { SideMenuSectionTitle } from "./side_menu/section_title";
import { SideMenuCollectionsMenu } from "./side_menu/collections_menu";
import SpacerRow from "./spacer_row";
import { EventPane } from "./core/event_pane";
import { Image } from "./common/scaled-next-image";
import { misc } from "../lib/misc";
import { useCustomRouter } from "../hooks/use_custom_router";
import { prebuildData } from "../lib/prebuild-data";
import { consumeAccount } from "../hooks/use_account";

export type SideMenuProps = {
};

export function SideMenu()
{  
    const { sideMenu: { openId,show,setShow },openSearch,page,openSmileIframe } = useContext(LayoutStateContext);
    const showRef = useRef(show);
    const openedRef = useRef(false);
    showRef.current = show;
    const awaitingSearchRef = useRef(false);

    const backClickHandler = () => 
    {
        if (openedRef.current) setShow(false);
    };

    const logoSize = 70;
    const accountIconSize = 26;
    const searchIconSize = 24;

    const transitionEndHandler = (event: TransitionEvent<HTMLDivElement>) => 
    {
        if (event.propertyName==="opacity")
        {
            openedRef.current = showRef.current;            
            if ((!openedRef.current) && (awaitingSearchRef.current)) {
                
                // awaitingSearchRef.current = false;
            }
        }
    };

    const router = useCustomRouter();

    const handleLogoClick = () => 
    {
        setShow(false);
        router.push("/",true);
    };

    const searchClickHandler = () => {
        setShow(false);    
        page.scrollToTopWithCallback(() => {
            openSearch();            
        });
    };

    const rewardClickHandle = () => {
        alert("Demo: this feature has been disabled");
        return;
        setShow(false);
        openSmileIframe();
    };

    const account = consumeAccount();

    const accountClickHandler = () => {
        setShow(false);
        if (account.value) {
            router.push("/account");
        }
        else {
            router.push("/login");
        }        
    };

    const { logo: { src: logoSrc },sideMenu: { topBackgroundColor,topIconColor,topTextColor } } = prebuildData.getActiveTheme();

    return (
        <>
            <div className={"container"+(show?" show":"")}>
                <div className="back" onTransitionEnd={transitionEndHandler}>
                    <div className="cross">
                        <Icon name="navbar-cross" size={20} />
                    </div>
                    <EventPane zIndex={0} onClick={backClickHandler} />
                </div>
                <div className="menu">
                    <div className="top">
                        <div className="logo">
                            <Image src={prebuildData.bustCache(logoSrc)} alt="Logo" width={logoSize} height={logoSize} />
                            <EventPane onClick={handleLogoClick} seoLinkUrl="/" />
                        </div>
                        <div className="account">
                            <div className="top-icon-text">{account.value?"Account":"Login"}</div>
                            <SpacerRow size={7} />
                            <div className="top-icon-image">
                                <Icon name="navbar-account" size={accountIconSize} />
                            </div>                            
                            <EventPane onClick={accountClickHandler} />
                        </div>
                        <div className="search">
                            <div className="top-icon-image">
                                <Icon name="navbar-search" size={searchIconSize} />
                            </div>    
                            <EventPane onClick={searchClickHandler} />
                        </div>
                    </div>
                    <div className="bottom">                        
                        {/*}
                        <SideMenuItem caption="Reorder" iconName="navbar-reorder" />
                        {*/}
                        <SideMenuItem caption="Rewards" iconName="navbar-rewards" onClick={rewardClickHandle} />
                        <SideMenuSeparator />
                        <SideMenuSectionTitle caption="Collections" />
                        <SideMenuCollectionsMenu openId={openId} />
                    </div>
                </div>
            </div>
            <style jsx>{`
            .container {
                position: fixed;
                left: 0rem;
                top: 0rem;
                width: 100%;
                height: 100%;
                z-index: 1000;
                visibility: hidden;
                --duration: 0.3s;
                --menu-item-height: 54rem;
                --menu-item-padding-left: 16rem;
            }

            .top-icon-text {
                color: ${topTextColor};
            }

            .top-icon-image {
                color: ${topIconColor};
            }

            .back {
                position: absolute;
                left: 0rem;
                top: 0rem;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.8);
                opacity: 0;
                visibility: hidden;                
                transition: opacity var(--duration),visibility var(--duration),background-color var(--touch-duration);
                cursor: pointer;
            }

            .back.touchdown {
                background-color: rgba(0,0,0,.831);
            }

            .back .cross {
                position: absolute;
                right: 29rem;
                top: 45rem;
                color: #FFFFFF;                
            }

            .menu {
                max-width: 400rem;
                position: absolute;
                left: 0rem;
                top: 0rem;
                height: 100%;
                width: calc(100% - 78rem);
                background-color: white;
                visibility: hidden;
                transition: transform var(--duration),visibility var(--duration);
                transform: translateX(-100%);
                --top-height: 125rem;
            }

            .top {
                position: absolute;
                left: 0rem;
                top: 0rem;
                width: 100%;
                height: var(--top-height);
                background-color: ${topBackgroundColor};
            }

            .bottom {
                position: absolute;
                left: 0rem;
                top: var(--top-height);
                width: 100%;
                bottom: 0rem;
                background-color: var(--color-collection-menu);
                display: flex;
                flex-direction: column;
            }

            .logo {
                position: absolute;
                --edge: 16rem;
                left: calc(var(--edge) - var(--padding-hori));
                bottom: calc(var(--edge) - 3rem);         
                cursor: pointer;       
                padding-top: 5rem;
                --padding-hori: 5rem;
                padding-left: var(--padding-hori);
                padding-right: var(--padding-hori);
                transition: background-color var(--touch-duration);
            }

            .logo.hover {
                opacity: 0.85;
            }

            .logo.touchdown {
                background-color: rgba(255,255,255,0.1);
            }

            .account {
                color: var(--color-collection-menu);
                position: absolute;
                --edge: 15rem;
                right: 0rem;
                top: 0rem;
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 15rem;
                padding: var(--edge) calc(var(--edge) + 5rem);
                cursor: pointer;
                transition: background-color var(--touch-duration);
            }

            .search {
                --size: 58rem;
                position: absolute;                
                right: 0rem;
                bottom: 0rem;
                color: var(--color-collection-menu);
                width: var(--size);
                height: var(--size);
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .account.hover,.search.hover {
                opacity: 0.85;
            }

            .account.touchdown,.search.touchdown {
                background-color: rgba(255,255,255,0.07);
            }

            .container.show .back {
                opacity: 1;
                visibility: visible;
            }

            .container.show .menu {
                transform: translateX(0rem);
                visibility: visible;
            }

            `}</style>
        </>
    )
}