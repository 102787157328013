import serverRefs from "../../server-refs.json";
import { infiniteFetch } from "../infinite-fetch";
import { CheckoutData } from "../services/cart/cart";
import { CartItemOptionValues } from "../services/cart/types";

export type EditOrderCartItem = {
    quantity: number;
    productId: string;
    options: CartItemOptionValues;
    freeProduct: boolean;
};

export type EditOrderType = "order" | "draft";

export type EditOrderOrder = {
    id: string;
    name: string;
    customer: {
        displayName: string;
        email: string;
    };
    priceCents: number;
    cartItems: EditOrderCartItem[];
    orderType: EditOrderType;
    orderReturnUrl: string;
    snapshotId: string;
};

class EditOrderApi {
    editSecureKey = "";

    async getOrder(editOrderId: string) {
        // const baseUrl = (process.env.NODE_ENV==="development")?"http://localhost:9000":serverRefs.apis.admin;
        const baseUrl = serverRefs.apis.admin;
        let url = `${baseUrl}/${this.editSecureKey}/edit-order?id=${editOrderId}&type=get-cart`;
        const response = await infiniteFetch(url);
        const res = (await response.json());
        if (res?.type==="success") {
            const { id,name,customer,cartItems,priceCents,orderType,orderReturnUrl,snapshotId } = res;
            const ret: EditOrderOrder = { id,name,customer,cartItems,orderType,orderReturnUrl,priceCents,snapshotId };
            return ret;
        }
        else {
            const errorMessage = res?.errorMessage || "unknown error occurred";
            alert(`error: ${errorMessage}`);
        }
    }

    async saveOrder(editOrderId: string,newCheckout: CheckoutData,display: string,ps: string,snapshotId: string,centVariantId: string,notifyCustomer: boolean,staffNote: string) {
        // const baseUrl = (process.env.NODE_ENV==="development")?"http://localhost:9000":serverRefs.apis.admin;
        const baseUrl = serverRefs.apis.admin;
        let url = `${baseUrl}/${this.editSecureKey}/edit-order`;

        const res = await fetch(url,{
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "order-edit-id": editOrderId,
                "new-checkout": JSON.stringify(newCheckout),
                "display": display,
                "ps": ps,
                "snapshot-id": snapshotId,
                "cent-variant-id": centVariantId,
                "notify-customer": notifyCustomer,
                "staff-note": staffNote
            })
        });
        const jsonRes = await res.json();
        if (jsonRes?.type==="error") {
            throw new Error(jsonRes?.errorMessage || "Unexpected response from server");
        }
        else if (jsonRes?.type==="success") {
            return;
        }
        else {
            throw new Error("Unexpected response from server");
        }
    }
}

export const apiEditOrder = new EditOrderApi();