export type SimpleObject = {[key: string]: any};

export const isSimpleObject = (value: any): value is SimpleObject => 
    (typeof value === "object") && (!Array.isArray(value)) && (value!==null);

export const cloneSimpleData = <T>(value: T): T => {
    if (isSimpleObject(value)) {
        const ret: any = {};
        for (let key in value) {
            ret[key] = cloneSimpleData(value[key]);
        }
        return ret as unknown as T;
    }
    else if (Array.isArray(value)) {
        return (value as any[]).map(cloneSimpleData) as unknown as T;
    }
    else if (value===null) {
        return null as unknown as T;
    }
    else if (value===undefined) {
        return undefined as unknown as T;
    }
    else if (["number","string","boolean"].includes(typeof value)) {
        return value as unknown as T;
    }
    else {
        throw new Error("cannot clone: unrecognized data type encountered");
    }
};