export function SideMenuSeparator()
{
    return (
        <>
            <div className="container">
                <div className="frame"></div>
            </div>
            <style jsx>{`
            .container {
                width: 100%;
            }

            .frame {
                width: 100%;
                height: 4rem;
                background-color: #d5dbdb;
            }
            `}</style>
        </>
    );
}