import { misc } from "./misc";
import { prebuildData } from "./prebuild-data";

type SeachTable = {
    [fragment: string]: {[productId: string]: true};
};

export type SearchResult = {
    productId: string;
    htmlCaption: string;
    collectionHtmlCaption: string;
};

class Search {
    private tableBuilt = false;
    private table: SeachTable = {};

    private breakIntoWords(value: string) {
        return value.split(" ").filter((word) => word!=="");
    }

    buildTable() {
        if (this.tableBuilt) return;
        const productTable = prebuildData.getProductTable();
        const ptcTable = prebuildData.getProductToCollectionTable();
        Object.keys(productTable).forEach((productId) => {
            const { caption,hidden } = productTable[productId];
            if (hidden) return;
            const collectionName = ptcTable[productId]?.collectionName || "";
            this.breakIntoWords(caption.toLowerCase()+" "+collectionName.toLowerCase()).forEach((word) => {
                for(let i=0;i<word.length;i++) {
                    for(let j=i+1;j<=word.length;j++) {
                        const fragment = word.substring(i,j);
                        this.table[fragment] = this.table[fragment] || {};
                        this.table[fragment][productId] = true;
                    }
                }
            });
        });
        this.tableBuilt = true;        
    }

    search(value: string,collectionId?: string) {
        if (value.trim()==="") return [];
        this.buildTable();
        const productTable = prebuildData.getProductTable();
        const productHash: {[productId: string]: {[word: string]: true}} = {};
        const valueWords = misc.removeDuplicates(this.breakIntoWords(value.toLowerCase()));
        valueWords.forEach((word) => {
            if (this.table[word]) {
                Object.keys(this.table[word]).forEach((productId) => {
                    if ((!collectionId) || (prebuildData.collectionHasProduct(collectionId,productId))) {
                        productHash[productId] = productHash[productId] || {};
                        productHash[productId][word] = true;                            
                    }
                });
            }
        });

        const wordCountHash: {[productId: string]: number} = {};

        const ret: SearchResult[] = Object.keys(productHash)
                .filter((productId) => Object.keys(productHash[productId]).length===valueWords.length)
                .map((productId) => {
            const product = productTable[productId];
            const { collectionName } = prebuildData.getProductToCollectionById(productId);
            const words = Object.keys(productHash[productId]);

            const captionToHtmlCaption = (caption: string) => {
                const lcCaption = caption.toLowerCase();
                const harr: boolean[] = [];
                harr.length = caption.length+1;
                for(let i=0;i<harr.length;i++) harr[i] = false;
                words.forEach((word) => {
                    let pnt = 0;
                    while(true) {
                        let index = lcCaption.indexOf(word,pnt);
                        if (index===-1) break;
                        for(let i=0;i<word.length;i++) {
                            harr[index+i] = true;
                        }
                        pnt = index+word.length;
                    }
                });
                let ret = "";
                let open = false;
                for(let i=0;i<harr.length;i++) {
                    if (open) {                    
                        if (!harr[i]) {
                            open = false;
                            ret+= `</span>`;
                        }
                        if (i<caption.length) ret+= caption.charAt(i);
                    }
                    else {
                        if (harr[i]) {
                            open = true;
                            ret+= `<span class="highlight">`;
                        }
                        if (i<caption.length) ret+= caption.charAt(i);
                    }
                }
                return ret;
            };

            const { caption } = product;
            const htmlCaption = captionToHtmlCaption(caption);
            const collectionHtmlCaption = captionToHtmlCaption(collectionName);
            wordCountHash[productId] = words.length;
            return {
                productId,
                htmlCaption,
                collectionHtmlCaption
            };
        });

        ret.sort((_a,_b) => {
            const a = wordCountHash[_a.productId.toString()];
            const b = wordCountHash[_b.productId.toString()];
            if (a<b) return 1;
            if (a>b) return -1;
            return 0;
        });

        return ret;
    }
}

export const libSearch = new Search();