import { styledButton } from "./button";

export const TextButton = styledButton({
    display: "inline-block",
    backgroundColor: "transparent",
    color: "#666",
    fontWeight: "600",
    fontSize: "inherit",
    padding: "0rem"
},{},{
    overlapHori: 6,
    overlapVert: 10,
    hoverOpacity: 0.75
});

export const TextButtonLight = styledButton({
    display: "inline-block",
    backgroundColor: "transparent",
    color: "#666",
    fontSize: "inherit",
    padding: "0rem"
},{
    opacity: 0.4
},{
    overlapHori: 6,
    overlapVert: 10,
    hoverOpacity: 0.75
});
