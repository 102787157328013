import { ProductReview } from "../../hooks/use_product_reviews_summary";
import serverRefs from "../../server-refs.json";
import { infiniteFetch } from "../infinite-fetch";
import { misc } from "../misc";
import { SeededRandomByProductId, libMockReviews } from "../mock-reviews";

export type ReviewHighlight = {
    title: string;
    body: string;
    name: string;
};

export type ReviewsResponse = {
    items: ProductReview[];
    lastSortKey: string;
};

export type ReviewsOrder = "most-recent" | "highest-rated" | "lowest-rated";

type ReviewDummy = {title: string;body: string};
type ReviewDummyCollections = {
    good: ReviewDummy[];
    bad: ReviewDummy[];
};

export class DummyReviewGen {
    constructor(private reviews: ReviewDummyCollections) {        
    }

    generateRandomName(productId: string | number) {        
        const random = new SeededRandomByProductId(productId);
        random.choice([0,1,2]);
        // console.log({productId});
        const firstNames = ["Alex", "Jordan", "Taylor", "Casey", "Jamie", "Morgan", "Charlie", "Dakota", "Robin", "Quinn"];
        const lastNames = ["Smith", "Johnson", "Williams", "Jones", "Brown", "Davis", "Miller", "Wilson", "Moore", "Taylor"];
        return random.choice(firstNames)+" "+random.choice(lastNames);
    }

    getRandom(productId: string | number): ReviewHighlight {
        const random = new SeededRandomByProductId(productId);        
        return {...random.choice(this.reviews.good),name: this.generateRandomName(productId)};
    }

    getByTypeAndIndex(type: "good" | "bad",index: number) {
        console.log(type,index);
        return this.reviews[type][index];
    }
}

class ApiReview {
    private cachedDummyReviewGen: DummyReviewGen = null;

    async getDummyReviewGens() {
        if (this.cachedDummyReviewGen!==null) return this.cachedDummyReviewGen;

        // const url = `${serverRefs.buckets.public}/reviews/dummy.json`;
        const url = "/static/local/demo-data/reviews/dummy.json";
        const response = await infiniteFetch(url);
        this.cachedDummyReviewGen = new DummyReviewGen(await response.json());
        return this.cachedDummyReviewGen;
    }

    async getHighlight(productId: string | number) {
        const dummyReviewGen = await this.getDummyReviewGens();
        return dummyReviewGen.getRandom(productId);
        // const url = `${serverRefs.buckets.public}/reviewsummaries/highlight_${productId}.json`;
        // const response = await infiniteFetch(url);
        // const items = await response.json();
        // if (Array.isArray(items)) {
        //     return items[Math.floor(Math.random()*items.length)];
        // }
        // else {
        //     return null;
        // }    
    }



    async getReviews(productId: string | number,order: ReviewsOrder = "most-recent",lastSortKey?: string,{byRating}: {byRating?: number} = {}) {
        console.log(order,lastSortKey,{byRating});
        await new Promise((resolve) => {            
            setTimeout(resolve,400+(Math.random()*500));
        });
        return await libMockReviews.getReviews(productId,order,lastSortKey,{byRating});
        // let url = `${serverRefs.apis.api}/reviews?product_id=${productId}&order=${order}`;
        // if (lastSortKey) url+= `&last_sort_key=${lastSortKey}`;
        // if (byRating) url+= `&by_rating=${byRating}`;
        // const response = await infiniteFetch(url);
        // const res: ReviewsResponse = await response.json();
        // if (Array.isArray(res?.items)) {
        //     return res;
        // }
        // else {
        //     return null;
        // }    
    }
}
export const apiReview = new ApiReview();