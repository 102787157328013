import { ProductReview } from "../../hooks/use_product_reviews_summary";
import serverRefs from "../../server-refs.json";
import { LocationAddress } from "../shared/types/location.types"
import { infiniteFetch } from "../infinite-fetch";

export type ReviewHighlight = {
    title: string;
    body: string;
    name: string;
};

export type ReviewsResponse = {
    items: ProductReview[];
    lastSortKey: string;
};

class ApiLocation {
    async search(text: string,countryCode: string) {
        try {
            const baseUrl = (process.env.NODE_ENV==="development")?"http://localhost:9000":serverRefs.apis.api;
            let url = `${baseUrl}/location`;
    
            const res = await fetch(url,{
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    text,
                    country_code: countryCode
                })
            });
            const jsonRes = await res.json();
            if (jsonRes?.type==="error") {
                throw new Error(jsonRes?.errorMessage || "Unexpected response from server");
            }
            else if (jsonRes?.type==="success") {            
                return jsonRes.results as LocationAddress[];
            }
            else {
                throw new Error("Unexpected response from server");
            }    
        }
        catch(err) {
            return [] as LocationAddress[];
        }
    }
}
export const apiLocation = new ApiLocation();