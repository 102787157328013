import { forwardRef, MutableRefObject, useEffect, useImperativeHandle, useRef } from "react";
import { useCssStateManager } from "../../../hooks/use_css_state_manager";
import { useSmartState } from "../../../hooks/use_smart_state";
import { misc } from "../../../lib/misc";


export type PriceSavedDisplayProps = {
    priceDiff: number;
};

export function PriceSavedDisplay({priceDiff}:PriceSavedDisplayProps)
{
    const cssStateManager = useCssStateManager(["price-diff-display"]);
    const sign = (priceDiff>=0)?"+":"—"
    const { dollar,cents } = misc.splitPriceForDisplay(Math.abs(priceDiff));

    return (
        <>
            <div className={cssStateManager.getClassNames()}>
                <span className="sup">$</span>{dollar}<span className="sup">{cents}</span>
                <span className="saved">saved</span>
            </div>
            <style jsx>{`
            .price-diff-display {
                font-size: 14rem;
                font-weight: 400;
                border: 1rem solid #bbb;
                border-radius: 12rem;
                padding: 2rem 10rem;
                padding-bottom: 3rem;
                background-color: #efefef;
                color: #666;
                transition: opacity 0.3s;
                display: inline-block;                
            }
            .sign {
                font-size: 12rem;
                position: relative;
                /* top: -1rem; */
                top: 2rem;           
                left: -2rem;                    
                font-weight: 600;
                width: 6rem;
                overflow-y: hidden;
                display: inline-block;
            }

            .sup {
                font-size: 9.4rem;
                position: relative;
                top: -3rem;
            }

            .saved {
                padding-left: 5rem;
                font-size: 0.9em;
                position: relative;
                top: -1rem;
            }
            `}</style>
        </>
    );
}

export type PriceUpdateProps = {
    price: number;
};

export type PriceUpdateRefInterface = {
    show: (priceUpdate: number) => void;    
};

type State = "idle" | "showing" | "hiding";

export default function PriceUpdate({price}:PriceUpdateProps)
{
    const cssStateManager = useCssStateManager(["container"]);
    const show = cssStateManager.useState(false,"show");
    const priceUpdateRef = useRef(0);
    const queuedRef = useRef(0);
    const isQueuedRef = useRef(false);
    const { current: priceUpdate } = priceUpdateRef;
    const sign = (priceUpdate>=0)?"+":"-"
    const { dollar,cents } = misc.splitPriceForDisplay(Math.abs(priceUpdate));
    const timeoutHandleRef = useRef(0);
    const stateRef = useRef<State>("idle");

    const showTimeoutMilliseconds = 2200;

    const goIntoHiding = () => {
        clearTimeout(timeoutHandleRef.current);
        timeoutHandleRef.current = 0;
        stateRef.current = "hiding";
        show.value = false;
    };
    const goIntoShowing = (value: number) => {
        if (value===0) return;
        priceUpdateRef.current = value;
        stateRef.current = "showing";
        show.value = true;
        timeoutHandleRef.current = setTimeout(goIntoHiding,showTimeoutMilliseconds) as unknown as number;
    };

    const queueValue = (value: number) => {
        queuedRef.current = value;
        isQueuedRef.current = true;
    };

    const unqueueValue = () => {
        const ret = queuedRef.current;
        isQueuedRef.current = false;
        return ret;
    };

    const showPriceUpdate = (value: number) => {

        if (stateRef.current==="idle") {
            goIntoShowing(value);
        }
        else if (stateRef.current==="showing") {
            goIntoHiding();
            queueValue(value);
        }
        else if (stateRef.current==="hiding") {
            queueValue(value);
        }
    };

    const lastPriceRef = useRef<number>(0);
    const lastPriceSetRef = useRef(false);

    useEffect(() => {
        if (lastPriceSetRef.current) {
            const priceUpdate = price-lastPriceRef.current;
            showPriceUpdate(priceUpdate);
        }        
        lastPriceRef.current = price;
        if (price>0) lastPriceSetRef.current = true;
    },[price]);

    const transitionEndHandler = (propertyName: string) => {
        if ((propertyName==="opacity") && (!show.value)) {
            if (isQueuedRef.current) {
                goIntoShowing(unqueueValue());
            }
            else {
                stateRef.current = "idle";
            }
        }
    };

    return (
        <>
            <div className={cssStateManager.getClassNames()} onTransitionEnd={(e) => transitionEndHandler(e.propertyName)}>
                <span className="sign">{sign}</span><span className="sup">$</span>{dollar}<span className="sup">{cents}</span>
            </div>
            <style jsx>{`
            .container {
                font-size: 14rem;
                font-weight: 400;
                border: 1rem solid #ddd;
                border-radius: 10rem;
                padding: 0rem 8rem;
                background-color: #f7f7f7;
                color: #666;
                opacity: 0;
                transition: opacity 0.3s;
            }
            .container.show {
                opacity: 1;
            }
            .sign {
                font-size: 12rem;
                position: relative;
                top: -1rem;            
                left: -1rem;                    
                font-weight: 600;
            }

            .sup {
                font-size: 9.4rem;
                position: relative;
                top: -3rem;
            }
            `}</style>
        </>
    );
}