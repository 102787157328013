export type SimpleObject = {[key: string]: any};

class MiscShared {
    isSimpleObject(value: any): value is SimpleObject {
        return (typeof value === "object") && (!Array.isArray(value)) && (value!==null);
    }    

    cloneSimpleData<T>(value: T): T {
        if (this.isSimpleObject(value)) {
            const ret: any = {};
            for (let key in value) {
                ret[key] = this.cloneSimpleData(value[key]);
            }
            return ret as unknown as T;
        }
        else if (Array.isArray(value)) {
            return (value as any[]).map(this.cloneSimpleData.bind(this)) as unknown as T;
        }
        else if (value===null) {
            return null as unknown as T;
        }
        else if (value===undefined) {
            return undefined as unknown as T;
        }
        else if (["number","string","boolean"].includes(typeof value)) {
            return value as unknown as T;
        }
        else {
            throw new Error("cannot clone: unrecognized data type encountered");
        }
    }

    convertSplitPhoneNumberToPlusPhoneNumber(value: string) {
        const parts = (value.includes("/")?value:`/${value}`).split("/");
        return "+"+parts[0]+parts.slice(1).join("/");
    }
}

export const miscShared = new MiscShared();