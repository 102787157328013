import NextImage, { ImageProps } from "next/image";
import { useContext } from "react";
import { LayoutStateContext } from "../../contexts/layout_state";

export function Image(props: ImageProps) {
    const { scaleFactor } = useContext(LayoutStateContext);
    const changedProps = {...props};
    if (typeof changedProps.width==="number") {
        changedProps.width*= scaleFactor;
    }
    if (typeof changedProps.height==="number") {
        changedProps.height*= scaleFactor;
    }
    return <NextImage {...changedProps} />
}
