import React, { useContext, useEffect, useRef, useState } from "react";

class IconWaiter
{
    ready: {[name: string]: boolean} = {};
    pending: {[name: string]: {iTag: HTMLElement,cb: () => void}} = {};
    running = false;

    start()
    {
        if (this.running) return;
        const next = () => 
        {
            const toDelete: string[] = [];
            for(let name in this.pending)
            {
                const { iTag,cb } = this.pending[name];
                if (iTag.clientHeight>0)
                {
                    toDelete.push(name);
                    this.ready[name] = true;
                    cb();
                    if (globalThis.document) iTag.remove();
                }
            }
            toDelete.forEach(name => delete this.pending[name]);
            if (Object.keys(this.pending).length>0)
            {
                setTimeout(next,20);
            }
            else
            {
                this.running = false;
            }            
        };

        this.running = true;
        next();
    }

    waitFor(name: string,cb: () => void)
    {
        if (this.ready[name])
        {
            cb();
        }
        else if (!this.pending[name])
        {
            if (globalThis.document) 
            {
                const iTag = document.createElement("i");
                iTag.className = `icon-${name}`;
                iTag.style.position = "absolute";
                iTag.style.visibility = "hidden";
                document.body.appendChild(iTag);
                this.pending[name] = {iTag,cb};

                this.start();
            }                        
        }
    }
}

const iconWaiter = new IconWaiter();

// ------------

type TIconsLoadedWrapperProps = {
    children: JSX.Element;
    fadeInDuration?: number;
};

class IconsLoadedTracker
{    
    onReady = () => {};
    ready = false;
    registerCount = 0;
    loadedCount = 0;

    constructor(onReady: () => void)
    {      
        this.onReady = onReady;  
    }

    register()
    {        
        this.registerCount++;
    }

    loaded()
    {        
        this.loadedCount++;
        if ((!this.ready) && (this.loadedCount===this.registerCount))
        {
            this.ready = true;
            this.onReady();
        }
    }
}

export const IconsLoadedContext = React.createContext<IconsLoadedTracker | undefined>(undefined);

export function IconsLoadedWrapper({children,fadeInDuration = 1.15}: TIconsLoadedWrapperProps)
{
    const [show,setShow] = useState(false);
    const { current: tracker } = useRef(new IconsLoadedTracker(() => { setShow(true); }));

    return (
        <>
            <div className={`container${show?" show":""}`} style={{transition: `opacity ${fadeInDuration}s,visibility ${fadeInDuration}s`}}>
                <IconsLoadedContext.Provider value={tracker}>{children}</IconsLoadedContext.Provider>
            </div>
            <style jsx>{`
            .container {
                opacity: 0;
                visibility: hidden;
            }

            .container.show {
                opacity: 1;
                visibility: visible;
            }
            `}</style>
        </>
    );
}


// ------------

export type IconProps = {
    name: string;
    size?: number;
    shiftTop?: number;
    shiftLeft?: number;
};

export function Icon({name,size = 30,shiftTop = 0,shiftLeft = 0}: IconProps)
{
    const tracker = useContext(IconsLoadedContext);

    useEffect(() => 
    {
        if (tracker) tracker.register();
        iconWaiter.waitFor(name,() => 
        {
            if (tracker) tracker.loaded();
        });
    },[]);
    
    return (
        <>
            <i className={`icon-${name}`} style={{
                fontSize: `${size}rem`,
                left: `${shiftLeft}rem`,
                top: `${shiftTop}rem`
            }}></i>
            <style jsx>{`
            i {
                position: relative;
            }
            `}</style>
        </>
    );
}