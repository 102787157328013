import { useContext, useEffect, useRef } from "react";
import { LayoutStateContext } from "../contexts/layout_state";
import { WindowSizeContext } from "../contexts/window_size";
import { useCssStateManager } from "../hooks/use_css_state_manager";
import { useCustomRouter } from "../hooks/use_custom_router";
import { useSmartState } from "../hooks/use_smart_state";
import { prebuildData } from "../lib/prebuild-data";

export type FooterProps = {
    hide: boolean;
};

type LinksHash = {[key: string]: {url?: string,handler?: () => void}};

export default function Footer({hide}:FooterProps)
{    
    const cssStateManager = useCssStateManager(["footer"]);
    cssStateManager.useProperty(hide,"hide");
    const dealsCompact = cssStateManager.useState(false,"deals-compact");

    const { mobileDetect,openBugReporter } = useContext(LayoutStateContext);

    const router = useCustomRouter();

    const { widthCutoff: mobileCutoff } = mobileDetect;
    const windowSize = useContext(WindowSizeContext);

    const columnContactsRef = useRef<HTMLDivElement>();

    useEffect(() => {
        if (columnContactsRef.current) {
            const { current: columnContactsElem  } = columnContactsRef;
            dealsCompact.value = (columnContactsElem.offsetWidth<330);
        }
    },[windowSize.width]);

    const reportABugHandler = () => {
        openBugReporter();
    };

    const quickLinks: LinksHash  = {
        "Home": {url: "/"},
        "Product Registration (A2)": {url: "/pages/caliburn-a2-product-registration"},
        "Warranty Claims (A2)": {url: "/pages/a2-warranty"},
        "Lab Reports": {url: "https://drive.google.com/drive/folders/1G-Y5y1SUA5mo6Buv-6vjI6KJoi-M-ZYU?usp=sharing"},
        "GCC Compliance": {url: "/pages/gcc-compliance"},
        "Recall Notice": {url: "/pages/recall-notice"},
        "Report a bug": {handler: reportABugHandler},
        "Support": {url: "/pages/help"},
    };    

    const newsText = "We've made some recent quality-of-life changes to our checkout to improve our customer's experience.";

    const newsLinks : {[key: string]: {url: string}} = {
        "Vaping News": {url: "/blogs/vape-news"},
        "Checkout Improvements": {url: "/blogs/vape-news/checkout-improvements"},
    };    

    const policyLinks : {[key: string]: {url: string}} = {
        "Privacy Policy": {url: "/policies/privacy-policy"},
        "Shipping Policy": {url: "/policies/shipping-policy"},
        "Refund Policy": {url: "/policies/refund-policy"},
        "Terms and Conditions": {url: "/policies/terms-of-service"}
    };    

    const linkClickHandler = (hash: LinksHash,key: string,preventDefault: () => void) => {
        const { url,handler } = hash[key];
        if ((url) && (!/^https?:\/\//.test(url))) {
            preventDefault();
            router.push(hash[key].url);
        }     
        else if (handler) {
            preventDefault();
            handler();
        }
    };

    const year = (new Date()).getFullYear();    

    const { footer: { backgroundColor,boldTextColor,buttonBackgroundColor,buttonTextColor,lightTextColor,linkHoverColor,socialIconColor,socialIconHoverColor } } = prebuildData.getActiveTheme();

    return (
        <>
            <div className={cssStateManager.getClassNames()}>
                <div className="columns">
                    <div className="spacer side"></div>
                    <div className="quick-links column">
                        <div className="title bold">Quick Links</div>
                        {Object.keys(quickLinks).map((key) => (
                            <div key={key} className="link">
                                <a target="_blank" href={quickLinks[key].url || "#"} onClick={(event) => linkClickHandler(quickLinks,key,event.preventDefault.bind(event))}>{key}</a>
                            </div>
                        ))}
                    </div>
                    <div className="spacer"></div>
                    <div className="news column">
                        {Object.keys(newsLinks).map((key) => (
                            <div key={key} className="link bold">
                                <a href={newsLinks[key].url} onClick={(event) => linkClickHandler(newsLinks,key,event.preventDefault.bind(event))}>{key}</a>
                            </div>
                        ))}
                        <div className="news-text">{newsText}</div>
                    </div>
                    <div className="spacer"></div>
                    <div className="contacts column" ref={columnContactsRef}>
                        <div className="title bold">Join Our Vape Deals Club</div>
                        <div className="deals-form">
                            <form action="//tbdliquids.us9.list-manage.com/subscribe/post?u=d87dae29583b5c7f54ab171c5&id=4c211797d8" method="post" target="_blank">
                                <input className="deals-input-text" type="email" placeholder="Email" name="EMAIL" id="mail" />
                                <br className="deals-input-break" />
                                <input type="submit" className="subscribe-button" value="Subscribe" name="subscribe" id="subscribe" />
                            </form>                    
                        </div>
                        <div className="section">
                            <div className="title bold">Follow Us On Social Media</div>
                            <div className="social-icons">
                                <a title="Follow us on Facebook" href="https://www.facebook.com/tbdliquids" target="_blank"><i className="icon-facebook social-icon"></i></a>
                                <div className="social-spacer"></div>
                                <a title="Follow us on Instagram" href="https://www.instagram.com/tbdliquids" target="_blank"><i className="icon-instagram social-icon"></i></a>
                            </div>
                        </div>
                        <div className="section">
                            <div className="line-text">Hours: Mon-Fri 10AM-6PM PST</div>
                            <div className="line-text">Phone: 1+800-823-0813</div>
                        </div>
                    </div>
                    <div className="spacer side"></div>
                </div>
                <div className="policy-menu">
                    {Object.keys(policyLinks).map((key,index) => (
                        <div key={key} className="link bold">
                            {(index!==0)?<><div className="policy-spacer"></div>|<div className="policy-spacer"></div></>:""}
                            <a href={policyLinks[key].url} onClick={(event) => linkClickHandler(policyLinks,key,event.preventDefault.bind(event))}>{key}</a>
                        </div>
                    ))}
                    <div className="copyright">
                        <div className="line-text">Copyright © {year} TBD Liquids</div>
                    </div>                    
                </div>
                <div className="cards">
                    <img src={prebuildData.bustCache("/images/footer/amex.svg")} />
                    <div className="cards-spacer"></div>
                    <img src={prebuildData.bustCache("/images/footer/discover.svg")} />
                    <div className="cards-spacer"></div>
                    <img src={prebuildData.bustCache("/images/footer/mastercard.svg")} />
                    <div className="cards-spacer"></div>
                    <img src={prebuildData.bustCache("/images/footer/visa.svg")} />
                </div>
            </div>
            <style jsx>{`
            .footer {
                width: 100%;
                background-color: ${backgroundColor};
                padding: 40rem 0rem;
                color: ${lightTextColor};
                height: 520rem;                
            }

            .deals-input-break {
                display: none;
            }

            .cards {
                display: flex;
                justify-content: center;
                margin-top: 22rem;
            }

            .cards img {
                height: 30rem;
            }

            .cards-spacer {
                flex: 0 0 20rem;
            }

            .copyright {
                margin-top: 15rem;
            }

            .columns {
                display: flex;
                justify-content: space-around;
                height: 327rem;
            }

            .deals-input-text {                
                padding: 8rem 6rem;
                border-radius: 4rem;
                outline: none;
                border: 1rem solid #ddd;
            }

            .deals-form {
                margin-top: 15rem;
            }

            .social-spacer {
                display: inline-block;
                width: 15rem;
            }

            .section {
                margin-top: 25rem;
            }

            .social-icon {
                font-size: 24rem;
                color: ${socialIconColor};
                transition: color 0.3s;
            }

            .policy-menu {
                text-align: center;
            }

            .policy-menu .link {
                display: inline-block;
            }

            .policy-spacer {
                display: inline-block;
                width: 6rem;
            }

            .subscribe-button {
                margin-left: 10rem;
                padding: 9rem 12rem;
                font-weight: 600;
                background-color: ${buttonBackgroundColor};
                color: ${buttonTextColor};
                border: none;
                border-radius: 4rem;
                cursor: pointer;
                transition: opacity 0.3s;
            }

            .subscribe-button:hover {
                opacity: 0.8;
            }

            .bold {
                font-weight: 600;
                color: ${boldTextColor};
            }

            .title {
                margin-bottom: 8rem;
            }
            .link {
                margin-bottom: 5rem;
            }

            .line-text {
                margin-bottom: 3rem;
                font-size: 15rem;
            }

            a {
                text-decoration: none;
                color: ${boldTextColor};
                transition: color 0.3s;
                font-size: 15rem;
            }

            a:hover {
                color: ${linkHoverColor};
            }

            a:hover .social-icon {
                color: ${socialIconHoverColor};
            }

            .news-text {
                margin-top: 12rem;
                font-size: 15rem;
                line-height: 22rem;
            }

            .hide {
                display: none;
            }

            .column {
                flex: 3 0;                
            }

            .column.contacts {
                /* min-width: 330rem; */
            }

            .spacer {
                flex: 1 0;
            }

            .spacer.side {
                flex: 2 0;
            }

            .deals-compact .deals-input-break {
                display: inline;
            }

            .deals-compact .subscribe-button {
                margin-left: 0rem;
                margin-top: 12rem;
            }

            @media screen and (max-width: ${mobileCutoff}px) {
                .footer {
                    height: auto;
                }

                .columns {
                    display: block;
                    height: auto;
                    /* height: 327rem; */
                }

                .column {
                    padding: 0rem 35rem;
                }

                .spacer {
                    height: 40rem;
                }

                .spacer.side {
                    height: 0rem;
                }

                .policy-menu {
                    margin-top: 45rem;
                    padding: 0rem 20rem;
                }
            }
            `}</style>
        </>
    );
}