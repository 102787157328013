import { CSSProperties, useEffect, useRef } from "react";
import { useCssStateManager } from "../../hooks/use_css_state_manager";
import { useSmartState } from "../../hooks/use_smart_state";

export type LinearTopWorkingIndicatorProps = {
    running: boolean;
};

type PhaseSide = {
    size: number;
    speed: number;
};

type Phase = {
    left: PhaseSide;
    right: PhaseSide;
};

export default function LinearTopWorkingIndicator({running}:LinearTopWorkingIndicatorProps) {
    const cssStateManager = useCssStateManager(["lineartopworkingindicator"]);
    cssStateManager.useProperty(running,"running");
    const runningRef = useRef(running);
    runningRef.current = running;

    const phases: Phase[] = [{
        left: {
            size: 0,
            speed: 0
        },
        right: {
            size: 1,
            speed: 0
        }
    },{
        left: {
            size: 0.0001,
            speed: 1
        },
        right: {
            size: 0.5,
            speed: 1
        }
    },{
        left: {
            size: 0.5,
            speed: 1
        },
        right: {
            size: 0,
            speed: 1
        }
    },{
        left: {
            size: 1,
            speed: 0.6
        },
        right: {
            size: 0,
            speed: 0
        }
    },{
        left: {
            size: 0,
            speed: 0.0001
        },
        right: {
            size: 1,
            speed: 0.0001
        }
    },{
        left: {
            size: 0.0001,
            speed: 0.9
        },
        right: {
            size: 0.4,
            speed: 0.9
        }
    },{
        left: {
            size: 1,
            speed: 0.7
        },
        right: {
            size: 0,
            speed: 0.6
        }
    },{
        left: {
            size: 0,
            speed: 0.0001
        },
        right: {
            size: 1,
            speed: 0.0001
        }
    }];
    const phaseIndex = useSmartState(0);

    const leftSize = phases[phaseIndex.value].left.size;
    const leftSpeed = phases[phaseIndex.value].left.speed;
    const leftStyles: CSSProperties = {
        width: `${leftSize*100}%`,
        transition: `width ${leftSpeed}s linear`
    };
    const rightSize = phases[phaseIndex.value].right.size;
    const rightSpeed = phases[phaseIndex.value].right.speed;
    const rightStyles: CSSProperties = {
        width: `${rightSize*100}%`,
        transition: `width ${rightSpeed}s linear`
    };
    useEffect(() => {
        if (running) {
            phaseIndex.value = 1;
        }
        else {
            phaseIndex.value = 7;
        }
    },[running]);

    const refLeft = useRef<HTMLDivElement>();

    const handleTransitionEnd = (target: HTMLDivElement,propertyName: string) => {
        if ((target===refLeft.current) && (propertyName==="width") && runningRef.current) {
            phaseIndex.value = (phaseIndex.value===7)?1:phaseIndex.value+1;
        }
    };

    return (
        <>
            <div className={cssStateManager.getClassNames()}>
                <div ref={refLeft} className="left strip" style={leftStyles} onTransitionEnd={(e) => handleTransitionEnd(e.target as HTMLDivElement,e.propertyName)}></div>
                <div className="right strip" style={rightStyles}></div>
            </div>
            <style jsx>{`
            .lineartopworkingindicator {
                position: fixed;
                left: 0rem;
                top: 0rem;
                height: 4rem;
                width: 100%;
                z-index: 10000;
                /* background-color: #a1a1fd; */
                background-color: #5d5de5;
                opacity: 0;
                visibility: hidden;
                --duration: 0.3s;
                transition: visibility var(--duration),opacity var(--duration);
            }

            .running {
                opacity: 1;
                visibility: visible;                
            }

            .strip {
                position: absolute;
                top: 0rem;
                height: 100%;
                background-color: #a1a1fd;
            }
            .left {
                left: 0rem;
            }
            .right {
                right: 0rem;
            }
            `}</style>
        </>
    );
}