import { useContext, useRef } from "react";
import { LayoutStateContext } from "../../contexts/layout_state";
import { useCssStateManager } from "../../hooks/use_css_state_manager";
import { useCustomRouter } from "../../hooks/use_custom_router";
import { prebuildData } from "../../lib/prebuild-data";
import { EventPane } from "../core/event_pane";

type TProp = {
    handle: string;
    caption: string;
    selected: boolean;
};

export default function MenuItem({handle,caption,selected}:TProp)
{
    const { page,collectionItems } = useContext(LayoutStateContext);
    const router = useCustomRouter();

    const cssStateManager = useCssStateManager(["item"]);
    cssStateManager.useProperty(selected,"selected");

    const routeRef = useRef<string>();
    const route = routeRef.current?routeRef.current:routeRef.current = (() =>{
        const collectionItem = prebuildData.getCollectionByHandle(handle);
        return collectionItem.route.split("{handle}").join(handle);
    })();

    const handleClick = () => router.push(route);

    const { miniNavbar: { menuTextColor } } = prebuildData.getActiveTheme();

    return (
        <>
            <div className={cssStateManager.getClassNames()}>
                {caption}
                <EventPane onClick={handleClick} seoLinkUrl={route} />
            </div>
            <style jsx>{`
            .item {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: 16.5rem;
                font-weight: 400;
                color: ${menuTextColor};
                padding: 0rem 18rem;
                cursor: pointer;
                transition: opacity 0.3s;
                opacity: 0.85;
                position: relative;
                transition: background-color var(--touch-duration);
            }

            .item.selected {
                opacity: 1;
            }

            @media screen and (max-width: 1550px)
            {
                .item {
                    font-size: 15.5rem;                                
                }
            }

            @media screen and (max-width: 1500px)
            {
                .item {
                    font-size: 14.5rem;                                
                }
            }

            @media screen and (max-width: 1450px)
            {
                .item {
                    font-size: 13.5rem;                                
                }
            }

            @media screen and (max-width: 1370px)
            {
                .item {
                    font-size: 12.5rem;                                
                }
            }

            .item.touchdown {
                background-color: rgba(255,255,255,0.07);
            }

            .item.hover
            {
                opacity: 1;
            }
            `}</style>
        </>
    )
}