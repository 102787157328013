import { useEffect, useRef } from "react";
import { consumeAccount } from "../hooks/use_account";
import { useCssStateManager } from "../hooks/use_css_state_manager";
import { useSmartState } from "../hooks/use_smart_state";
import { useRouter } from "next/router";
import { EventPane } from "./core/event_pane";
import { useCustomRouter } from "../hooks/use_custom_router";
import Desktop from "./common/desktop";
import Mobile from "./common/mobile";
import AccountBarMobileDropDown from "./account-bar/mobile-drop-down";
import { horizontalSpacer } from "./common/horizontal-spacer";
import { misc } from "../lib/misc";
import { usePubSubReceiver, usePubSubSender } from "../hooks/use_pub_sub";
import { TextButton } from "./common/text-button";

export type AccountBarProps = {
};

const HorizontalSpacer = horizontalSpacer(8);

export type AcccountBarParentNavEntry = {
    caption: string;
    path: string;
};

const parentNavStack: AcccountBarParentNavEntry[] = [];
let accountBarUpdateCounter = 0;

export const useAccountBarParentNav = (caption: string,path: string) => {
    const entryRef = useRef<AcccountBarParentNavEntry>();
    entryRef.current = entryRef.current || {caption,path};

    const { send: sendCounterUpdate } = usePubSubSender("account-bar-update-counter");

    useEffect(() => {
        parentNavStack.push(entryRef.current);
        sendCounterUpdate(++accountBarUpdateCounter);
        return () => {
            misc.removeItemsFromArray(parentNavStack,entryRef.current);
            sendCounterUpdate(++accountBarUpdateCounter);
        };
    },[]);
};

export default function AccountBar({}:AccountBarProps) {
    const reactRouter = useRouter();
    const router = useCustomRouter();
    const beforeQuery = reactRouter.asPath.split("?")[0];
    const isOnAccountPage = /^\/account(?:\/|$)/.test(beforeQuery) || beforeQuery==="/pages/help";
    const currentlySigningOut = useSmartState("");

    usePubSubReceiver("account-bar-update-counter",0);

    type BreadcrumbTableComplex = {
        parentCaption: string;
        parentPath: string;
    };

    type BreadcrumbTable = {
        [key: string] : true | BreadcrumbTableComplex
    };

    const breadcrumbTable: BreadcrumbTable = {
        orders: true,
        "manage-account": true,
        "your-addresses": true,
        "your-transactions": true,
        "your-reviews": true,
        "manage-account/name": {
            parentCaption: "Manage Account",
            parentPath: "/account/manage-account",
        }
    };

    const breadcrumbId = useSmartState("");

    const cssStateManager = useCssStateManager(["account-bar"]);

    const account = consumeAccount();

    const handleSignoutClick = () => {
        if (account.value) {
            currentlySigningOut.value = account.value.user.email;
            account.value = null;
            router.push("/login");
        }
    };

    useEffect(() => {
        const beforeQuery = reactRouter.asPath.split("?")[0];        
        if ((beforeQuery==="/login") && (currentlySigningOut.value)) {
            currentlySigningOut.value = "";
        }        
        if (beforeQuery.startsWith("/account/")) {            
            const potentialId = beforeQuery.substring("/account/".length);
            breadcrumbId.value = breadcrumbTable[potentialId]?potentialId:"";
        }
        else {
            breadcrumbId.value = "";
        }
    },[reactRouter.asPath]);

    const handleBreadcrumbAccountClick = () => {
        router.push("/account");
    };

    const genHandleBreadcrumbParentClick = (parentPath: string) => {
        return () => {
            if (misc.isExternalPath(parentPath)) {
                location.href = parentPath;
            }
            else {
                router.push(parentPath);
            }            
        };
    };

    return (
        ((isOnAccountPage && account.value) || currentlySigningOut.value)?(
        <>
            <Desktop>
                <div className={cssStateManager.getClassNames()}>
                    {
                        (() => {
                            if (parentNavStack.length>0) {
                                const { caption: parentCaption,path: parentPath} = parentNavStack[parentNavStack.length-1];
                                return (
                                    <div className="breadcrumbs">
                                        <TextButton caption={`≪ ${parentCaption}`} onClick={genHandleBreadcrumbParentClick(parentPath)} />
                                    </div>
                                );
                            }
                            else {
                                return "";
                            }
                        })()
                    }
                    <div className="filler"></div>
                    <div className="email">
                        {account.value?account.value.user.email:currentlySigningOut.value}
                    </div>
                    <div className="sep">|</div>
                    <div className="signout">
                        Signout
                        <EventPane overlapVert={10} overlapHori={5} onClick={handleSignoutClick} />
                    </div>
                </div>
            </Desktop>
            <Mobile>
                <AccountBarMobileDropDown email={account.value?account.value.user.email:currentlySigningOut.value} onSignoutClick={handleSignoutClick} />
            </Mobile>
            <style jsx>{`
            .account-bar {
                display: flex;
                /* justify-content: flex-end; */
                padding: 0rem 50rem;
                padding-top: 24rem;
                padding-bottom: 4rem;
                font-size: 14rem;
            }
            .breadcrumbs {
            }
            .filler {
                flex: 1 0;
            }
            .sep {
                padding: 0rem 5rem;
                color: #999;
            }
            .email {
                color: #777;
            }
            .signout {
                font-weight: 600;
                color: #555;
                position: relative;
            }

            .signout.hover {
                opacity: 0.80;
            }
            `}</style>
        </>):<></>
    );
}