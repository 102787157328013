import { misc } from "./misc";

export const infiniteFetch = async (url: string,init?: RequestInit) => {
    while(true) {
        try {
            return await fetch(url,init);
        }
        catch(err) {
            console.log("infiniteFetch err",err);
            await misc.sleep(1000);
        }        
    }
};