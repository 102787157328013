import { useContext, useEffect, useRef } from "react";
import { LayoutStateContext } from "../../contexts/layout_state";
import { useCurrentCollection } from "../../hooks/use_current_collection";
import { useDesktopScrollbar } from "../../hooks/use_desktop_scrollbar";
import { prebuildData } from "../../lib/prebuild-data";
import { SideMenuCollectionsMenuItem } from "./collections_menu_item";

export type SideMenuCollectionsMenuProps = {
    openId: number;
};

export function SideMenuCollectionsMenu({openId}: SideMenuCollectionsMenuProps)
{
    const collectionItems = prebuildData.getCollectionItems();

    const { handle: selectedCollectionHandle } = useCurrentCollection();

    const { ref: scrollerRef,className: scrollerClassName,styles: scrollerStyles,injectedStyles: scrollerInjectedStyles } = useDesktopScrollbar();    

    useEffect(() =>
    {
        const { current: scrollerElem } = scrollerRef;
        if (scrollerElem)
        {
            scrollerElem.scrollTop = 0;
        }
    },[openId]);

    return (
        <>
            <div ref={scrollerRef} className={`container ${scrollerClassName}`} style={scrollerStyles} >
                {collectionItems.map(({handle,caption},index) => (<SideMenuCollectionsMenuItem key={handle} handle={handle} caption={caption} selected={selectedCollectionHandle===handle} />))}
            </div>
            <style jsx>{scrollerInjectedStyles}</style>
            <style jsx>{`
            .container {
                flex: 1 0;
                overflow-y: auto;
            }
            `}</style>
        </>
    );
}