import { useRef, useState } from "react";
import { SmartState, useSmartState } from "./use_smart_state";

export const useCssStateManager = (permanentClassNames: string[] = [],{ horiVertiCentered = false }: { horiVertiCentered?: boolean } = {}) =>
{
    const entriesRef = useRef<{controller: {value: boolean},className: string}[]>([]);
    const entries = entriesRef.current;
    entries.length = 0;

    const controllerRef = useRef({
        useState(initialValue: boolean,className: string,{timedMilliseconds}: {timedMilliseconds?: number} = {}) {
            const [value,setValue] = useState(initialValue);
            const ref = useRef(value);
            const refTimeout = useRef(0);
            ref.current = value;
            const ret: SmartState<boolean> = {
                get value() {
                    return ref.current;
                },
                set value(newValue) {
                    if (timedMilliseconds>0) {
                        clearTimeout(refTimeout.current);
                        refTimeout.current = 0;
                    }
                    setValue(newValue);
                    if ((timedMilliseconds>0) && (newValue)) {
                        refTimeout.current = setTimeout(() => {
                            refTimeout.current = 0;
                            ret.value = false;
                        },timedMilliseconds) as unknown as number;
                    }
                },
                subscribe: () => () => {}
            };
            entries.push({controller: ret,className});
            return ret;
        },
        useStringState<T extends string = string>(initialValue: T,options: T[]) {
            const ret = useSmartState<T>(initialValue);
            for(let i=0;i<options.length;i++) {
                controllerRef.current.useProperty(ret.value===options[i],options[i]);
            }            
            return ret;
        },
        useProperty(value: boolean,className: string) {
            entries.push({controller: {value},className});
        },
        getClassNames(...additionalClassNames: string[]) {
            return [...permanentClassNames,...(horiVertiCentered?["hori-verti-centered"]:[]),...entries.map(({controller,className}) => controller.value?className:null).filter((value) => value!==null),...additionalClassNames].join(" ");
        }
    });

    return controllerRef.current;
};
