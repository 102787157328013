import { useCallback, useEffect, useRef, useState } from "react";

export type SmartState<T> = {
    value: T;
    subscribe?: (cb: (value: T) => void) => (() => void);
};

export const useIsMounted = () => {
    const isMountedRef = useRef(true);
    const isMounted = useCallback(() => isMountedRef.current, []);
  
    useEffect(() => {
      return () => void (isMountedRef.current = false);
    },[]);
  
    return isMounted;
}

export const useSmartState = <T>(initialValue: T,{onSet = () => {}}: {onSet?: (value: T) => void} = {}): SmartState<T> => {
    type subscriptionCb = (value: T) => void;
    
    const [value,setValue] = useState(initialValue);
    const ref = useRef(value);
    const subscriptionsRef = useRef<subscriptionCb[]>([]);
    ref.current = value;

    const isMounted = useIsMounted();

    const interfaceRef = useRef<SmartState<T>>();

    return interfaceRef.current = interfaceRef.current || {
        get value() {
            return ref.current;
        },
        set value(value) {
            if (!isMounted()) return;
            ref.current = value;
            setValue(value);
            onSet(value);
            subscriptionsRef.current.forEach((cb) => cb(value));
        },
        subscribe(cb: subscriptionCb) {
            subscriptionsRef.current.push(cb);
            return () => {
                const tmp = subscriptionsRef.current.filter((_cb) => _cb!==cb);
                subscriptionsRef.current.length = 0;
                subscriptionsRef.current.push(...tmp);
            };
        } 
    };
};