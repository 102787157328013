import { useCssStateManager } from "../hooks/use_css_state_manager";
import { prebuildData } from "../lib/prebuild-data";

export type CreatingCheckoutProps = {
    open: boolean;
    caption?: string;
};

export default function CreatingCheckout({open,caption}:CreatingCheckoutProps)
{
    if (!caption) caption = "Creating Checkout";
    const cssStateManager = useCssStateManager(["container"]);
    cssStateManager.useProperty(open,"open")
    return (
        <>
            <div className={cssStateManager.getClassNames()}>
                <img src={prebuildData.bustCache("/images/creating_checkout/loading.svg")} />
                <div className="text">{caption}...</div>
            </div>
            <style jsx>{`
            .container {
                --duration: 0.4s;
                position: fixed;
                left: 0rem;
                top: 0rem;
                right: 0rem;
                bottom: 0rem;
                background-color: #f5f5f5;
                visibility: hidden;
                opacity: 0;
                transition: visibility var(--duration),opacity var(--duration);
                z-index: 999;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .container.open {
                visibility: visible;
                opacity: 1;
            }

            .container img {
                position: relative;
                top: -26rem;
            }

            .text {
                position: relative;
                top: -72rem;
                color: #939393;
            }
            `}</style>
        </>
    );
}