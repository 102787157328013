import { CSSProperties, useContext, useEffect, useRef } from "react";
import { LayoutStateContext } from "../contexts/layout_state";
import { WindowSizeContext } from "../contexts/window_size";
import { useCachedValue } from "../hooks/use_cached_value";
import { useCssStateManager } from "../hooks/use_css_state_manager";
import { useCustomRouter } from "../hooks/use_custom_router";
import { useDesktopScrollbar } from "../hooks/use_desktop_scrollbar";
import { consumeReviewsHeader } from "../hooks/use_reviews_header";
import { useSmartState } from "../hooks/use_smart_state";
import { keypress } from "../lib/keypress";
import { misc } from "../lib/misc";
import { prebuildData } from "../lib/prebuild-data";
import { libSearch, SearchResult } from "../lib/search";
import PriceDisplay from "./common/price-display";
import StarRating from "./common/star_rating";
import { EventPane } from "./core/event_pane";
import SquareImage from "./core/square_image";

type NoProductFoundProps = {
};

function NoProductFound({}:NoProductFoundProps)
{
    const cssStateManager = useCssStateManager(["no-products-found"]);
    return (
        <>
            <div className={cssStateManager.getClassNames()}>
                No Products Found
            </div>
            <style jsx>{`
            .no-products-found {
                height: 80rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding-left: 32rem;
                color: #ddd;
                font-size: 19rem;
                font-weight: 600;
            }
            `}</style>
        </>
    );
}

type SearchResultItemProps = {
    result: SearchResult;
    selected: boolean;
};

function SearchResultItem({result: { productId,htmlCaption,collectionHtmlCaption },selected}:SearchResultItemProps)
{
    const cssStateManager = useCssStateManager(["search-result-item"]);
    cssStateManager.useProperty(selected,"selected");
    
    const { smallImages } = prebuildData.getProductById(productId);
    
    const layout = useContext(LayoutStateContext);    
    const { searchBarCommRef,mobileDetect } = layout;
    const router = useCustomRouter();
    const { current: searchBarComm } = searchBarCommRef;
    const { widthCutoff: mobileCutoff,mobileSmallScreenCutoff  } = mobileDetect;

    let { reviewsCount,starRating } = consumeReviewsHeader(productId,layout);

    const reviewText = (reviewsCount!==-1)?((reviewsCount>0)?`${reviewsCount} review${(reviewsCount>1)?"s":""}`:"No reviews yet"):"---";
    starRating = Math.min(Math.max(Math.round(starRating*2)/2,0),5);
    
    const productPageRoute = useCachedValue(() => prebuildData.getProductPageRoute(productId),productId);

    const handleClick = () => {
        router.push(productPageRoute);
        searchBarComm.endBlurDelay();
    };

    const handleTouchOrMouseDown = () => {
        searchBarComm.openBlurDelay();
    };

    return (
        <>
            <div className={cssStateManager.getClassNames()}>
                <div className="thumb-column">
                    <div className="thumb">
                        <SquareImage src={prebuildData.bustCache(smallImages[0].src)} />
                    </div>
                </div>
                <div className="info">
                    <div className="caption" dangerouslySetInnerHTML={{__html: htmlCaption}}></div>
                    <div className="rating">
                        <StarRating rating={starRating} scale={0.75} />
                        {/*}
                        <div className="rating-count">{reviewText}</div>
                        {*/}
                    </div>
                    <div className="small-screen-footer">
                        <div className="collection-name" dangerouslySetInnerHTML={{__html: collectionHtmlCaption}}></div>        
                    </div>
                </div>
                <div className="collection-name-wrapper">
                    <div className="collection-name" dangerouslySetInnerHTML={{__html: collectionHtmlCaption}}></div>
                </div>    
                <div className="selected-overlay"></div>
                <div className="hover-overlay"></div>
                <EventPane onClick={handleClick} onTouchOrMouseDown={handleTouchOrMouseDown} seoLinkUrl={productPageRoute} />
            </div>
            <style jsx>{`
            .search-result-item {
                padding: 0rem 10rem;
                position: relative;
                display: flex;
                height: 80rem;
                overflow-y: hidden;
            }
            .thumb-column {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .thumb {
                width: 60rem;
            }
            .info {
                margin-left: 15rem;
                /* margin-top: 6rem; */
                flex: 1 1;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .caption {
                font-size: 18rem;
                color: #444;
            }

            .caption :global(.highlight),.collection-name :global(.highlight) {
                background-color: #e8e9ff;
            }

            .rating {
                margin-top: 4rem;
                display: flex;
            }

            .rating-count {
                font-size: 14rem;
                color: #8e9cf5;
                margin-left: 3rem;
            }

            .small-screen-footer {
                display: none;
            }

            .collection-name-wrapper {
                margin-right: 9rem;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .collection-name {
                color: #bbb;
                font-weight: 600;
                font-size: 17rem;
            }

            .hover-overlay,.selected-overlay {
                position: absolute;
                left: 0rem;
                right: 0rem;
                top: 0rem;
                bottom: 0rem;
                opacity: 0;                                
            }

            .hover-overlay {
                background-color: rgba(0,0,0,0.06);
                transition: opacity 0.2s;
                
            }

            .selected-overlay {
                background-color: rgba(0,0,0,0.09);
                border-left: 5rem solid #b0beff;
            }

            .hover .hover-overlay {
                opacity: 1;
            }

            .touchdown .hover-overlay {
                opacity: 1;
            }

            .selected .selected-overlay {
                opacity: 1;
            }

            @media screen and (max-width: ${mobileCutoff}px) {
                .collection-name {
                    font-size: 15rem;
                }

                .caption {
                    font-size: 16rem;
                }
            }

            @media screen and (max-width: ${mobileSmallScreenCutoff}px) {
                .collection-name {
                    display: none;
                }

                .caption {
                    font-size: 14rem;
                }

                .thumb {
                    width: 44rem;
                }

                .rating {
                    display: none;
                }

                .small-screen-footer {
                    display: block;
                }

                .small-screen-footer .collection-name {
                    display: block;
                    font-size: 13rem;
                    padding-top: 5rem;
                }
            }            
            `}</style>
        </>
    );
}

export type SearchProps = {
    open: boolean;
};

type ShowInputTextState = "Invisible" | "Offscreen" | "Visible";

export default function Search({open}:SearchProps)
{
    const { searchBarCommRef,searchOpen,sideCartOpenRef,page,mobileDetect,openSearch,searchOpenType,searchCollectionId,navbarHeaderRef,scalePixelToRem } = useContext(LayoutStateContext);
    const router = useCustomRouter();

    if (globalThis.window) (globalThis.window as any)._searchRouter = router;
    const { isActive: isMobile,widthCutoff: mobileCutoff,isSmallScreen,miniWidthCutoff } = mobileDetect;
    
    const { current: searchBarComm } = searchBarCommRef;
    const searchResults = useSmartState<SearchResult[]>([]);
    const searchInputTextRef = useRef("");
    const lastResultSignatureRef = useRef("");

    const windowSize = useContext(WindowSizeContext);

    const marginFromWindow = 93;
    const mobileMarginFromWindow = 40;
    const collectionMarginFromWindow = 30;
    const mobileCollectionMarginFromWindow = 32;
    const smallScreenMarginFromWindow = 20;
    const heightPerRow = 80;

    const maxRows = useSmartState(8);
    const overflows = searchResults.value.length>maxRows.value;

    const disableKeyboard = useSmartState(false);
    const selectedIndex = useSmartState(0);
    const overlayTop = useSmartState(0);
    const inputTop = useSmartState(0);
    const resultsTop = useSmartState(0);
    const mobileNavbarCoverTop = useSmartState(0);

    useEffect(() => {
        const hasTouchEvents = misc.hasTouchEvents();
        disableKeyboard.value = hasTouchEvents;
        if (hasTouchEvents) {
            selectedIndex.value = -1;
        }
    },[]);

    const isCollectionType = searchOpenType.value==="collection";

    const placeholderText = isMobile.current?(searchCollectionId?`Search in ${prebuildData.getCollectionById(searchCollectionId).caption}`:""):"";

    let { ref: resultsRef,styles: resultsStyles,className: resultsClassName,injectedStyles: resultsInjectedStyles } = useDesktopScrollbar();        

    let tmp: {left: number;top: number};

    useEffect(() => {
        if (resultsRef.current) {
            const availableSpace = windowSize.height-(misc.getOffsetRelativeToParent(resultsRef.current,".page").top-page.scrollTop());
            const potAns = Math.floor(availableSpace/heightPerRow);
            maxRows.value = ((potAns>1)?potAns-1:potAns);
        }
        if (navbarHeaderRef.current) {
            const headerTop = scalePixelToRem(misc.getOffsetRelativeToParent(navbarHeaderRef.current,".page").top);
            const headerHeight = scalePixelToRem(navbarHeaderRef.current.offsetHeight);
            overlayTop.value = headerTop+headerHeight+(isCollectionType && isMobile.current?41:0)+(isCollectionType && !isMobile.current?48:0);
            inputTop.value = (isCollectionType?(isMobile.current?168:176):40)+headerTop-21;            
            resultsTop.value = (isCollectionType?(isMobile.current?219:226):102)+headerTop-22;
            mobileNavbarCoverTop.value = headerTop;
        }        
    },[windowSize.height,resultsRef.current?misc.getOffsetRelativeToParent(resultsRef.current,".page").top:0,searchOpen.value]);      
    
    const overlayStyles: CSSProperties = {
        top: `${overlayTop.value}rem`
    };
    
    const rightOpen = (isMobile.current?(isCollectionType?`${mobileCollectionMarginFromWindow}rem`:`${isSmallScreen.current?smallScreenMarginFromWindow:mobileMarginFromWindow}rem`):(isCollectionType?`${collectionMarginFromWindow}rem`:`${marginFromWindow}rem`));
    const widthOpen = (isMobile.current?`calc(100% - ${(isCollectionType?mobileCollectionMarginFromWindow:(isSmallScreen.current?smallScreenMarginFromWindow:mobileMarginFromWindow))*2}rem)`:(isCollectionType?"520rem":"calc(50% - 185rem)"));
    
    const animationStyles: CSSProperties = {
        top: `${inputTop.value}rem`,
        right: open?rightOpen:`${isSmallScreen.current?smallScreenMarginFromWindow:marginFromWindow}rem`,
        width: open?(isCollectionType?"0rem":widthOpen):"0rem"
    };    

    const inputWrapperStyles: CSSProperties = {
        top: `${inputTop.value}rem`,
        right: rightOpen,
        width: widthOpen
    };        
    
    const resultsMarginRight = isMobile.current?`${isCollectionType?mobileCollectionMarginFromWindow:(isSmallScreen.current?smallScreenMarginFromWindow:mobileMarginFromWindow)}rem`:(isCollectionType?`${collectionMarginFromWindow}rem`:`${marginFromWindow}rem`);
    const resultsMarginLeft = ((isCollectionType && !isMobile.current)?"40%":resultsMarginRight);

    resultsStyles = {
        ...resultsStyles,        
        left: resultsMarginLeft,
        right: resultsMarginRight,
        top: `${resultsTop.value}rem`
    };

    const mobileNavbarCoverStyles: CSSProperties = {
        top: `${mobileNavbarCoverTop.value}rem`
    };

    useEffect(() => {
        return keypress.onKey(() => {
            if ((searchInputRef.current) && (!sideCartOpenRef.current)) {
                if (page.scrollTop()>0) {
                    page.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                    misc.waitForCondition(() => page.scrollTop()===0,() => {
                        openSearch();
                    });
                }
                else {
                    openSearch();
                }                
            }
        },"s",{ctrl: true});
    },[]);

    useEffect(() => {
        const scrollIntoView = (index: number) => {
            const overflows = searchResults.value.length>maxRows.value;
            if (overflows) {
                const viewportHeight = maxRows.value*heightPerRow;
                const viewportStart = resultsRef.current.scrollTop;
                const viewportEnd = viewportStart+viewportHeight;
                const itemStart = index*heightPerRow;
                const itemEnd = (index+1)*heightPerRow;
                let diff = 0;
                if (itemStart<viewportStart) {          
                    diff = itemStart-viewportStart;
                }
                else if (itemEnd>viewportEnd) {
                    diff = itemEnd-viewportEnd;
                }
                resultsRef.current.scrollTop+= diff; 
            }
        };

        searchBarComm.notifyMeOnPlaceholderAnimationStart = () => {
            if (!isMobile.current) {
                showInputText.value = "Offscreen";
                if (inputWrapperRef.current) {
                    misc.waitForStyleValue(inputWrapperRef.current,"display","block",() => {
                        if (searchInputRef.current) searchInputRef.current.focus();
                    });
                }                
            }
        };

        searchBarComm.notifyMeOnPlaceholderAnimationEnd = () => {
            showInputText.value = "Visible";
            if (isMobile.current) {
                if (inputWrapperRef.current) {
                    misc.waitForStyleValue(inputWrapperRef.current,"display","block",() => {
                        if (searchInputRef.current) searchInputRef.current.focus();
                    });
                }                
            }
        };

        searchBarComm.notifyMeOnBlurDelayEnd = () => {
            handleSearchInputBlur();
        };

        searchBarComm.notifyMeOnArrowUpPressed = () => {
            if (disableKeyboard.value) return;
            let newValue: number;
            if (selectedIndex.value>0) {
                newValue = selectedIndex.value-1;
            }
            else {                
                newValue = searchResults.value.length-1
            }     
            selectedIndex.value = newValue;
            scrollIntoView(newValue);
        };    
        searchBarComm.notifyMeOnArrowDownPressed = () => {
            if (disableKeyboard.value) return;
            let newValue: number;
            if (selectedIndex.value<searchResults.value.length-1) {
                newValue = selectedIndex.value+1;
            }
            else {
                newValue = 0;
                selectedIndex.value = 0;
            }
            selectedIndex.value = newValue;
            scrollIntoView(newValue);
        };
        searchBarComm.notifyMeOnEnterPressed = () => {
            if (disableKeyboard.value) return;
            const { productId } = searchResults.value[selectedIndex.value];
            const productPageRoute = prebuildData.getProductPageRoute(productId);
            router.push(productPageRoute);
            if (searchInputRef.current) searchInputRef.current.blur();
        };
    },[]);

    const cssStateManager = useCssStateManager(["search"]);
    cssStateManager.useProperty((searchInputTextRef.current==="") || (!open),"no-search");    
    cssStateManager.useProperty(open,"open");
    cssStateManager.useProperty(overflows,"overflows");
    cssStateManager.useProperty(isCollectionType,"is-collection-type");
    const showInputText = useSmartState<ShowInputTextState>("Invisible");
    cssStateManager.useProperty(showInputText.value==="Offscreen","show-input-text-offscreen");
    cssStateManager.useProperty(showInputText.value==="Visible","show-input-text");

    const singleCharSearchTimeoutHandleRef = useRef(0);

    const searchInputText = useSmartState("");

    const searchInputRef = useRef<HTMLInputElement>();

    const handleSearchInputBlur = () => {
        if (!searchBarComm.isBlurDelayed()) {
            searchInputText.value = "";
            handleSearchInputChange("");
            searchOpen.value = false;    
            showInputText.value = "Invisible";
        }
        else {
            searchBarComm.declareBlurWasBlocked();
        }
    };

    const handleSearchInputChange = (value: string) => {
        searchInputText.value = value;
        if (singleCharSearchTimeoutHandleRef.current!==0) {
            clearTimeout(singleCharSearchTimeoutHandleRef.current);
            singleCharSearchTimeoutHandleRef.current = 0;
        }
        searchInputTextRef.current = value;           
        const results = libSearch.search(value,isCollectionType?searchCollectionId:undefined);
        const signature = results.map(({productId}) => productId).join("-");
        const signatureChanged = lastResultSignatureRef.current!==signature;
        lastResultSignatureRef.current = signature;
        if (value.length===1) {
            const limit = Math.round(maxRows.value*1.5);
            if (results.length>=limit) {
                const tmp = [...results];
                tmp.length = limit;
                searchResults.value = tmp;
                singleCharSearchTimeoutHandleRef.current = setTimeout(() => {
                    searchResults.value = results;
                    singleCharSearchTimeoutHandleRef.current = 0;
                },750) as unknown as number;
            }
            else {
                searchResults.value = results;
            }
        }
        else {
            searchResults.value = results;
        }   
        if (signatureChanged) {
            if (resultsRef.current) resultsRef.current.scrollTop = 0;
            if (!disableKeyboard.value) {
                selectedIndex.value = 0;            
            }
        }
    };

    const handleSearchInputKeyDown = (key: string,preventDefault: () => void) => {
        if (key==="Escape") {
            if (searchInputRef.current) searchInputRef.current.blur();
        };
        if (key==="ArrowUp") {
            preventDefault();
            searchBarComm.notifyMeOnArrowUpPressed();
        }
        if (key==="ArrowDown") {
            preventDefault();
            searchBarComm.notifyMeOnArrowDownPressed();
        }
        if (key==="Enter") {
            preventDefault();
            searchBarComm.notifyMeOnEnterPressed();
        }
    };

    const percentSideMargins = 10;

    const inputBackAnimationRef = useRef<HTMLDivElement>();
    const inputWrapperRef = useRef<HTMLDivElement>();

    const backAnimationTransitionEndHandler = (target: HTMLDivElement,propertyName: string) => {
        if ((inputBackAnimationRef.current===target) && (propertyName==="width") && (searchOpen.value)) {
            searchBarComm.notifyMeOnPlaceholderAnimationEnd();
        }
    };    

    return (
        <>
            <div className={cssStateManager.getClassNames()}>
                <div className="overlay" style={overlayStyles}>
                    <EventPane />
                </div>
                <div className="mobile-navbar-cover" style={mobileNavbarCoverStyles}>
                </div>
                <div 
                    ref={inputBackAnimationRef} 
                    className="input-back-animation" 
                    onTransitionEnd={(e) => backAnimationTransitionEndHandler(e.target as HTMLDivElement,e.propertyName)}
                    style={animationStyles}
                ></div>
                <div ref={inputWrapperRef} className="input-wrapper" style={inputWrapperStyles}>
                    <input 
                        ref={searchInputRef} 
                        className="input" 
                        type="text" 
                        value={searchInputText.value} 
                        onChange={(e) => handleSearchInputChange(e.target.value)}
                        onBlur={handleSearchInputBlur}
                        onKeyDown={(e) => handleSearchInputKeyDown(e.code,e.preventDefault.bind(e))}
                        placeholder={placeholderText}
                    />
                    <div className="search-icon">
                        <i className="icon-navbar-search" />
                    </div>
                    <div className="search-close">
                        <i className="icon-navbar-cross" />
                        <EventPane onClick={handleSearchInputBlur} />
                    </div>
                </div>
                <div ref={resultsRef} className={`results ${resultsClassName}`} style={{
                    ...(overflows?{height: `${heightPerRow*maxRows.value}rem`}:{}),
                    ...resultsStyles
                }}>
                    {searchResults.value.map((result,index) => <SearchResultItem key={result.productId} result={result} selected={selectedIndex.value===index} />)}
                    {((searchInputTextRef.current.trim()!=="") && (searchResults.value.length===0))?<NoProductFound />:""}
                </div>
            </div>
            <style jsx>{resultsInjectedStyles}</style>
            <style jsx>{`
            .search {
                --duration: var(--search-open-duration);
            }

            .no-search .results {
                visibility: hidden;
            }

            .mobile-navbar-cover {
                z-index: 98;
                position: absolute;
                left: 0rem;
                right: 0rem;
                top: 22rem;
                height: 80rem;
                background-color: var(--color-header);
                visibility: hidden;
                opacity: 0;
                transition: opacity var(--duration),visibility var(--duration);
            }

            .input-wrapper {
                position: absolute;
                height: 37rem;

                z-index: 99;         
                display: none; 
            }

            .is-collection-type .input-wrapper {
            }

            .show-input-text .input-wrapper {
                display: block; 
            }

            .show-input-text-offscreen .input-wrapper {
                display: block;
                transform: translateX(-5000rem);
            }

            .input {
                width: 100%;
                font-size: 16rem;
                padding-right: 10rem;
                padding-top: 11rem;    
                padding-bottom: 9rem;    
                padding-left: 40rem;            
                border-radius: 6rem;
                color: #555;               
                outline: none;
                border: 1rem solid #aaa; 
            }

            .input::placeholder {
                color: #aaa;
                font-size: 15rem;
                font-family: "Open Sans", "Arial", "Helvetica", sans-serif;
            }

            .search-icon {
                position: absolute;
                font-size: 20rem;
                color: #777;
                left: 11rem;
                top: 7rem;
            }

            .search-close {
                position: absolute;
                right: 5rem;
                top: 3rem;
                font-size: 14rem;
                color: #aaa;
                --size: 32rem;
                width: var(--size);
                height: var(--size);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .overlay {
                position: absolute;
                left: 0rem;
                right: 0rem;
                bottom: 0rem;
                z-index: 96;
                background-color: rgba(0,0,0,0.5);                
                opacity: 0;
                visibility: hidden;
                transition: opacity var(--duration),visibility var(--duration);
            }

            :not(.is-collection-type) .overlay {
                z-index: 98;
            }

            .is-collection-type .overlay {
                top: 150rem;
            }

            .open .overlay {
                opacity: 1;
                visibility: visible;
            }

            .results {
                position: absolute;
                background-color: #fff;
                z-index: 99;
                border: 1rem solid #666;
            }

            .is-collection-type .results {
            }

            .overflows .results {
                overflow-y: auto;
            }

            .input-back-animation {
                position: absolute;
                height: 40rem;
                background-color: #FFF;
                z-index: 98;
                border-radius: 6rem;
                opacity: 0;
                transition: width var(--duration), right var(--duration), opacity var(--duration);
            }

            .open .input-back-animation {
                opacity: 1;
            }

            @media screen and (max-width: ${mobileCutoff}px) {
                :not(.is-collection-type).open .mobile-navbar-cover {
                    opacity: 1;
                    visibility: visible;
                }

                .is-collection-type .input-wrapper {
                }

                .is-collection-type .results {
                }

                .is-collection-type .overlay {
                }

                input {
                    font-size: 15rem;
                }
            }

            @media screen and (max-width: ${miniWidthCutoff}px) {
                .input::placeholder,.input {
                    font-size: 14rem;
                }

                .input {
                    padding-top: 12rem;    
                    padding-bottom: 10rem;    
                }
            }            
            `}</style>
        </>
    );
}