import PromoSpace from "./promo_space"

type TProp = {
    aspectRatio: number;
    iconName: string;
    text: string;
    url?: string;
};

export default function PromoRight({aspectRatio,iconName,text,url}: TProp)
{
    return (
        <>
            <PromoSpace {...{aspectRatio,iconName,text,url}}>
                <div></div>
            </PromoSpace>
        </>
    );
}