import { CSSProperties } from "react";

export type VerticalCenterProps = {
    children?: React.ReactElement;
    frameStyle?: CSSProperties;
};

export function VerticalCenter({children = null,frameStyle}: VerticalCenterProps)
{
    frameStyle = frameStyle || {};
    return (
        <>
            <div className="container"
                    style={frameStyle}
                >
                    <div>
                        {children}
                    </div>
                </div>
            <style jsx>{`
            .container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
            }
            `}</style>
        </>
    );
}