import { useIconButton } from "./icon_button";
import SpacerRow from "./spacer_row"
import Logo from "./navbar/logo"
import PromoLeft from "./navbar/promo_left"
import PromoRight from "./navbar/promo_right"
import { Menu } from "./navbar/menu"
import { CSSProperties, useContext, useEffect, useRef, useState } from "react"
import { GlobalStateContext } from "../contexts/global_state"
import { misc } from "../lib/misc"
import { LayoutStateContext } from "../contexts/layout_state"
import { Text } from "./core/text"
import { DimensionsStateContext } from "../contexts/dimensions_state"
import { prebuildData } from "../lib/prebuild-data"
import { useResizeObserver } from "../hooks/use_resize_observer"
import { useCssStateManager } from "../hooks/use_css_state_manager"
import { useCustomRouter } from "../hooks/use_custom_router"
import { consumeAccount } from "../hooks/use_account";

type Props = {
    cartClickHandler?: () => void;
    cartItemCount: number;
};

export default function Navbar({cartClickHandler = () => {},cartItemCount}: Props)
{    
    const downFixedTopThreshold = 22;
    const upFixedTopThreshold = 40;
    let lastScrollTop = 0;

    const router = useCustomRouter();

    const globalState = useContext(GlobalStateContext);    
    const { sideMenu,mobileDetect,searchOpen,openSearch,searchOpenType,openSmileIframe,navbarHeaderRef } = useContext(LayoutStateContext);
    const { mobileSmallScreenCutoff: mobileSmallWidthCutoff } = mobileDetect;
    const collectionItems = prebuildData.getCollectionItems();

    const sidePaddingSpacerSize = 20;
    const buttonSpacerSize = 22;
    const logoSize = 75;

    const menuClickHandler = () => {
        sideMenu.setShow(true);
    };

    const isCollectionType = searchOpenType.value==="collection";
    
    const cssStateManager = useCssStateManager(["navbar"]);
    cssStateManager.useProperty(searchOpen.value,"search-open");
    cssStateManager.useProperty(isCollectionType,"is-collection-type");

    const fixedIconButtonWidth = 60;

    const buttonVerticalPadding = 100;
    const logoPaddingHori = 5;

    const { navbarHeight } = useContext(DimensionsStateContext);

    const refNavbarOnResize = useResizeObserver(({height}) =>
    {
        navbarHeight.set(height);
    });

    const blockSearchClickRef = useRef(false);
    const blockSearchClickTimeoutHandle = useRef(0);

    const searchClickHandler = () => {
        if (blockSearchClickRef.current) {
            if (blockSearchClickTimeoutHandle.current!==0) {
                clearTimeout(blockSearchClickTimeoutHandle.current);
                blockSearchClickTimeoutHandle.current = 0;
            }
            blockSearchClickRef.current = false;
            return;
        }
        if (!searchOpen.value) openSearch();
    };

    const searchTouchOrMouseDownHandler = () => {
        if (searchOpen.value) {
            blockSearchClickRef.current = true;
            blockSearchClickTimeoutHandle.current = setTimeout(() => {
                blockSearchClickRef.current = false;
                blockSearchClickTimeoutHandle.current = 0;
            },500) as unknown as number;
        }
    };

    const { promo } = prebuildData.getGlobalConfig();

    const rewardsClickHandler = () => {
        alert("Demo: this feature is disabled.");
        // openSmileIframe();
    };

    const account = consumeAccount();

    const loginClickHandler = () => {        
        if (account.value) {
            router.push("/account");
        }
        else {
            router.push("/login");
        }        
    };

    const headerRef = useRef<HTMLDivElement>();

    useEffect(() => {
        navbarHeaderRef.current = headerRef.current;
    },[headerRef.current]);

    const { 
        topNicotineNotice: { 
            show: showTopNicotineNotice,
            backgroundColor: topNicotineNoticeBackgroundColor,
            textColor: topNicotineNoticeTextColor
        },
        announcementBar,
        logo: { 
            scale: logoScale 
        },
        navbar 
    } = prebuildData.getActiveTheme();

    const IconButton = useIconButton(navbar.buttonsIconColor,navbar.buttonsTextColor);

    const headerStyle: CSSProperties = {
        backgroundColor: navbar.backgroundColor
    };    

    return (
        <>
            <div className={cssStateManager.getClassNames()} ref={refNavbarOnResize}>
                <div className="warning" style={{display: showTopNicotineNotice?"block":"none"}}>
                    <Text>WARNING: This products contains tobacco-free nicotine. Nicotine is an addictive chemical.</Text>
                </div>
                {((announcementBar) && (announcementBar.show))?(
                    <div 
                        className="announcement-bar"
                        style={{
                            ...(announcementBar.textColor?{color: announcementBar.textColor}:{}),
                            ...(announcementBar.fontSize?{fontSize: `${announcementBar.fontSize}rem`}:{}),
                            ...(announcementBar.fontWeight?{fontWeight: announcementBar.fontWeight}:{}),
                            ...(announcementBar.padding?{padding: `${announcementBar.padding}rem`}:{}),
                            ...(announcementBar.backgroundColor?{backgroundColor: announcementBar.backgroundColor}:{}),
                        }}
                    >{announcementBar.text}</div>
                ):""}
                <div ref={headerRef} className="header" style={headerStyle}>
                    <div className="panel left">                        
                        <div className="left-menu-wrapper">
                            <IconButton 
                                name="navbar-menu" 
                                caption="" 
                                wholeShift={-1}
                                verticalPadding={buttonVerticalPadding}
                                fixedWidth={fixedIconButtonWidth+10} 
                                onClick={menuClickHandler} 
                            />
                        </div>
                        <div className="left-icons-wrapper">
                            <SpacerRow size={sidePaddingSpacerSize-8.375} />
                            <IconButton 
                                name="navbar-account" 
                                caption={account.value?"Account":"Login"} 
                                iconShift={3} 
                                fixedWidth={fixedIconButtonWidth}
                                verticalPadding={buttonVerticalPadding}
                                onClick={loginClickHandler}
                            />                        
                            <SpacerRow size={buttonSpacerSize-8.375-8.617} />
                            {/*}
                            <IconButton 
                                name="navbar-reorder" 
                                caption="Reorder" 
                                disabled={!globalState.session}
                                fixedWidth={fixedIconButtonWidth}
                                verticalPadding={buttonVerticalPadding}
                            />
                            <SpacerRow size={buttonSpacerSize-8.617-7.164} />
                            {*/}
                            <IconButton
                                name="navbar-rewards" 
                                caption="Rewards" 
                                disabled={!globalState.session} 
                                fixedWidth={fixedIconButtonWidth}
                                verticalPadding={buttonVerticalPadding}
                                onClick={rewardsClickHandler}
                            />
                        </div>
                        {promo.left?(
                            <PromoLeft 
                                aspectRatio={1.461538462} 
                                iconName={promo.left.icon}
                                text={promo.left.caption}
                                url={promo.left.url}
                            />
                        ):""}
                    </div>
                    <Logo scale={logoScale} size={logoSize} shift={1} centered paddingLeft={logoPaddingHori} paddingRight={logoPaddingHori} />
                    <div className="panel right">                        
                        <div className="promo-right-wrapper hide-on-search-open">
                            {promo.right?(
                                <PromoRight 
                                    aspectRatio={0.991902834} 
                                    iconName={promo.right.icon} 
                                    text={promo.right.caption}
                                    url={promo.right.url}
                                />
                            ):""}
                        </div>
                        <div className="search-button-wrapper hide-on-search-open">
                            <IconButton 
                                    name="navbar-search" 
                                    caption="Search" 
                                    disabled={!globalState.session} 
                                    fixedWidth={fixedIconButtonWidth}
                                    verticalPadding={buttonVerticalPadding}                                
                                    wholeShift={1}
                                    scale={1.1} 
                                    onClick={() => searchClickHandler()}
                                    onTouchOrMouseDown={() => searchTouchOrMouseDownHandler()}
                                />
                        </div>    
                        <IconButton 
                            name="navbar-cart" 
                            caption="Cart" 
                            iconText={cartItemCount.toString()} scale={1.3} 
                            iconTextShift={6} 
                            wholeShift={-3} 
                            widthPlaceholder={39} 
                            fixedWidth={fixedIconButtonWidth+19}
                            verticalPadding={buttonVerticalPadding}
                            onClick={() => cartClickHandler()}
                        />
                    </div>
                </div>
                <Menu menuItems={collectionItems} />
            </div>
            <style jsx>{`
                .warning {
                    --height: 22rem;
                    height: var(--height);
                    line-height: var(--height);
                    font-size: calc(var(--height) * 0.39);
                    background-color: ${topNicotineNoticeBackgroundColor};
                    color: ${topNicotineNoticeTextColor};
                    text-align: center;
                    font-weight: bold;
                }

                .announcement-bar {
                    text-align: center;
                }

                .left-icons-wrapper {
                    display: flex;
                    flex-direction: row;
                }

                .left-menu-wrapper {
                    display: none;
                    flex-direction: row;
                }

                .promo-right-wrapper {
                    flex-grow: 1;
                    height: 100%;
                }

                .hide-on-search-open {
                    opacity: 1;
                    transition: opacity var(--search-open-duration);
                }

                :not(.is-collection-type).search-open .hide-on-search-open {
                    opacity: 0;
                }

                @media screen and (max-width: ${mobileSmallWidthCutoff}px)
                {
                    .left-icons-wrapper {
                        display: none;
                    }

                    .left-menu-wrapper {
                        display: flex;
                    }    

                    .search-button-wrapper {                    
                        display: none;
                    }
                }
                    
                .panel
                {
                    position: absolute;
                    top: 0rem;
                    width: calc(50% - ${logoSize/2}rem);
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }

                .panel.left {
                    left: 0rem;
                }

                .panel.right {
                    right: 0rem;
                }

                .horizontal-flex {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }

                .navbar {
                    width: 100%;
                    z-index: 2;
                    position: relative;
                }

                .header {
                    height: 80rem;
                    position: relative;
                    overflow: hidden;
                }

                .side-panel
                {
                    flex-basis: 1;
                }

                .header .middle
                {
                    flex-basis: 1;
                }
            `}
            </style>
        </>
    );
}