import { BoughtTogetherClient } from "../hooks/use_bought_together_client";
import { ProductReview, ProductReviewSummary } from "../hooks/use_product_reviews_summary";
import { ReviewsHeaderProductRaw, ReviewsHeaderRaw } from "../hooks/use_reviews_header";
import { DummyReviewGen, ReviewsOrder, ReviewsResponse, apiReview } from "./api/review";
import { prebuildData } from "./prebuild-data";

export class SeededRandomByProductId {
    private seed: number;

    constructor(productId: string | number) {
        this.seed = parseInt(productId.toString())%(2 ** 32);
    }

    next() {
        const a = 1664525;
        const c = 1013904223;
        const m = 2 ** 32;
    
        this.seed = (a * this.seed + c) % m;
        return this.seed / m;
    }

    fromLength(length: number) {
        return Math.floor(this.next()*length);
    }

    betweenInclusive(from: number,to: number) {
        const trueFrom = Math.min(from,to);
        const trueTo = Math.max(from,to);
        return trueFrom+this.fromLength(trueTo-trueFrom+1);
    }

    choice<T>(arr: T[]) {
        return arr[this.fromLength(arr.length)];
    }

    shuffleArray<T>(arr: T[]) {
        for (let i=arr.length-1;i>0;i--) {
            const j = Math.floor(this.next()*(i+1));    
            [arr[i],arr[j]] = [arr[j],arr[i]];
        }
        return arr;
    }
}

export type MockReviewHeader = {
    rating: number;
    timestamp: number;
    type: "good" | "bad";
};

export type MockReviewViewItem = {
    reviewIndex: number;
    ringIndex: number;
};
export type MockReviewView = MockReviewViewItem[];

const randomRing = [8,16,12,2,14,13,6,7,1,17,11,5,3,0,4,19,9,15,18,10,20,19,9,1,2,15,7,0,8,13,6,4,20,5,17,11,16,14,10,3,18,12,1,3,20,8,11,14,19,12,7,18,10,9,2,0,16,15,13,5,17,4,6,12,13,2,14,19,8,18,15,6,1,5,9,17,4,0,11,3,7,10,16,20,12,13,0,2,3,1,8,17,10,9,15,18,14,7,19,20,16,5,4,6,11];

export class ProductMockReviewCollection {
    productId: number;
    header: ReviewsHeaderProductRaw;
    reviewHeaders: MockReviewHeader[] = [];
    ratingsHeader: ProductReviewSummary["ratings"] = {
        "5": 0,
        "4": 0,
        "3": 0,
        "2": 0,
        "1": 0,
    };
    views: {
        mostRecent: MockReviewView;
        highestRated: MockReviewView;
        lowestRated: MockReviewView;
        perRating: {
            "5": MockReviewView;
            "4": MockReviewView;
            "3": MockReviewView;
            "2": MockReviewView;
            "1": MockReviewView;
        }
    } = {
        mostRecent: [],
        highestRated: [],
        lowestRated: [],
        perRating: {
            "5": [],
            "4": [],
            "3": [],
            "2": [],
            "1": [],
        },
    };    
    ringShuffleMap: number[] = [];

    private shuffleArray<T>(arr: T[]) {
        const random = new SeededRandomByProductId(this.productId);
        for (let i=arr.length-1;i>0;i--) {
            const j = Math.floor(random.next()*(i+1));    
            [arr[i],arr[j]] = [arr[j],arr[i]];
        }
        return arr;
    }

    constructor(productId: string | number) {
        this.productId = parseInt(productId.toString());
        let ratings = {"5": 60, "4": 40,"3":20,"2":5,"1": 3};
        this.header = libMockReviews.genReviewsHeaderRaw().products[this.productId];

        const random = new SeededRandomByProductId(this.productId);
        const ratingsRatio = {
            "5": 1100+random.fromLength(30), 
            "4": 400+random.fromLength(30),
            "3": 200+random.fromLength(30),
            "2": 50+random.fromLength(30),
            "1": 30+random.fromLength(30)
        };        
        const ratingsTotal = ratingsRatio["5"]+ratingsRatio["4"]+ratingsRatio["3"]+ratingsRatio["2"]+ratingsRatio["1"];
        const totals = {
            "5": 0,
            "4": 0,
            "3": 0,
            "2": 0,
            "1": 0,
        };
        let tmpTotal = 0;
        for(let i=2;i<=5;i++) {
            totals[i.toString()] = Math.floor((ratingsRatio[i.toString()]/ratingsTotal)*this.header.totalReviews);
            tmpTotal+= totals[i.toString()];
        }
        totals["1"] = this.header.totalReviews-tmpTotal;
        this.ratingsHeader = totals;
        const ratingsOrder: number[] = [];
        for(let i=1;i<=5;i++) {            
            for(let j=0;j<totals[i.toString()];j++) {
                ratingsOrder.push(i);
            }
        }
        this.shuffleArray(ratingsOrder);

        let pntTimestamp = (new Date(2024,1,17,0,0,0)).getTime();

        type TypeCounter = {"good": number; "bad": number; };
        type RatingNumber = "5" | "4" | "3" | "2" | "1";

        let typeIndex: TypeCounter = {good: 0,bad: 0};
        let typeIndexPerRating: {
            "5": TypeCounter;
            "4": TypeCounter;
            "3": TypeCounter;
            "2": TypeCounter;
            "1": TypeCounter;
        } = {
            "5": {good: 0,bad: 0},
            "4": {good: 0,bad: 0},
            "3": {good: 0,bad: 0},
            "2": {good: 0,bad: 0},
            "1": {good: 0,bad: 0},
        };
        for(let i=0;i<ratingsOrder.length;i++) {
            pntTimestamp-= random.betweenInclusive(30*60*1000,24*60*60*1000);            
            const rating = ratingsOrder[i];
            const ratingString = rating.toString() as RatingNumber;
            this.reviewHeaders.push({
                rating,
                timestamp: pntTimestamp,
                type: (ratingsOrder[i]>3)?"good":((ratingsOrder[i]<3)?"bad":((random.fromLength(2)===0)?"good":"bad")),
            });
            const { type } = this.reviewHeaders[this.reviewHeaders.length-1];
            this.views.mostRecent.push({
                reviewIndex: i,
                ringIndex: typeIndex[type]++,
            });
            this.views.perRating[ratingString].push({
                reviewIndex: i,
                ringIndex: typeIndexPerRating[ratingString][type]++,
            });
        }

        typeIndex = {good: 0,bad: 0};
        for(let i=5;i>=1;i--) {
            for(let j=0;j<this.views.perRating[i.toString() as RatingNumber].length;j++) {
                const { reviewIndex } = this.views.perRating[i.toString() as RatingNumber][j];
                const { type } = this.reviewHeaders[reviewIndex];
                const ringIndex = typeIndex[type]++;
                this.views.highestRated.push({
                    reviewIndex,
                    ringIndex,
                });
            }
        }

        typeIndex = {good: 0,bad: 0};
        for(let i=1;i<=5;i++) {
            for(let j=0;j<this.views.perRating[i.toString() as RatingNumber].length;j++) {
                const { reviewIndex } = this.views.perRating[i.toString() as RatingNumber][j];
                const { type } = this.reviewHeaders[reviewIndex];
                const ringIndex = typeIndex[type]++;
                this.views.lowestRated.push({
                    reviewIndex,
                    ringIndex,
                });
            }
        }

        for(let i=0;i<21;i++) this.ringShuffleMap.push(i);
        this.shuffleArray(this.ringShuffleMap);
    }

    ringIndexToDummyIndex(index: number) {
        return this.ringShuffleMap[randomRing[index%randomRing.length]];        
    }

    createRandomRing() {
        const random = new SeededRandomByProductId(723826378267);

        type ExcludeConditions = {[num: string]: true};
        
        const arrayToExcludeConditions = (arr: number[]) => {
            const ret: ExcludeConditions = {};
            arr.forEach(x => ret[x] = true);
            return ret;
        };

        let getOrder = (startCond: ExcludeConditions,endCond: ExcludeConditions) => {
            const arr: number[] = [];
            for(let i=0;i<21;i++) arr.push(i);
            while(true) {
                const newArr = [...arr];
                random.shuffleArray(newArr);
                let found = false;
                for(let i=0;i<5;i++) {
                    found = found || startCond[newArr[i]] || endCond[newArr[24-i]];
                }
                if (!found) return newArr;
            }
        };

        const orders: number[][] = [];
        orders.push(getOrder({},{}));
        orders.push(getOrder(arrayToExcludeConditions(orders[0].slice(20)),{}));
        orders.push(getOrder(arrayToExcludeConditions(orders[1].slice(20)),{}));
        orders.push(getOrder(arrayToExcludeConditions(orders[2].slice(20)),{}));
        orders.push(getOrder(arrayToExcludeConditions(orders[3].slice(20)),arrayToExcludeConditions(orders[0].slice(0,5))));
        console.log(JSON.stringify(orders.flat()));
    }

    private mockReviewViewItemToProductReview(dummyReviewGens: DummyReviewGen,item: MockReviewViewItem) {
        const { reviewIndex,ringIndex } = item;
        const { rating,timestamp,type } = this.reviewHeaders[reviewIndex];            
        const { body,title } = dummyReviewGens.getByTypeAndIndex(type,this.ringIndexToDummyIndex(ringIndex));            
        const ret: ProductReview = {
            title,
            body,
            rating,
            timestamp,
            name: dummyReviewGens.generateRandomName(this.productId+ringIndex+((type==="bad")?100000:0))
        };
        return ret;
    }

    async getProductReviewSummary() {
        const ret: ProductReviewSummary = {
            lastSortKey: "",
            ratings: this.ratingsHeader,
            reviews: []
        };

        const dummyReviewGens = await apiReview.getDummyReviewGens();

        for(let i=0;i<5;i++) {
            ret.reviews.push(
                this.mockReviewViewItemToProductReview(dummyReviewGens,this.views.mostRecent[i])
            );
        }
        ret.lastSortKey = "9";

        return ret;
    }

    async getReviews(order: ReviewsOrder = "most-recent",lastSortKey?: string,{byRating}: {byRating?: number} = {}) {
        const dummyReviewGens = await apiReview.getDummyReviewGens();

        const view = (() => {
            if (byRating) {
                return this.views.perRating[byRating.toString()];
            }
            else {
                if (order==="highest-rated") {
                    return this.views.highestRated;
                }
                else if (order==="lowest-rated") {
                    return this.views.lowestRated;
                }
                else {                    
                    return this.views.mostRecent;
                }
            }    
        })();
        const ret: ReviewsResponse = {
            items: [],
            lastSortKey: "",
        };        
        let pntIndex = lastSortKey?parseInt(lastSortKey)+1:0;
        while((pntIndex<view.length) && (ret.items.length<5)) {            
            ret.lastSortKey = pntIndex.toString();
            ret.items.push(
                this.mockReviewViewItemToProductReview(dummyReviewGens,view[pntIndex++])
            );            
        }
        return ret;
    }
}

class MockReviews {
    private cachedReviewsHeaderRaw: ReviewsHeaderRaw = null;

    genReviewsHeaderRaw() {
        if (this.cachedReviewsHeaderRaw) {
            return this.cachedReviewsHeaderRaw;            
        }

        const ret: ReviewsHeaderRaw = {
            products: {},
        };

        const productTable = prebuildData.getProductTable();
        Object.keys(productTable).forEach((productId) => {
            const random = new SeededRandomByProductId(productId);

            const totalReviews = Math.floor(random.next()*200)+435;
            const totalRating = (3.9+random.next())*totalReviews;
            ret.products[productId] = {
                totalRating,
                totalReviews
            };
        });

        this.cachedReviewsHeaderRaw = ret;

        return ret;
    }

    genBoughtTogetherClient() {
        const random = new SeededRandomByProductId(8008146747649);

        const productTable = prebuildData.getProductTable();
        const productIds = Object.keys(productTable);
        const ret: BoughtTogetherClient = {
            table: {},
            popular: [],
        };

        const getRandomProductId = (excluding: {[id: string]: boolean}) => {
            while(true) {
                const candidate = productIds[Math.floor(random.next()*productIds.length)];
                if (!excluding[candidate]) return candidate;
            }            
        };

        Object.keys(productTable).forEach((productId) => {
            ret.table[productId] = [];
            const excluding: {[id: string]: boolean} = {};
            excluding[productId] = true;
            for(let i=0;i<10;i++) {
                const id = getRandomProductId(excluding);
                const weight = Math.floor(random.next()*10)+1;
                ret.table[productId].push({
                    id,
                    weight
                });
                excluding[id] = true;
            }
        });
        const excluding: {[id: string]: boolean} = {};
        for(let i=0;i<10;i++) {
            const id = getRandomProductId(excluding);
            ret.popular.push(id);
            excluding[id] = true;
        }
        return ret;        
    }

    private currentReviewCollection?: ProductMockReviewCollection;

    async getProductReviewSummary(productId: string | number) {
        if (this.currentReviewCollection?.productId!==parseInt(productId.toString())) {
            this.currentReviewCollection = new ProductMockReviewCollection(productId);
        }
        return await this.currentReviewCollection!.getProductReviewSummary();
    }

    async getReviews(productId: string | number,order: ReviewsOrder = "most-recent",lastSortKey?: string,{byRating}: {byRating?: number} = {}) {
        if (this.currentReviewCollection?.productId!==parseInt(productId.toString())) {
            this.currentReviewCollection = new ProductMockReviewCollection(productId);
        }
        return await this.currentReviewCollection!.getReviews(order,lastSortKey,{byRating});
    }
}

export const libMockReviews = new MockReviews();