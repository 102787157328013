import { useCssStateManager } from "../../hooks/use_css_state_manager";
import { Image } from "../common/scaled-next-image";

export type SquareImageProps = {
    src: string;
    fadeIn?: boolean;
};

export default function SquareImage({src,fadeIn}:SquareImageProps)
{
    const cssStateManager = useCssStateManager(["container"]);
    const show = cssStateManager.useState(false,"show");
    const loadHandler = () => {
        show.value = true;
    };

    return (
        <>
            <div 
                className={cssStateManager.getClassNames()} 
                style={{
                    ...(fadeIn?{transition: "opacity 0.9s"}:{})
                }}
            >
                <div className="frame">
                    <Image src={src} layout="fill" onLoad={loadHandler}/>
                </div>
            </div>
            <style jsx>{`
            .container {
                width: 100%;
                position: relative;
                padding: 50%;
                opacity: 0;
            }
            .container.show {
                opacity: 1;
            }
            .frame {
                position: absolute;
                left: 0rem;
                top: 0rem;
                width: 100%;
                height: 100%;
            }
            `}</style>
        </>
    );
}