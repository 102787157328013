import { useRouter } from "next/router";
import { useCssStateManager } from "../hooks/use_css_state_manager";
import { SmartState } from "../hooks/use_smart_state";
import { misc } from "../lib/misc";
import { Dialog } from "./bug-reporter";
import { EventPane } from "./core/event_pane";

export type SalesStartEndNoticeProps = {
    open: SmartState<boolean>;
    message: string;
    fromCheckout: boolean;
};

export default function SalesStartEndNotice({open,message,fromCheckout}:SalesStartEndNoticeProps) {
    const cssStateManager = useCssStateManager(["sales-start-end-notice"]);
    cssStateManager.useProperty(open.value,"open");

    const reactRouter = useRouter();

    const closeHandler = () => {
        setTimeout(() => {
            if (fromCheckout) {
                window.location.href = misc.addQueryParamToUrl(window.location.href,"from-checkout","true");
            }
            else {
                reactRouter.reload();
            }
        },0);
    };

    return (
        <>
            <div className={cssStateManager.getClassNames()}>
                <div className="overlay">
                    <EventPane onClick={closeHandler} />
                </div>
                <Dialog show onClose={closeHandler} message={message} buttonCaption="Continue" />
            </div>
            <style jsx>{`
            .sales-start-end-notice {
                --duration: 0.4s;
                position: fixed;
                left: 0rem;
                right: 0rem;
                top: 0rem;
                bottom: 0rem;
                z-index: 1501;
                opacity: 0;
                visibility: hidden;
                transition: opacity var(--duration),visibility var(--duration);
                padding: 0rem 10rem;
            }

            .open {
                opacity: 1;
                visibility: visible;                
            }

            .overlay {
                position: absolute;
                left: 0rem;
                right: 0rem;
                top: 0rem;
                bottom: 0rem;
                background-color: rgba(0,0,0,0.45);
            }
            `}</style>
        </>
    );
}