import React from 'react'

export type TWindowSize = {
    width: number;
    height: number;
};

export const WindowSizeContext = React.createContext<TWindowSize>({width: 0,height: 0});

export const getWindowSize = (): TWindowSize => 
{
    if (typeof window !== "undefined")
    {
        const width  = window.innerWidth || document.documentElement.clientWidth || 
        document.body.clientWidth;
        const height = window.innerHeight|| document.documentElement.clientHeight|| 
        document.body.clientHeight;
        return {width,height};        
    }
    else
    {
        return {width: 0,height: 0};        
    }
};