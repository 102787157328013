export const lstore = {
    set(key: string,obj: any) {
        if (globalThis.document) {
            localStorage.setItem(key,JSON.stringify(obj));
        }        
    },
    get<T = any>(key: string) {
        if (!globalThis.document) return null;
        try {
            return JSON.parse(localStorage.getItem(key)) as T;
        }
        catch(e) {
            return null;
        }        
    }
};