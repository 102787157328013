import { CSSProperties, useEffect } from "react";
import { useCssStateManager } from "../../hooks/use_css_state_manager";
import { useSmartState } from "../../hooks/use_smart_state";
import { misc } from "../../lib/misc";

export type PriceDisplayProps = {
    size?: number;
    price: number;
    plus?: boolean;    
    strike?: boolean;
};

interface CustomCSS extends CSSProperties {
    '--size': string;
}

export default function PriceDisplay({size = 30,price,plus = false,strike = false}:PriceDisplayProps)
{
    const cssStateManager = useCssStateManager(["price-display"]);
    cssStateManager.useProperty(strike,"has-strike");
    const { dollar,cents } = misc.splitPriceForDisplay(Math.abs(price));

    const rsize = useSmartState(0);

    useEffect(() => {
        rsize.value = size;
    },[size]);

    return (
        <>
            <div className={cssStateManager.getClassNames()}
                style={{
                    "--size": `${rsize.value}rem`
                } as CustomCSS}
            >
                <div className="flex-container">
                    {plus?(
                        <div className="plus">+</div>
                    ):""}
                    {(price<0)?(
                        <div className="plus">-</div>
                    ):""}                    
                    <div className="sup">$</div>
                    <div className="dollar">{dollar}</div>
                    <div className="sup cents">{cents}</div>
                    <div className="strike"></div>
                </div>
            </div>
            <style jsx>{`
            .price-display {
                display: inline-block;
                font-weight: 600;
                font-size: var(--size);
                display: inline-block;
            }
            .has-strike {
                font-weight: 400;
            }
            .flex-container {
                position: relative;
                display: flex;
                align-items: flex-start;
            }
            .strike {
                position: absolute;
                left: 0rem;
                right: 0rem;
                top: calc(var(--size) * 0.65);
                height: 1rem;
                background-color: #000;
                display: none;
            }

            .has-strike .strike {
                display: block;
            }
            .sup {
                font-size: 55%;
                position: relative;
                top: calc(var(--size) * 0.14);
            }
            .cents {
                left: calc(var(--size) * 0.07);
            }
            .plus {
                font-size: 75%;
                position: relative;
                top: calc(var(--size) * 0.14);
                left: calc(var(--size) * -0.05);
            }
            `}</style>
        </>
    );
}