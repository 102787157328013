export type SquareProps = {
    children: React.ReactNode;
};

export default function Square({children}:SquareProps)
{
    return (
        <>
            <div className="container">
                <div className="frame">{children}</div>
            </div>
            <style jsx>{`
            .container {
                width: 100%;
                position: relative;
                padding: 50%;
            }
            .frame {
                position: absolute;
                left: 0rem;
                top: 0rem;
                width: 100%;
                height: 100%;
            }
            `}</style>
        </>
    );
}