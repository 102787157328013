import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import Head from "next/head"
import Navbar from "./navbar"
import Footer from "./footer"
import { MiniNavBar } from "./mini_navbar"
import { SideMenu } from "./side_menu"
import { LayoutStateContext, MobileDetectType, MobileDetectUseEffectCallback, ScaleFactorDetectUseEffectCallback, SearchOpenType, SmileState, TCollectionItems, TLayoutState, TManagedScrollTopAnimationDuration } from "../contexts/layout_state";
import { misc } from "../lib/misc";
import { DimensionsStateContext, TDimensionsState, useSettableValue } from "../contexts/dimensions_state";
import { useRouter } from "next/router";
import { Router } from 'next/dist/client/router';
import collectionItems from "../config/collections.json";
import { CollectionFilterState, CollectionFilterStateContext, FilterBarSubscriptionCB, FilterMap, SortOrder } from "../contexts/collection_filter_state";
import { routerTransition, RouterTransition } from "./core/router_transition";
import { createCart } from "../lib/api/storefront";
import SideCart from "./side_cart";
import { cart, useCart } from "../lib/services/cart/cart";
import MobileCartHolder from "./mobile_cart_holder";
import { SmartState, useSmartState } from "../hooks/use_smart_state";
import { getWindowSize, TWindowSize, WindowSizeContext } from "../contexts/window_size";
import { useDesktopScrollbar } from "../hooks/use_desktop_scrollbar";
import CreatingCheckout from "./creating_checkout";
import { useReviewsHeader } from "../hooks/use_reviews_header";
import { useBoughtTogetherClient } from "../hooks/use_bought_together_client";
import { libSearch } from "../lib/search";
import Search from "./search";
import { SearchBarComm } from "./navbar/promo_left";
import { libWindowMessages } from "../lib/windowmessages";
import { prebuildData } from "../lib/prebuild-data";
import AgeCheckerNet from "./integrations/age-checker-net";
import Smile from "./integrations/smile";
import EditBottomBar from "./edit-bottom-bar";
import BugReporter from "./bug-reporter";
import serverRefs from "../server-refs.json";
import SalesStartEndNotice from "./sales-start-end-notice";
import { useAccount } from "../hooks/use_account";
import AccountBar from "./account-bar";
import LinearTopWorkingIndicator from "./common/linear-top-working-indicator";
import { apiLocation } from "../lib/api/location";

export const getInjectableHeaderTags = (title: string,description: string,url: string) => {
    url = `https://www.tbdliquids.com${url}`;
    return (
      <>
          <title>{title}</title>
          <meta name="description" content={description} />
          <link rel="canonical" href={url} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:url" content={url} />
      </>
    );
};
  
export default function Layout({ children,windowSize }: {children: React.ReactNode; windowSize: TWindowSize}): JSX.Element {
    const isFirstRender = useSmartState(true);

    const sideMenuOpenIdRef = useRef(0);
    const [showSideMenu,setShowSideMenu] = useState(false);
    const [fontReady,setFontReady] = useState(false);
    const [importOpenSans,setImportOpenSans] = useState(false);
    const { ref: pageRef,styles: pageStyles,className: pageClassName,injectedStyles,hideOverlayScrollbar: pageHideOverlayScrollbar } = useDesktopScrollbar();
    const [innerPageLock,setInnerPageLock] = useState<{scrollHeight: number}>(null);

    const { ref: pageHoriRef,styles: pageHoriStyles,className: pageHoriClassName,injectedStyles: pageHoriInjectedStyles } = useDesktopScrollbar();

    const router = useRouter();
    const routerRef = useRef(router);
    routerRef.current = router;

    const pageEventHandlersRef = useRef<{[eventName: string]: (() => void)[]}>({});

    const routingTransitionIsOpenRef = useRef(false);

    const salesNoticeMessageRef = useRef("");
    const salesNoticeFromCheckoutRef = useRef(false);
    const salesNoticeOpen = useSmartState(false);

    const openSalesNotice = (message: string,fromCheckout = false) => {
        salesNoticeMessageRef.current = message;
        salesNoticeFromCheckoutRef.current = fromCheckout;
        salesNoticeOpen.value = true;
    };

    const loginPreloadedEmail = useSmartState("");
    const loginPreloadedType = useSmartState("");
    
    const registerError = useSmartState("");    

    useEffect(() => {
        if (isFirstRender.value) return;

        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());        
        if (params["from-checkout"]==="true") {                        
            router.push(misc.removeQueryParamsFromUrl(router.asPath,"from-checkout"));
            setSideCartOpen(true);
        }        
        else if (params["smile_status"]==="success") {
            if (params["smile_access_token"]) {
                router.push(misc.removeQueryParamsFromUrl(router.asPath,"smile_access_token","smile_status"));
                openSmileIframe(params["smile_access_token"] as string);
            }
            else {
                router.push(misc.removeQueryParamsFromUrl(router.asPath,"smile_status"));                
                smileState.value = "login-confirmation";    
            }
        }        
        else if ((window.location.pathname==="/login") && (params["activated"] || params["reset"])) {
            loginPreloadedEmail.value = params["activated"] || params["reset"];            
            loginPreloadedType.value = params["activated"]?"activated":"reset";
            router.push(misc.removeQueryParamsFromUrl(router.asPath,"activated","reset"));
        }
        else if ((window.location.pathname==="/register") && (params["error"])) {
            registerError.value = params["error"];            
            router.push(misc.removeQueryParamsFromUrl(router.asPath,"error"));
        }
    },[router.asPath,isFirstRender.value]);

    const highlightIdsRef = useRef<string[]>([]);
    const flashIdsRef = useRef<string[]>([]);

    const scaleFactor = useSmartState(1);
    const screenRatio = useSmartState(1);

    const smallDesktopWidthCutoff = 1315;
    const mobileWidthCutoff = 830;
    const mobileSmallWidthCutoff = 600;
    const mobileMiniScreenWidthCutoff = 380;
    const mobileActive = useSmartState(-1);
    const mobileActiveLiveCheck = () => {
        // const scaleFactorValue = calcScaleFactor();
        // const scaleRemToPixel = (remValue: number) => remValue*scaleFactorValue;
        const scaleRemToPixel = (x: number) => x;
        return (getWindowSize().width<=scaleRemToPixel(mobileWidthCutoff));
    };
    const mobileSmallScreen = useSmartState(-1);
    const mobileSmallScreenLiveCheck = () => {
        // const scaleFactorValue = calcScaleFactor();
        // const scaleRemToPixel = (remValue: number) => remValue*scaleFactorValue;
        const scaleRemToPixel = (x: number) => x;
        return (getWindowSize().width<=scaleRemToPixel(mobileSmallWidthCutoff));
    };
    const mobileMiniScreen = useSmartState(-1);
    const mobileMiniScreenLiveCheck = () => {
        // const scaleFactorValue = calcScaleFactor();
        // const scaleRemToPixel = (remValue: number) => remValue*scaleFactorValue;
        const scaleRemToPixel = (x: number) => x;
        return (getWindowSize().width<=scaleRemToPixel(mobileMiniScreenWidthCutoff));
    };
    const smallDesktopScreen = useSmartState(-1);
    const smallDesktopScreenLiveCheck = () => {
        // const scaleFactorValue = calcScaleFactor();
        // const scaleRemToPixel = (remValue: number) => remValue*scaleFactorValue;
        const scaleRemToPixel = (x: number) => x;
        return (getWindowSize().width<=scaleRemToPixel(smallDesktopWidthCutoff));
    };    

    const calcScaleFactor = () => (window.screen.width>window.screen.height)?(window.screen.height/1080):1;

    const reviewsHeader = useReviewsHeader();    
    const boughtTogetherClient = useBoughtTogetherClient();

    const mobileDetectUseEffect = (cb: MobileDetectUseEffectCallback,listenToChanges: React.DependencyList = [],type: MobileDetectType = "main") => {
        const callbackRef = useRef(cb);
        callbackRef.current = cb;

        const selectListenValue = () => {
            if (type==="main") {
                return mobileActive.value;     
            }
            else if (type==="small") {
                return mobileSmallScreen.value;     
            }
            else if (type==="mini") {
                return mobileMiniScreen.value;     
            }
            else /* if (type==="small_desktop") */ {
                return smallDesktopScreen.value;     
            }
        };

        const liveCheck = () => {
            if (type==="main") {
                return mobileActiveLiveCheck();
            }
            else if (type==="small") {
                return mobileSmallScreenLiveCheck();
            }
            else if (type==="mini") {
                return mobileMiniScreenLiveCheck();
            }
            else /* if (type==="small_desktop") */ {
                return smallDesktopScreenLiveCheck();
            }
        };

        useEffect(() => {
            callbackRef.current(liveCheck());
        },[selectListenValue(),...listenToChanges]);
    };
    
    const scaleFactorDetectUseEffect = (cb: ScaleFactorDetectUseEffectCallback,listenToChanges: React.DependencyList = []) => {
        const callbackRef = useRef(cb);
        callbackRef.current = cb;

        useEffect(() => {
            const scaleFactorValue = calcScaleFactor();
            const scalePixelToRem = (pixelValue: number) => pixelValue/scaleFactorValue;
            const scaleRemToPixel = (remValue: number) => remValue*scaleFactorValue;
            callbackRef.current(scalePixelToRem,scaleRemToPixel);
        },[scaleFactor.value,...listenToChanges]);
    };

    const searchBarOffsetLeft = useSmartState(0);
    const searchInputTextPointer = useSmartState<SmartState<string>>(null);
    const searchOpen = useSmartState(false);
    const collectionSearchBarOpen = useSmartState(false);
    const searchOpenType = useSmartState<SearchOpenType>("site-width");
    const searchCollectionIdRef = useRef("");
    const searchBarCommRef = useRef(new SearchBarComm());
    const [sideCartOpen,setSideCartOpen] = useState(false);
    const sideCartOpenRef = useRef(sideCartOpen);
    sideCartOpenRef.current = sideCartOpen;

    const pageMinWidth = 1315;    

    const configuredBannerHeightRef = useRef({
        height: -1
    });

    const openSearch = (type: SearchOpenType = "site-width",collectionId = "") => {
        searchBarCommRef.current.notifyMeOnPlaceholderAnimationStart();
        searchCollectionIdRef.current = collectionId;
        searchOpenType.value = type;
        searchOpen.value = true;
    };

    const isMobileActiveRef = useRef((mobileActive.value===1));
    isMobileActiveRef.current = (mobileActive.value===1);

    const isMobileSmallScreenRef = useRef((mobileSmallScreen.value===1));
    isMobileSmallScreenRef.current = (mobileSmallScreen.value===1);

    const isMiniScreenRef = useRef((mobileMiniScreen.value===1));
    isMiniScreenRef.current = (mobileMiniScreen.value===1); 

    const isSmallDesktopScreenRef = useRef((smallDesktopScreen.value===1));
    isSmallDesktopScreenRef.current = (smallDesktopScreen.value===1);     

    const bugReporterOpen = useSmartState(false);
    const bugReporterCounter = useSmartState(0);
    const openBugReporter = () => {
        bugReporterOpen.value = true;
    };
    const resetBugReporter = () => {
        bugReporterCounter.value++;
    };


    const smileState = useSmartState<SmileState>("none");
    const smileAccessTokenRef = useRef("");

    const openSmileIframe = (accessToken = "") => {
        smileAccessTokenRef.current = accessToken;
        smileState.value = "iframe-normal";
    };

    const productPageLoadCounter = useSmartState(0);

    const creatingCheckoutCaptionRef = useRef<string>("");

    const navbarHeaderRef = useRef<HTMLDivElement>();

    const account = useAccount();

    const widthCutoffStyleSection = (content: string) => {
        return `@media screen and (max-width: ${mobileWidthCutoff}px) {
            ${content}
        }`;        
    };

    const clearLoginPreloaded = () => {
        loginPreloadedEmail.value = "";
        loginPreloadedType.value = "";
    };

    const linearTopWorkingIndicatorRunning = useSmartState(false);

    const routerIsLocked = useSmartState(false);

    const layoutStateContextValue: TLayoutState = {
        routerIsLocked,
        account,
        linearTopWorkingIndicatorRunning,
        registerError: registerError.value,
        loginPreloadedEmail: loginPreloadedEmail.value,
        loginPreloadedType: loginPreloadedType.value,
        clearLoginPreloaded,
        openSalesNotice,
        navbarHeaderRef,
        openBugReporter,
        resetBugReporter,
        creatingCheckoutCaptionRef,
        productPageLoadCounter,
        smileState,
        smileAccessToken: smileAccessTokenRef.current,
        openSmileIframe,
        pageRef,
        collectionSearchBarOpen,
        searchCollectionId: searchCollectionIdRef.current,
        openSearch,
        configuredBannerHeight: configuredBannerHeightRef.current,
        pageMinWidth,
        sideCartOpenRef,
        searchBarCommRef,
        searchOpen,
        searchOpenType,
        searchInputTextPointer,
        searchBarOffsetLeft,
        reviewsHeader,
        boughtTogetherClient,
        mobileDetect: {
            widthCutoff: mobileWidthCutoff,
            widthCutoffStyleSection: widthCutoffStyleSection,
            smallWidthCutoff: mobileSmallWidthCutoff,
            miniWidthCutoff: mobileMiniScreenWidthCutoff,
            smallDesktopWidthCutoff,
            isActive: isMobileActiveRef,
            isSmallScreen: isMobileSmallScreenRef,
            isMiniScreen: isMiniScreenRef,
            isSmallDesktopScreen: isSmallDesktopScreenRef,
            mobileSmallScreenCutoff: mobileSmallWidthCutoff            
        },        
        mobileDetectUseEffect,
        scaleFactorDetectUseEffect,
        scaleFactor: scaleFactor.value,
        screenRatio: screenRatio.value,
        scalePixelToRem: (pixelValue: number) => pixelValue/scaleFactor.value,
        scaleRemToPixel: (remValue: number) => remValue*scaleFactor.value,
        sideMenu: {
            openId: sideMenuOpenIdRef.current,
            show: showSideMenu,
            setShow: (value) => {
                if (value) sideMenuOpenIdRef.current++;
                setShowSideMenu(value);
                if (value)
                {
                    document.body.classList.add("side_menu_open");
                }
                else
                {
                    document.body.classList.remove("side_menu_open");
                }
            }
        },
        collectionItems: collectionItems as TCollectionItems,        
        page: {
            addScrollEventListener(handler)
            {
                const { current: pageElem } = pageRef;
                if (pageElem)
                {
                    const listener = () => 
                    {
                        handler({top: pageElem.scrollTop,left: pageElem.scrollLeft,topMax: pageElem.scrollHeight-pageElem.clientHeight,leftMax: pageElem.scrollWidth-pageElem.clientWidth});
                    };
                    pageElem.addEventListener("scroll",listener);
                    return () => 
                    {
                        pageElem.removeEventListener("scroll",listener);
                    };
                }
                else
                {
                    return () => {};
                }                
            },
            hideOverlayScrollbar: () => {
                pageHideOverlayScrollbar.value = true;
            },
            showOverlayScrollbar: () => {
                pageHideOverlayScrollbar.value = false;
            },
            scrollTop()
            {
                const { current: pageElem } = pageRef;
                return pageElem?pageElem.scrollTop:0;
            },
            scrollToTopWithCallback(cb: () => void,target = 0) {
                target = Math.round(target);
                if (layoutStateContextValue.page.scrollTop()===target) {
                    cb();
                }
                else {
                    layoutStateContextValue.page.scrollTo({top: target,behavior: "smooth"});
                    misc.waitForCondition(() => {
                        return (Math.abs(layoutStateContextValue.page.scrollTop()-target)<1);
                    },cb);
                };
            },
            setScrollTop(value: number)
            {
                const { current: pageElem } = pageRef;
                if (pageElem)
                {
                    pageElem.scrollTop = value;
                }
            },
            animateScrollTop(value: number,durationMilliseconds: number,cb: () => void = () => {})
            {
                const { current: pageElem } = pageRef;
                if (pageElem)
                {
                    const startedAt = (new Date()).getTime();
                    const startScrollTop = pageElem.scrollTop;
                    const next = () => 
                    {
                        const elapsed = Math.min((new Date()).getTime()-startedAt,durationMilliseconds);                        
                        const t = elapsed/durationMilliseconds;
                        const ratio = t * t * (3 - 2 * t);
                        pageElem.scrollTop = startScrollTop+((ratio)*(value-startScrollTop));
                        if (elapsed<durationMilliseconds) 
                        {
                            requestAnimationFrame(next);
                        }
                        else
                        {
                            cb();
                        }
                    };
                    requestAnimationFrame(next);
                }
            },
            animateScrollTopManaged(target: number,{durationMs = 220,threshold = 200,direction = TManagedScrollTopAnimationDuration.Up},cb?: () => void)
            {
                const { page } = layoutStateContextValue;
                let scrollTop = page.scrollTop();
                if ((direction===TManagedScrollTopAnimationDuration.Up) && (scrollTop<=target)) return;
                if ((direction===TManagedScrollTopAnimationDuration.Down) && (scrollTop>=target)) return;
                if (scrollTop===target) return;

                let duration = 0;

                if (scrollTop>target)
                {
                    if ((scrollTop-target)>threshold) page.setScrollTop(scrollTop=target+threshold);
                    duration = ((scrollTop-target)/threshold)*durationMs;
                }
                else 
                {
                    if ((target-scrollTop)>threshold) page.setScrollTop(scrollTop=target-threshold);
                    duration = ((target-scrollTop)/threshold)*durationMs;
                }

                page.animateScrollTop(target,duration,() =>
                {
                    if (cb) cb();
                });
            },
            scrollLeft()
            {
                const { current: pageElem } = pageRef;
                return pageElem?pageElem.scrollLeft:0;
            },
            setScrollLeft(value: number)
            {
                const { current: pageElem } = pageRef;
                if (pageElem)
                {
                    pageElem.scrollLeft = value;
                }
            },
            scrollTopMax()
            {
                const { current: pageElem } = pageRef;
                return pageElem?pageElem.scrollHeight-pageElem.clientHeight:0;
            },
            scrollLeftMax()
            {
                const { current: pageElem } = pageRef;
                return pageElem?pageElem.scrollWidth-pageElem.clientWidth:0;
            },
            scrollTo(options)
            {
                const { current: pageElem } = pageRef;
                if (pageElem)
                {
                    pageElem.scrollTo(options);
                }
            },
            scrollWidth()
            {
                const { current: pageElem } = pageRef;
                return pageElem?pageElem.scrollWidth:0;
            },
            scrollHeight()
            {
                const { current: pageElem } = pageRef;
                return pageElem?pageElem.scrollHeight:0;
            },
            clientWidth()
            {
                const { current: pageElem } = pageRef;
                return pageElem?pageElem.clientWidth:0;
            },
            clientHeight()
            {
                const { current: pageElem } = pageRef;
                return pageElem?pageElem.clientHeight:0;
            },
            addEventListener<K extends keyof HTMLElementEventMap>(type: K,listener: (this: HTMLDivElement,ev: HTMLElementEventMap[K]) => any)
            {
                const { current: pageElem } = pageRef;
                if (pageElem) pageElem.addEventListener(type,listener);
            },
            removeEventListener<K extends keyof HTMLElementEventMap>(type: K,listener: (this: HTMLDivElement,ev: HTMLElementEventMap[K]) => any)
            {
                const { current: pageElem } = pageRef;
                if (pageElem) pageElem.removeEventListener(type,listener);
            },
            scrollBarWidth()
            {
                const { current: pageElem } = pageRef;
                return pageElem?pageElem.offsetWidth-pageElem.clientWidth:0;
            },
            lockScroll()
            {
                const { current: pageElem } = pageRef;
                if (pageElem)
                {
                    setInnerPageLock({scrollHeight: pageElem.scrollHeight});
                }
            },
            unlockScroll()
            {
                setInnerPageLock(null);
            },
            isScrollLocked: innerPageLock?true:false,
            on(eventName,handler)
            {
                const { current: pageEventHandlers } = pageEventHandlersRef;
                pageEventHandlers[eventName] = pageEventHandlers[eventName] || [];
                if (pageEventHandlers[eventName].filter((h) => h===handler).length===0)
                {
                    pageEventHandlers[eventName].push(handler);
                    if ((eventName==="before-router-scroll-up") && (routingTransitionIsOpenRef.current))
                    {
                        handler();
                    }
                }
            },
            off(eventName,handler)
            {
                const { current: pageEventHandlers } = pageEventHandlersRef;
                if (pageEventHandlers[eventName])
                {
                    pageEventHandlers[eventName] = pageEventHandlers[eventName].filter((h) => h!==handler);
                }
            }
        },
        font: {
            ready: fontReady,
            setReady: (value) => {
                setFontReady(value);
            }
        },
        misc: {
            filterBarItemClassNameIdentifier: "__filterbar_controls_item__"
        },
        sideCart: {
            open({highlightIds = [],flashIds = []}: {highlightIds?: string[]; flashIds?: string[]} = {}) {
                highlightIdsRef.current = [...highlightIds];
                flashIdsRef.current = [...flashIds];
                setSideCartOpen(true);
            }
        }
    };    

    useEffect(() => {
        if (isFirstRender.value) return;

        (window as any).prebuildData = prebuildData;
        (window as any).cartController = cartController;
        (window as any).misc = misc;
        (window as any).apiLocation = apiLocation;        
        (window as any).reactRouter = reactRouter;
        libSearch.buildTable();
        libWindowMessages.initialize();

        let routeChangeCompleteHandler = () => 
        {
            try
            {
                const { page } = layoutStateContextValue;
                const { current: pageEventHandlers } = pageEventHandlersRef;
                const duration = 220;
                const threshold = 200;
                if (page.scrollTop()>0)
                {
                    pageEventHandlers["before-router-scroll-up"]?.forEach((h) => h());
                    routingTransitionIsOpenRef.current = true;
                    page.scrollToTopWithCallback(() => {
                        setTimeout(() => {
                            page.unlockScroll();
                            pageEventHandlers["after-router-scroll-up"]?.forEach((h) => h());
                            routingTransitionIsOpenRef.current = false;    
                        },0);
                    })
                }
                else
                {
                    page.unlockScroll();
                }    
            }
            finally
            {
                routerTransition.stop();
            }            
        };
        Router.events.on("routeChangeComplete",routeChangeCompleteHandler);
        return () => 
        {
            Router.events.off("routeChangeComplete",routeChangeCompleteHandler);
        };
    },[isFirstRender.value]);

    const navbarHeight = useSettableValue(0);
    const bannerHeight = useSettableValue(0);
    const miniNavbarHeight = useSettableValue(0);
    const collectionGridHeight = useSettableValue(0);
    const filterBarControlsHeight = useSettableValue(0);    
    const scrollBottomPadding = useSettableValue(0);
    
    const dimensionsStateContextValue: TDimensionsState = {
        navbarHeight,
        bannerHeight,
        miniNavbarHeight,
        collectionGridHeight,
        filterBarControlsHeight,
        scrollBottomPadding
    };    

    const isSwitching = useSettableValue(false);

    const processFilterBarAction = (cb: () => void) => {
        layoutStateContextValue.page.scrollToTopWithCallback(cb);
    };

    const sortOrder = useSettableValue<SortOrder>("popular",(fromValue,toValue) => 
    {
        if (toValue!==fromValue)
        {
            processFilterBarAction(() => {
                isSwitching.set(true);
                layoutStateContextValue.page.lockScroll();
                routerTransition.start();
                if (refreshSwitch.value===0)
                {
                    refreshSwitch.set(1);
                }
                else if (refreshSwitch.value===2)
                {
                    refreshSwitch.set(3);
                }                            
            });
        }
    });
    const filter = useSettableValue<FilterMap>({},(fromValue,toValue) => 
    {        
        if (Object.keys(toValue).sort().join("-")!==Object.keys(fromValue).sort().join("-"))
        {
            processFilterBarAction(() => {
                isSwitching.set(true);
                layoutStateContextValue.page.lockScroll();
                routerTransition.start();
                if (refreshSwitch.value===0)
                {
                    refreshSwitch.set(1);
                }
                else if (refreshSwitch.value===2)
                {
                    refreshSwitch.set(3);
                }                            
            });
        }
    });
    const refreshSwitch = useSettableValue(0);
    
    const collectionFilterStateContentValue: CollectionFilterState = {
        sortOrder,
        filter,
        refreshSwitch,
        isSwitching
    };

    useEffect(() => {
        if (isFirstRender.value) return;

        const fontFamily = "Open Sans";
        if (misc.isFontAvailable(fontFamily))
        {
            setTimeout(() => setFontReady(true),0);
        }
        else
        {            
            setImportOpenSans(true);
            misc.awaitFont(fontFamily,3000,() => 
            {
                setFontReady(true);
            });
        }        
    },[isFirstRender.value]);

    const [pageInnerTranslateY,setPageInnerTranslateY] = useState(0);

    const reactRouter = useRouter();
    const hideLayout = (reactRouter.pathname==="/preview/[id]") || (reactRouter.pathname==="/banner-height/[height]");
    
    const onMobile = (windowSize.width<=600);

    const cartOpenHandler = () => {        
        setSideCartOpen(true);
    };

    const cartCloseHandler = () => {
        highlightIdsRef.current = [];
        flashIdsRef.current = [];
        setSideCartOpen(false);
    };

    const cartController = useCart(isFirstRender.value);
    const cartItemCount = cartController.getItemCount();
    const mobileCartOpen = useSmartState(false);
    const creatingCheckoutOpen = useSmartState(false);

    useEffect(() => {
        if (isFirstRender.value) return;

        scaleFactor.value = calcScaleFactor();
        screenRatio.value = window.screen.width/window.screen.height;        
        mobileActive.value = mobileActiveLiveCheck()?1:0;        
        mobileSmallScreen.value = mobileSmallScreenLiveCheck()?1:0;        
        mobileMiniScreen.value = mobileMiniScreenLiveCheck()?1:0;        
        smallDesktopScreen.value = smallDesktopScreenLiveCheck()?1:0;
    },[`${windowSize.width},${windowSize.height}`,isFirstRender.value]);

    if ((reactRouter.pathname.startsWith("/nov/")) || (reactRouter.pathname.startsWith("/landing/"))) {
        return children as JSX.Element;
    }   
    
    if (isFirstRender.value) {
        prebuildData.registerOpenSalesNotice(openSalesNotice);
        prebuildData.loadSnapshot();
        prebuildData.processSales();        
        isFirstRender.value = false;
    }

    const { general: { bodyBackgroundColor } } = prebuildData.getActiveTheme();    

    const serverTimeScript = (process.env.NODE_ENV==="development")?<script>{`globalThis.TBDLServerTimeDiff = 0;`}</script>:<script src={`${serverRefs.apis.api}/server_time`}></script>;

    return (
      <>
        <Head>
            {serverTimeScript}
            <link rel="icon" href={prebuildData.bustCache("/favicon.ico")} />
            <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
            <link rel="shortcut icon" href="//cdn.shopify.com/s/files/1/0659/7615/t/135/assets/favicon.png?v=163593555351234607351667503332" type="image/png" />
            <meta name="ROBOTS" content="noindex, nofollow" />
            <meta charSet="utf-8" />
            {/*}<meta name="description" content="Delicious and affordable premium e-liquid and e-juice. Nicotine salt e-liquid. Custom nicotine, create your perfect bottle today!" />{*/}
            {/*}<link rel="canonical" href="https://www.tbdliquids.com/policies/shipping-policy" />{*/}
            <meta property="og:type" content="website" />
            {/*}<meta property="og:title" content="Shipping policy" />{*/}
            {/*}<meta property="og:description" content="Uwell Caliburn help, guide, faq, and tips. Nicotine salts and e-juice faq, guide, help, and more." />{*/}
            <meta property="og:image" content="http://cdn.shopify.com/s/files/1/0659/7615/t/135/assets/logo.png?v=115789196930231978651667503332" />
            <meta property="og:image:secure_url" content="https://cdn.shopify.com/s/files/1/0659/7615/t/135/assets/logo.png?v=115789196930231978651667503332" />
            {/*}<meta property="og:url" content="https://www.tbdliquids.com/policies/shipping-policy" />{*/}
            <meta property="og:site_name" content="TBD Liquids" />

            {importOpenSans?(
                <style>
                    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
                </style>)
                :null} 
            <style>{`
            html {
                font-size: ${scaleFactor.value}px;
            }
            `}</style>            
        </Head>          
        {/*}
        <AgeCheckerNet />
        {*/}
        <LayoutStateContext.Provider value={layoutStateContextValue}>
            <DimensionsStateContext.Provider value={dimensionsStateContextValue} >
                <CollectionFilterStateContext.Provider value={collectionFilterStateContentValue} >
                    <div ref={pageHoriRef} className={`page-hori-scroll ${pageHoriClassName}`} style={pageHoriStyles}>
                        <div ref={pageRef} className={`page ${pageClassName}`} style={pageStyles}>
                            <div className="page-inner" style={{                            
                                ...(innerPageLock?{height: innerPageLock.scrollHeight,overflow: "hidden"}:{})
                            }}>{!hideLayout?<>
                                <Navbar cartClickHandler={cartOpenHandler} cartItemCount={cartItemCount} /> 
                                <AccountBar />                               
                                <main>{children}</main>
                                <Footer hide={!!innerPageLock} />                                                            
                                <RouterTransition />
                            </>:<main>{children}</main>} 
                                <Search open={searchOpen.value} />
                            </div>                                                
                        </div>
                        <MiniNavBar cartClickHandler={cartOpenHandler} cartItemCount={cartItemCount} />
                    </div>
                    <CreatingCheckout open={creatingCheckoutOpen.value} caption={creatingCheckoutCaptionRef.current} />
                    <SideMenu />
                    <div className="desktop-cart">
                        <SideCart 
                            open={sideCartOpen} 
                            closeHandler={cartCloseHandler} 
                            cartController={cartController} 
                            creatingCheckoutOpen={creatingCheckoutOpen} 
                            onMobile={false} 
                            highlightIds={highlightIdsRef.current}
                            flashIds={flashIdsRef.current}
                        />
                    </div>
                    <div className="mobile-cart">
                        <SideCart 
                            open={sideCartOpen} 
                            closeHandler={cartCloseHandler} 
                            cartController={cartController} 
                            creatingCheckoutOpen={creatingCheckoutOpen} 
                            onMobile={true} 
                            highlightIds={highlightIdsRef.current}
                            flashIds={flashIdsRef.current}
                        />
                    </div>                    
                    {/*} <MobileCartHolder open={mobileCartOpen} /> {*/}
                    <Smile smileState={smileState} />
                    <EditBottomBar editOrder={cart.editOrder} />
                    <BugReporter key={bugReporterCounter.value} open={bugReporterOpen} />
                    <SalesStartEndNotice open={salesNoticeOpen} message={salesNoticeMessageRef.current} fromCheckout={salesNoticeFromCheckoutRef.current} />
                    <LinearTopWorkingIndicator running={linearTopWorkingIndicatorRunning.value} />
                </CollectionFilterStateContext.Provider>
            </DimensionsStateContext.Provider>
        </LayoutStateContext.Provider>
        <style jsx>{injectedStyles}</style>
        <style jsx>{pageHoriInjectedStyles}</style>        
        <style jsx global>{`
            :root {
                --color-collection-menu: rgba(241, 242, 246, 1);
                --color-collection-menu-zero-alpha: rgba(241, 242, 246, 0);
                --color-header: #121314;
                --color-menu-item: #222222;
                --color-menu-item-highlight: #444;
                --color-light-blue: #6ab8ff;
                --color-black: #131315;                
                --touch-duration: 0.2s;
                --scrollbar-forecolor: #a9a9a9;
                --scrollbar-backcolor: #d5d4d4;
                --scrollbar-width: 8rem;
                --body-background-color: ${bodyBackgroundColor};
                --search-open-duration: 0.3s;
            }

            html,
            body {
                padding: 0;
                margin: 0;
                font-family: "Open Sans", "Arial", "Helvetica", sans-serif;
                background-color: var(--body-background-color);
            }

            body {
                font-size: 16rem;
            }

            html {                
            }

            main {
                min-height: 660rem;
            }

            .page {
                position: absolute;
                left: 0rem;
                top: 0rem;
                width: 100%;
                height: 100%;
                /* min-width: ${pageMinWidth}rem; */
                overflow-x: hidden;
                overflow-y: auto;                
            }

            .page-hori-scroll {
                position: fixed;
                left: 0rem;
                top: 0rem;
                width: 100%;
                height: 100%;
                overflow-x: auto;
            }

            .page-inner {
                position: relative;
            }

            /*
            .page::-webkit-scrollbar {
                width: var(--scrollbar-width);
            }

            .page::-webkit-scrollbar-thumb {
                background: var(--scrollbar-forecolor);
                border-radius: calc(var(--scrollbar-width) * 2);
            }

            .page::-webkit-scrollbar-track {
                background: var(--scrollbar-backcolor);                    
                border: 1rem solid var(--scrollbar-forecolor);
            } 
            */

            body:global(.side_menu_open) .page {
                top: 0.4rem;
            }

            body:global(.disable-pull-to-refresh) {
                overscroll-behavior: contain;
            }

            :global(.hori-verti-centered) {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            :global(.fill-parent) {
                position: absolute;
                left: 0rem;
                right: 0rem;
                top: 0rem;
                bottom: 0rem;
            }

            * {
                box-sizing: border-box;
                user-select: none;
                /*
                scrollbar-color: var(--scrollbar-forecolor) var(--scrollbar-backcolor);
                scrollbar-width: var(--scrollbar-width);                
                */
            }

            /*

            *::-webkit-scrollbar {
                width: var(--scrollbar-width);
            }

            *::-webkit-scrollbar-thumb {
                background: var(--scrollbar-forecolor);
                border-radius: calc(var(--scrollbar-width) * 2);
            }

            *::-webkit-scrollbar-track {
                background: var(--scrollbar-backcolor);                    
                border: 1rem solid var(--scrollbar-forecolor);
            } 
            */

            body {
                position: relative;
                overflow: hidden;
                height: 100%;
            }        

            .desktop-cart {
                display: block;
            }

            .mobile-cart {
                display: none;
            }

            @media screen and (max-width: ${mobileSmallWidthCutoff}px) {
                .desktop-cart {
                    display: none;
                }

                .mobile-cart {
                    display: block;
                }
            }

            @media screen and (max-width: ${mobileWidthCutoff}px) {
                .page {
                    min-width: 0rem;
                }
            }
            
      `}</style>
      </>
    )
  }