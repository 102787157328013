import { NextRequest } from "next/server";
import { MutableRefObject } from "react";

let _isFontAvailable: ((fontFamily: string) => boolean);

class Misc
{
    getScrollTop()
    {
        return (typeof window !== "undefined")?document.documentElement.scrollTop:0;
    }

    getServerTime() {
        return (new Date()).getTime()+(globalThis?.TBDLServerTimeDiff || 0);
    }

    getWindowSize()
    {
        if (typeof window !== "undefined")
        {
            return {
                width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
                height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
            };
        }
        else
        {
            return {width: 0,height: 0};
        }
    }

    setLongTimeout(cb: () => void,longTimeout: number) {
        const eventAt = (new Date()).getTime()+longTimeout;
        const interval = 5000;
        // const interval = 60*60*1000;
        const next = () => {
            const currentTime = (new Date()).getTime();
            const remaining = eventAt-currentTime;
            if (remaining<=0) {
                cb();
            }
            else {
                setTimeout(next,Math.min(remaining,interval));
            }
        };

        next();
    };

    integerSplit(total: number,count: number) {
        const ret: number[] = [];
        const part = Math.max(1,Math.floor(total/count));
        while(total>=part) {
            ret.push(part);
            total-= part;
        }
        if (total>0) ret[ret.length-1]+= total;
        while(ret.length<count) ret.push(0);
        return ret;
    }

    integerSplitByRatio(total: number,ratios: number[]) {
        const ratiosTotal = ratios.reduce((acc,value) => acc+value,0);
        let remaining = total;
        const ret: number[] = [];
        for(let i=0;i<ratios.length;i++) {            
            const take = (i<ratios.length-1)?Math.floor((ratios[i]/ratiosTotal)*total):remaining;
            ret.push(take);
            remaining-= take;
        }
        return ret;
    }

    limitCeiling(value: number,ceiling: number) {
        return Math.min(ceiling,value);
    }

    limitFloor(value: number,floor: number) {
        return Math.max(floor,value);
    }

    getTextWidth(text: string,fontFamily: string,fontSize: number,fontWeight = 400,letterSpacing = 0)
    {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        ctx.textBaseline = "top";
        ctx.fillStyle = "blue";
        ctx.font = `${fontWeight} ${fontSize}rem ${fontFamily}`;                
        return ctx.measureText(text).width+(text.length*Math.max(0,letterSpacing*fontSize));
    }

    getTextAspectRatio(text: string,fontFamily: string,fontWeight = 400,letterSpacing = 0)
    {
        const fontSize = 50;
        return this.getTextWidth(text,fontFamily,fontSize,fontWeight,letterSpacing)/fontSize;
    }

    removeDuplicates(values: (number | string)[]) {
        const hash: {[value: string]: true} = {};
        return values.filter((value) => (hash[value])?false:(hash[value] = true));
    }
    
    isFontAvailable(fontFamily: string)
    {
        if (!_isFontAvailable)
        {
            const body = document.body;
        
            const container = document.createElement("span");
            container.innerHTML = Array(100).join("abcdejz");
            container.style.cssText = [
                "position:absolute",
                "width:auto",
                "font-size:128rem",
                "left:-99999rem"
            ].join(" !important;");
        
            const getWidth = (fontFamily: string) => 
            {
                container.style.fontFamily = fontFamily;
        
                body.appendChild(container);
                const ret = container.clientWidth;
                body.removeChild(container);
        
                return ret;
            };
        
            const monoWidth  = getWidth("monospace");
            const serifWidth = getWidth("serif");
            const sansWidth  = getWidth("sans-serif");
        
            _isFontAvailable = (fontFamily) => 
            {
                return monoWidth !== getWidth(fontFamily + ",monospace") ||
                    sansWidth !== getWidth(fontFamily + ",sans-serif") ||
                    serifWidth !== getWidth(fontFamily + ",serif");
            };
        }
        return (_isFontAvailable)?_isFontAvailable(fontFamily):false;
    }

    awaitFont(fontFamily: string,timeoutMilliseconds: number,cb: () => void)
    {
        const startedAt = (new Date()).getTime();
        const next = () => 
        {
            const elapsed = (new Date()).getTime()-startedAt;
            if (this.isFontAvailable(fontFamily) || (elapsed>=timeoutMilliseconds))
            {
                cb();
            }
            else
            {
                requestAnimationFrame(next);
            }            
        };
        requestAnimationFrame(next);
    }

    async sleep(milliseconds: number)
    {
        return new Promise((resolve) => { setTimeout(resolve,milliseconds) });
    }

    promiseGenRetrierGen<T>(promiseGen: () => Promise<T>,retries = 5,sleepBetweenMs = 1000): () => Promise<T>
    {
        return async () => 
        {
            let lastError: any = null;
            for(let i=0;i<retries+1;i++)
            {
                try
                {
                    return await promiseGen();
                }
                catch(err)
                {        
                    lastError = err;        
                }
                if (i<retries) await this.sleep(sleepBetweenMs);
            }
            throw lastError;    
        };
    }

    removeAllCaps(text: string)
    {
        const parts = (text+" ").split(/(\. |\? )/g);
        for(let i=0;i<parts.length;i+= 2)
        {
            const sentence = parts[i];
            const words = sentence.split(" ");
            parts[i] = words.map((word,index) => 
            {
                if ((word.split("").filter((ch) => /[a-z]/i.test(ch)?/[A-Z]/.test(ch):true).length===word.length) && (word!=="I"))
                {
                    word = word.toLowerCase();
                    if (index===0) word = word.substring(0,1).toUpperCase()+word.substring(1);
                }
                return word;
            }).join(" ");
        }
        return parts.join("").trimEnd();
    }

    getParameterByName(name: string, url = window.location.href) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }    

    parseQuerySearchString(query: string) {
        const ret: {[key: string]: string} = {};
        query.split("&").forEach((keyValue) => {
            const parts = keyValue.split("=");
            if (parts.length===2) {
                const key = decodeURIComponent(parts[0]);
                const value = decodeURIComponent(parts[1]);
                ret[key] = value;
            }
        });
        return ret;
    }

    queryParamsToQuerySearchString(params: {[key: string]: string}) {        
        return Object.keys(params).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join("&");
    }

    getQueryParams() {        
        const urlSearchParams = new URLSearchParams(globalThis?.location?.search || "");
        return Object.fromEntries(urlSearchParams.entries());        
    }

    addQueryParamToUrl(url: string,paramName: string,paramValue: string | number,overwrite = false) {
        const h = url.indexOf("#");
        let hashTag = "";
        if (h!==-1) {
            hashTag = url.substring(h);
            url = url.substring(0,h);
        }
        const r = url.indexOf("?");
        if (r!==-1) {            
            const queryParams = this.parseQuerySearchString(url.substring(r+1));            
            if ((overwrite) || !queryParams.hasOwnProperty(paramName)) {
                queryParams[paramName] = paramValue.toString();
            }
            return `${url.substring(0,r)}?${this.queryParamsToQuerySearchString(queryParams)}${hashTag}`;
        }
        else {
            return `${url}?${encodeURIComponent(paramName)}=${encodeURIComponent(paramValue.toString())}${hashTag}`;
        }
    }

    removeQueryParamsFromUrl(url: string,...paramNames: string[]) {
        const h = url.indexOf("#");
        let hashTag = "";
        if (h!==-1) {
            hashTag = url.substring(h);
            url = url.substring(0,h);
        }
        const r = url.indexOf("?");
        if (r!==-1) {            
            const queryParams = this.parseQuerySearchString(url.substring(r+1));            
            paramNames.forEach((paramName) => {
                delete queryParams[paramName];
            });
            const querySearchString = this.queryParamsToQuerySearchString(queryParams);
            return `${url.substring(0,r)}${querySearchString?"?"+querySearchString:""}${hashTag}`;
        }
        else {
            return `${url}${hashTag}`;
        }
    }

    splitPriceForDisplay(price: number) {
        const pricePre = `00${Math.round(price)}`;
        return {
            dollar: parseInt(pricePre.substring(0,pricePre.length-2)).toString(),
            cents: pricePre.substring(pricePre.length-2)
        };
    };    

    createImageDimensions(aspectRatioWidth: number,aspectRatioHeight: number,height: number) {
        const aspectRatio = aspectRatioWidth/aspectRatioHeight;
        return {            
            height,
            width: height*aspectRatio
        };
    } 

    combinations(count: number,cb: (indexes: number[]) => void) {
        const indexes: number[] = [];
        const hash: {[index: string]: true} = {};
        const process = () => {
            if (indexes.length===count) {
                cb(indexes);
            }
            else {
                for(let i=0;i<count;i++) {
                    if (!hash[i]) {
                        hash[i] = true;
                        indexes.push(i);
                        process();
                        indexes.pop();
                        delete hash[i];
                    }
                }
            }
        };

        process();
    }

    genUUID() {
        return Date.now().toString(36) + Math.random().toString(36).substring(2);
    }

    lookupArray<T>(arr: T[],lookupCb: (value: T,index: number) => boolean) {
        for(let i=0;i<arr.length;i++) {
            if (lookupCb(arr[i],i)) return i;
        }
        return -1;
    }

    getOffsetRelativeToParent(elem: HTMLDivElement,parentQuery: string) {
        const ret = {
            left: 0,
            top: 0
        };
        let pnt: HTMLDivElement = elem;
        while(pnt.offsetParent) {
            ret.left+= pnt.offsetLeft;
            ret.top+= pnt.offsetTop;
            const parent = pnt.offsetParent as HTMLDivElement;
            if (parent.matches(parentQuery)) return ret;
            pnt = parent;
        }
        
        return null;
    }

    waitForStyleValue(element: Element,propertyName: string,value: string,cb: () => void,{ interval = 100 }: {interval?: number} = {}) {
        this.waitForCondition(() => getComputedStyle(element)[propertyName]===value,cb,{interval});
    }

    waitForCondition(check: () => boolean,cb: () => void,{ interval = 100 }: {interval?: number} = {}) {
        const next = () => {
            if (check()) {
                cb();
            }
            else {
                setTimeout(next,interval);
            }            
        };
        next();
    }

    hasTouchEvents() {
        return ("ontouchstart" in window);
    }

    async sha1Hash(value: string) {
        const sourceBytes = new TextEncoder().encode(value);
        const digest = await crypto.subtle.digest("SHA-1", sourceBytes);
        const resultParts: string[] = [];
        const arr = new Uint8Array(digest);        
        for(let i=0;i<arr.length;i++) resultParts.push(arr[i].toString(16).padStart(2,"0"));
        return resultParts.join("");
    }

    focusInputElement(ref: MutableRefObject<HTMLInputElement>) {
        if (ref.current) ref.current.focus();
    }

    focusSelectElement(ref: MutableRefObject<HTMLSelectElement>) {
        if (ref.current) ref.current.focus();
    }

    getInputElementValue(ref: MutableRefObject<HTMLInputElement>) {
        return ref.current?ref.current.value:"";
    }

    getInputElementChecked(ref: MutableRefObject<HTMLInputElement>) {
        return ref.current?ref.current.checked:"";
    }

    getInputElementSelectionStart(ref: MutableRefObject<HTMLInputElement>) {
        return ref.current?ref.current.selectionStart:0;
    }

    getSelectElementValue(ref: MutableRefObject<HTMLSelectElement>) {
        return ref.current?ref.current.value:"";
    }

    setInputElementValue(ref: MutableRefObject<HTMLInputElement>,value: string) {
        if (ref.current) {
            ref.current.value = value;
        }
    }

    setInputElementChecked(ref: MutableRefObject<HTMLInputElement>,checked: boolean) {
        if (ref.current) {
            ref.current.checked = checked;
        }
    }

    setSelectElementValue(ref: MutableRefObject<HTMLSelectElement>,value: string) {
        if (ref.current) {
            ref.current.value = value;
        }
    }

    setSelectElementSelectedIndex(ref: MutableRefObject<HTMLSelectElement>,value: number) {
        if (ref.current) {
            ref.current.selectedIndex = value;
        }
    }

    isValidEmail(value: string) {
        return /^.+\@.+$/.test(value);
    }

    removeItemsFromArray<T = any>(arr: T[],...items: T[]) {
        const newArr = arr.filter((x => !items.includes(x)));
        arr.length = 0;
        arr.push(...newArr);
    }

    arrayToHash<T>(values: T[]) {
        const ret: {[key: string]: true} = {};
        values.forEach((x) => ret[x+""] = true);
        return ret;
    }

    isExternalPath(path: string) {
        return /^https?\:\/\//.test(path);
    }
}

export const misc = new Misc();