import { useRef } from "react";

export const useCachedValue = <T,U>(cb: () => T,template: U) => {
    const ref = useRef<T>();
    const templateRef = useRef<U>();
    if (ref.current) {
        if (templateRef.current===template) {
            return ref.current;
        }
        else {
            templateRef.current = template;
            return ref.current = cb();
        }
    }
    else {
        templateRef.current = template;
        return ref.current = cb();
    }
};