import serverRefs from "../../server-refs.json";
import { prebuildData } from "../prebuild-data";

export type VerifySignatureResult = "matches" | "new-snapshot" | "new-sale" | "sale-ended";

class ApiSalesSignature {
    async getSignature() {
        // const baseUrl = (process.env.NODE_ENV==="development")?"http://localhost:9000":serverRefs.apis.api;
        const baseUrl = serverRefs.apis.api;
        let url = `${baseUrl}/get_sales_signature`;

        const res = await fetch(url,{
            method: "GET",
            headers: {
                "Accept": "application/json",
            }
        });
        const jsonRes = await res.json();
        if (jsonRes?.type==="error") {
            throw new Error(jsonRes?.errorMessage || "Unexpected response from server");
        }
        else if (jsonRes?.type==="success") {
            return jsonRes.signature as string;
        }
        else {
            throw new Error("Unexpected response from server");
        }
    }

    async verifySignature(): Promise<VerifySignatureResult> {
        if (location.href.toString().startsWith("http://localhost:")) return "matches";

        const splitSignature = (signature: string) => {
            const snapshotId = signature.split("-")[0];
            const saleName = signature.substring(snapshotId.length+1);
            return { snapshotId,saleName };
        };

        const clientSignature = prebuildData.getSalesSignature();
        const serverSignature = await this.getSignature();
        if (clientSignature===serverSignature) {
            return "matches";
        }
        else {
            const { snapshotId: clientSnapshotId } = splitSignature(clientSignature);
            const { snapshotId: serverSnapshotId,saleName: serverSaleName } = splitSignature(serverSignature);
            if (clientSnapshotId!==serverSnapshotId) {
                return "new-snapshot";
            }
            else if (serverSaleName) {
                return "new-sale";
            }
            else {
                return "sale-ended";
            }
        }
    }
}
export const apiSalesSignature = new ApiSalesSignature();