import { useRouter } from "next/router";
import { useContext, useEffect } from "react";
import { LayoutStateContext, SmileState } from "../../contexts/layout_state";
import { useCssStateManager } from "../../hooks/use_css_state_manager";
import { SmartState } from "../../hooks/use_smart_state";
import { libWindowMessages } from "../../lib/windowmessages";
import CenteredSpinner from "../common/centered-spinner";
import { EventPane } from "../core/event_pane";

type ReadyEvent = {
    type: "ready_from_iframe";
};

type CloseEvent = {
    type: "close_from_iframe";
};

type Event = ReadyEvent | CloseEvent;


export type SmileProps = {
    smileState: SmartState<SmileState>;
};

export default function Smile({smileState}:SmileProps)
{
    const { mobileDetect: { widthCutoff: mobileCutoff },smileAccessToken } = useContext(LayoutStateContext);

    const reactRouter = useRouter();
    const urlPath = reactRouter.asPath.split("?")[0];

    const cssStateManager = useCssStateManager(["smile"]);
    cssStateManager.useProperty(smileState.value==="iframe-normal","iframe-normal");
    cssStateManager.useProperty(smileState.value==="login-confirmation","login-confirmation");    
    const iframeVisible = cssStateManager.useState(false,"iframe-visible")

    const clickHandler = () => {
        smileState.value = "none";
    };

    useEffect(() => {
        return libWindowMessages.subscribe<Event>(["ready_from_iframe","close_from_iframe"],(event) => {
            if (event.type==="ready_from_iframe") {
                iframeVisible.value = true;
            }
            else if (event.type==="close_from_iframe") {
                smileState.value = "none";
            }
        });
    },[]);

    useEffect(() => {                
        if (smileState.value==="iframe-normal") {
            iframeVisible.value = false;
        }
    },[smileState.value]);

    return (
        <>
            <div className={cssStateManager.getClassNames()}>
                <div className="overlay">
                    <div className="spinner-wrapper">
                        <CenteredSpinner />
                    </div>
                    <EventPane onClick={clickHandler} />
                </div>
                {(smileState.value==="iframe-normal")?(
                    <div className="iframe-wrapper">                        
                        <iframe frameBorder="0" src={smileAccessToken?`https://d20kvjti7j73kv.cloudfront.net?smile_access_token=${encodeURIComponent(smileAccessToken)}&smile_status=success`:`https://d20kvjti7j73kv.cloudfront.net${urlPath}#smile-home`} allowTransparency></iframe>
                    </div>
                ):""}     
                {(smileState.value==="login-confirmation")?(
                    <div className="check-your-email-wrapper hori-verti-centered">
                        <div className="check-your-email">
                            <div className="mail-icon hori-verti-centered">
                                <i className="icon-mail"></i>                                
                            </div>
                            <div className="title">Check your email</div>
                            <div className="text">We just sent you a secure link you can use to sign in to your account - no password needed!</div>
                            <div className="cross hori-verti-centered">
                                <i className="icon-navbar-cross"></i>                                
                                <EventPane onClick={clickHandler} />
                            </div>
                        </div>
                    </div>
                ):""}                
            </div>
            <style jsx>{`
            .smile {
                --duration: 0.3s;
                position: fixed;
                left: 0rem;
                right: 0rem;
                top: 0rem;
                bottom: 0rem;
                opacity: 0;
                visibility: hidden;
                transition: opacity var(--duration),visibility var(--duration);                
                z-index: 1010;
            }

            .smile.iframe-normal,.smile.login-confirmation {
                opacity: 1;
                visibility: visible;
            }

            .overlay {
                position: absolute;
                left: 0rem;
                right: 0rem;
                top: 0rem;
                bottom: 0rem;                
                background-color: rgba(0,0,0,0.5);
            }

            .spinner-wrapper {
                position: absolute;
                right: 0rem;
                bottom: 0rem;
                height: 316rem;
                width: 400rem;
                opacity: 0;
            }

            .iframe-normal .spinner-wrapper {
                opacity: 1;
            }

            .iframe-visible .spinner-wrapper {
                opacity: 0;
            }

            .iframe-wrapper {
                position: absolute;
                left: 0rem;
                right: 0rem;
                top: 0rem;
                bottom: 0rem;                
                transform: translateX(-100%);
            }

            .iframe-visible .iframe-wrapper {
                transform: translateX(0rem);
            }

            .iframe-wrapper iframe {
                background-color: transparent;
                width: 100%;
                height: 100%;
            }

            .check-your-email-wrapper {
                pointer-events: none;
                position: absolute;
                left: 0rem;
                right: 0rem;
                top: 0rem;
                bottom: 0rem;                
            }

            .check-your-email {
                pointer-events: all;
                position: relative;
                width: 380rem;
                height: 220rem;
                background-color: #fff;
                border-radius: 10rem;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .check-your-email .mail-icon {
                --size: 60rem;
                font-size: 24rem;
                color: #000;
                background-color: #f5f5f5;
                padding: 10rem;
                margin-top: 25rem;
                width: var(--size);
                height: var(--size);
                border-radius: var(--size);
            }

            .check-your-email .title {
                margin-top: 15rem;
                font-size: 15rem;
                font-weight: 600;
                color: #444;                
            }

            .check-your-email .text {
                margin-top: 25rem;
                font-size: 13rem;
                color: #444;
                text-align: center;
                padding: 0rem 30rem;
            }

            .check-your-email .cross {
                --size: 40rem;
                --padding: 6rem;
                font-size: 12rem;
                width: var(--size);
                height: var(--size);
                position: absolute;
                right: var(--padding);
                top: var(--padding);
                color: #aaa;
                transition: color 0.3s,background-color 0.3s;
            }

            .check-your-email .cross.hover {
                color: #888;
            }

            .check-your-email .cross.touchdown {
                background-color: rgba(0,0,0,0.04);
            }

            @media screen and (max-width: ${mobileCutoff}px) {
                .spinner-wrapper {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }               
            }
            `}</style>
        </>
    );
}