import { Fragment, useContext, useRef } from "react";
import { LayoutStateContext } from "../../../contexts/layout_state";
import { useCssStateManager } from "../../../hooks/use_css_state_manager";
import { useCustomRouter } from "../../../hooks/use_custom_router";
import { prebuildData } from "../../../lib/prebuild-data";
import { ConfigProduct } from "../../../scripts/pre-build/types";
import GridItem from "./grid_item";
import GridItemRating from "./grid_item_rating";
import GridRow from "./grid_row";

export type AlsoBoughtProps = {
    productIds: string[];
    itemCount: number;
    show: boolean;
    onHidden?: () => void;
    closeSideCartHandler?: () => void;
    onProductPage?: boolean;
};

export default function AlsoBought({itemCount,show,onHidden = () => {},productIds,closeSideCartHandler = () => {},onProductPage = false}:AlsoBoughtProps)
{            
    productIds = productIds.filter((pid) => !!prebuildData.getProductById(pid));
    const rows: ConfigProduct[][] = [];
    const rowCount = Math.ceil(productIds.length/2);
    for(let i=0;i<rowCount;i++) {
        const index = i*2;
        const columns: ConfigProduct[] = [];        
        columns.push(prebuildData.getProductById(productIds[index]));
        if (index+1<productIds.length) {
            columns.push(prebuildData.getProductById(productIds[index+1]));
        }
        rows.push(columns);
    }    

    const cssStateManager = useCssStateManager(["also-bought"]);
    cssStateManager.useProperty(show,"show");
    cssStateManager.useProperty(onProductPage,"on-product-page");    

    const ref = useRef<HTMLDivElement>();

    const transitionEndHandler = (target: HTMLDivElement,propertyName: string) => {
        if ((target===ref.current) && (propertyName==="opacity") && (!show)) {
            onHidden();
        }
    };

    const router = useCustomRouter();

    const clickHandler = (productId: string) => {
        closeSideCartHandler();
        const productPageRoute = prebuildData.getProductPageRoute(productId);
        router.push(productPageRoute);
    };

    const titleCaption = onProductPage?"Frequently Bought With":`Customers Who Bought ${(itemCount>1)?"These Items":"This Item"} Also Bought`;

    const { 
        alsoBoughtWith: {
            titleColor
        }
    } = prebuildData.getActiveTheme();

    return (
        <>
            <div ref={ref} className={cssStateManager.getClassNames()} onTransitionEnd={(e) => transitionEndHandler(e.target as HTMLDivElement,e.propertyName)}>
                <div className="title">{titleCaption}</div>
                <div className="grid">
                    {rows.map((row,index) => (<Fragment key={index}>
                        <GridRow items={row.map(({caption,smallImages,id}) => ({
                                imageSrc: prebuildData.bustCache(smallImages[0].src),
                                imageHoverSrc: (smallImages.length>1)?prebuildData.bustCache(smallImages[1].src):"",
                                title: caption,
                                productId: id.toString()
                            }))} 
                            productIds={row.map(({id}) => id.toString())}
                            onClick={clickHandler}
                            onProductPage={onProductPage}
                        />                        
                    </Fragment>))}
                    {/*}
                    <GridRow items={products1.map(({starRating,ratingCount,caption,smallImages}) => ({
                        imageSrc: smallImages[0].src,
                        imageHoverSrc: (smallImages.length>1)?smallImages[1].src:"",
                        title: caption,
                        rating: starRating,
                        ratingCount
                    }))} />
                    <GridRow items={products2.map(({starRating,ratingCount,caption,smallImages}) => ({
                        imageSrc: smallImages[0].src,
                        imageHoverSrc: (smallImages.length>1)?smallImages[1].src:"",
                        title: caption,
                        rating: starRating,
                        ratingCount
                    }))} />
                    {*/}
                </div>                
            </div>
            <style jsx>{`
            .also-bought {
                margin-top: 40rem;
                opacity: 0;
                transition: opacity 0.3s;
            }
            .also-bought.on-product-page {
                margin-top: 29rem;
            }

            .also-bought.show {
                opacity: 1;
                transition-duration: 0s;
            }

            .title {
                text-align: center;
                font-weight: 700;
                font-size: 17rem;
                color: ${titleColor};
                padding: 0rem 20rem;
                line-height: 29rem;
            }
            .grid {
                padding: 0rem 30rem;
                margin-top: 25rem;
                margin-bottom: 50rem;
            }
            .on-product-page .title {
                font-size: 18rem;
            }
            .on-product-page .grid {
                margin-top: -4rem;
                padding: 0rem 20rem;
            }
            `}</style>
        </>
    );
}