import serverRefs from "../../server-refs.json";
import { misc } from "../misc";

class ApiBugReporter {
    async submitBugReport(email: string,name: string,description: string) {
        const baseUrl = (process.env.NODE_ENV==="development")?"http://localhost:9000":serverRefs.apis.api;
        let url = `${baseUrl}/bugreport`;

        const res = await fetch(url,{
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email,
                name,
                description
            })
        });
        const jsonRes = await res.json();
        if (jsonRes?.type==="error") {
            throw new Error(jsonRes?.errorMessage || "Unexpected response from server");
        }
        else if (jsonRes?.type==="success") {
            return;
        }
        else {
            throw new Error("Unexpected response from server");
        }
    }
}
export const apiBugReporter = new ApiBugReporter();