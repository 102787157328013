import { MutableRefObject, useEffect } from "react";
import { useCssStateManager } from "../../hooks/use_css_state_manager";
import { misc } from "../../lib/misc";
import { prebuildData } from "../../lib/prebuild-data";
import { EventPane } from "../core/event_pane";
import { Image } from "./scaled-next-image";

export type BackToTopProps = {
    position?: "absolute" | "fixed"; 
    right?: number;
    bottom?: number;
    handleScrollToTop?: () => void;
    show: boolean;
    containerRef?: MutableRefObject<HTMLDivElement>;
};

export default function BackToTop({position = "absolute",bottom = 33,right = 31,handleScrollToTop = () => {},show,containerRef}:BackToTopProps)
{
    const cssStateManager = useCssStateManager(["container"]);
    cssStateManager.useProperty(show,"show");
    const scrollToTopSvg = misc.createImageDimensions(266,320,20);

    return (
        <>
            <div 
                className={cssStateManager.getClassNames()}
                style={{
                    position: position,
                    right: `${right}rem`,
                    bottom: `${bottom}rem`
                }}
                ref={containerRef}
            >
                <Image src={prebuildData.bustCache("/images/side_cart/scroll-to-top.svg")} width={scrollToTopSvg.width} height={scrollToTopSvg.height} />
                <EventPane onClick={handleScrollToTop} />
            </div>
            <style jsx>{`
            .container {
                --size: 40rem;
                --duration: 0.3s;
                background-color: #000;
                width: var(--size);
                height: var(--size);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: var(--size);
                background-color: rgba(0,0,0,0.25);              
                opacity: 0;
                visibility: hidden;
                transition: background-color var(--duration),opacity var(--duration),visibility var(--duration);
            }

            .hover,.touchdown {
                background-color: rgba(0,0,0,0.4);
            }
            .show {
                opacity: 1;
                visibility: visible;                                    
            }
            `}</style>
        </>
    );
}