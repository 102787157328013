import { useCssStateManager } from "../../hooks/use_css_state_manager";
import PromoLine from "./promo_line"

type TProp = {
    aspectRatio: number;
    iconName: string;
    text: string;
    children?: JSX.Element;
    url?: string;
};

export default function PromoSpace({aspectRatio,iconName,text,url,children}: TProp) {
    const sideSizePerc = 0;

    const cssStateManager = useCssStateManager(["promo-space"]);

    return (
        <>        
            <div className={cssStateManager.getClassNames()}>
                <div className="frame">
                    {children}
                    <PromoLine {...{aspectRatio,iconName,text,url}} />
                </div>
            </div>
            <style jsx>{`
            .promo-space {
                flex-grow: 1;
                height: 100%;
                position: relative;
            }

            .frame {
                position: absolute;
                top: 0rem;
                height: 100%;
                width: 100%;
                left: 0rem;
                padding-top: 9rem;
                padding-bottom: 9rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            @media screen and (max-width: 1125px)
            {
                .frame {
                    display: none;
                }
            }
            `}</style>
        </>
    );    
}