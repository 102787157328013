import { useContext, useEffect, useRef } from "react";
import { LayoutStateContext } from "../../../contexts/layout_state";
import { useCachedValue } from "../../../hooks/use_cached_value";
import { useCssStateManager } from "../../../hooks/use_css_state_manager";
import { consumeReviewsHeader } from "../../../hooks/use_reviews_header";
import { SmartState, useSmartState } from "../../../hooks/use_smart_state";
import { prebuildData } from "../../../lib/prebuild-data";
import { Image } from "../../common/scaled-next-image";
import StarRating from "../../common/star_rating";
import { EventPane } from "../../core/event_pane";
import Square from "../../core/square";
import { TitleHeight } from "./grid_row";

export type GridItemData = {
    imageSrc: string;
    imageHoverSrc?: string;
    title: string;
    productId: string;
};

export type GridItemProps = {
    data: GridItemData;
    titleHeight?: SmartState<TitleHeight>;
    clickHandler?: () => void;
    onProductPage?: boolean;
};

export default function GridItem({data:{imageSrc,imageHoverSrc,title,productId},titleHeight,clickHandler = () => {},onProductPage = false}:GridItemProps)
{    
    const titleRef = useRef<HTMLDivElement>();

    const { scalePixelToRem,mobileDetect: { widthCutoff: mobileCutoff } } = useContext(LayoutStateContext);

    const productPageRoute = useCachedValue(() => prebuildData.getProductPageRoute(productId),productId);

    useEffect(() => {
        if ((titleRef.current) && (titleHeight)) {
            titleHeight.value.max = Math.max(titleHeight.value.max,scalePixelToRem(titleRef.current.offsetHeight));
            titleHeight.value.knownCount++;
            if (titleHeight.value.knownCount===titleHeight.value.itemCount) {
                titleHeight.value.value = titleHeight.value.max;
                titleHeight.value = {...titleHeight.value};
            }
        }
    },[]);

    const { reviewsCount: ratingCount,starRating: rating } = consumeReviewsHeader(productId);

    const reviewText = (ratingCount!==-1)?((ratingCount>0)?`(${ratingCount})`:"No reviews yet"):"---";

    const cssStateManager = useCssStateManager(["grid-item"]);
    cssStateManager.useProperty(ratingCount===0,"no-reviews");
    cssStateManager.useProperty(onProductPage,"on-product-page");

    const hoverSmartState = useSmartState(false);

    const { 
        alsoBoughtWith: {
            itemTitleColor,
            itemTitleHoverColor,
            itemReviewCountColor,
            itemReviewCountHoverColor
        }
    } = prebuildData.getActiveTheme();

    return (
        <>
            <div className={cssStateManager.getClassNames()}>
                <Square>
                    <Image src={imageSrc} layout="fill" priority={true} />
                    {imageHoverSrc?(
                        <div className="hover-image">
                            <Image src={imageHoverSrc} layout="fill" priority={true} />    
                        </div>
                    ):null}                       
                </Square>
                <div className="title" ref={titleRef} style={{height: ((titleHeight) && (titleHeight.value.value!==-1))?`${titleHeight.value.value}rem`:"auto"}}>{title}</div>
                <div className="rating">
                    <span className="stars">
                        <StarRating rating={rating} hoverSmartState={hoverSmartState} scale={onProductPage?0.75:0.75} />
                    </span>
                    &nbsp;
                    <span className="reviews-text">{reviewText}</span>
                </div>
                <div className="mobile-overlay fill-parent"></div>                 
                <EventPane hoverSmartState={hoverSmartState} onClick={clickHandler} seoLinkUrl={productPageRoute} />
            </div>
            <style jsx>{`
            .grid-item {          
                padding: 0rem;      
                position: relative;
                --duration: 0.3s;
            }
            .title {
                text-align: center;
                margin-top: 12rem;
                font-weight: 600;                                
                color: ${itemTitleColor};
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .rating {
                text-align: center;
                margin-top: 7rem;
            }
            .reviews-text {
                color: ${itemReviewCountColor};
                font-size: 14rem;
            }
            .title,.reviews-text {
                transition: color var(--duration);
            }
            .grid-item.no-reviews .stars {
                display: none;
            }
            .hover-image {
                opacity: 0;
                transition: opacity var(--duration);
            }

            .grid-item.hover .hover-image {    
                opacity: 1;
            }

            .grid-item.hover .reviews-text,.grid-item.touchdown .reviews-text {
                color: ${itemReviewCountHoverColor};
            }
            .grid-item.hover .title,.grid-item.touchdown .title {
                color: ${itemTitleHoverColor};
            }

            .on-product-page .title {
                font-size: 15rem;
            }

            .on-product-page .rating {
                margin-top: 3rem;
            }

            .mobile-overlay {
                position: absolute;
                background-color: rgba(0,0,0,0.045);
                opacity: 0;
                transition: opacity var(--duration);
            }

            @media screen and (max-width: ${mobileCutoff}px) {
                .grid-item.touchdown .mobile-overlay {
                    opacity: 1;
                }
            }

            `}</style>
        </>
    );
}