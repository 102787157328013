import React from 'react';
import { TSettableValue } from './dimensions_state';

export type SortOrder = "popular" | "reviewed" | "newest" | "favorites";
export type FilterMap = {
    fruit?: true,
    dessert?: true,
    candy?: true,
    cool?: true,
    other?: true,
};

export type FilterBarSubscriptionCB = (cb: () => void) => void;

export type CollectionFilterState = {
    sortOrder: TSettableValue<SortOrder>;
    filter: TSettableValue<FilterMap>;
    refreshSwitch: TSettableValue<number>;
    isSwitching: TSettableValue<boolean>;
};

export const CollectionFilterStateContext = React.createContext<CollectionFilterState>(null);