import { useEffect, useRef, useState } from "react";
import { CollectionsGridNoItems } from "../../../components/pages/collection/grid/no-items";
import { OptionsCompiler } from "../../shared/options/snapshot/compiler";
import { DiscountBreakdown, prebuildData } from "../../prebuild-data";
import { ActivatedOptionsMap, CartItem, CartItemDisplay, CartItemOptionValues, CartItemOptionValuesArray, CartItemPropertyGroupDisplay, OptionValue, OptionValueButton, OptionValueCheckboxGroup, OptionValueProductSelect, OptionValueSelect } from "./types";
import { PriceItem } from "../../shared/options/types";
import { checkoutLineItemsAdd, createCheckout, LineItem, splitObject } from "../../api/storefront";
import { NextRequest } from "next/server";
import { cloneSimpleData, isSimpleObject } from "../../shared/options/snapshot/misc";
import { misc } from "../../misc";
import { lstore } from "../../localstorage";
import { useRouter } from "next/router";
import { EditOrderCartItem, EditOrderOrder } from "../../api/editorder";
import { apiSalesSignature } from "../../api/sales_signature";
import { Account } from "../../../hooks/use_account";
import { miscShared } from "../../shared/lib/misc";
import { OptionSnapshotProductVariantSignature } from "../../shared/options/snapshot/types";

export type VariantPriceContribution = {[variantId: string]: {price: number; fromQuantity: number}};

const _generateCartItemPropertiesCache: {[digest: string]: { 
    price: number; 
    priceBeyondFirst: number; 
    properties: CartItemPropertyGroupDisplay[];
    imageSrc: string;
    originalSrc: string;
    variantPriceContribution: VariantPriceContribution;
    variantPriceContributionBeyondFirst: VariantPriceContribution;
    variantId: string;

}} = {};

export const dollarPriceToCentPrice = (value: number) => Math.round(value*100);

export const infoAttributesToDisplayString = (infoAttributes: InfoAttributes) => {
    const itemSep = "+/0/+";
    const infoSep = "+/1/+";
    const propertySep = "+/2/+";
    const titleSep = "+/3/+";
    const linesSep = "+/4/+";    
    return infoAttributes.items.map(({title,thumbSrc,price,quantity,properties}) => {
        return [
            title,
            thumbSrc,
            price,
            quantity,
            properties.map(({title,lines}) => `${title}${titleSep}${lines.join(linesSep)}`).join(propertySep)
        ].join(infoSep);
    }).join(itemSep);
};

type PackingSlipTitlePartColor = "black" | "green" | "yellow" | "blue" | "red";

type PackingSlipTitlePart = {
    color: PackingSlipTitlePartColor;
    text: string;
};

type PackingSlipTitle = PackingSlipTitlePart[];

type PackingSlipItemPart = {
    title: PackingSlipTitle;
    thumbSrc: string;
    dodgerLines: string[];
    blueLines: string[];
    blackLines: string[];
    quantity: number;
};

const packingSlipTitlePartToString = ({color,text}: PackingSlipTitlePart) => {
    const titlePartSep = "+/3/+";
    return [color,text].join(titlePartSep);
};


const packingSlipTitleToString = (title: PackingSlipTitle) => {
    const titleSep = "+/2/+";
    return title.map(packingSlipTitlePartToString).join(titleSep);
};


const packingSlipPartToString = ({title,thumbSrc,quantity,dodgerLines,blueLines,blackLines}: PackingSlipItemPart) => {
    const infoSep = "+/1/+";
    const dodgerLineSep = "+/4/+";
    const blueLineSep = "+/5/+";
    const blackLineSep = "+/6/+";
    return [
        packingSlipTitleToString(title),
        thumbSrc,
        quantity,
        dodgerLines.join(dodgerLineSep),
        blueLines.join(blueLineSep),
        blackLines.join(blackLineSep)
    ].join(infoSep);
};

const packingSlipPartsToString = (itemParts: PackingSlipItemPart[]) => {
    const itemSep = "+/0/+";
    return itemParts.map(packingSlipPartToString).join(itemSep);
};

export const genPackslipAttributeString = (cartItems: CartItem[]) => {
    const optionsCompiler = prebuildData.getOptionsCompiler();

    const getBlackLinesFromAddons = (optionValues: CartItemOptionValues) => {
        const blackLines: string[] = [];

        const addonsOption = optionValues["addons"];
        const extraFlavoringOption = optionValues["extraFlavoring"];
        const extraCoolOption = optionValues["extraCool"];            

        if ((addonsOption) && (addonsOption.type==="checkbox-group") && (addonsOption.ids.length>0)) {
            const idToString = (id: string) => {
                const map = {
                    sour: "+Sour (Malic)",
                    cool: "+Cool (Menthol)",
                    sweet: "+Sweet (Sucralose)"
                };
                return map[id];
            };
            blackLines.push(addonsOption.ids.map(idToString).join(", "));
        }            

        if ((extraFlavoringOption) && (extraFlavoringOption.type==="select") && (extraFlavoringOption.id!=="none")) {
            blackLines.push(`+${extraFlavoringOption.id.substring(4)}% Flavor`);
        }

        if ((extraCoolOption) && (extraCoolOption.type==="select") && (extraCoolOption.id!=="none")) {
            blackLines.push(`+${extraCoolOption.id.charAt(0).toUpperCase()}${extraCoolOption.id.substring(1)} Menthol Shot`);
        }

        return blackLines;
    };

    const aliases: string[] = [
        "agentCool",
        "sweetener",
        "originalCaliburnReplacementPods",
        "caliburnA2ReplacementPods",
        "whiteCaliburnA2Device",
        "caliburnA2Device",
        "usbCCable",
        "caliburnA2StarterBundle",
        "mysteryFlavor",
        "buildYourOwnPack",
        "essentialSaltsPack",
        "starterSaltsPack"];
    const aliasedProductIds: {[alias: string]: string} = {};
    const whiteDeviceColorId = "specialEditionWhite";
    aliases.forEach((alias) => {
        aliasedProductIds[alias] = prebuildData.getProductAliasStub(alias).id;;
    });

    let agentCoolQuantity = 0;
    let sweetenerQuantity = 0;
    let usbCableQuantity = 0;
    const itemParts: PackingSlipItemPart[] = [];
    const salts: { [productId: string]: { [nicotineId: string]: number } } = {};
    const pods: { [productId: string]: { [resistanceId: string]: number } } = {};
    const devices: { [colorId: string]: number } = {};

    const addSimpleItem = (productId: string,title: string,quantity: number,slideshowMatch?: string) => {
        const thumbImage = prebuildData.getProductSmallImageSlideShowMatch(productId,slideshowMatch);
        if (thumbImage) {
            const { originalSrc: thumbSrc } = thumbImage;
            itemParts.push({
                title: [{color: "black",text: title}],
                thumbSrc,
                quantity,
                dodgerLines: [],
                blueLines: [],
                blackLines: []
            });    
        }
    };

    const addNonCustomSalt = (productId: string,nicotineId: string,quantity: number) => {
        if (["mg15","mg30","mg50"].includes(nicotineId)) {
            salts[productId] = salts[productId] || {};
            salts[productId][nicotineId] = salts[productId][nicotineId] || 0;
            salts[productId][nicotineId]+= quantity;    
        }
    };

    const addReplacementPod = (productId: string,resistanceId: string,quantity: number) => {
        if (["ohm09","ohm14","ohm12"].includes(resistanceId)) {
            pods[productId] = pods[productId] || {};
            pods[productId][resistanceId] = pods[productId][resistanceId] || 0;
            pods[productId][resistanceId]+= quantity;    
        }
    };

    const extractReplacementPods = (optionValues: CartItemOptionValues,quantity: number) => {
        const replacementPodsOption = optionValues["replacementPods"];
        if (replacementPodsOption?.type==="variant-select") {
            const { id,quantity: podQuantity } = replacementPodsOption;
            const { title,productId } = prebuildData.getVariantStub(id);
            const resistanceId = `ohm${title.substring(0,title.length-1).split(".").join("")}`;
            addReplacementPod(productId,resistanceId,quantity*podQuantity);
        }
    };

    const extractUsbCable = (optionValues: CartItemOptionValues,quantity: number) => {
        const usbCCableOption = optionValues["usbCCable"];
        if (usbCCableOption?.type==="checkbox") {
            usbCableQuantity+= quantity;
        }
    };

    const extractColorInfo = (optionValues: CartItemOptionValues,productId: string,itemId = "color") => {  
        const colorOption = optionValues[itemId];
        const colorOptionRef = optionsCompiler.getProductOptions(productId).options.filter(({id}) => id===itemId)[0];
        if ((colorOption?.type==="buttons") && (colorOptionRef?.type==="buttons")) {
            const colorItemRef = colorOptionRef.items.filter(({id}) => id===colorOption.id)[0];
            if (colorItemRef) {
                const { caption: colorCaption,slideshowMatch: colorSlideshowMatch } = colorItemRef;
                const colorId = colorOption.id;
                return {
                    colorId,
                    colorCaption,
                    colorSlideshowMatch
                };
            }
        }
        return null;
    };

    const colorCode: {[id: string]: PackingSlipTitlePart} = {
        mg15: {color: "yellow",text: "15mg"},
        mg30: {color: "blue",text: "30mg"},
        mg50: {color: "red",text: "50mg (FIFTY)"},
        custom: {color: "green",text: "custom"}
    };

    const extractNicotineInfo = (optionValues: CartItemOptionValues) => {
        const nicotineOption = optionValues["nicotine"];
        if (nicotineOption?.type==="buttons") {
            const titlePart = colorCode[nicotineOption.id];
            const customLine = (nicotineOption.id==="custom")?`Nicotine ${nicotineOption.value}`:"";
            return {titlePart,customLine};
        }
        return null;
    };

    const extractFlavorBlackLines = (optionValues: CartItemOptionValues) => {
        const flavorsOption = optionValues["flavors"];
        if (flavorsOption?.type==="product-select") {
            return flavorsOption.ids.map((productId) => prebuildData.getProductById(productId).caption);
        }
        return [];
    };

    const extractAddonProducts = (optionValues: CartItemOptionValues,quantity: number) => {
        const agentCoolOption = optionValues["agentCool"];
        const sweetenerOption = optionValues["sweetener"];
        if (agentCoolOption?.type==="checkbox") agentCoolQuantity+= quantity;
        if (sweetenerOption?.type==="checkbox") sweetenerQuantity+= quantity;
    };

    const extractPodPacksInfo = (productId: string,optionValues: CartItemOptionValues,quantity: number) => {
        const podPackOptionRef = optionsCompiler.getProductOptions(productId).options.filter(({id}) => id==="podPack")[0];
        const podPackOption = optionValues["podPack"];
        if ((podPackOptionRef?.type==="buttons") && (podPackOption?.type==="buttons")) {
            const podPackId = podPackOption.id;
            const podPackQuantity = podPackOption.quantity || 1;
            if ((podPackQuantity>1) && (podPackOptionRef.link)) {
                const variantId: string = prebuildData.extractVariantsFromLink(productId,optionValues,podPackId,podPackOptionRef.link)?.[0]?.id || "";
                if (variantId) {
                    const { title,productId } = prebuildData.getVariantStub(variantId);
                    const resistanceId = `ohm${title.substring(0,title.length-1).split(".").join("")}`;
                    addReplacementPod(productId,resistanceId,quantity*(podPackQuantity-1));        
                }
            }            
            const podPackCaption = `(${podPackId.substring(0,2).toUpperCase()}) ${podPackId.substring(6).split("").join(".")} ohm`;
            return {
                podPackId,
                podPackQuantity,
                podPackCaption
            };            
        }
        return null;
    };

    const extractFlavorPackInfo = (productId: string,optionValues: CartItemOptionValues) => {
        const flavorPackOptionRef = optionsCompiler.getProductOptions(productId).options.filter(({id}) => id==="flavorPack")[0];
        const flavorPackOption = optionValues["flavorPack"];
        if ((flavorPackOptionRef?.type==="buttons") && (flavorPackOption?.type==="buttons")) {
            const flavorPackId = flavorPackOption.id;
            const flavorPackItemRef = flavorPackOptionRef.items.filter(({id}) => id===flavorPackId)[0];
            if (flavorPackItemRef) {
                const { caption: flavorPackCaption } = flavorPackItemRef;
                const flavors: string[] = [];
                if (flavorPackOptionRef.link) {                
                    flavors.push(...prebuildData.extractVariantsFromLink(productId,optionValues,flavorPackId,flavorPackOptionRef.link).map(({id}) => {
                        return prebuildData.getProductById(prebuildData.getVariantStub(id).productId).caption;
                    }));
                }
                return { flavorPackId,flavorPackCaption,flavors };    
            }            
        }
        return null;
    };

    const extractBonusFlavor = (optionValues: CartItemOptionValues) => {
        const bonusFlavorOption = optionValues["bonusFlavor"];
        if (bonusFlavorOption?.type==="product-select") {
            const product = prebuildData.getProductById(bonusFlavorOption.ids[0] || "");
            if (product) {
                const bonusFlavorCaption = product.caption;
                return { bonusFlavorCaption };                
            }
        }
        return null;
    };

    const customTitlePart: PackingSlipTitlePart = {color: "green",text: "custom"};
    const sepTitlePart: PackingSlipTitlePart = {color: "black",text: " / "};

    cartItems.forEach(({productId,optionValues,quantity}) => {        
        const { collectionId,collectionName } = prebuildData.getProductToCollectionById(productId);
        const { caption,smallImages } = prebuildData.getProductById(productId);
        const thumbSrc = smallImages[0].originalSrc;
        if ((collectionId==="handcrafted-salts") || ((collectionId==="retired-products") && (caption.endsWith(" Salt")))) {
            const nicotineOption = optionValues["nicotine"];
            if ((collectionId==="handcrafted-salts") && (nicotineOption?.type==="buttons") && (nicotineOption?.id!=="custom")) {
                addNonCustomSalt(productId,nicotineOption?.id,quantity);
            }
            else {
                const title: PackingSlipTitle = [
                    {color: "black",text: `${caption} - `},
                ];            
                const dodgerLines: string[] = [collectionName];
                const blackLines: string[] = getBlackLinesFromAddons(optionValues);
                    
                const sizeOption = optionValues["size"];

                const nicotineInfo = extractNicotineInfo(optionValues);
                if (nicotineInfo) {
                    const { titlePart,customLine } = nicotineInfo
                    title.push(titlePart);
                    if (customLine!=="") dodgerLines.push(customLine);
                }
    
                if (sizeOption?.type==="buttons") {
                    const sizeValue = parseInt(sizeOption.id.substring(2));
                    const infoMap = {
                        "30": "(small)",
                        "60": "(medium)",
                        "120": "(value)"
                    };
                    title.push(sepTitlePart);
                    title.push({color: "black",text: `${sizeValue}ml ${infoMap[sizeValue]}`});
                }

                extractAddonProducts(optionValues,quantity);
    
                itemParts.push({
                    title,
                    thumbSrc,
                    quantity,
                    dodgerLines,
                    blueLines: [],
                    blackLines
                });    
            }
        }
        else if ((collectionId==="signature-e-juice") || ((collectionId==="retired-products") && (!caption.endsWith(" Salt")))) {
            const title: PackingSlipTitle = [
                {color: "black",text: `${caption} - `},
            ];
            const blueLines: string[] = [collectionName];
            const blackLines: string[] = getBlackLinesFromAddons(optionValues);
            const nicotineOption = optionValues["nicotine"];
            const vgOption = optionValues["vg"];
            const sizeOption = optionValues["size"];
            
            if (nicotineOption.type==="buttons") {
                if (nicotineOption.id==="custom") {                    
                    title.push(customTitlePart);     
                    blueLines.push(`Nicotine ${nicotineOption.value}`);           
                }
                else {
                    title.push({color: "black",text: `${nicotineOption.id.substring(2)}mg`});
                }                
            }
            title.push(sepTitlePart);                                                
            if (vgOption.type==="buttons") {
                if (vgOption.id==="custom") {                    
                    title.push(customTitlePart);                
                    blueLines.push(`VG ${vgOption.value}`);           
                }
                else {
                    const vgValue = parseInt(vgOption.id.substring(2));
                    title.push({color: "black",text: `${vgValue}VG/${100-vgValue}PG`});
                }                
            }
            title.push(sepTitlePart);
            if (sizeOption.type==="buttons") {
                const sizeValue = parseInt(sizeOption.id.substring(2));
                const infoMap = {
                    "30": "(small)",
                    "60": "(medium)",
                    "120": "(value)"
                };
                title.push({color: "black",text: `${sizeValue}ml ${infoMap[sizeValue]}`});
            }

            itemParts.push({
                title,
                thumbSrc,
                quantity,
                dodgerLines: [],
                blueLines,
                blackLines
            });
        }                
        else if (productId===aliasedProductIds["agentCool"]) {
            const sizeOption = optionValues["size"];
            if (sizeOption?.type==="buttons") {
                if (sizeOption.id==="ml15") {
                    agentCoolQuantity+= quantity;
                }
                else {
                    addSimpleItem(aliasedProductIds["agentCool"],"Agent Cool - 60ml (bulk) Super Concentrate",quantity);
                }
            }
        }
        else if (productId===aliasedProductIds["sweetener"]) {
            sweetenerQuantity+= quantity;
        }
        else if ((productId===aliasedProductIds["originalCaliburnReplacementPods"]) || (productId===aliasedProductIds["caliburnA2ReplacementPods"])) {
            const resistanceOption = optionValues["resistance"];
            const dealOption = optionValues["deal"];
            if (resistanceOption?.type==="buttons") {
                addReplacementPod(productId,resistanceOption.id,quantity);
            }
            if ((dealOption?.type==="variant-select") && (dealOption?.id)) {
                const variantStub = prebuildData.getVariantStub(dealOption?.id);
                if (variantStub) {
                    const nicotineId = `mg${variantStub.title}`;
                    addNonCustomSalt(variantStub.productId,nicotineId,1);
                }
            }
        }
        else if (productId===aliasedProductIds["whiteCaliburnA2Device"]) {
            devices[whiteDeviceColorId] = devices[whiteDeviceColorId] || 0;
            devices[whiteDeviceColorId]+= quantity;
            extractReplacementPods(optionValues,quantity);
            extractUsbCable(optionValues,quantity);
        }
        else if (productId===aliasedProductIds["caliburnA2Device"]) {
            const colorInfo = extractColorInfo(optionValues,productId);
            if (colorInfo) {
                const { colorId } = colorInfo;
                devices[colorId] = devices[colorId] || 0;
                devices[colorId]+= quantity;
                extractReplacementPods(optionValues,quantity);
                extractUsbCable(optionValues,quantity);        
            }
        }
        else if ((productId===aliasedProductIds["caliburnA2StarterBundle"]) || (productId===aliasedProductIds["buildYourOwnPack"])) {
            const isCaliburnBundle = (productId===aliasedProductIds["caliburnA2StarterBundle"]);
            const colorInfo = extractColorInfo(optionValues,productId,isCaliburnBundle?"color":"caliburnA2DeviceKit");
            if (colorInfo) {
                const { colorId,colorCaption,colorSlideshowMatch } = colorInfo;
                const nicotineInfo = extractNicotineInfo(optionValues);
                if (nicotineInfo) {
                    const { titlePart,customLine } = nicotineInfo;
                    const title: PackingSlipTitle = [
                        {color: "black",text: `${caption}${isCaliburnBundle?` - ${colorCaption}`:""} / `},
                        titlePart
                    ];
                    const dodgerLines: string[] = customLine?[customLine]:[];
                    const blackLines: string[] = extractFlavorBlackLines(optionValues);
                    const mysteryFlavorVariantCaption = optionsCompiler.getProductOptions(aliasedProductIds["mysteryFlavor"]).vars["variantCaption"] || "";
                    blackLines.push(`Mystery Flavor${mysteryFlavorVariantCaption?` - ${mysteryFlavorVariantCaption}`:""}`);

                    const thumbImage = prebuildData.getProductSmallImageSlideShowMatch(productId,isCaliburnBundle?colorSlideshowMatch:undefined);
                    if (thumbImage) {
                        const { originalSrc: thumbSrc } = thumbImage;

                        itemParts.push({
                            title,
                            thumbSrc,
                            quantity,
                            dodgerLines,
                            blueLines: [],
                            blackLines
                        }); 
                        
                        extractReplacementPods(optionValues,quantity);
                        extractUsbCable(optionValues,quantity);
                        extractAddonProducts(optionValues,quantity);

                        if ((!isCaliburnBundle) && (colorId!=="none")) {
                            devices[colorId] = devices[colorId] || 0;
                            devices[colorId]+= quantity;
                        }
                    }
                }
            }
        }
        else if ((productId===aliasedProductIds["starterSaltsPack"]) || (productId===aliasedProductIds["essentialSaltsPack"])) {
            const isStarterPack = (productId===aliasedProductIds["starterSaltsPack"]);
            const nicotineInfo = extractNicotineInfo(optionValues);
            const podPacksInfo = extractPodPacksInfo(productId,optionValues,quantity);
            const flavorPackInfo = extractFlavorPackInfo(productId,optionValues);
            if ((nicotineInfo) && (podPacksInfo) && (flavorPackInfo)) {
                const { titlePart: nicotineTitlePart,customLine: nicotineCustomLine } = nicotineInfo;
                const { podPackCaption } = podPacksInfo;
                const { flavorPackCaption,flavors } = flavorPackInfo;
                const title: PackingSlipTitle = [
                    {color: "black",text: `${caption} - `},
                    nicotineTitlePart,
                    {color: "black",text: ` / ${podPackCaption} / ${flavorPackCaption}`}
                ];
                const dodgerLines: string[] = [isStarterPack?"(3) Salts + Pod Pack +":"(6) Salts + Pod Pack +"];
                if (nicotineCustomLine) dodgerLines.push(nicotineCustomLine);
                const blackLines: string[] = flavors;

                const extractBonusInfo = extractBonusFlavor(optionValues);
                if (extractBonusInfo) {
                    const { bonusFlavorCaption } = extractBonusInfo;
                    blackLines.push(`[bold]${bonusFlavorCaption}`);                        
                }

                const mysteryFlavorVariantCaption = optionsCompiler.getProductOptions(aliasedProductIds["mysteryFlavor"]).vars["variantCaption"] || "";
                blackLines.push(`Mystery Flavor${mysteryFlavorVariantCaption?` - ${mysteryFlavorVariantCaption}`:""}`);

                itemParts.push({
                    title,
                    thumbSrc,
                    quantity,
                    dodgerLines,
                    blueLines: [],
                    blackLines
                }); 

                extractAddonProducts(optionValues,quantity);

                const colorInfo = extractColorInfo(optionValues,productId,"caliburnA2DeviceKit");
                if ((colorInfo) && (colorInfo.colorId!=="none")) {
                    devices[colorInfo.colorId] = devices[colorInfo.colorId] || 0;
                    devices[colorInfo.colorId]+= quantity;
                }
            }
        }
    });
    Object.keys(salts).forEach((productId) => {
        const { collectionName } = prebuildData.getProductToCollectionById(productId);
        const { caption,smallImages } = prebuildData.getProductById(productId);
        const thumbSrc = smallImages[0].originalSrc;        
        Object.keys(salts[productId]).forEach((nicotineId) => {
            const quantity = salts[productId][nicotineId];            
            const title: PackingSlipTitle = [
                {color: "black",text: `${caption} - `},
                colorCode[nicotineId]
            ];         
            const dodgerLines: string[] = [collectionName];

            itemParts.push({
                title,
                thumbSrc,
                quantity,
                dodgerLines,
                blueLines: [],
                blackLines: []
            });    
        });
    });
    const deviceCaption = prebuildData.getProductById(aliasedProductIds["caliburnA2Device"]).caption;
    const whiteDeviceCaption = prebuildData.getProductById(aliasedProductIds["whiteCaliburnA2Device"]).caption;
    Object.keys(devices).forEach((colorId) => {
        const quantity = devices[colorId];
        const colorInfo = extractColorInfo({
            color: {
                type: "buttons",
                id: colorId
            }
        },aliasedProductIds["caliburnA2Device"],"color");
        if (colorInfo) {
            const { colorCaption,colorSlideshowMatch } = colorInfo;            
            addSimpleItem(aliasedProductIds["caliburnA2Device"],(colorId===whiteDeviceColorId)?whiteDeviceCaption:`${deviceCaption} - ${colorCaption}`,quantity,colorSlideshowMatch);
        }
    });
    Object.keys(pods).forEach((productId) => {
        const { caption } = prebuildData.getProductById(productId);
        Object.keys(pods[productId]).forEach((resistanceId) => {
            const quantity = pods[productId][resistanceId];
            addSimpleItem(productId,`${caption} - ${resistanceId.substring(3).split("").join(".")}Ω`,quantity);
        });
    });
    if (agentCoolQuantity>0) {
        addSimpleItem(aliasedProductIds["agentCool"],"Agent Cool Super Concentrate",agentCoolQuantity);
    }
    if (sweetenerQuantity>0) {
        addSimpleItem(aliasedProductIds["sweetener"],"Sweetener Super Concentrate",sweetenerQuantity);
    }
    if (usbCableQuantity>0) {
        addSimpleItem(aliasedProductIds["usbCCable"],"USB-C Cable Charging Cable",usbCableQuantity);
    }
    
    return packingSlipPartsToString(itemParts);
};

export type InfoAttributesItem = {
    thumbSrc: string;
    price: number;
    quantity: number;
    title: string;
    properties: CartItemPropertyGroupDisplay[];
    productId: string;
    options: CartItemOptionValues;
    collection: {
        id: string;
        name: string;
    };
    freeProduct: boolean;
};
export type InfoAttributes = {
    cartUrl: string;
    items: InfoAttributesItem[];
};
export type CheckoutData = {
    subtotal: number;
    infoAttributes: InfoAttributes;
    variantLinksQuantityHash: {
        [variantId: string]: {
            [attrJson: string]: number
        };
    },
    snapshotId: string;
};

export const generateCartItemInitialOptionValues = (productId: string) => {
    const optionsCompiler = prebuildData.getOptionsCompiler();

    const ret: CartItemOptionValues = {};
    const productOptions = optionsCompiler.getProductOptions(productId);
    productOptions.options.forEach((option) => {
        if (option.type==="buttons") {
            const value: OptionValueButton = {
                type: "buttons",
                id: ""
            };
            let preSelected = false;
            let selectedIndex = 0;
            for(let i=0;i<option.items.length;i++) {
                if (option.items[i].selected) {
                    selectedIndex = i;
                    preSelected = true;
                    break;
                }
            }
            if (!isSimpleObject(option.items[selectedIndex].value)) {
                value.id = option.items[selectedIndex].id;                
                ret[option.id] = {...value,...(preSelected?{}:{defaultSet: true})};
            }
        }
        else if (option.type==="select") {            
            const value: OptionValueSelect = {
                type: "select",
                id: option.items[0].id
            };
            ret[option.id] = {...value,defaultSet: true};
        }
        else if ((option.type==="checkbox") && (option.included)) {
            ret[option.id] = {
                type: "checkbox"
            };
        }            
    });
    return ret;
};

export const getSimpleOptionPrice = (productId: string,optionId: string) => {
    const optionsCompiler = prebuildData.getOptionsCompiler();

    const price = (() => {
        const productOptions = optionsCompiler.getProductOptions(productId);
        const option = productOptions.options.filter(({id}) => id===optionId)[0];
        if ((option) && (option.type==="checkbox") && (option.cartLink)) {
            const variant = prebuildData.extractVariantsFromLink(productId,{},optionId,option.cartLink)[0];
            return dollarPriceToCentPrice(variant?.price || 0);
        }
        else if ((option) && (option.type==="buttons") && (option.cartLink)) {
            for(let i=0;i<option.items.length;i++) {
                const variant = prebuildData.extractVariantsFromLink(productId,{},option.items[i].id,option.cartLink)[0];
                if (variant) {
                    return dollarPriceToCentPrice(variant.price || 0);
                }                
            }
            return 0;
        }
        else {
            if (productOptions) {
                if (typeof productOptions.price[optionId]==="number") {
                    return dollarPriceToCentPrice(productOptions.price[optionId] as number);
                }
                else if (typeof productOptions.price[optionId]==="string") {
                    let priceFunctionCode = productOptions.price[optionId] as string;
                    if (priceFunctionCode.startsWith("price.")) {
                        priceFunctionCode = `({price}) => ${priceFunctionCode}`;
                    }
                    const globalPriceObj = prebuildData.getGlobalConfig().vars.price || {};
                    const fnPrice = prebuildData.evalInContext(priceFunctionCode);
                    return dollarPriceToCentPrice(fnPrice({price: globalPriceObj}));
        
                }
                else {
                    return 0;    
                }
            }
            else {
                return 0;
            }        
        }
    })();
    return prebuildData.getProductPageDiscountedPrice(productId,price).price;
};

export const generateCartItemPriceProperties = (item: CartItem,{enforceLimitOnePerOrder = false,hasMoreThanOneItem = false,ignoreUuid = ""}: {enforceLimitOnePerOrder?: boolean; hasMoreThanOneItem?: boolean,ignoreUuid?: string} = {}) => {
    const optionsCompiler = prebuildData.getOptionsCompiler();

    const digest = `${item.uuid}-${item.version}-${hasMoreThanOneItem}`;

    const process = () => {
        item.activatedOptionsMap = {};
        const { activatedOptionsMap } = item;
        const productOptions = optionsCompiler.getProductOptions(item.productId);
        const itemProduct = prebuildData.getProductById(item.productId);
        let imageSrc = itemProduct.smallImages[0].src;
        let originalSrc = itemProduct.smallImages[0].originalSrc;
        const indexHash: {[id: string]: number} = {};

        type DeterminePriceResult = {
            priceAddedHash: {[id: string]: true};
            price: number;
            priceBeyondFirst: number;
        };

        const determinePrice = (optionId: string,fnParam: any,itemId?: string,multi = 1,tryParentIfNoChild = false,limitOnePerOrder = false): DeterminePriceResult => {
            let ret: DeterminePriceResult = { priceAddedHash: {},price: 0,priceBeyondFirst: 0};
            // if (item.freeProduct) return ret;
            if (productOptions.price.hasOwnProperty(optionId)) {
                if (itemId) {
                    let added = false;
                    if (productOptions.price[optionId].hasOwnProperty(itemId)) {
                        if (typeof productOptions.price[optionId][itemId]==="number") {
                            const delta = dollarPriceToCentPrice(productOptions.price[optionId][itemId])*multi;
                            ret.price+= delta;
                            if (!limitOnePerOrder) ret.priceBeyondFirst+= delta;
                            added = true;
                        }
                        else if (typeof productOptions.price[optionId][itemId]==="string") {
                            let priceFunctionCode = productOptions.price[optionId][itemId] as string;                            
                            if (priceFunctionCode.startsWith("price.")) {
                                priceFunctionCode = `({price}) => ${priceFunctionCode}`;
                            }
                            const fnPrice = prebuildData.evalInContext(priceFunctionCode);
                            const globalPriceObj = prebuildData.getGlobalConfig().vars.price || {};
                            const _fnParam = isSimpleObject(fnParam)?{...fnParam,price: globalPriceObj}:{price: globalPriceObj};
                            const delta = dollarPriceToCentPrice(fnPrice(_fnParam))*multi;
                            ret.price+= delta;
                            if (!limitOnePerOrder) ret.priceBeyondFirst+= delta;
                            added = true;
                        }                        
                    }
                    if ((tryParentIfNoChild) && (!added)) {
                        ret = determinePrice(optionId,fnParam,undefined,multi,false,limitOnePerOrder);
                    }
                }
                else if (typeof productOptions.price[optionId]==="number") {
                    const combinedPriceAddedHash = {...priceAddedHash,...ret.priceAddedHash};
                    if (!combinedPriceAddedHash[optionId]) {
                        const delta = dollarPriceToCentPrice(productOptions.price[optionId] as number)*multi;
                        ret.price+= delta;
                        if (!limitOnePerOrder) ret.priceBeyondFirst+= delta;
                        ret.priceAddedHash[optionId] = true;
                    }                    
                }
                else if (typeof productOptions.price[optionId]==="string") {
                    let priceFunctionCode = productOptions.price[optionId] as string;
                    if (priceFunctionCode.startsWith("price.")) {
                        priceFunctionCode = `({price}) => ${priceFunctionCode}`;
                    }
                    const fnPrice = prebuildData.evalInContext(priceFunctionCode);
                    const globalPriceObj = prebuildData.getGlobalConfig().vars.price || {};
                    const _fnParam = isSimpleObject(fnParam)?{...fnParam,price: globalPriceObj}:{price: globalPriceObj};
                    const delta = dollarPriceToCentPrice(fnPrice(_fnParam))*multi;
                    ret.price+= delta;
                    if (!limitOnePerOrder) ret.priceBeyondFirst+= delta;
                }
            }
            return ret;
        };

        // let price = item.freeProduct?0:(dollarPriceToCentPrice((typeof productOptions.price.base==="number")?productOptions.price.base:0));
        // let price = (dollarPriceToCentPrice((typeof productOptions.price.base==="number")?productOptions.price.base:0));
        let price = determinePrice("base",{}).price;
        let priceBeyondFirst = price;        

        let properties = productOptions.display.map(({id,caption},index) => {
            const ret: CartItemPropertyGroupDisplay = {
                title: caption,
                lines: []
            };
            indexHash[id] = index;
            return ret;
        });

        let priceAddedHash: {[id: string]: true} = {};

        let variantPriceContribution: VariantPriceContribution = {};
        let variantPriceContributionBeyondFirst: VariantPriceContribution = {};

        const breakdown = cart.getVariantLinkBreakdownForItem(item,{ignoreActivatedOptionsMap: true,ignoreQuantity: true});
        const breakdownNew = cart.getVariantLinkBreakdownForItemNew(item,{ignoreActivatedOptionsMap: true,ignoreQuantity: true});
        const variantId = Object.keys(breakdownNew[":variant"] || {})[0] || "";

        const transferBreakdownToSingleContribution = (optionId: string,price: number,contribution: typeof variantPriceContribution,quantityPricingOverOne = false) => {
            const vb = (() => {
                if (optionId===":link:variant") {
                    const ret: {[variantId: string]: number} = {};
                    const add = (hash?: {[variantId: string]: number}) => {
                        if (hash) {
                            Object.keys(hash).forEach((variantId) => {
                                ret[variantId] = ret[variantId] || 0;
                                ret[variantId]+= hash[variantId];
                            });
                        }
                    };
                    add(breakdown[":link"]);
                    add(breakdown[":variant"]);
                    return ret;
                }
                else {
                    return breakdown[optionId] || {};
                }                
            })();
            if (vb) {
                const variantIds = Object.keys(vb);
                const splitCount = variantIds.length;
                const partPrice = Math.floor(price/splitCount);
                variantIds.forEach((variantId,index) => {                    
                    const take = (index<splitCount-1)?partPrice:price;
                    const variantQuantity = vb[variantId];
                    price-= take;
                    contribution[variantId] = contribution[variantId] || { price: 0, fromQuantity: 0 };                    
                    contribution[variantId].fromQuantity+= variantQuantity-(((splitCount===1) && (quantityPricingOverOne))?1:0);
                    contribution[variantId].price+= take;
                });
            }
        };

        const transferBreakdownToContribution = (optionId: string,res: DeterminePriceResult,quantityPricingOverOne = false) => {
            transferBreakdownToSingleContribution(optionId,res.price,variantPriceContribution,quantityPricingOverOne);
            transferBreakdownToSingleContribution(optionId,res.priceBeyondFirst,variantPriceContributionBeyondFirst,quantityPricingOverOne);
        };

        transferBreakdownToContribution(":link:variant",{price,priceBeyondFirst,priceAddedHash: {}});

        // console.log(prebuildData.getProductById(item.productId).caption,"breakdown",breakdown);

        const addToPrice = (optionId: string,fnParam: any,itemId?: string,multi = 1,tryParentIfNoChild = false,limitOnePerOrder = false) => {
            const res = determinePrice(optionId,fnParam,itemId,multi,tryParentIfNoChild,limitOnePerOrder);
            priceAddedHash = {...priceAddedHash,...res.priceAddedHash}
            price+= res.price;
            priceBeyondFirst+= res.priceBeyondFirst;
            return res;
        };

        const optionsStub: {[optionId: string]: {itemId: string}} = {};

        productOptions.options.forEach((option) => {
            if (option.type==="buttons") {
                const {id} = option;
                const optionValue = item.optionValues[id];
                if ((optionValue) && (optionValue.type==="buttons")) {
                    optionsStub[id] = {
                        itemId: optionValue.id
                    };                    
                }
            }
        });

        const addDeterminePriceResult = (a: DeterminePriceResult,b: DeterminePriceResult) => {
            a.price+= b.price;
            a.priceBeyondFirst+= b.priceBeyondFirst;
        };

        const emptyDeterminPriceResult = () => {
            const ret: DeterminePriceResult = {
                price: 0,
                priceBeyondFirst: 0,
                priceAddedHash: {}
            };
            return ret;
        };

        const activatePriceUsedMap: {[key: string]: true} = {};

        productOptions.options.forEach((option) => {
            const {display,id} = option;
            const optionValue = item.optionValues[id];
            if (optionValue) {
                if ((optionValue.type==="buttons") && (option.type==="buttons")) {
                    if (!option.cartLink) {
                        if (typeof indexHash[id]==="number") {
                            const optionItem = option.items.filter(({id}) => id===optionValue.id)[0];
                            if (optionItem) {
                                const res = emptyDeterminPriceResult();
    
                                if (optionItem.slideshowMatch) {
                                    for(let i=0;i<itemProduct.smallImages.length;i++) {
                                        if (itemProduct.smallImages[i].src.indexOf(optionItem.slideshowMatch)!==-1) {
                                            imageSrc = itemProduct.smallImages[i].src;
                                            originalSrc = itemProduct.smallImages[i].originalSrc;
                                            break;
                                        }                        
                                    }                                
                                }
                                const value = ((typeof optionItem.value!=="string") && (typeof optionItem.value!=="number"))?optionValue.value:optionItem.value;
                                const caption = optionItem.caption;
                                const displayFn = prebuildData.evalInContext(display);
                                if ((!option.displayNullId) || (optionItem.id!==option.displayNullId)) {                                
                                    properties[indexHash[id]].lines.push(displayFn({value,caption,id: optionItem.id})+(optionValue.quantity?` (x${optionValue.quantity})`:""));
                                }                            
                                if (optionItem.activatePrice) {
                                    if (!activatePriceUsedMap[optionItem.activatePrice]) {
                                        addDeterminePriceResult(res,
                                            addToPrice(optionItem.activatePrice,{options: optionsStub,option: {itemId: optionItem.id}})
                                        );
                                        activatePriceUsedMap[optionItem.activatePrice] = true;
                                    }
                                }
                                if (option.quantity) {
                                    const quantityValue = (optionValue.quantity || 1)-(option.quantity.pricingOverOne?1:0);
                                    if (quantityValue>0) {
                                        addDeterminePriceResult(res,
                                            addToPrice(id,{options: optionsStub,option:{itemId: optionItem.id}},optionValue.id,quantityValue,true)
                                        );
                                    }                                
                                }
                                else {
                                    addDeterminePriceResult(res,
                                        addToPrice(id,{options: optionsStub,option:{itemId: optionItem.id}},optionValue.id,1,true)
                                    );
                                }                            
                                transferBreakdownToContribution(option.id,res,!!option.quantity?.pricingOverOne);
                                activatedOptionsMap[id] = true;
                            }    
                        }    
                    }
                }            
                else if ((optionValue.type==="checkbox") && (option.type==="checkbox")) {                                        
                    if (!option.cartLink) {
                        const displayId = option.displayId || id;
                        if (typeof indexHash[displayId]==="number") {
                            const value = "checked";
                            const caption = option.caption;
                            const displayFn = prebuildData.evalInContext(display);
                            properties[indexHash[displayId]].lines.push(displayFn({value,caption}));
                            const res = addToPrice(id,{options: optionsStub});
                            transferBreakdownToContribution(option.id,
                                res
                            );
                            activatedOptionsMap[id] = true;                        
                        }    
                    }
                }
                else if ((optionValue.type==="select") && (option.type==="select")) {
                    if (typeof indexHash[id]==="number") {
                        const optionItem = option.items.filter(({id}) => id===optionValue.id)[0];
                        if (optionItem) {
                            const value = optionValue.id;
                            const caption = optionItem.caption;
                            const displayFn = prebuildData.evalInContext(display);
                            if ((!option.displayNullId) || (optionItem.id!==option.displayNullId)) {
                                properties[indexHash[id]].lines.push(displayFn({value,caption}));    
                            }                            
                            transferBreakdownToContribution(option.id,
                                addToPrice(id,{options: optionsStub},optionValue.id)
                            );
                            activatedOptionsMap[id] = true;
                        }
                    }
                }    
                else if ((optionValue.type==="checkbox-group") && (option.type==="checkbox-group")) {
                    if (typeof indexHash[id]==="number") {
                        const optionItems = option.items.filter(({id}) => optionValue.ids.indexOf(id)!==-1);
                        let selected = false;
                        optionItems.forEach((optionItem) => {
                            const value = optionItem.id;
                            const caption = optionItem.caption;
                            const displayFn = prebuildData.evalInContext(display);
                            properties[indexHash[id]].lines.push(displayFn({value,caption}));
                            transferBreakdownToContribution(option.id,
                                addToPrice(id,{options: optionsStub},optionItem.id)
                            );
                            selected = true;
                        });
                        if (selected) {
                            activatedOptionsMap[id] = true;
                        }
                    }
                }
                else if ((optionValue.type==="variant-select") && (option.type==="variant-select")) {
                    if (!option.cartLink) {
                        if ((enforceLimitOnePerOrder) && (option.limitOnePerOrder) && (cart.hasDealAlready(item.productId,option.id,ignoreUuid))) {
                            return;
                        }
                        
                        if (typeof indexHash[id]==="number") {
                            const variantHash = prebuildData.getVariantHash();
                            if (variantHash[optionValue.id]) {
                                const { productId } = variantHash[optionValue.id];
                                const product = prebuildData.getProductById(productId);
                                if (product) {
                                    if (option.itemCaption) {
                                        const itemCaptionFn = prebuildData.evalInContext(option.itemCaption);
                                        const productStub = prebuildData.getProductStub(productId);
                                        const variantStub = prebuildData.getVariantStub(optionValue.id);
                                        const quantity = optionValue.quantity || 1;
                                        const value = "";
                                        const caption = itemCaptionFn({product: productStub,variant: variantStub});
                                        const displayFn = prebuildData.evalInContext(display);
                                        properties[indexHash[id]].lines.push(displayFn({value,caption})+((quantity>1)?` (x${quantity})`:"")+((option.limitOnePerOrder && hasMoreThanOneItem)?" [only 1]":""));                                                                                                            
                                        transferBreakdownToContribution(option.id,
                                            addToPrice(id,{options: optionsStub},undefined,((option.multiple) && (optionValue.quantity>1))?optionValue.quantity:1,false,option.limitOnePerOrder)
                                        );
                                        activatedOptionsMap[id] = true;
                                    }
                                }
                            }
                        }    
                    }
                }
                else if ((optionValue.type==="product-select") && (option.type==="product-select")) {
                    if (typeof indexHash[id]==="number") {
                        let selected = 0;
                        optionValue.ids.forEach((productId) => {
                            const product = prebuildData.getProductById(productId);
                            if (product) {
                                if (option.itemCaption) {
                                    const itemCaptionFn = prebuildData.evalInContext(option.itemCaption);
                                    const productStub = prebuildData.getProductStub(productId);
                                    const value = "";
                                    const caption = itemCaptionFn({product: productStub});
                                    const displayFn = prebuildData.evalInContext(display);
                                    properties[indexHash[id]].lines.push(displayFn({value,caption}));                                    
                                    selected++;
                                }
                            }
                        });
                        if (option.multiSelect) {
                            if (selected>0) {
                                const res = addToPrice(id,{
                                    options: optionsStub,
                                    option: {
                                        multiSelect: {
                                            selectedCount: selected
                                        }
                                    }
                                });
                                transferBreakdownToContribution(option.id,
                                    res
                                );
                                activatedOptionsMap[id] = true;
                            }
                        }
                        else {
                            if (selected>0) {
                                transferBreakdownToContribution(option.id,
                                    addToPrice(id,{options: optionsStub})
                                );
                                activatedOptionsMap[id] = true;
                            }
                        }
                    }
                }
            }
        });
        properties.forEach((propertyEntry,index) => {
            if ((propertyEntry.lines.length>1) && (productOptions.display[index].line==="single")) {
                propertyEntry.lines[0] = propertyEntry.lines.join(", ");
                propertyEntry.lines.length = 1;
            }            
        })
        properties = properties.filter(({lines}) => lines.length>0);

        const digestKeys = Object.keys(_generateCartItemPropertiesCache);
        const prefix = `${item.uuid}-`;

        digestKeys.forEach((digestKey) => {
            if ((digestKey.startsWith(prefix)) && (digestKey!==digest)) {
                delete _generateCartItemPropertiesCache[digestKey];     
            }
        });

        return {
            price,
            priceBeyondFirst,
            properties,
            imageSrc,
            originalSrc,
            variantPriceContribution,
            variantPriceContributionBeyondFirst,
            variantId
        };
    };

    return _generateCartItemPropertiesCache[digest] = _generateCartItemPropertiesCache[digest] || process();
};

const cartItemToCartDisplay = (item: CartItem): CartItemDisplay  => {
    const product = prebuildData.getProductById(item.productId);
    if (!product) return null;

    let { price,priceBeyondFirst,properties,imageSrc,originalSrc,variantPriceContribution,variantPriceContributionBeyondFirst,variantId } = generateCartItemPriceProperties(item,{ hasMoreThanOneItem: (item.quantity>1) });
    variantPriceContribution = cloneSimpleData(variantPriceContribution);
    variantPriceContributionBeyondFirst = cloneSimpleData(variantPriceContributionBeyondFirst);
    
    const realPrice = price+(priceBeyondFirst*(item.quantity-1));
    const { price: discountedPrice,discountBreakdown } = prebuildData.getProductPageDiscountedPrice(item.productId,realPrice,item.freeProduct,item.freeComponentName);
    Object.keys(discountBreakdown).forEach((componentName) => {
        discountBreakdown[componentName].fromQuantity = item.quantity;
    });
    
    if (discountedPrice<realPrice) {
        const applyDiscountToVariantPriceContribution = (contribution: VariantPriceContribution) => {
            Object.keys(contribution).forEach((variantId) => {
                contribution[variantId].price = Math.floor(contribution[variantId].price*(discountedPrice/realPrice));                
            });
        };

        applyDiscountToVariantPriceContribution(variantPriceContribution);
        applyDiscountToVariantPriceContribution(variantPriceContributionBeyondFirst);
    }

    if (item.quantity>1) {
        Object.keys(variantPriceContributionBeyondFirst).forEach((variantId) => {
            const { price,fromQuantity } = variantPriceContributionBeyondFirst[variantId];
            variantPriceContribution[variantId] = variantPriceContribution[variantId] || { price: 0,fromQuantity: 0 };
            variantPriceContribution[variantId].price+= price*(item.quantity-1);
            variantPriceContribution[variantId].fromQuantity+= fromQuantity*(item.quantity-1);
        });
    }

    const ret: CartItemDisplay = {
        id: item.uuid,
        // imageSrc: product.smallImages[0].src,
        imageSrc,
        // originalImageSrc: product.smallImages[0].originalSrc,
        originalImageSrc: originalSrc,
        price: realPrice,
        ...((discountedPrice!==realPrice)?{salePrice: discountedPrice}:{}),
        productId: item.productId,
        properties,
        quantity: item.quantity,
        quantityDisplay: item.quantityDisplay,
        title: product.caption,
        deleted: item.deleted,
        notQuantityEditable: !!item.freeProduct,
        variantPriceContribution,
        discountBreakdown,
        variantId
    };

    return ret;
};

const editMode = false;
const editItems = [] as CartItem[];

const editOrder = {
    "id": "1148493168897-inkmnse6oo9dgwbm",
    "name": "#D11",
    "customer": {
        "displayName": "Dominique Adolphe",
        "email": "dominique.adolphe@gmail.com"
    },
    "cartItems": [],
    "orderType": "draft",
    "orderReturnUrl": "https://tbdtesting2.myshopify.com/admin/draft_orders/1148493168897",
    "priceCents": 0
} as EditOrderOrder;

class Cart {
    note = "";
    // note = "What do we know?";
    items: CartItem[] = !editMode?[]:editItems;
    private lsKey = "cart";
    private loadedFromLocalStorage = false;
    editOrder: EditOrderOrder = !editMode?undefined:editOrder;
    editCartItem: CartItem;
    editOrderIsCreate = false;
    private beforeLoadedCbs: (() => void)[] = [];
    version = 0;

    hasDealAlready(productId: string,optionId: string,ignoreUuid = "") {
        return (this.items.filter(({productId: _productId,optionValues,uuid}) => (_productId===productId) && (ignoreUuid!==uuid) && (!!optionValues[optionId])).length>0);
    }

    accountShopifyIntegrationStore(checkoutUrl: string,accountValue: Account | false) {
        const res = /\/checkouts\/([^\?$]+)/.exec(checkoutUrl);
        if (res) {
            const checkoutId = res[1];                
            const accountData = (() => {
                const convertPhone = (phone: string) => {
                    phone = phone?miscShared.convertSplitPhoneNumberToPlusPhoneNumber(phone):"";
                    if (phone==="+") phone = "";
                    return phone;
                };
                if (accountValue) {
                    let { user: { email,firstName,lastName,phone,addresses } } = accountValue; 
                    phone = convertPhone(phone);
                    addresses.forEach((address) => {
                        let { phone } = address;
                        if (phone) {
                            phone = convertPhone(phone);
                            if (phone) address.phone = phone;
                        }
                    });
                    return {
                        email,
                        firstName,
                        lastName,
                        ...(phone?{phone}:{}),
                        addresses
                    };
                }
                else {
                    return null;
                }

            })();
            localStorage.setItem(`checkout-${checkoutId}.accountData`,JSON.stringify(accountData));
        }
    }

    loadEditOrder(order: EditOrderOrder) {
        this.editOrder = order;
        this.items = [];
        order.cartItems.forEach((item) => {
            if (!item.freeProduct) {
                this.addEditOrderItem(item);
            }
        });
        this.editOrderIsCreate = (order.cartItems.length===0);
        this.fireSubscriptions();
    }

    updateCartItem(id: string,editedItem: CartItem) {
        const item = this.lookupItem(id);
        if (item) {
            item.optionValues = cloneSimpleData(editedItem.optionValues);
            item.digest = this.genDigest(item).digest;
            item.version++;
            this.fireSubscriptions();
        }
    }

    saveToLocalStorage() {
        if (this.editOrder) return;
        const { note,items } = this;        
        lstore.set(this.lsKey,{note,items: items.filter(({freeProduct}) => !freeProduct)});
    }
    loadFromLocalStorage() {
        if (this.editOrder) return;
        if (this.loadedFromLocalStorage) return;
        const res = lstore.get(this.lsKey);
        if ((res) && (res.hasOwnProperty("note") && (res.hasOwnProperty("items")))) {
            this.note = res.note;
            this.items = res.items.filter(({productId}) => !!prebuildData.getProductById(productId));
        }
        this.loadedFromLocalStorage = true;
        const copyCbs = [...this.beforeLoadedCbs];
        this.beforeLoadedCbs.length = 0;
        copyCbs.forEach((cb) => cb());
    }
    runWhenLoaded(cb: () => void) {
        if (this.loadedFromLocalStorage) {
            cb();
        }
        else {
            this.beforeLoadedCbs.push(cb);
        }
    }
    lookupItem(id: string) {
        return this.items.filter(({uuid}) => uuid===id)[0];
    }
    deleteItem(id: string) {
        const item = this.lookupItem(id);
        if (item) {
            item.deleted = true;
        }
    }
    removeItem(id: string) {        
        let foundDigest = "";
        this.items = this.items.filter(({uuid,digest}) => {
            if (uuid===id) {
                foundDigest = digest;
            }
            return uuid!==id;
        });
        if (foundDigest!=="") {      
            this.fireDeletionSubscriptions(foundDigest);
            this.saveToLocalStorage();
        }
    }

    applyQuantityDiscounts(items: CartItemDisplay[]) {
        // console.log("applyQuantityDiscounts");
        // const item = items[0];
        // item.salePrice = item.price-500;
        // item.discountBreakdown["discounted-pods"] = {
        //     discount: 500,
        //     fromQuantity: 1
        // };
        // console.log("item",item);
        // //discounted-pods
        // console.log("prebuildData.quantityDiscountDefinitions",prebuildData.quantityDiscountDefinitions);

        // return items;
        const usedVariants: {[variantId: string]: true} = {};

        type ItemQuantityDiscounts = {
            [componentName: string]: {
                discount: number;
                fromQuantity: number;
            };
        };

        const reversedItems = [...items].reverse();

        const itemQuantityDiscounts: ItemQuantityDiscounts[] = reversedItems.map(() => ({}));        

        prebuildData.quantityDiscountDefinitions.forEach(({variantHash,counter,componentName}) => {
            counter.reset();
            const saleUsedVariants: {[variantId: string]: true} = {};
            reversedItems.forEach((item,itemIndex) => {
                const {notQuantityEditable,variantId,quantity} = item;
                const itemSalePrice = item.hasOwnProperty("salePrice")?item.salePrice:item.price;
                if (notQuantityEditable) return;
                const itemSaleContribution = { price: 0,fromQuantity: 0};
                if ((variantHash[variantId]) && (!usedVariants[variantId])) {
                    itemSaleContribution.fromQuantity = quantity;
                    itemSaleContribution.price = itemSalePrice;
                    saleUsedVariants[variantId] = true;
                }

                if (itemSaleContribution.price>0) {
                    misc.integerSplit(itemSaleContribution.price,itemSaleContribution.fromQuantity).forEach((part) => {
                        const percentageDiscount = counter.use();                        
                        if (percentageDiscount>0) {
                            itemQuantityDiscounts[itemIndex][componentName] = itemQuantityDiscounts[itemIndex][componentName] || { discount: 0,fromQuantity: 0 };
                            itemQuantityDiscounts[itemIndex][componentName].discount+= Math.round((percentageDiscount/100)*part);
                            itemQuantityDiscounts[itemIndex][componentName].fromQuantity++;
                        }
                    });                    
                }
            });
            Object.keys(saleUsedVariants).forEach((variantId) => {
                usedVariants[variantId] = true;
            });
        });

        itemQuantityDiscounts.forEach((quantityDiscounts,itemIndex) => {
            const item = reversedItems[itemIndex];
            const itemSalePrice = item.hasOwnProperty("salePrice")?item.salePrice:item.price;
            const componentNames = Object.keys(quantityDiscounts);
            let totalDiscount = misc.limitCeiling(componentNames.reduce((acc,componentName) => acc+quantityDiscounts[componentName].discount,0),itemSalePrice);
            if (totalDiscount>0) {                                
                const newSalePrice = misc.limitCeiling(Math.round((itemSalePrice-totalDiscount)/50)*50,itemSalePrice);
                const realDiscount = itemSalePrice-newSalePrice;                
                const ratios = componentNames.map((componentName) => quantityDiscounts[componentName].discount);
                misc.integerSplitByRatio(realDiscount,ratios).forEach((realDiscountPart,index) => {
                    const componentName = componentNames[index];                    
                    item.discountBreakdown[componentName] = {
                        discount: realDiscountPart,
                        fromQuantity: quantityDiscounts[componentName].fromQuantity
                    };
                });
                item.salePrice = newSalePrice;            
            }
        });

        return items;
    }

    genDisplayItems() {
        return this.applyQuantityDiscounts(this.items.map(cartItemToCartDisplay).filter((item) => item!==null));
    }

    private subscriptionCbs: (() => void)[] = [];

    subscribe(cb: () => void) {
        this.subscriptionCbs.push(cb);
        return () => {
            this.subscriptionCbs = this.subscriptionCbs.filter((_cb) => _cb!==cb);
        };
    }

    fireSubscriptions() {
        this.subscriptionCbs.forEach((cb) => cb());
    }

    private deletionSubscriptionCbs: ((digest: string) => void)[] = [];

    deletionSubscribe(cb: (digest: string) => void) {
        this.deletionSubscriptionCbs.push(cb);
        return () => {
            this.deletionSubscriptionCbs = this.deletionSubscriptionCbs.filter((_cb) => _cb!==cb);
        };
    }

    fireDeletionSubscriptions(digest: string) {
        this.deletionSubscriptionCbs.forEach((cb) => cb(digest));
    }

    genDigest(item: CartItem) {
        const optionsCompiler = prebuildData.getOptionsCompiler();

        const { productId,optionValues } = item;
        const productOptions = optionsCompiler.getProductOptions(productId);
        const ids = Object.keys(optionValues).filter((id) => {
            const option = productOptions.options.filter(({id: _id}) => id===_id)[0];
            // if ((optionValues[id].type==="variant-select") && (option) && (option.type==="variant-select") && (option.limitOnePerOrder)) {
            //     return false;
            // }
            if (((option?.type==="buttons") || (option?.type==="checkbox") || (option?.type==="variant-select")) && (option.cartLink)) {
                return false;
            }
            else {
                return true;
            }            
        });
        ids.sort();

        const normalizeValue = (value: OptionValue) => {
            if (value.type==="checkbox-group") {
                const ret: OptionValueCheckboxGroup = {
                    type: "checkbox-group",
                    ids: [...value.ids]
                };
                ret.ids.sort();
                return ret;
            }
            else if (value.type==="product-select") {
                const ret: OptionValueProductSelect = {
                    type: "product-select",
                    ids: [...value.ids]
                };
                ret.ids.sort();
                return ret;
            }
            return value;
        };

        const optionValueArray: CartItemOptionValuesArray = ids.map((id) => ({id,value: normalizeValue(optionValues[id])}));
        const digestObj = {productId,optionValues: optionValueArray,...(item.freeProduct?{freeProduct:true}:{})};        
        return {digest: JSON.stringify(digestObj),digestObj};
    }

    addItem(item: CartItem,willProvideUuid = false) {
        const ret: { 
            highlightIds: string[];
            flashIds: string[];
        } = {
            highlightIds: [],
            flashIds: []
        };
        const optionsCompiler = prebuildData.getOptionsCompiler();
        const newId = willProvideUuid?item.uuid:misc.genUUID();        
        const newItem = cloneSimpleData(item);
        newItem.digest = this.genDigest(item).digest;
        newItem.uuid = newId;
        // const newItem = {...item,digest: this.genDigest(item).digest,uuid: newId};
        const { uuid } = this.getItemIdByDigest(newItem);
        if (uuid!=="") {
            const existingItem = this.lookupItem(uuid);
            if (existingItem) {
                existingItem.quantity+= item.quantity;
                existingItem.quantityDisplay = existingItem.quantity.toString();
                this.items.splice(this.items.indexOf(existingItem),1);                            
                this.items.splice(0,0,existingItem);
                ret.flashIds.push(uuid);
            }
        }
        else {
            this.items.splice(0,0,newItem);
            ret.highlightIds.push(newId);
        }
        
        const options = optionsCompiler.getProductOptions(item.productId);
        
        options.options.forEach((option) => {
            const optionValue = item.optionValues[option.id];
            const res = (() => {
                if ((option.type==="buttons") && (optionValue.type==="buttons") && (optionValue.id) && (option.cartLink)) {
                    const variant = prebuildData.extractVariantsFromLink(item.productId,{},optionValue.id,option.cartLink)[0];
                    if (variant) {
                        return {variant,quantity: 1};
                    }                
                }
                else if ((option.type==="checkbox") && (optionValue?.type==="checkbox") && (option.cartLink)) {
                    const variant = prebuildData.extractVariantsFromLink(item.productId,{},"",option.cartLink)[0];
                    if (variant) {
                        return {variant,quantity: 1};
                    }                
                }
                else if ((option.type==="variant-select") && (optionValue?.type==="variant-select") && optionValue.id && (option.cartLink)) {
                    const variant = prebuildData.getVariantHash()[optionValue.id]?.variant;
                    if (variant) {                        
                        return {variant,quantity: optionValue.quantity || 1};
                    }                
                }
            })();
            if (res) {
                const { variant,quantity } = res;
                const { productId } = prebuildData.getVariantStub(variant.id);
                const linkOptions = optionsCompiler.getProductOptions(productId);
                const signature = variant.signature as OptionSnapshotProductVariantSignature[];
                const newId = misc.genUUID();
                console.log("signature",signature);
                const optionValues: CartItemOptionValues = {};
                signature.forEach(({id,value}) => {
                    const linkOption = linkOptions.options.filter(({id: _id}) => _id===id)[0];
                    if ((linkOption?.type==="checkbox") && (typeof linkOption.track==="string")) {
                        let trackFn: any;
                        try {
                            trackFn = eval(linkOption.track);
                        }
                        catch(err) {
                        }    
                        if (typeof trackFn==="function") {
                            const { value: checkedValue } = trackFn({option: {checked: true}});
                            const { value: uncheckedValue } = trackFn({option: {checked: false}});
                            console.log("checkedValue",checkedValue);
                            console.log("uncheckedValue",uncheckedValue);
                            console.log("value",value);
                            if (checkedValue===value) {
                                optionValues[id] = {type: "checkbox"};
                            }
                        }                                
                        return [id,{type: "buttons",id: value}];
                    }
                    else if (linkOption?.type==="buttons") {
                        optionValues[id] = {type: "buttons",id: value};
                    }
                });       
                console.log("optionValues",optionValues);         
                const linkItem: CartItem = {
                    activatedOptionsMap: {},
                    optionValues,
                    productId,
                    quantity,
                    quantityDisplay: quantity.toString(),
                    uuid: "",
                    version: 0,                        
                };
                const { uuid } = this.getItemIdByDigest(linkItem);
                if (uuid!=="") {
                    const existingItem = this.lookupItem(uuid);
                    if (existingItem) {
                        existingItem.quantity+= linkItem.quantity;
                        existingItem.quantityDisplay = existingItem.quantity.toString();
                        this.items.splice(this.items.indexOf(existingItem),1);                            
                        this.items.splice(0,0,existingItem);
                        ret.flashIds.push(uuid);
                    }
                }
                else {
                    const newItem = cloneSimpleData(linkItem);                    
                    newItem.digest = this.genDigest(linkItem).digest;
                    newItem.uuid = newId;
                    this.items.splice(0,0,newItem);    
                    ret.highlightIds.push(newId);
                }
            }                
        });
        
        this.fireSubscriptions();
        this.saveToLocalStorage();
        return ret;
    }

    addEditOrderItem(item: EditOrderCartItem) {
        const newId = misc.genUUID();
        const newItem: CartItem = {
            optionValues: item.options,
            productId: item.productId.toString(),
            quantity: item.quantity,
            quantityDisplay: item.quantity.toString(),
            uuid: newId,
            activatedOptionsMap: {},
            version: 1
        };
        newItem.digest = this.genDigest(newItem).digest;
        this.items.push(newItem);
    }


    getItemIdByDigest(item: CartItem) {
        const {digest,digestObj} = this.genDigest(item);
        for(let i=0;i<this.items.length;i++) {
            if (this.items[i].digest===digest) {
                return {uuid: this.items[i].uuid,digest};
            }
        }        
        return {uuid: "",digest,digestObj};
    }

    clearCart() {
        this.items.forEach(({digest}) => {
            this.fireDeletionSubscriptions(digest);
        });
        this.items.length = 0;
        this.saveToLocalStorage();
    }

    updateItemQuantity(id: string,quantityDisplay: string) {
        const item = this.lookupItem(id);
        if (item) {
            item.quantityDisplay = quantityDisplay.split("").filter((ch) => /[0-9]/.test(ch)).join("");
            item.quantity = parseInt(item.quantityDisplay) || 0;
            this.saveToLocalStorage();
            return true;
        }
        return false;
    }

    minusItemQuantity(id: string) {        
        const item = this.lookupItem(id);
        if (item) {
            const currentQuantity = item.quantity;
            const newQuantity = Math.max(0,currentQuantity-1);
            if (newQuantity>0) {
                item.quantity = newQuantity;
                item.quantityDisplay = item.quantity.toString();
                this.saveToLocalStorage();
            }
            else {
                this.deleteItem(id);
            }
            return true;
        }
        return false;
    }

    plusItemQuantity(id: string) {
        const item = this.lookupItem(id);     
        if (item) {
            item.quantity++;
            item.quantityDisplay = item.quantity.toString();
            this.saveToLocalStorage();
            return true;
        }
        return false;
    }

    itemQuantityBlur(id: string) {
        const item = this.lookupItem(id);     
        if (item) {
            if (item.quantity===0) {
                this.deleteItem(id);
                return true;
            }
        }
        return false;
    }

    updateNote(note: string) {
        const oldValue = this.note;
        this.note = note.trim();
        if (oldValue!==this.note) {
            this.saveToLocalStorage();
            return true;    
        }
        return false;
    }

    genItemsToEditOrderDigest() {        
        return JSON.stringify(this.items.filter(({freeProduct}) => !freeProduct).map(({productId,quantity,optionValues: options,freeProduct}) => {
            const ret: EditOrderCartItem = {
                productId,
                options,
                quantity,
                freeProduct: false
            };
            return ret;
        }));
    }

    genEditOrderDigest() {        
        return JSON.stringify(this.editOrder?this.editOrder.cartItems.filter(({freeProduct}) => !freeProduct):[]);
    }

    itemsDifferFromEditOrder() {
        return this.editOrder?(this.genItemsToEditOrderDigest()!==this.genEditOrderDigest()):false;
    }

    getVariantLinkBreakdownForItem(item: CartItem,{ignoreActivatedOptionsMap = false,ignoreQuantity = false}: {ignoreActivatedOptionsMap?: boolean; ignoreQuantity?: boolean} = {}) {
        
        const debug = item.productId==="8008148189441";

        const optionsCompiler = prebuildData.getOptionsCompiler();
        const variantLinksHash: {[optionId: string]: {[variantId: string]: number}} = {};

        const { activatedOptionsMap } = item;
        const productOptions = optionsCompiler.getProductOptions(item.productId);        
        if (productOptions) {
            const addVariantLink = (optionId: string,id: string,quantity = 1) => {
                // if ((prebuildData.variantExists(id)) && (!prebuildData.isVariantCustom(id))) {
                if (prebuildData.variantExists(id)) {
                    variantLinksHash[optionId] = variantLinksHash[optionId] || {};
                    variantLinksHash[optionId][id] = variantLinksHash[optionId][id] || 0;
                    const itemQuantity = ignoreQuantity?1:item.quantity;
                    variantLinksHash[optionId][id]+= quantity*itemQuantity;
                }
            };
            const processLink = (optionId: string,link: string,param: any = {},quantity = 1) => {
                return;
                const fn = prebuildData.evalInContext(link);
                const variants = [fn(param)].flat().map((link) => link?._variants?.[0]).filter((variant) => !!variant);
                variants.forEach(({id}) => {
                    addVariantLink(optionId,id,quantity);
                });
            };
            if (productOptions.link) {
                processLink(":link",productOptions.link);
            }
            const optionsStub: {[id: string]: any} = {};
            const itemProductStub = prebuildData.getProductStub(item.productId);                    
            let pnt = itemProductStub;            
            if (!pnt) return;

            productOptions.options.forEach((option) => {
                const optionValue = item.optionValues[option.id];
                if ((option.type==="buttons") && (optionValue?.type==="buttons")) {                    
                    optionsStub[option.id] = {itemId: optionValue.id,...((typeof optionValue.value==="number")?{value: optionValue.value}:{})};
                    if (option.track) {
                        if ((pnt) && (pnt[option.id]?.[optionValue.id])) {
                            pnt = pnt[option.id][optionValue.id];                                
                        }
                        else {
                            pnt = null;
                        }    
                    }
                }
                else if ((option.type==="product-select") && (optionValue?.type==="product-select") && (option.multiSelect) && (typeof option.track==="string")) {
                    let trackFn: any;
                    try {
                        trackFn = eval(option.track);
                    }
                    catch(err) {                        
                    }
                    if (typeof trackFn==="function") {
                        const optionParam = {
                            multiSelect: {
                                selectedCount: optionValue.ids.length
                            }
                        };
                        const res = trackFn({option: optionParam});
                        if ((isSimpleObject(res)) && (typeof res.value==="string")) {                            
                            optionsStub[option.id] = {itemId: res.value};
                            if ((pnt) && (pnt[option.id]?.[res.value])) {
                                pnt = pnt[option.id][res.value];                                
                            }
                            else {
                                pnt = null;
                            }    
                        }
                    }
                }
                else if ((option.type==="variant-select") && (optionValue?.type==="variant-select") && (optionValue.id)) {
                    optionsStub[option.id] = prebuildData.getVariantStub(optionValue.id);
                }
            });

            const getProperties = () => {
                let ret: {[name: string]: string} = {};
                productOptions.options.forEach((option) => {
                    const optionValue = item.optionValues[option.id];
                    if (
                        ((option.type==="buttons") && (optionValue?.type==="buttons")) || 
                        ((option.type==="variant-select") && (optionValue?.type==="variant-select") && (optionValue.id))
                    ) {
                        if (typeof option.lineItemProperties==="string") {
                            let fn: any;
                            try {                                
                                fn = prebuildData.evalInContext(option.lineItemProperties);
                            }
                            catch(err) {                                
                            }
                            if (typeof fn==="function") {
                                const res = fn({option: optionsStub[option.id]});
                                if (isSimpleObject(res)) {
                                    ret = {...ret,...res};
                                }
                            }
                        }
                    }
                    // else if ((option.type==="variant-select") && (optionValue?.type==="variant-select") && (optionValue.id)) {

                    // }
                    // else if ((option.type==="product-select") && (optionValue?.type==="product-select") && (option.multiSelect) && (typeof option.track==="string")) {
                    //     let trackFn: any;
                    //     try {
                    //         trackFn = eval(option.track);
                    //     }
                    //     catch(err) {                        
                    //     }
                    //     if (typeof trackFn==="function") {
                    //         const optionParam = {
                    //             multiSelect: {
                    //                 selectedCount: optionValue.ids.length
                    //             }
                    //         };
                    //         const res = trackFn({option: optionParam});
                    //         if ((isSimpleObject(res)) && (typeof res.value==="string")) {                            
                    //             optionsStub[option.id] = {itemId: res.value};
                    //             if ((pnt) && (pnt[option.id]?.[res.value])) {
                    //                 pnt = pnt[option.id][res.value];                                
                    //             }
                    //             else {
                    //                 pnt = null;
                    //             }    
                    //         }
                    //     }
                    // }
                });
        
                return ret;
            };

            if ((pnt) && (pnt!==itemProductStub)) {                
                if (pnt._variants[0]?.id) {
                    // const properties = getProperties();
                    // console.log("properties !!",properties);
                    addVariantLink(":variant",pnt._variants[0].id);
                }
            }
            // else if (productOptions.vars.track) {
            else if (itemProductStub._variants[0]?.id) {
                // if (itemProductStub._variants[0]?.id) {
                    // const properties = getProperties();
                    // console.log("properties - -",properties);
                    addVariantLink(":variant",itemProductStub._variants[0].id);
                // }                        
            }
            productOptions.options.forEach((option) => {
                const optionValue = item.optionValues[option.id];
                if (!optionValue) return;

                if (option.type==="buttons") {
                    if (optionValue.type==="buttons") {
                        const { id,link } = option;                        
                        if ((activatedOptionsMap[id] || ignoreActivatedOptionsMap) && (link)) {                                       
                            processLink(option.id,link,{options: optionsStub,option: {itemId: optionValue.id}},option.quantity?(optionValue.quantity || 1):1);
                        }    
                    }
                }
                else if (option.type==="checkbox") {
                    const { id,link } = option;
                    if ((activatedOptionsMap[id] || ignoreActivatedOptionsMap) && (link)) {
                        processLink(option.id,link);
                    }
                }
                else if (option.type==="checkbox-group") {
                    const { id,link } = option;
                    if ((activatedOptionsMap[id] || ignoreActivatedOptionsMap) && (link)) {
                        processLink(option.id,link);
                    }
                }
                else if (option.type==="select") {
                    const { id,link } = option;
                    if ((activatedOptionsMap[id] || ignoreActivatedOptionsMap) && (link)) {
                        processLink(option.id,link);
                    }
                }
                // else if (option.type==="variant-select") {                            
                //     if (optionValue?.type==="variant-select") {
                //         if (optionValue.id) {                        
                //             // addVariantLink(option.id,optionValue.id,((option.multiple) && (typeof optionValue.quantity==="number") && (optionValue.quantity>1))?optionValue.quantity:1);
                //         }
                //     }
                // }
                else if (option.type==="product-select") {
                    if (optionValue.type==="product-select") {
                        const fn = prebuildData.evalInContext(option.selectVariant);
                        optionValue.ids.forEach((productId) => {
                            const productStub = prebuildData.getProductStub(productId);
                            if (productStub) {
                                const param = {product: productStub,options: optionsStub};
                                const variants = [fn(param)].flat().map((link) => link?._variants?.[0]).filter((variant) => !!variant);
                                variants.forEach(({id}) => {
                                    // addVariantLink(option.id,id);
                                });                                                        
                            }
                        });
                    }
                }
            });
        }

        return variantLinksHash;
    }

    getVariantLinkBreakdownForItemNew(item: CartItem,{ignoreActivatedOptionsMap = false,ignoreQuantity = false}: {ignoreActivatedOptionsMap?: boolean; ignoreQuantity?: boolean} = {}) {
        
        const debug = item.productId==="8008148189441";
        // if (debug) console.log("item",item);

        const optionsCompiler = prebuildData.getOptionsCompiler();
        const variantLinksHash: {[optionId: string]: {
            [variantId: string]: {
                [attrJson: string]: number
            }
        }} = {};

        const { activatedOptionsMap } = item;
        const productOptions = optionsCompiler.getProductOptions(item.productId);        
        if (productOptions) {
            const addVariantLink = (optionId: string,id: string,attributes: {[name: string]: string} = {},quantity = 1) => {
                // if ((prebuildData.variantExists(id)) && (!prebuildData.isVariantCustom(id))) {
                if (prebuildData.variantExists(id)) {
                    const attrJson = JSON.stringify(attributes);
                    variantLinksHash[optionId] = variantLinksHash[optionId] || {};
                    variantLinksHash[optionId][id] = variantLinksHash[optionId][id] || {};
                    variantLinksHash[optionId][id][attrJson] = variantLinksHash[optionId][id][attrJson] || 0;
                    const itemQuantity = ignoreQuantity?1:item.quantity;
                    variantLinksHash[optionId][id][attrJson]+= quantity*itemQuantity;
                }
            };
            const processLink = (optionId: string,link: string,param: any = {},quantity = 1) => {
                return;
                const fn = prebuildData.evalInContext(link);
                const variants = [fn(param)].flat().map((link) => link?._variants?.[0]).filter((variant) => !!variant);
                variants.forEach(({id}) => {
                    // addVariantLink(optionId,id,quantity);
                });
            };
            if (productOptions.link) {
                processLink(":link",productOptions.link);
            }
            const optionsStub: {[id: string]: any} = {};
            const itemProductStub = prebuildData.getProductStub(item.productId);                    
            // console.log("itemProductStub",itemProductStub);
            let pnt = itemProductStub;            
            if (!pnt) return;

            productOptions.options.forEach((option) => {
                const optionValue = item.optionValues[option.id];
                if ((option.type==="buttons") && (optionValue?.type==="buttons")) {
                    optionsStub[option.id] = {itemId: optionValue.id,...((typeof optionValue.value==="number")?{value: optionValue.value}:{})};
                    if (option.track) {
                        if ((pnt) && (pnt[option.id]?.[optionValue.id])) {
                            pnt = pnt[option.id][optionValue.id];                                
                        }
                        else {
                            pnt = null;
                        }    
                    }
                }
                else if ((option.type==="product-select") && (optionValue?.type==="product-select") && (option.multiSelect)) {
                    const optionParam = {
                        multiSelect: {
                            selectedCount: optionValue.ids.length,
                            items: optionValue.ids.map((id) => prebuildData.getProductStub(id))
                        },                            
                    };
                    optionsStub[option.id] = optionParam;
                    if (typeof option.track==="string") {
                        let trackFn: any;
                        try {
                            trackFn = eval(option.track);
                        }
                        catch(err) {                        
                        }
                        if (typeof trackFn==="function") {
                            const res = trackFn({option: optionParam});
                            if ((isSimpleObject(res)) && (typeof res.value==="string")) {                                                        
                                if ((pnt) && (pnt[option.id]?.[res.value])) {
                                    pnt = pnt[option.id][res.value];                                
                                }
                                else {
                                    pnt = null;
                                }    
                            }
                        }    
                    }
                }
                else if ((option.type==="variant-select") && (typeof option.track==="string")) {
                    let trackFn: any;
                    try {
                        trackFn = eval(option.track);
                    }
                    catch(err) {                        
                    }
                    if (typeof trackFn==="function") {
                        const getVariantSelectOptionStub = (variantId: string) => {
                            const variant = prebuildData.getVariantStub(variantId);
                            const product = prebuildData.getProductStub(variant.productId);
                            return {variant,product};
                        };                        

                        const optionParam = ((optionValue?.type==="variant-select") && optionValue?.id)?getVariantSelectOptionStub(optionValue?.id):{};
                        const res = trackFn({option: optionParam});
                        if ((isSimpleObject(res)) && (typeof res.value==="string")) {                            
                            optionsStub[option.id] = (optionValue?.type==="variant-select")?getVariantSelectOptionStub(optionValue?.id):null;
                            if ((pnt) && (pnt[option.id]?.[res.value])) {
                                pnt = pnt[option.id][res.value];                                
                            }
                            else {
                                pnt = null;
                            }    
                        }
                    }
                }
                else if ((option.type==="checkbox") && (typeof option.track==="string")) {
                    let trackFn: any;
                    try {
                        trackFn = eval(option.track);
                    }
                    catch(err) {                        
                    }
                    if (typeof trackFn==="function") {
                        const getVariantSelectOptionStub = (variantId: string) => {
                            const variant = prebuildData.getVariantStub(variantId);
                            const product = prebuildData.getProductStub(variant.productId);
                            return {variant,product};
                        };                        

                        const optionParam = {checked: (optionValue?.type==="checkbox")};
                        const res = trackFn({option: optionParam});
                        if ((isSimpleObject(res)) && (typeof res.value==="string")) {                            
                            optionsStub[option.id] = optionParam;
                            if ((pnt) && (pnt[option.id]?.[res.value])) {
                                pnt = pnt[option.id][res.value];                                
                            }
                            else {
                                pnt = null;
                            }    
                        }
                    }
                }
                else if ((option.type==="select") && (optionValue?.type==="select")) {
                    optionsStub[option.id] = {itemId: optionValue.id};
                }
                else if ((option.type==="checkbox-group") && (optionValue?.type==="checkbox-group")) {
                    optionsStub[option.id] = {itemIds: optionValue.ids};
                }
            });

            const getProperties = () => {
                let ret: {[name: string]: string} = {};
                productOptions.options.forEach((option) => {
                    const optionValue = item.optionValues[option.id];
                    if (
                        ((option.type==="buttons") && (optionValue?.type==="buttons")) || 
                        ((option.type==="select") && (optionValue?.type==="select")) || 
                        ((option.type==="checkbox-group") && (optionValue?.type==="checkbox-group")) ||
                        ((option.type==="variant-select") && (optionValue?.type==="variant-select")) ||
                        ((option.type==="product-select") && (optionValue?.type==="product-select") && (option.multiSelect))
                    ) {
                        if (typeof option.lineItemProperties==="string") {
                            let fn: any;
                            try {
                                fn = prebuildData.evalInContext(option.lineItemProperties);                                
                            }
                            catch(err) {                                
                            }
                            if (typeof fn==="function") {
                                // console.log("optionsStub[option.id]",optionsStub[option.id]);
                                // console.log("option.lineItemProperties",option.lineItemProperties);
                                const res = fn({option: optionsStub[option.id]});
                                // console.log("res",res);
                                if (isSimpleObject(res)) {
                                    ret = {...ret,...res};
                                }
                            }
                        }
                    }
                    // else if ((option.type==="product-select") && (optionValue?.type==="product-select") && (option.multiSelect) && (typeof option.track==="string")) {
                    //     let trackFn: any;
                    //     try {
                    //         trackFn = eval(option.track);
                    //     }
                    //     catch(err) {                        
                    //     }
                    //     if (typeof trackFn==="function") {
                    //         const optionParam = {
                    //             multiSelect: {
                    //                 selectedCount: optionValue.ids.length
                    //             }
                    //         };
                    //         const res = trackFn({option: optionParam});
                    //         if ((isSimpleObject(res)) && (typeof res.value==="string")) {                            
                    //             optionsStub[option.id] = {itemId: res.value};
                    //             if ((pnt) && (pnt[option.id]?.[res.value])) {
                    //                 pnt = pnt[option.id][res.value];                                
                    //             }
                    //             else {
                    //                 pnt = null;
                    //             }    
                    //         }
                    //     }
                    // }
                });
        
                return ret;
            };

            if ((pnt) && (pnt!==itemProductStub)) {                
                if (pnt._variants[0]?.id) {
                    const properties = getProperties();
                    // console.log("properties ---",properties);
                    addVariantLink(":variant",pnt._variants[0].id,properties);
                }
            }
            // else if (productOptions.vars.track) {
            else if (itemProductStub._variants[0]?.id) {
                // if (itemProductStub._variants[0]?.id) {
                    const properties = getProperties();
                    // console.log("properties !!!",properties);
                    addVariantLink(":variant",itemProductStub._variants[0].id,properties);
                // }                        
            }
            
            productOptions.options.forEach((option) => {
                const optionValue = item.optionValues[option.id];

                if (option.type==="buttons") {
                    if (optionValue.type==="buttons") {
                        const { id,link } = option;                        
                        if ((activatedOptionsMap[id] || ignoreActivatedOptionsMap) && (link)) {                                       
                            processLink(option.id,link,{options: optionsStub,option: {itemId: optionValue.id}},option.quantity?(optionValue.quantity || 1):1);
                        }    
                    }
                }
                else if (option.type==="checkbox") {
                    const { id,link } = option;
                    if ((activatedOptionsMap[id] || ignoreActivatedOptionsMap) && (link)) {
                        processLink(option.id,link);
                    }
                }
                else if (option.type==="checkbox-group") {
                    const { id,link } = option;
                    if ((activatedOptionsMap[id] || ignoreActivatedOptionsMap) && (link)) {
                        processLink(option.id,link);
                    }
                }
                else if (option.type==="select") {
                    const { id,link } = option;
                    if ((activatedOptionsMap[id] || ignoreActivatedOptionsMap) && (link)) {
                        processLink(option.id,link);
                    }
                }
                else if (option.type==="variant-select") {                            
                    if (optionValue?.type==="variant-select") {
                        if (optionValue.id) {                        
                            // addVariantLink(option.id,optionValue.id,((option.multiple) && (typeof optionValue.quantity==="number") && (optionValue.quantity>1))?optionValue.quantity:1);
                        }
                    }
                }
                else if (option.type==="product-select") {
                    if (optionValue?.type==="product-select") {
                        const fn = prebuildData.evalInContext(option.selectVariant);
                        optionValue.ids.forEach((productId) => {
                            const productStub = prebuildData.getProductStub(productId);
                            if (productStub) {
                                const param = {product: productStub,options: optionsStub};
                                const variants = [fn(param)].flat().map((link) => link?._variants?.[0]).filter((variant) => !!variant);
                                variants.forEach(({id}) => {
                                    // addVariantLink(option.id,id);
                                });                                                        
                            }
                        });
                    }
                }
            });
        }

        return variantLinksHash;
    }

    getVariantLinks(ignoreFreeProducts = false) {
        const variantLinksHash: {[variantId: string]: {
            [attrJson: string]: number
        }} = {};        

        cart.items.forEach((item) => {
            if ((item.freeProduct) && (ignoreFreeProducts)) return;

            const breakdown = this.getVariantLinkBreakdownForItemNew(item);

            Object.keys(breakdown).forEach((optionId) => {
                Object.keys(breakdown[optionId]).forEach((variantId) => {
                    Object.keys(breakdown[optionId][variantId]).forEach((attrJson) => {
                        const quantity = breakdown[optionId][variantId][attrJson];
                        variantLinksHash[variantId] = variantLinksHash[variantId] || {};
                        variantLinksHash[variantId][attrJson] = variantLinksHash[variantId][attrJson] || 0;
                        variantLinksHash[variantId][attrJson]+= quantity;
                    });
                });
            });

        });    

        return variantLinksHash;                        
    }

}

export const cart = new Cart();

export const useCart = (isFirstRender: boolean) => {        
    const [items,setItems] = useState<CartItemDisplay[]>([]);
    const isFirstCall = useRef(true);
    const isFirstRefresh = useRef(true);
    const beforeRefreshSubscriptionsRef = useRef<(() => void)[]>([]);

    const fireBeforeRefresh = () => {
        const subsciptions = beforeRefreshSubscriptionsRef.current;
        subsciptions.forEach((cb) => cb());
    };

    const refresh = () => {
        fireBeforeRefresh();
        cart.version++;
        setItems(cart.genDisplayItems());
        if (isFirstRefresh.current) {
            isFirstRefresh.current = false;
            refresh();
        }
    };

    useEffect(() => {
        if (isFirstRender) return;
        cart.loadFromLocalStorage();
        refresh();
        return cart.subscribe(() => {
            // ret.processFreeProducts();
            refresh();
        });
    },[isFirstRender]);

    const ret = {
        items,
        note: cart.note,
        version: cart.version,
        updateItemQuantity(id: string,quantityDisplay: string) {
            if (cart.updateItemQuantity(id,quantityDisplay)) {
                refresh();
            }
        },
        subscribeBeforeRefresh(cb: () => void){
            const subsciptions = beforeRefreshSubscriptionsRef.current;
            subsciptions.push(cb);

            return () => {
                const newState = subsciptions.filter(x => x!==cb);
                subsciptions.length = 0;
                subsciptions.push(...newState);
            };
        },
        processFreeProducts() {
            prebuildData.freeProductDefinitions.forEach((fpDefinition) => {
                const { cartUuid,conditions,productVariant,componentName } = fpDefinition;                
                const variantQuantityHash = cart.getVariantLinks(true);
                let totalQuantity = 0;
                conditions.forEach(({variants,ratio}) => {
                    let quantity = 0;
                    variants.forEach(({variant: { id }}) => {
                        Object.keys(variantQuantityHash[id] || {}).forEach((attrJson) => {
                            quantity+= (variantQuantityHash[id][attrJson] || 0);
                        });
                    });
                    totalQuantity+= Math.floor(quantity*ratio);
                });
                
                const existingCartItem = cartUuid?cart.lookupItem(cartUuid):null;
    
                if (existingCartItem) {
                    if (totalQuantity>0) {
                        if (existingCartItem.quantity!==totalQuantity) {
                            ret.updateItemQuantity(cartUuid,totalQuantity.toString());
                        }                        
                    }
                    else {
                        ret.removeItem(cartUuid);
                        delete fpDefinition.cartUuid;
                    }
                }
                else {
                    if (totalQuantity>0) {
                        const cartItem: CartItem = {
                            activatedOptionsMap: {},
                            optionValues: {},
                            productId: productVariant.productId,
                            quantity: totalQuantity,
                            quantityDisplay: totalQuantity.toString(),
                            uuid: misc.genUUID(),
                            version: 1,
                            freeProduct: true,
                            freeComponentName: componentName
                        };
                        productVariant.variant.signature.forEach(({id,value}) => {
                            cartItem.optionValues[id] = {
                                type: "buttons",
                                id: value
                            };
                        });
                        fpDefinition.cartUuid = cartItem.uuid;
                        cart.addItem(cartItem,true);
                    }
                }
            });
        },    
        minusItemQuantity(id: string) {
            if (cart.minusItemQuantity(id)) {
                refresh();
            }
        },
        plusItemQuantity(id: string) {      
            if (cart.plusItemQuantity(id)) {
                refresh();
            }
        },
        itemQuantityBlur(id: string) {            
            if (cart.itemQuantityBlur(id)) {
                refresh();
            }
        },
        removeItem(id: string){
            cart.removeItem(id);
            refresh();
        },
        clearCart(){
            cart.clearCart();
            refresh();
        },
        getItemCount() {
            return ret.items.reduce((acc,{quantity,deleted}) => acc+(deleted?0:quantity),0);
        },
        getNonDeletedRealItemCount() {
            return ret.items.filter(({deleted}) => !deleted).length;
        },
        getSubtotal() {
            const discountBreakdown: DiscountBreakdown = {};
            ret.items.forEach(({discountBreakdown: db}) => {
                Object.keys(db).forEach((componentName) => {
                    const { discount,fromQuantity } = db[componentName];
                    discountBreakdown[componentName] = discountBreakdown[componentName] || { discount: 0,fromQuantity: 0 };
                    discountBreakdown[componentName].discount+= discount;
                    discountBreakdown[componentName].fromQuantity+= fromQuantity;
                });
            });            

            return {
                subTotal: ret.items.reduce((acc,{price,salePrice,deleted}) => acc+(deleted?0:((typeof salePrice==="number")?salePrice:price)),0),
                discountBreakdown
            };
            // console.log("ret.items",ret.items);
            // let subtotal = ret.items.reduce((acc,{price,salePrice,deleted}) => acc+(deleted?0:((typeof salePrice==="number")?salePrice:price)),0);
            // const usedVariants: {[variantId: string]: true} = {};
            // prebuildData.quantityDiscountDefinitions.forEach(({ discount: { apply,per,percentage },variantHash }) => {
            //     const saleUsedVariants: {[variantId: string]: true} = {};
            //     const saleContribution = { price: 0,fromQuantity: 0};
            //     ret.items.forEach(({productId,variantPriceContribution,notQuantityEditable}) => {
            //         if (notQuantityEditable) return;
            //         Object.keys(variantPriceContribution).forEach((variantId) => {
            //             if ((variantHash[variantId]) && (!usedVariants[variantId])) {
            //                 const { fromQuantity,price } = variantPriceContribution[variantId];
            //                 saleContribution.fromQuantity+= fromQuantity;
            //                 saleContribution.price+= price;
            //                 saleUsedVariants[variantId] = true;
            //             }
            //         });
            //     });
            //     Object.keys(saleUsedVariants).forEach((variantId) => {
            //         usedVariants[variantId] = true;
            //     });
            //     if ((saleContribution.price>0) && (percentage>0)) {
            //         const totalRound = per+apply;
            //         const remainder = saleContribution.fromQuantity%totalRound;
            //         const roundCount = Math.round((saleContribution.fromQuantity-remainder)/totalRound);
            //         const remainderPerComponent = Math.min(per,remainder);
            //         const perComponent = (roundCount*per)+remainderPerComponent;
            //         const applyComponent = saleContribution.fromQuantity-perComponent;
            //         let toDiscount = Math.round((applyComponent/saleContribution.fromQuantity)*(percentage/100)*saleContribution.price);
            //         toDiscount = Math.min(Math.round(toDiscount/50)*50,subtotal);
            //         // console.log("toDiscount",toDiscount);
            //         // console.log("saleContribution",saleContribution);                    
            //         // subtotal-= toDiscount;
            //     }
            // });
            // return subtotal;
        },
        getUndiscountedSubtotal() {
            return ret.items.reduce((acc,{price,salePrice,deleted}) => acc+(deleted?0:price),0);
        },
        updateNote(newValue: string) {
            if (cart.updateNote(newValue)) {
                refresh();
                return true;    
            }
            return false;
        },
        //ignoreFreeProducts = false
        getCheckoutData() {
            const optionsCompiler = prebuildData.getOptionsCompiler();

            const subtotal = ret.getSubtotal().subTotal;
            let variantLinksHash = cart.getVariantLinks();
            
            // console.log("i was here",prebuildData.isVariantsDisabled());
            // if (!prebuildData.isVariantsDisabled()) {
                // variantLinksHash = cart.getVariantLinksNew();
            // }
            // else {
            //     variantLinksHash = cart.getVariantLinks();
            // }

            const infoAttributes: InfoAttributes = {
                cartUrl: misc.addQueryParamToUrl(window.location.href,"from-checkout","true"),
                items: items.map(({originalImageSrc: thumbSrc,price,salePrice,quantity,title,properties},index) => {
                    const { collectionId,collectionName } = prebuildData.getProductToCollectionById(cart.items[index].productId);                    
                    const result: InfoAttributesItem = {
                        thumbSrc,
                        price: (typeof salePrice==="number")?salePrice:price,
                        quantity,
                        title,
                        properties,
                        productId: cart.items[index].productId,
                        options: cart.items[index].optionValues,
                        collection: {
                            id: collectionId,
                            name: collectionName
                        },
                        freeProduct: !!cart.items[index].freeProduct
                    };
                    return result;
                })
            };            

            const result: CheckoutData = {
                subtotal,
                infoAttributes,
                variantLinksQuantityHash: variantLinksHash,
                snapshotId: prebuildData.getSnapshotId()
            };
            return result;
        },
        async checkout() {
            const { subtotal,infoAttributes,variantLinksQuantityHash,snapshotId } = ret.getCheckoutData();
            // console.log("infoAttributes.items",infoAttributes.items);
            // return;
            const centVariantId = prebuildData.getCentVariantId();
            const display = infoAttributesToDisplayString(infoAttributes);
            // const ps = genPackslipAttributeString(cart.items);
            const ps = "";
            const snpsid = snapshotId;

            // const subtotal = ret.getSubtotal().subTotal;
            // const centVariantId = prebuildData.getCentVariantId();
            // const infoVariantId = prebuildData.getInfoVariantId();            
            // const variantLinksHash: {[variantId: string]: number} = {};

            // const isVariantsDisabled = prebuildData.isVariantsDisabled();
            
            // if (!isVariantsDisabled) {
            //     cart.items.forEach((item) => {
            //         const { activatedOptionsMap } = item;                
            //         const productOptions = optionsCompiler.getProductOptions(item.productId);
            //         if (productOptions) {              
            //             const addVariantLink = (id: string,quantity = 1) => {                        
            //                 if ((prebuildData.variantExists(id)) && (!prebuildData.isVariantCustom(id))) {
            //                     variantLinksHash[id] = variantLinksHash[id] || 0;
            //                     variantLinksHash[id]+= quantity*item.quantity;    
            //                 }
            //             };
            //             const processLink = (link: string,param: any = {},quantity = 1) => {
            //                 const fn = prebuildData.evalInContext(link);
            //                 const variants = [fn(param)].flat().map((link) => link?._variants?.[0]).filter((variant) => !!variant);
            //                 variants.forEach(({id}) => {
            //                     addVariantLink(id,quantity);
            //                 });
            //             };
            //             if (productOptions.link) {
            //                 processLink(productOptions.link);
            //             }
            //             const optionsStub: {[id: string]: {itemId: string}} = {};
            //             const itemProductStub = prebuildData.getProductStub(item.productId);                    
            //             let pnt = itemProductStub;
            //             if (!pnt) return;
            //             productOptions.options.forEach((option) => {
            //                 const optionValue = item.optionValues[option.id];
            //                 if ((option.type==="buttons") && (optionValue.type==="buttons")) {
            //                     optionsStub[option.id] = {itemId: optionValue.id};
            //                     if (option.track) {
            //                         if ((pnt) && (pnt[option.id]?.[optionValue.id])) {
            //                             pnt = pnt[option.id][optionValue.id];                                
            //                         }
            //                         else {
            //                             pnt = null;
            //                         }    
            //                     }
            //                 }
            //             });
            //             if ((pnt) && (pnt!==itemProductStub)) {
            //                 if (pnt._variants[0]?.id) {
            //                     addVariantLink(pnt._variants[0].id);
            //                 }
            //             }
            //             else if (productOptions.vars.track) {
            //                 if (itemProductStub._variants[0]?.id) {
            //                     addVariantLink(itemProductStub._variants[0].id);
            //                 }                        
            //             }
            //             productOptions.options.forEach((option) => {
            //                 const optionValue = item.optionValues[option.id];
            //                 if (option.type==="buttons") {
            //                     if (optionValue.type==="buttons") {
            //                         const { id,link } = option;
            //                         if ((activatedOptionsMap[id]) && (link)) {                                       
            //                             processLink(link,{options: optionsStub,option: {itemId: optionValue.id}},option.quantity?(optionValue.quantity || 1):1);
            //                         }    
            //                     }
            //                 }
            //                 else if (option.type==="checkbox") {
            //                     const { id,link } = option;
            //                     if ((activatedOptionsMap[id]) && (link)) {
            //                         processLink(link);
            //                     }
            //                 }
            //                 else if (option.type==="checkbox-group") {
            //                     const { id,link } = option;
            //                     if ((activatedOptionsMap[id]) && (link)) {
            //                         processLink(link);
            //                     }
            //                 }
            //                 else if (option.type==="select") {
            //                     const { id,link } = option;
            //                     if ((activatedOptionsMap[id]) && (link)) {
            //                         processLink(link);
            //                     }
            //                 }
            //                 else if (option.type==="variant-select") {                            
            //                     if (optionValue?.type==="variant-select") {
            //                         if (optionValue.id) {                        
            //                             addVariantLink(optionValue.id,((option.multiple) && (typeof optionValue.quantity==="number") && (optionValue.quantity>1))?optionValue.quantity:1);
            //                         }
            //                     }
            //                 }
            //                 else if (option.type==="product-select") {
            //                     if (optionValue.type==="product-select") {
            //                         const fn = prebuildData.evalInContext(option.selectVariant);
            //                         optionValue.ids.forEach((productId) => {
            //                             const productStub = prebuildData.getProductStub(productId);
            //                             if (productStub) {
            //                                 const param = {product: productStub,options: optionsStub};
            //                                 const variants = [fn(param)].flat().map((link) => link?._variants?.[0]).filter((variant) => !!variant);
            //                                 variants.forEach(({id}) => {
            //                                     addVariantLink(id);
            //                                 });                                                        
            //                             }
            //                         });
            //                     }
            //                 }
            //             });
            //         }
            //     });                            
            // }
            // const infoAttributes = {
            //     cartUrl: misc.addQueryParamToUrl(window.location.href,"from-checkout","true"),
            //     items: items.map(({originalImageSrc: thumbSrc,price,quantity,title,properties},index) => {
            //         const { collectionId,collectionName } = prebuildData.getProductToCollectionById(cart.items[index].productId);                    
            //         return {
            //             thumbSrc,
            //             price,
            //             quantity,
            //             title,
            //             properties,
            //             productId: cart.items[index].productId,
            //             options: cart.items[index].optionValues,
            //             collection: {
            //                 id: collectionId,
            //                 name: collectionName
            //             }
            //         };
            //     })
            // };

            const cartUrl = misc.addQueryParamToUrl(window.location.href,"from-checkout","true");

            const centLineItem: LineItem = {
                attributes: [],
                quantity: subtotal,
                variantId: centVariantId
            };
            
            // const infoLineItems: LineItem[] = [];
            // const infoKeys = Object.keys(infoAttributesHash);
            // const bufferKeys: string[] = [];

            // const maxAttributesPerItem = 250;
            

            // while(infoKeys.length>0) {
            //     while((infoKeys.length>0) && (bufferKeys.length<maxAttributesPerItem)) bufferKeys.push(infoKeys.shift()!);
            //     infoLineItems.push({
            //         attributes: bufferKeys.map((key) => ({key,value: infoAttributesHash[key]})),
            //         quantity: 1,
            //         variantId: infoVariantId
            //     });
            //     bufferKeys.length = 0;
            // }

            // console.log("variantLinksQuantityHash",variantLinksQuantityHash);

            const linkedLineItems = Object.keys(variantLinksQuantityHash).map((variantId) => {
                return Object.keys(variantLinksQuantityHash[variantId]).map((attrJson) => {
                    const properties: {[name: string]: string} = JSON.parse(attrJson);
                    const ret: LineItem = {
                        attributes: Object.keys(properties).map((key) => ({key,value: properties[key].toString()})),
                        quantity: variantLinksQuantityHash[variantId][attrJson],
                        variantId
                    };
                    return ret;
                });                
            }).flat();

            // console.log("linkedLineItems",linkedLineItems);

            // return "www";

            const res = await createCheckout({cartUrl,/* items: JSON.stringify(infoAttributes),display, */ps,snpsid},[/*centLineItem,*/...linkedLineItems],cart.note);
            if (res?.data?.checkoutCreate?.checkout) {
                let { id: checkoutId,webUrl } = res.data.checkoutCreate.checkout as { id: string; webUrl: string; };
                const { checkoutDomain } = prebuildData.getStorefront();
                if (checkoutDomain) {
                    const regexRes = /^https:\/\/([^\/]+)\//.exec(webUrl);
                    if (regexRes) {
                        webUrl = webUrl.split(regexRes[1]).join(checkoutDomain);
                    }    
                }
                // for(let i=0;i<infoLineItems.length;i++) {
                //     const infoLineItem = infoLineItems[i];
                //     const liaRes = await checkoutLineItemsAdd(checkoutId,infoLineItem);
                //     if (!liaRes?.data?.checkoutLineItemsAdd?.checkout) return null;
                // }
                return webUrl;
            }
            else {
                // console.log("res",res);
                return null;
            }
        }
    };    

    if (isFirstCall.current) {
        isFirstCall.current = false;
        ret.subscribeBeforeRefresh(() => {
            ret.processFreeProducts();
        });
    }

    return ret;
};