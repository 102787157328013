import { MutableRefObject, TouchEvent, useEffect, useRef } from "react";
import { SmartState } from "../../hooks/use_smart_state";
import { env } from "../../lib/env";

export type EventPaneProps = {
    zIndex?: number;
    touchHoldTimeoutMilliseconds?: number;
    touchRadius?: number;
    onClick?: () => void;
    onStartHover?: () => void;
    onEndHover?: () => void;
    onTouchOrMouseDown?: () => void;
    touchDownOnMouseDown?: boolean;    
    paneClassNames?: string[];
    hint?: string;
    disabled?: boolean;
    hoverSmartState?: SmartState<boolean>;
    overlapHori?: number;
    overlapVert?: number;
    overlapLeft?: number;
    overlapRight?: number;
    overlapTop?: number;
    overlapBottom?: number;
    hoverOnTouchDown?: boolean;
    parentElemRef?: MutableRefObject<HTMLDivElement>;
    seoLinkUrl?: string;
    externalLink?: boolean;
};

export function EventPane({
    zIndex = 0,
    touchHoldTimeoutMilliseconds = 1000,
    touchRadius = 10,
    onClick = () => {},
    onStartHover = () => {},
    onEndHover = () => {},
    onTouchOrMouseDown = () => {},
    touchDownOnMouseDown = false,
    paneClassNames = [],
    hint = "",
    disabled = false,
    hoverSmartState = {value: false, subscribe: () => () => {}},
    overlapHori = 0,
    overlapVert = 0,
    overlapLeft = overlapHori,
    overlapRight = overlapHori,
    overlapTop = overlapVert,
    overlapBottom = overlapVert,
    hoverOnTouchDown = false,
    parentElemRef,
    seoLinkUrl,
    externalLink = false
    }: EventPaneProps)
{
    const paneDivRef = useRef<HTMLDivElement>(null);
    const paneAnchorRef = useRef<HTMLAnchorElement>(null);

    const _onClick = () => {
        if ((!seoLinkUrl) || (!externalLink)) onClick();
    };

    const getParentElem = () => {
        if (parentElemRef?.current) {
            return parentElemRef?.current;
        }
        else {
            if ((paneAnchorRef.current) && (paneAnchorRef.current.parentElement)) {
                return paneAnchorRef.current.parentElement;
            }
            else if ((paneDivRef.current) && (paneDivRef.current.parentElement)) {
                return paneDivRef.current.parentElement;
            }
            else {
                return null;
            }
        }
    };

    const mouseEnterHandler = () => 
    {
        if (env.isMobile()) return;
        const parentElem = getParentElem();
        if (parentElem) {
            parentElem.classList.add("hover");
            hoverSmartState.value = true;
            onStartHover();
        }
    };

    const mouseDownHandler = () => 
    {
        if (env.isMobile()) return;
        onTouchOrMouseDown();
        if (touchDownOnMouseDown) {
            const parentElem = getParentElem();
            if (parentElem) {            
                parentElem.classList.add("touchdown");
                if (hoverOnTouchDown) parentElem.classList.add("hover");
            }    
        }        
    };

    const mouseUpHandler = () => 
    {
        if (env.isMobile() || !touchDownOnMouseDown) return;
        const parentElem = getParentElem();
        if (parentElem) {            
            parentElem.classList.remove("touchdown");
            if (hoverOnTouchDown) parentElem.classList.remove("hover");
        }
    };

    const mouseLeaveHandler = () => 
    {
        if (env.isMobile()) return;
        const parentElem = getParentElem();
        if (parentElem) {            
            parentElem.classList.remove("hover");
            hoverSmartState.value = false;
            onEndHover();
        }
    };

    const activeTouches: {[identifier: string]: true} = {};
    let inDeadZone = false;
    let timeoutId: any = 0;
    let startPoint: {x: number,y: number} = null;

    const addTouchDown = () => 
    {
        const parentElem = getParentElem();
        if (parentElem) {            
            parentElem.classList.add("touchdown");
            if (hoverOnTouchDown) parentElem.classList.add("hover");
        }
    };
    const removeTouchDown = () => 
    {
        const parentElem = getParentElem();
        if (parentElem) {            
            parentElem.classList.remove("touchdown");
            if (hoverOnTouchDown) parentElem.classList.remove("hover");
        }
    };

    const clearTouchDown = () => 
    {
        removeTouchDown();
        clearTimeout(timeoutId);
        timeoutId = 0; 
        startPoint = null;
    };

    const enterDeadZone = () => 
    {
        if ((Object.keys(activeTouches).length>0) && (!inDeadZone))
        {
            clearTouchDown();
            inDeadZone = true;
        }
    };

    const touchStartHandler = (event: TouchEvent<HTMLDivElement>) => 
    {
        if (env.isDesktop()) return;
        for(let i=0;i<event.changedTouches.length;i++)
        {
            const touch = event.changedTouches[i];
            const beforeTouchCount = Object.keys(activeTouches).length;
            activeTouches[touch.identifier.toString()] = true;
            const afterTouchCount = Object.keys(activeTouches).length;
            if ((beforeTouchCount===0) && (afterTouchCount===1))
            {
                startPoint = {
                    x: touch.screenX,
                    y: touch.screenY
                };
                timeoutId = setTimeout(enterDeadZone,touchHoldTimeoutMilliseconds);                
                addTouchDown();
                onTouchOrMouseDown();
            }
            else if (afterTouchCount>1)
            {
                enterDeadZone();
            }
        }
    };
    const touchMoveHandler = (event: TouchEvent<HTMLDivElement>) => 
    {
        if (env.isDesktop()) return;
        for(let i=0;i<event.changedTouches.length;i++)
        {
            const touch = event.changedTouches[i];            
            if ((Object.keys(activeTouches).length==1) && activeTouches[touch.identifier.toString()] && (!inDeadZone))
            {
                const dx = touch.screenX-startPoint.x;
                const dy = touch.screenY-startPoint.y;
                const d = Math.sqrt(dx*dx+dy*dy);
                if (d>=touchRadius) enterDeadZone();
            }
        }
    };
    const touchEndHandler = (event: TouchEvent<HTMLDivElement>) => 
    {
        if (env.isDesktop()) return;
        for(let i=0;i<event.changedTouches.length;i++)
        {
            const touch = event.changedTouches[i];
            const beforeTouchCount = Object.keys(activeTouches).length;
            delete activeTouches[touch.identifier.toString()];
            const afterTouchCount = Object.keys(activeTouches).length;
            if ((beforeTouchCount===1) && (afterTouchCount===0)) {
                clearTouchDown();
                if (!inDeadZone) {
                    _onClick();
                }
                inDeadZone = false;
            }
        }
    };

    const clickHandler = () => {
        if (env.isDesktop()) _onClick();
    };    

    useEffect(() => 
    {
        const parentElem = getParentElem();
        if (parentElem) {            
            parentElem.style["-webkit-tap-highlight-color"] = "rgba(0,0,0,0)";
            parentElem.style["-webkit-touch-callout"] = "none";
        }
    },[]);

    const props = {
        className: ["pane",...(disabled?["disabled"]:[]),...paneClassNames].join(" "),
        ref: paneDivRef,
        style: {
            zIndex: zIndex,
            left: `-${overlapLeft}rem`,
            top: `-${overlapTop}rem`,
            right: `-${overlapRight}rem`,
            bottom: `-${overlapBottom}rem`,
        },
        onMouseDown: mouseDownHandler,
        onMouseUp: mouseUpHandler,
        onMouseEnter: mouseEnterHandler,
        onMouseLeave: mouseLeaveHandler,
        onTouchStart: touchStartHandler,
        onTouchMove: touchMoveHandler,
        onTouchEnd: touchEndHandler,
        onClick: clickHandler,
        title: hint
    };
    
    return (
        <>
            {seoLinkUrl?<a ref={paneAnchorRef} href={seoLinkUrl} {...externalLink?{target: "_blank"}:{}}  onClick={(e) => { if (!externalLink) e.preventDefault(); }}><div {...props}></div></a>:<div {...props}></div>}
            <style jsx>{`
            .pane {
                position: absolute;
                cursor: pointer;
                -webkit-tap-highlight-color: transparent;
                -webkit-touch-callout: none;
            }

            .disabled {
                display: none;
            }
            `}</style>
        </>
    );
}