import App from "next/app";
import { useRouter } from 'next/router'
import { withRouter } from 'next/router';
import type { AppProps, AppContext } from 'next/app'
import Layout from "../components/layout"
// import "../public/static/local/fonts/vector-font/vector-font.css"
import "../public/static/local/fonts/vectors/fontcustom/fontcustom.css"
import { GlobalStateContext } from "../contexts/global_state"
import { useEffect, useState } from 'react'
import { getWindowSize, WindowSizeContext } from '../contexts/window_size'
import serverRefs from "../server-refs.json";
import { misc } from "../lib/misc";

if (typeof window !== "undefined") {
    const pid = misc.getParameterByName("pid");
    if (pid) {
        document.body.style.display = "none";
        const { pathname,search } = document.location;        
        document.location.href = `/landing/preview?preview_id=${pid}&loc=${encodeURIComponent(pathname+search)}`;
    }
}

function MyApp({ Component, pageProps }: AppProps) 
{
    const [windowSize,setWindowSize] = useState(getWindowSize());
    const { asPath } = useRouter();
    
    const globalState = {
        session: /* pageProps.__query["session"]!=="none" */ true,
        animation: /* pageProps.__query["animation"]!=="none" */ true
    };

    useEffect(() => 
    {
        window.addEventListener("resize",() => 
        {
            setWindowSize(getWindowSize());
        });
    },[]);    

    return (
        <WindowSizeContext.Provider value={windowSize}>
            <GlobalStateContext.Provider value={globalState}>
                <Layout windowSize={windowSize}>
                    <Component {...pageProps} />
                </Layout>
            </GlobalStateContext.Provider>
        </WindowSizeContext.Provider>
    )
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.

MyApp.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);

  appProps.pageProps["__query"] = appContext.ctx.query;

  return { ...appProps }
}

export default MyApp;
