import { cloneSimpleData } from "./misc";
import { OptionsDefinitionDefinition, OptionsDefinitionWithExtends, OptionsDefinitionWithInherits, OptionsDefinitionWithOptions } from "../types";
import { OptionSnapshot } from "./types";

export class OptionsCompiler {
    private cache: { 
        productOptions: { [productId: string]: OptionsDefinitionWithOptions };
        collectionOptions: { [collectionId: string]: { withoutLoad?: OptionsDefinitionWithOptions; withLoad: { [id: string]: OptionsDefinitionWithOptions } } };
    } = { productOptions: {}, collectionOptions: {} };
    private productToParentCollection: {[productId: string]: string} = {};
    private extensionStack: {collectionId: string;load?: string}[] = [];

    constructor(private snapshot: OptionSnapshot) {
        for(let collectionId in snapshot.collections) {
            this.cache.collectionOptions[collectionId] = { withLoad: {} };
            snapshot.collections[collectionId].productIds.forEach((productId) => {
                this.productToParentCollection[productId] = collectionId;
            });
        }
    }

    private createEmptyDefinitionWithOptions() {
        const ret: OptionsDefinitionWithOptions = {
            type: "options",
            options: [],
            price: {},
            vars: {},
            display: []
        };
        return ret;
    }

    private loadDefinitionDefinition(definition: OptionsDefinitionDefinition) {
        if (definition.type==="options") {
            return definition;
        }
        else {                        
            return this.getCollectionOptionsAsExtension(definition.extends.collection,definition.load,definition);
        }  
    }

    getCollectionOptions(collectionId: string,load?: string) {
        if (!this.snapshot.collections[collectionId]) throw new Error(`Attempting to extend an inexistant collection '${collectionId}'`);

        const process = () => {
            const { definition } = this.snapshot.collections[collectionId];
            if (load) {
                if (definition.type==="definitions") {
                    if (!definition.definitions[load]) throw new Error(`Attemping to load inexistant definition '${load}' on collection '${collectionId}'`);
                    return this.loadDefinitionDefinition(definition.definitions[load]);
                }
                else {
                    throw new Error(`Attempting to load definition '${load}' from a collection without definitions (collection: '${collectionId}')`);
                }
            }
            else {
                if (definition.type==="definitions") {
                    if (definition.load) {
                        if (!definition.definitions[definition.load]) throw new Error(`Attemping to load inexistant definition '${definition.load}' on collection '${collectionId}'`);
                        return this.loadDefinitionDefinition(definition.definitions[definition.load]);    
                    }
                    else {
                        return this.createEmptyDefinitionWithOptions();
                    }
                }
                else if (definition.type==="extends") {
                    return this.getCollectionOptionsAsExtension(definition.extends.collection,definition.load,definition);
                }
                else {
                    return definition;
                }
            }
        };

        if (load) {
            return this.cache.collectionOptions[collectionId].withLoad[load] = this.cache.collectionOptions[collectionId].withLoad[load] || process();
        }
        else {
            return this.cache.collectionOptions[collectionId].withoutLoad = this.cache.collectionOptions[collectionId].withoutLoad || process();
        }        
    }    

    private getCollectionOptionsAsExtension(collectionId: string,load: string | undefined,mergeDefinition: OptionsDefinitionWithExtends | OptionsDefinitionWithInherits) {
        if (this.extensionStack.filter((item) => (item.collectionId===collectionId) && (item.load===load)).length>0) {
            throw new Error(`circular collection extension reference encountered (collectionId: ${collectionId},load: ${load})`);
        }
        this.extensionStack.push({collectionId,load});                
        const ret = this.mergeDefinitionWithCustomization(cloneSimpleData(this.getCollectionOptions(collectionId,load)),mergeDefinition);
        this.extensionStack.pop();
        return ret;
    }

    private mergeDefinitionWithCustomization(definition: OptionsDefinitionWithOptions,customization: OptionsDefinitionWithExtends | OptionsDefinitionWithInherits) {
        definition.price = {...definition.price,...customization.price};
        definition.vars = {...definition.vars,...customization.vars};
        definition.display = [...((customization.display.length>0)?customization.display:definition.display)];
        return definition;
    }

    getProductOptions(productId: number | string) {2
        productId = productId.toString();
        const { products } = this.snapshot;
        if (!products[productId]) throw new Error(`trying to get product options for an inexistant product (id: ${productId})`);

        const process = () => {                        
            const { definition } = products[productId];
            if (definition.type==="options") {                
                return definition;
            }
            else  {
                const collectionId = (definition.type==="extends")?definition.extends.collection:this.productToParentCollection[productId];
                return this.getCollectionOptionsAsExtension(collectionId,definition.load,definition);
            }
        };

        const removeNegativeConditionalOptions = (definition: OptionsDefinitionWithOptions) => {
            definition.options = definition.options.filter(({conditional}) => {
                if (conditional) {
                    const conditionalFn = eval(conditional);                    
                    return !!conditionalFn({vars: definition.vars});
                }
                else {
                    return true;
                }
            });
            definition.options.forEach((option) => {
                if (option.type==="checkbox-group") {
                    option.items = option.items.filter(({conditional}) => {
                        if (conditional) {
                            const conditionalFn = eval(conditional);
                            return !!conditionalFn({vars: definition.vars});
                        }
                        else {
                            return true;
                        }
                    });
                }
            });
            return definition;
        };

        return this.cache.productOptions[productId] = this.cache.productOptions[productId] || removeNegativeConditionalOptions(process());
    }
}