class Env
{
    isMobile()
    {
        return matchMedia("(hover: none)").matches;
    }

    isDesktop()
    {
        return !this.isMobile();
    }
}

export const env = new Env();