import Image from "next/image";
import { CSSProperties } from "react";
import { useCustomRouter } from "../../hooks/use_custom_router";
import { prebuildData } from "../../lib/prebuild-data";
import { EventPane } from "../core/event_pane";
import { Text } from "../core/text";
import SpacerRow from "../spacer_row"

type TProp = {
    aspectRatio: number;
    iconName: string;
    text: string;
    url?: string;
};

export default function PromoLine({aspectRatio,iconName,text,url}: TProp)
{
    const height = 23;
    const width = height*aspectRatio;

    const router = useCustomRouter();

    const clickHandler = () => {
        router.push(url);
    };

    const iconParams = {
        "shipping": {
            iconShiftX: 0,
            iconShiftY: 0,
            iconScale: 1,
        },
        "percent": {
            iconShiftX: 3,
            iconShiftY: 3,
            iconScale: 0.8,
        },
        "www": {
            iconShiftX: 3,
            iconShiftY: 3,
            iconScale: 0.7,
        }
    };

    const { iconShiftX,iconShiftY,iconScale } = iconParams[iconName];

    const iconStyles: CSSProperties = {
        transform: `translate(${iconShiftX}rem,${iconShiftY}rem) scale(${iconScale})`
    };

    const { navbar: {  promoIconColor: iconColor,promoTextColor: textColor } } = prebuildData.getActiveTheme();

    const imageStyles: CSSProperties = {
        color: iconColor
    };

    const textStyles: CSSProperties = {
        color: textColor
    };

    return (
        <>
            <div className="container">
                <div className="holder">
                    <div className="image" style={imageStyles}>
                        <i className={`icon-promo-${iconName} promo-icon`} style={iconStyles} /> 
                    </div>
                    <SpacerRow size={8} />
                    <div className="text" style={textStyles}><Text>{text}</Text></div>
                    {url?<EventPane hoverOnTouchDown seoLinkUrl={url} externalLink={/^https?:\/\//.test(url)} onClick={clickHandler} />:""}
                </div>                 
            </div>
            <style jsx>{`
            .container {
                color: #f1f2f6;
                font-size: 16rem;
                font-weight: 400;
                justify-content: center;
                position: relative;
                top: -4rem;
            }

            .promo-icon {
                font-size: 32rem;
            }

            @media screen and (max-width: 1300px)
            {
                .container {
                    font-size: 14rem;
                }
            }

            @media screen and (max-width: 1200px)
            {
                .container {
                    font-size: 13rem;
                }
            }

            @media screen and (max-width: 1150px)
            {
                .container {
                    font-size: 12rem;
                }
            }

            .container,.holder
            {
                display: flex;
                flex-direction: row;                
                align-items: center;                
            }

            .holder {
                position: relative;
                opacity: 1;
                transition: opacity 0.3s;
            }

            .holder.hover
            {
                opacity: 0.85;
            }

            .image
            {
                position: relative;
            }

            .image i {
                position: absolute;
                left: -33rem;
                top: -25rem;
            }

            .text
            {
                text-decoration: underline;
            }
            `}</style>
        </>
    );    
}