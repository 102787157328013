import Logo from "./navbar/logo";
import MenuItem from "./mini_navbar/menu_item";
import { useIconButton } from "./icon_button";
import { useContext, useEffect, useRef, useState } from "react";
import { LayoutStateContext } from "../contexts/layout_state";
import { WindowSizeContext } from "../contexts/window_size";
import { DimensionsStateContext } from "../contexts/dimensions_state";
import { prebuildData } from "../lib/prebuild-data";
import { useResizeObserver } from "../hooks/use_resize_observer";
import { usePubSubSender } from "../hooks/use_pub_sub";
import { useCurrentCollection } from "../hooks/use_current_collection";
import { consumeAccount } from "../hooks/use_account";
import { useCustomRouter } from "../hooks/use_custom_router";

type Props = {
    cartClickHandler?: () => void;
    cartItemCount: number;
};

export function MiniNavBar({cartClickHandler = () => {},cartItemCount}: Props)
{
    const [up,setUp] = useState(true);
    const [pageScrollbarWidth,setPageScrollbarWidth] = useState(0);
    const { page,sideMenu,scaleRemToPixel,pageMinWidth,mobileDetect,openSearch } = useContext(LayoutStateContext);
    const { widthCutoff: mobileCutoff } = mobileDetect;
    const collectionItems = prebuildData.getCollectionItems();
    const _windowSize = useContext(WindowSizeContext);

    const { miniNavbarHeight } = useContext(DimensionsStateContext);

    useEffect(() => {        
        return page.addScrollEventListener(({top}) => 
        {
            setUp(top<scaleRemToPixel(150));
        });
    },[]);

    const { send: sendMiniNavbarDown } = usePubSubSender<boolean>("mini-navbar-down");

    useEffect(() => {
        if (up) {
            sendMiniNavbarDown(false);
        }
    },[up]);

    const menuClickHandler = () => {
        sideMenu.setShow(true);
    };

    const buttonVerticalPadding = 100;
    const mobileFixedButtonWidth = 21+20*2;
    const desktopFixedButtonWidth = 46;
    const logoPadding = 10;
    const desktopIconButtonScale = 0.75;

    const refOnResize = useResizeObserver(({height}) =>
    {
        miniNavbarHeight.set(height);
    });

    const transitionEndHandler = (propertyName: string) => {
        if ((propertyName==="transform") && (!up)) {
            sendMiniNavbarDown(true);
        }
    };

    const searchClickHandler = () => {
        page.scrollToTopWithCallback(() => {
            openSearch();            
        });
    };

    const { handle: selectedCollectionHandle } = useCurrentCollection();

    const { miniNavbar: { backgroundColor,backdropFilter,iconColor } } = prebuildData.getActiveTheme();

    const IconButton = useIconButton(iconColor,"#FFF");

    const router = useCustomRouter();

    const account = consumeAccount();

    const loginClickHandler = () => {        
        if (account.value) {
            router.push("/account");
        }
        else {
            router.push("/login");
        }        
    };

    return (
        <>
            <div ref={refOnResize} className={`mini-navbar${up?" up":""}`}
                    style={{
                        width: `calc(100% - ${pageScrollbarWidth}rem)`
                    }}
                    onTransitionEnd={(e) => transitionEndHandler(e.propertyName)}
                >
                <div className="bar desktop">
                    <div className="left-side">
                        <Logo 
                            size={31} 
                            shift={2}
                            paddingLeft={logoPadding}
                            paddingRight={logoPadding}
                            paddingTop={logoPadding}
                            paddingBottom={logoPadding}
                        />
                        <IconButton 
                            name="navbar-search" 
                            scale={desktopIconButtonScale} 
                            caption="" 
                            iconShift={0} 
                            wholeShift={-1}
                            fixedWidth={desktopFixedButtonWidth}
                            verticalPadding={buttonVerticalPadding}
                            onClick={() => searchClickHandler()}
                        />
                    </div>
                    <div className="right-side">
                        <IconButton 
                            name="navbar-account" 
                            scale={desktopIconButtonScale} 
                            caption="" 
                            iconShift={0} 
                            wholeShift={-1}
                            fixedWidth={desktopFixedButtonWidth}
                            verticalPadding={buttonVerticalPadding}
                            onClick={() => loginClickHandler()}
                        />
                        <IconButton 
                            name="navbar-cart" 
                            scale={desktopIconButtonScale} 
                            caption="" 
                            iconText={cartItemCount.toString()} 
                            iconTextShift={-3} 
                            iconShift={0} 
                            wholeShift={-1} 
                            fixedWidth={desktopFixedButtonWidth}
                            verticalPadding={buttonVerticalPadding}
                            onClick={() => cartClickHandler()}
                        />
                    </div>
                    {collectionItems.map(({handle,caption},index) => (<MenuItem key={handle} handle={handle} caption={caption} selected={handle===selectedCollectionHandle} />))}
                </div>
                <div className="bar mobile">
                    <div style={{flex: "0 0 86rem"}}>
                        <IconButton 
                            name="navbar-menu" 
                            scale={0.7} 
                            caption="" 
                            wholeShift={-1} 
                            onClick={menuClickHandler} 
                            verticalPadding={buttonVerticalPadding}
                            fixedWidth={mobileFixedButtonWidth}
                        />
                    </div>
                    <Logo 
                        size={35} 
                        shift={2} 
                        paddingLeft={logoPadding}
                        paddingRight={logoPadding}
                        paddingTop={logoPadding}
                        paddingBottom={logoPadding}
                    />
                    <div style={{display: "flex",flex: "0 0 86rem"}}>
                        <IconButton 
                            name="navbar-search" 
                            scale={0.75} 
                            caption="" 
                            iconShift={0} 
                            wholeShift={-1} 
                            verticalPadding={buttonVerticalPadding}
                            fixedWidth={mobileFixedButtonWidth*0.7}
                            onClick={() => searchClickHandler()}
                        />
                        <IconButton 
                            name="navbar-cart" 
                            scale={0.75} 
                            caption="" 
                            iconText={cartItemCount.toString()} 
                            iconTextShift={-2} 
                            iconShift={0} 
                            wholeShift={-1} 
                            verticalPadding={buttonVerticalPadding}
                            fixedWidth={mobileFixedButtonWidth*0.7}
                            onClick={() => cartClickHandler()}
                        />
                    </div>
                </div>
            </div>
            <style jsx>{`
            .mini-navbar {
                --height: 48rem;
                position: absolute;
                z-index: 100;
                left: 0rem;
                top: 0rem;
                height: var(--height);
                background-color: var(--color-header);
                transform: translateY(0rem);
                transition: transform 0.4s;
                overflow: hidden;
                /* min-width: ${pageMinWidth}rem; */
            }

            .bar {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }

            .bar.desktop {
                display: flex;
                position: relative;
            }

            .bar.desktop .left-side,.bar.desktop .right-side {
                position: absolute;
                top: 0rem;
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }

            .bar.desktop .left-side
            {
                left: 0rem;
                width: 138rem;
            }

            .bar.desktop .right-side
            {
                right: 0rem;
                width: 169rem;
            }

            .bar.mobile {
                justify-content: space-between;
                padding: 0rem 0rem;
                display: none;
            }

            @media (hover: hover) {
                .mini-navbar {
                    background-color: ${backgroundColor};                
                    backdrop-filter: ${backdropFilter};
                }
            }

            .mini-navbar.up {
                transform: translateY(calc(var(--height) * -1));
            }

            @media screen and (max-width: 1250px) {
                .bar.desktop {
                    display: none;
                }    

                .bar.mobile {
                    display: flex;
                }

                .mini-navbar {
                    min-width: 0rem;
                    --height: 50rem;
                }
            }
            `}</style>
        </>
    );
}