import { useContext, useRef } from "react";
import { LayoutStateContext } from "../../contexts/layout_state";
import { useCssStateManager } from "../../hooks/use_css_state_manager";
import { useCustomRouter } from "../../hooks/use_custom_router";
import { prebuildData } from "../../lib/prebuild-data";
import { EventPane } from "../core/event_pane";

export type SideMenuCollectionsMenuItemProps = {
    handle: string;
    caption: string;
    selected: boolean;
};

export function SideMenuCollectionsMenuItem({handle,caption,selected}:SideMenuCollectionsMenuItemProps)
{
    const { sideMenu: { setShow } } = useContext(LayoutStateContext);
    const router = useCustomRouter();

    const cssStateManager = useCssStateManager(["side-menu-collections-menu-item"])
    cssStateManager.useProperty(selected,"selected");

    const routeRef = useRef<string>();
    const route = routeRef.current?routeRef.current:routeRef.current = (() =>{
        const collectionItem = prebuildData.getCollectionByHandle(handle);
        return collectionItem.route.split("{handle}").join(handle);
    })();

    const itemClickHandler = () => {
        setShow(false);
        router.push(route);
    };

    const { sideMenu: { menuHighlightTextColor,menuTextColor } } = prebuildData.getActiveTheme();

    return (
        <>
            <div className={cssStateManager.getClassNames()}>
                {caption}
                <EventPane onClick={itemClickHandler} seoLinkUrl={route} />
            </div>
            <style jsx>{`
            .side-menu-collections-menu-item {
                color: ${menuTextColor};
                height: var(--menu-item-height);
                padding: var(--menu-item-padding-left);
                text-align: right;
                font-size: 15rem;
                position: relative;
                cursor: pointer;
                transition: background-color var(--touch-duration);
            }

            .side-menu-collections-menu-item.selected {
                color: ${menuHighlightTextColor};
                font-weight: 600; 
            }

            .side-menu-collections-menu-item.hover {
                background-color: rgba(0,0,0,0.035);
            }

            .side-menu-collections-menu-item.touchdown {
                background-color: rgba(0,0,0,0.05);
            }
            `}</style>
        </>
    );
}