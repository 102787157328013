import { CSSProperties, useContext, useRef } from "react";
import { Account } from "../../hooks/use_account";
import { useCssStateManager } from "../../hooks/use_css_state_manager";
import { SmartState } from "../../hooks/use_smart_state";
import { InlineButton, styledButton } from "../common/button";
import { LayoutStateContext } from "../../contexts/layout_state";
import { EventPane } from "../core/event_pane";

export type AccountBarMobileDropDownProps = {
    email: string;
    onSignoutClick?: () => void;
};

const Button = styledButton({ display: "inline-block",fontSize: "13rem",padding: "10rem 25rem" });

export default function AccountBarMobileDropDown({email,onSignoutClick = () => {}}:AccountBarMobileDropDownProps)
{
    const { scalePixelToRem } = useContext(LayoutStateContext);
    const cssStateManager = useCssStateManager(["account-bar-mobile-drop-down"]);
    const isOpen = cssStateManager.useState(false,"open");
    const innerRef = useRef<HTMLDivElement>();
    const closedHeight = 42;

    const getInnerHeight = () => {
        if (innerRef.current) {
            return scalePixelToRem(innerRef.current.offsetHeight);
        }
        else {
            return 0;
        }
    };

    const dropdownStyles: CSSProperties = {
        height: `${isOpen.value?getInnerHeight():closedHeight}rem`
    };

    const handleOpenClick = () => {
        isOpen.value = !isOpen.value;
    };

    return (
        <>
            <div className={cssStateManager.getClassNames()}>
                <div className="dropdown" style={dropdownStyles}>
                    <div ref={innerRef} className="inner">
                        <div className="top-section">
                            <div className="email">
                                {email}
                            </div>
                            <div className="arrow">
                                <div className="icon-navbar-right-arrow" />
                            </div>      
                            <EventPane onClick={handleOpenClick} parentElemRef={innerRef} />              
                        </div>
                        <div className="bottom-section">
                            <Button caption="Signout" onClick={onSignoutClick} />
                        </div>
                    </div>
                </div>                
            </div>
            <style jsx>{`
            .account-bar-mobile-drop-down {
                padding: 0rem 20rem;
                padding-top: 20rem;
                padding-bottom: 10rem;
            }

            .dropdown {
                --duration: 0.4s;
                border: 1rem solid #aaa;
                border-radius: 5rem;
                font-size: 14rem;
                color: #555;     
                overflow-y: hidden;
                transition: height var(--duration);
            }

            .inner {
                padding: 10rem 5rem;       
                padding-right: 0rem;
            }

            .inner.touchdown {
                /* background-color: rgba(0,0,0,0.08); */
            }

            .top-section {
                display: flex;
                position: relative;
            }

            .bottom-section {
                text-align: center;
                padding-top: 13rem;
                padding-bottom: 3rem;
            }

            .email {
                text-align: center;
                flex: 1 0;
                text-overflow: ellipsis;
                overflow: hidden;
                padding: 0rem 5rem;
            }
            
            .arrow {
                flex: 0 0 30rem;
            }

            .arrow div {
                transform: rotate(90deg);
                position: relative;
                top: 0rem;
                color: #999;
                overflow: hidden;
                width: 12rem;
                height: 20rem;
                transition: transform var(--duration);
            }

            .open .arrow div {
                transform: rotate(-90deg);
            }
            `}</style>
        </>
    );
}