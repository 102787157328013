import { CSSProperties } from "react";
import { useSmartState } from "./use_smart_state";

export const useImageLoadEffect = (fadeDuration = 0.3) => {
    const loaded = useSmartState(false);

    const wrapperStyles: CSSProperties = {
        visibility: loaded.value?"visible":"hidden",
        opacity: loaded.value?1:0,
        transition: `opacity ${fadeDuration}s,visibility ${fadeDuration}s`
    };

    const imageLoadHandler = () => {
        setTimeout(() => {
            loaded.value = true;
        },100);        
    };
    return {
        wrapperStyles,
        imageLoadHandler
    };
};