import { CSSProperties } from "react";
import { useCssStateManager } from "../../hooks/use_css_state_manager";
import { prebuildData } from "../../lib/prebuild-data";

export type CenteredSpinnerProps = {
    scale?: number;
    usePixels?: boolean;
};

interface CustomCSS extends CSSProperties {
    '--size': string;
}

export default function CenteredSpinner({scale = 1,usePixels}:CenteredSpinnerProps)
{
    const cssStateManager = useCssStateManager(["centered-spinner"]);
    const size = 144*scale;
    return (
        <>
            <div className={cssStateManager.getClassNames()} style={{
                "--size": `${size}${usePixels?"px":"rem"}`
            } as CustomCSS}>
                <div className="spinner-frame">
                    <img src={prebuildData.bustCache("/images/creating_checkout/loading.svg")} />
                </div>
            </div>
            <style jsx>{`
            .centered-spinner {
                text-align: center;
            }

            .spinner-frame {
                overflow: hidden;
                display: inline-block;
                width: calc(var(--size) * 0.34); 
                height: calc(var(--size) * 0.34); 
            }
            .spinner-frame img {                
                position: relative;
                left: calc(var(--size) * -0.33);
                top: calc(var(--size) * -0.33);
                width: var(--size);
                height: var(--size);
            }
            `}</style>
        </>
    );
}