import { KeyboardEvent } from "react";

type ControlKeys = {ctrl?: true;alt?: true,shift?: true};
type Entry = {
    cb: () => void;
    key: string;
    controlKeys: ControlKeys;
};

class KeyPress {
    private entries: Entry[] = [];
    private eventRegistered = false;

    private matchesEntry(entry: Entry,event: globalThis.KeyboardEvent) {
        const { key,controlKeys: { ctrl,alt,shift } } = entry;
        if (event.key!==key) return false;
        if ((!!ctrl) != event.ctrlKey) return false;
        if ((!!alt) != event.altKey) return false;
        if ((!!shift) != event.shiftKey) return false;
        return true;
    }

    private registerEvent() {
        if (this.eventRegistered) return;
        document.addEventListener("keydown",(event) => {
            let called = false;
            this.entries.forEach((entry) => {
                if (this.matchesEntry(entry,event)) {
                    entry.cb();
                    called = true;
                }
            });
            if (called) {
                event.preventDefault();
            }
        });
        this.eventRegistered = true;
    }

    onKey(cb: () => void,key: string,controlKeys: ControlKeys = {}) {
        const entry: Entry = {
            cb,
            key,
            controlKeys
        };
        this.entries.push(entry);
        this.registerEvent();
        return () => {
            this.entries = this.entries.filter((_entry) => _entry!==entry);
        };
    }
}

export const keypress = new KeyPress();