import { useCssStateManager } from "../hooks/use_css_state_manager";
import { EditOrderOrder } from "../lib/api/editorder";
import { cart } from "../lib/services/cart/cart";

export type EditBottomBarProps = {
    editOrder?: EditOrderOrder;
};

export default function EditBottomBar({editOrder}:EditBottomBarProps)
{
    const cssStateManager = useCssStateManager(["edit-bottom-bar"]);
    if (!editOrder) return null;
    const { name,customer } = editOrder;
    const centerText = cart.editOrderIsCreate?"Creating Order":`Editing ${(editOrder.orderType==="draft")?"Draft ":""}Order`;
    return (
        <>
            <div className={cssStateManager.getClassNames()}>
                <div className="name">{name}</div>
                <div className="center hori-verti-centered">{centerText}</div>
                <div className="customer">
                    {customer?(<>
                        <div>{customer.displayName}</div>
                        <div className="email">{customer.email}</div>
                    </>):<><br />No Customer<br/><br/> </>}
                </div>
            </div>
            <style jsx>{`
            .edit-bottom-bar {
                position: fixed;
                z-index: 50;
                bottom: 0rem;
                left: 0rem;
                right: 0rem;
                /* height: 200rem; */
                background-color: blue;
                color: #FFF;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 7rem 20rem;
                font-size: 18rem;
            }
            .name {
                font-size: 16rem;
            }
            .center {
                position: absolute;
                left: 0rem;
                right: 0rem;
                top: 0rem;
                bottom: 0rem;
            }
            .customer {
                font-size: 14rem;
                text-align: right;
            }

            .email {
                color: #ccc;
                margin-top: 3rem;
            }
            `}</style>
        </>
    );
}