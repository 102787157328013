import { useRouter } from "next/router";
import { prebuildData } from "../lib/prebuild-data";

export const useCurrentCollection = () => {
    let { asPath } = useRouter();
    asPath = asPath.split("?")[0];
    const res = /^\/collections\/([^\/]*)$/.exec(asPath);
    let handle = res?res[1]:null;    
    if (asPath==="/pages/help") handle = "help";
    const id = handle?prebuildData.getCollectionByHandle(handle).id:null;
    return { handle,id };
}