import { useEffect, useRef, useState } from "react";
import { SmartState } from "../../hooks/use_smart_state";

let startRouterHandler: (() => void) = null;
let stopRouterHandler: (() => void) = null;

let startCounter = 0;

export const routerTransition = {
    start() {
        if (startRouterHandler) {
            startCounter++;
            startRouterHandler();
        }
    },
    stop()
    {        
        if (stopRouterHandler) stopRouterHandler();
    },
    async run(cb: () => Promise<void>,{ working,onCleanComplete = () => {},dontChangeWorkingOnSuccess = false,onInterrupted = () => {}}: {working?: SmartState<boolean>;onCleanComplete?: (errorMessage?: string) => void;dontChangeWorkingOnSuccess?: boolean,onInterrupted?: () => void} = {}) {
        routerTransition.start();
        if (working) working.value = true;
        const savedCounter = startCounter;
        let errorMessage: string | undefined;
        try {
            await cb();
        }
        catch(err) {
            errorMessage = err.message;
            if (errorMessage==="Failed to fetch") {
                errorMessage = "Unable to contact network. Make sure you have an internet connection and try again.";
            }
        }        
        if ((working) && ((!dontChangeWorkingOnSuccess) || errorMessage)) working.value = false;
        if (savedCounter===startCounter) {
            routerTransition.stop();     
            setTimeout(() => {
                onCleanComplete(errorMessage);
            },300);            
        }   
        else {
            onInterrupted();
        }
    }
};

export type RouterTransitionProps = {
    transitionDuration?: number;
    accelerateFactor?: number;
};

export function RouterTransition({
    transitionDuration = 0.5,
    accelerateFactor = 4
}:RouterTransitionProps)
{
    const [running,setRunning] = useState(false);
    const [showing,setShowing] = useState(false);
    const [canceled,setCanceled] = useState(false);
    const runningRef = useRef(running);
    runningRef.current = running;
    const showingRef = useRef(showing);
    showingRef.current = showing;
    const shouldChainStartRef = useRef(false);
    
    const [percentage,setPercentage] = useState(0);
    const percentagePermRef = useRef(percentage);
    percentagePermRef.current = percentage;
    const wrapperRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);    
    const counterRef = useRef(0);
    const stopCounterRef = useRef(0);

    useEffect(() => {
        (window as any).routerTransition = routerTransition;

        startRouterHandler = () => {
            const { current: running } = runningRef;
            const { current: showing } = showingRef;
            if (running) {
                if (!showing) {
                    setCanceled(true);
                    setRunning(false);runningRef.current = false;
                    setPercentage(0);
                    const stopCounterSaved = stopCounterRef.current;
                    setTimeout(() => {
                        if (stopCounterSaved===stopCounterRef.current) routerTransition.start();
                    },100);        
                }
                return;
            }

            setCanceled(false);
            setRunning(true);runningRef.current = true;
            setShowing(true);showingRef.current = true;
            setPercentage(100/accelerateFactor);
        };

        stopRouterHandler = () => {
            stopCounterRef.current++;
            const { current: running } = runningRef;
            if (!running) return;

            setShowing(false);showingRef.current = false;
            counterRef.current = 0;
        };

        return () => {
            startRouterHandler = null;
            stopRouterHandler = null;
        };
    },[]);

    useEffect(() => 
    {
        if ((wrapperRef.current) && (containerRef.current))
        {
            let wrapperTransitionCompleteHandler: (this: HTMLDivElement,ev: globalThis.TransitionEvent) => any;
            let containerTransitionCompleteHandler: (this: HTMLDivElement,ev: globalThis.TransitionEvent) => any;
            wrapperRef.current.addEventListener("transitionend",wrapperTransitionCompleteHandler=(ev) =>
            {
                if ((ev.target===wrapperRef.current) && (ev.propertyName==="opacity")) 
                {
                    const { current: running } = runningRef;
                    const { current: showing } = showingRef;
                    if ((running) && (!showing))
                    {
                        setRunning(false);runningRef.current = false;
                        setPercentage(0);    
                    }
                }
            });
            containerRef.current.addEventListener("transitionend",containerTransitionCompleteHandler=(ev) =>
            {
                if (ev.target===containerRef.current) 
                {
                    counterRef.current++;                
                    if (counterRef.current<100/accelerateFactor)
                    {
                        const { current: percentage } = percentagePermRef;
                        setPercentage(percentage+(100-percentage)/accelerateFactor);    
                    }    
                }
            });
            return () => 
            {
                if (wrapperRef.current) wrapperRef.current.removeEventListener("transitionend",wrapperTransitionCompleteHandler);
                if (containerRef.current) containerRef.current.removeEventListener("transitionend",containerTransitionCompleteHandler);
            };
        }
    },[]);

    return (
        <>
            <div ref={wrapperRef} className={"wrapper"
                +(running?" running":"")
                +(showing?" showing":"")
                +(canceled?" canceled":"")
                }>
                <div ref={containerRef} className="container" style={{ transform: `translateX(${percentage}%)` }}></div>
            </div>
            <style jsx>{`
            .wrapper {
                z-index: 1000;
                position: fixed;
                left: -100%;
                top: 0rem;
                width: 100%;
                height: 3rem;
                opacity: 0;
                visibility: hidden;
                --show-duration: 1.5s;
                transition: opacity var(--show-duration),visibility var(--show-duration);
            }

            .wrapper.showing {
                opacity: 1;
                visibility: visible;
                transition: none;
            }

            html .wrapper.canceled {
                transition: none;
            }

            .container {
                background-color: #da904c;
                width: 100%;
                height: 100%;
            }

            .wrapper.running .container {
                transition: transform ${transitionDuration}s linear;
            }
            `}</style>
        </>
    );
}