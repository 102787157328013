import { useContext } from "react";
import { LayoutStateContext } from "../../contexts/layout_state";

export default function Mobile({children}:any) {
    const { mobileDetect: { widthCutoff: mobileCutoff } } = useContext(LayoutStateContext);
    return (
        <>
            <div className="mobile">{children}</div>
            <style jsx>{`         
            .mobile {
                display: none;
            }       
            @media screen and (max-width: ${mobileCutoff}px) {
                .mobile {
                    display: block;
                }
            }
            `}</style>
        </>
    );
}