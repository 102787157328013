import { prebuildData } from "../../lib/prebuild-data";
import { EventPane } from "../core/event_pane";
import { Icon } from "../core/icon";

export type SideMenuItemProps = {
    caption: string;
    iconName: string;
    onClick?: () => void;
};

export function SideMenuItem({caption,iconName,onClick}:SideMenuItemProps)
{
    const { sideMenu: { actionsIconColor,actionsTextColor } } = prebuildData.getActiveTheme();

    return (
        <>
            <div className="container">
                <div className="frame">
                    <div className="caption">{caption}</div>
                    <div className="icon"><Icon name={iconName} size={22} /></div>
                </div>
                <EventPane onClick={onClick} />
            </div>
            <style jsx>{`
            .container {
                width: 100%;            
                position: relative;    
                cursor: pointer;
                transition: background-color var(--touch-duration);
            }

            .container.hover {
                background-color: rgba(0,0,0,0.035);
            }

            .container.hover .frame {
                color: var(--color-menu-item-highlight);
            }

            .container.touchdown {
                background-color: rgba(0,0,0,0.05);
            }

            .frame {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;                
                height: var(--menu-item-height);
                width: 100%;
            }

            .caption {
                color: ${actionsTextColor};
                font-size: 18rem;
                font-weight: 600;
                padding-left: var(--menu-item-padding-left);
            }

            .icon {
                padding-right: 14rem;
                color: ${actionsIconColor};
            }
            `}</style>
        </>
    );
}