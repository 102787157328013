import { useRouter } from "next/router";
import { useContext, useEffect, useRef } from "react";
import { LayoutStateContext } from "../../contexts/layout_state";
import { useCachedValue } from "../../hooks/use_cached_value";
import { useCssStateManager } from "../../hooks/use_css_state_manager";
import { useCustomRouter } from "../../hooks/use_custom_router";
import { useSmartState } from "../../hooks/use_smart_state";
import { misc } from "../../lib/misc";
import { cloneSimpleData } from "../../lib/shared/options/snapshot/misc";
import { prebuildData } from "../../lib/prebuild-data";
import { cart, useCart } from "../../lib/services/cart/cart";
import { CartItemDisplay } from "../../lib/services/cart/types";
import { Image } from "../common/scaled-next-image";
import { EventPane } from "../core/event_pane";

type Props = {
    item: CartItemDisplay;
    hidden?: boolean;
    flashit?: boolean;
    deleted: boolean;
    updateQuantity?: (quantityDisplay: string) => void;
    minusQuantity?: () => void;
    plusQuantity?: () => void;
    quantityBlur?: () => void;
    remove?: () => void;
    onMobile: boolean;
    closeSideCartHandler: () => void;
};

export default function SideCartItem({
        item,
        hidden = false,
        flashit = false,
        deleted,
        onMobile,
        updateQuantity = () => {},
        minusQuantity = () => {},
        plusQuantity = () => {},
        quantityBlur = () => {},
        remove = () => {},
        closeSideCartHandler}: Props) {

    const { scalePixelToRem,productPageLoadCounter } = useContext(LayoutStateContext);
            
    const binSvg = misc.createImageDimensions(242,256,15);

    const { 
        id,
        title,
        price,
        salePrice,
        salesNotice,
        thumbSalesNotice,
        quantitySalesNotice,
        quantity,
        quantityDisplay,
        properties,
        imageSrc,
        notEditable,
        notQuantityEditable,
        productId
     } = item;
    const { dollar,cents } = misc.splitPriceForDisplay(price);
    const { dollar: spDollar,cents: spCents } = misc.splitPriceForDisplay(salePrice || 0);

    const isFreeProduct = notQuantityEditable && (salePrice===0);

    const onsale = (typeof salePrice==="number");
    const hasSalesNotice = (typeof salesNotice==="string") && (salesNotice!=="");
    const hasThumbSalesNotice = (typeof thumbSalesNotice==="string") && (thumbSalesNotice!=="");
    const hasQuantitySalesNotice = (typeof quantitySalesNotice==="string") && (quantitySalesNotice!==""); 
    
    const reactRouter = useRouter();
    
    const cssStateManager = useCssStateManager(["container",item.id]);
    cssStateManager.useProperty(onsale,"onsale");
    cssStateManager.useProperty(hasSalesNotice,"has-sales-notice");
    cssStateManager.useProperty(hasThumbSalesNotice,"has-thumb-sales-notice");
    cssStateManager.useProperty(hasQuantitySalesNotice,"hasQuantitySalesNotice");
    cssStateManager.useProperty(notEditable,"not-editable");
    cssStateManager.useProperty(notQuantityEditable,"not-quantity-editable");
    cssStateManager.useProperty(!!cart.editOrder && !item.notQuantityEditable,"show-edit");
    const hiddenState = cssStateManager.useState(false,"hidden");
    const thumbLoaded = cssStateManager.useState(false,"thumb-loaded");
    const flashing = cssStateManager.useState(false,"flashing");
    const flash = cssStateManager.useState(false,"flash");
    const fadeOut = cssStateManager.useState(false,"fade-out");
    const flashCountRef = useRef(0);
    const flaskCountMax = onMobile?2:2;

    const containerHeight = useSmartState(-1);

    const ref = useRef<HTMLDivElement>();

    const animateDeletion = () => {
        const { current: elem } = ref;
        if (elem) {
            containerHeight.value = scalePixelToRem(elem.offsetHeight);
        }
    };

    useEffect(() => {
        if (containerHeight.value>0) {
            setTimeout(() => {
                containerHeight.value = 0;            
                fadeOut.value = true;
            },50);
        }
    },[containerHeight.value]);

    useEffect(() => {
        if (hidden) {
            hiddenState.value = true;
        }
    },[hidden]);

    useEffect(() => {
        if (deleted) {
            animateDeletion();
        }
    },[deleted]);    

    useEffect(() => {
        if (flashit) {
            flashCountRef.current = 0;
            flashing.value = true;
            flash.value = true;
        }
    },[flashit]);    

    useEffect(() => {
        if (hiddenState.value) {
            hiddenState.value = false;
        }
    },[hiddenState.value]);

    const editClickHandler = () => {
        const pageRoute = prebuildData.getProductPageRoute(productId);
        const currentRoute = reactRouter.asPath.split("?")[0];
        cart.editCartItem = cloneSimpleData(cart.lookupItem(id));
        closeSideCartHandler();
        if (pageRoute!==currentRoute) {
            router.push(pageRoute);
        }
        else {
            productPageLoadCounter.value++;
        }
    };

    const coverTransitionEndHandler = (propertyName: string) => {
        if ((propertyName==="opacity") && (flashing.value)) {
            if (flash.value) {
                flash.value = false
            }
            else {
                flashCountRef.current++;
                if (flashCountRef.current<flaskCountMax) {
                    flash.value = true;
                }
                else {
                    flashing.value = false;
                }
            }
        }
    };

    const transitionEndHandler = (target: HTMLDivElement,propertyName: string) => {
        if ((target===ref.current) && (propertyName==="opacity") && (fadeOut.value)) {
            remove();
        }
    };

    const router = useCustomRouter();

    const pageRoute = useCachedValue(() => prebuildData.getProductPageRoute(productId),productId);

    const clickHandler = () => {
        closeSideCartHandler();
        router.push(pageRoute);
    };

    const { 
        cart: {
            itemTitleColor,
            itemTitleHoverColor,
            itemPropertiesGroupTitleColor
        }
    } = prebuildData.getActiveTheme();

    return (
        <>
            <div 
                className={cssStateManager.getClassNames()} 
                ref={ref} 
                style={{ height: (containerHeight.value!==-1)?`${containerHeight.value}rem`:"auto" }}
                onTransitionEnd={(e) => transitionEndHandler(e.target as HTMLDivElement,e.propertyName)}
            >
                <div className="padding-frame">
                    <div className="frame">
                        <div className="thumbnail-column">
                            <div className="image-wrapper">
                                <div className="inner">
                                    <div className="inner-relative">
                                        <Image 
                                            src={prebuildData.bustCache(imageSrc)} 
                                            layout="fill" 
                                            onLoadingComplete={() => {
                                                thumbLoaded.value = true;
                                            }}
                                        />
                                        <div className="thumb-sale-notice">
                                            <div className="thumb-sale-notice-inner">SALE</div>
                                        </div>
                                    </div>
                                </div>                     
                                <EventPane onClick={clickHandler} parentElemRef={ref} seoLinkUrl={pageRoute} />
                            </div>
                            <div className="quantity-not-editable">
                                {quantity}
                            </div>
                            <div className="quantity-control">
                                <div className={`button left-button ${(quantity>1)?"minusable":""}`}>
                                    <div className="bin-wrapper">
                                        <Image 
                                            src={prebuildData.bustCache("/images/side_cart/bin.svg")} 
                                            width={binSvg.width} 
                                            height={binSvg.height}                                             
                                        />
                                    </div>
                                    <span>–</span>
                                    <EventPane onClick={minusQuantity} />
                                </div>
                                <div className="display">
                                    <input 
                                        type="text" 
                                        value={quantityDisplay} 
                                        onChange={({target}) => updateQuantity(target.value)} 
                                        onBlur={quantityBlur}
                                        />
                                </div>
                                <div className="button right-button">
                                    <span>+</span>
                                    <EventPane onClick={plusQuantity} />
                                </div>
                            </div>
                            <div className="quantity-sales-notice">
                                {quantitySalesNotice}
                            </div>
                            <div className="edit-button-wrapper">
                                <div className="edit-button">
                                    Edit
                                    <EventPane onClick={editClickHandler} />
                                </div>
                            </div>
                        </div>
                        <div className="thumbnail-content">
                            <div className="title">{title}</div>
                            {isFreeProduct?(<>
                                <div className="price force-free">                                
                                    <span className="price-free">Free</span>
                                </div>
                            </>):(<>
                                <div className="price">                                
                                    {((dollar==="0") && (cents==="00"))?(
                                        <span className="price-free">Free</span>
                                    ):(
                                        <>
                                            <span className="superscript">$</span>
                                            {dollar}
                                            <span className="superscript">{cents}</span>
                                            <div className="cross-out"></div>
                                        </>
                                    )}
                                </div>
                                <div className="price sale-price">
                                    <span className="superscript">$</span>
                                    {spDollar}
                                    <span className="superscript">{spCents}</span>
                                </div>
                            </>)}
                            <div className="sale-notice">{salesNotice}</div>
                            <div className="properties">
                                {properties.map(({title,lines},index) => (
                                    <div key={index} className="group">
                                        <div className="highlight">{title}</div>
                                        {lines.map((line,index) => <div key={index} className="normal">{line}</div>)}
                                    </div>
                                ))}
                            </div>
                            <EventPane onClick={clickHandler} parentElemRef={ref} seoLinkUrl={pageRoute} />
                        </div>                                        
                    </div>
                    <div className="cover" onTransitionEnd={(e) => coverTransitionEndHandler(e.propertyName)}></div>
                </div>                
            </div>
            <style jsx>{`
            .container {
                --duration: 0.3s;
                --padding-vert: 5rem;
                --padding-hori: calc(8rem + var(--extra-desktop-padding-hori));
                /* --padding-hori: 0rem; */
                position: relative;
                overflow: hidden;
                opacity: 1;
                transition: height var(--duration),opacity var(--duration);
            }
            .container.touchdown {
                background-color: rgba(0,0,0,0.015);
            }
            .padding-frame {
                padding: var(--padding-vert) var(--padding-hori);
            }
            .container.fade-out {
                opacity: 0;
            }
            .cover {
                --duration: 1.3s;
                position: absolute;
                left: 0rem;
                top: 0rem;
                right: 0rem;
                bottom: 0rem;
                background-color: #efefef;
                opacity: 0;
                visibility: hidden;
                transition: opacity var(--duration),visibility var(--duration);
                pointer-events: none;
            }
            .flashing .cover {
                --duration: 0.36s;
                background-color: rgba(0,0,0,0.03);
                --border-color: #ddd;
                --border-width: 4rem;
                left: 0rem;
                top: 0rem;
                right: 0rem;
                bottom: 0rem;
                border-top: var(--border-width) solid var(--border-color);
                border-bottom: var(--border-width) solid var(--border-color);
                border-left: var(--border-width) solid var(--border-color);
                border-right: var(--border-width) solid var(--border-color);
            }

            .flashing.flash .cover {
                opacity: 1;
                visibility: visible;
            }

            .frame {
                position: relative;
                display: flex;
                padding: 10rem 20rem;
            }
            .hidden .cover {
                opacity: 1;
                visibility: visible;
                transition-duration: 0s;
            }
            .thumbnail-column {
                flex: 45%;
                min-width: 105rem;
            }
            .image-wrapper {
                width: 100%;
                position: relative;
                padding: 50%;
                border: 1rem solid #eee;
                opacity: 0;
                transition: opacity 0.5s;
            }
            .thumb-loaded .image-wrapper {
                opacity: 1;
            }
            .container.hover .image-wrapper {
                border: 1rem solid #ddd;
            }
            .image-wrapper .inner {
                position: absolute;
                left: 0rem;
                top: 0rem;
                height: 100%;
                width: 100%;                
            }
            .image-wrapper .inner-relative {
                position: relative;
                width: 100%;
                height: 100%;                
            }
            .quantity-control {
                display: flex;
                height: 31rem;
                margin-top: 11rem;
            }
            .quantity-control .button {
                height: 100%;
                flex: 1 0;
                background: linear-gradient(to bottom,#f7f8fa,#e7e9ec);
                border-style: solid;
                border-width: 1rem;
                box-sizing: border-box;
                border-color: #D5D9D9;
                box-shadow: 0 2rem 5rem 0 rgba(213,217,217,.5);
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;                
                transition: border-color 0.2s;
            }
            .quantity-control .button.hover,.quantity-control .button.touchdown {
                border-color: var(--color-light-blue);
            }
            .quantity-control .button span {
                font-size: 22rem;
                line-height: 24rem;
                position: relative;
                top: -2rem;
            }
            .quantity-control .button.left-button span {
                display: none;
            }

            .quantity-control .button.left-button .bin-wrapper {
                position: relative;
                top: 1rem;                
            }

            .quantity-control .button.left-button.minusable .bin-wrapper {
                display: none;
            }            

            .quantity-control .button.left-button.minusable span {
                display: inline;
            }
            
            .quantity-control .button.left-button {
                border-top-left-radius: 8rem;
                border-bottom-left-radius: 8rem;                
            }
            .quantity-control .button.right-button {
                border-top-right-radius: 8rem;
                border-bottom-right-radius: 8rem;                
            }
            .quantity-control .display {
                height: 100%;
                background-color: #fff;
                flex: 1.3 0;
                border-color: #D5D9D9;
                border-top-style: solid;
                border-width: 1rem;
                border-bottom-style: solid;
                box-sizing: border-box;
                box-shadow: 0 2rem 5rem 0 rgba(213,217,217,.5);                
            }
            .quantity-control .display input {
                width: 100%;
                padding: 0;
                height: 100%;
                border: none;
                text-align: center;
                color: #007185;
                font-size: 15rem;
                box-sizing: border-box;
                box-shadow: 0 1rem 3rem #0000000f inset;
                display: inline-block;
                line-height: 16rem;
                outline: none;            
            }
            .thumbnail-content {
                padding-left: 18rem;
                /* max-width: 200rem; */
                flex: 55%;
                font-size: 16rem;            
                position: relative;
            }
            .thumbnail-content .title {
                margin-bottom: 1rem;
                color: ${itemTitleColor};
            }
            .container.hover .thumbnail-content .title {
                color: ${itemTitleHoverColor};
            }
            .thumbnail-content .price {
                font-weight: 600;
                font-size: 25rem;
                margin-bottom: 2rem;
                display: inline-block;                              
                position: relative;
            }
            .thumbnail-content .sale-notice {
                display: none;
                border: 1rem solid #363636;
                font-size: 11rem;
                background-color: #fff;
                padding: 1rem 5rem;
                margin-left: 5rem;
                vertical-align: top;
                margin-top: 5rem;
                color: #363636;                
            }
            .container.has-sales-notice .thumbnail-content .sale-notice {
                display: inline-block;
            }
            .thumbnail-content .price .cross-out {
                position: absolute;
                left: -3rem;
                top: 15rem;
                width: calc(100% + 6rem);
                height: 1rem;
                background-color: #979797;                
                display: none;
            }            
            .container.onsale .thumbnail-content .price .cross-out {
                display: block;
            }
            .thumbnail-content .sale-price {
                display: none;
                margin-left: 6rem;
            }
            .container.onsale .thumbnail-content .sale-price {
                display: inline-block;
            }
            .container.onsale .thumbnail-content .price:not(.sale-price):not(.force-free) {
                color: #979797;
            }
            .superscript {
                font-size: 15rem;
                position: relative;
                top: -7rem;                
            }
            .properties {
                font-size: 14rem;
                overflow: hidden;                
            }
            .properties .group {
                margin-bottom: 5rem;                
            }
            .properties .highlight {
                font-size: 12rem;
                color: ${itemPropertiesGroupTitleColor};
                font-weight: 600;
                line-height: 17rem;                
            }
            .properties .normal {
                font-size: 12rem;
                line-height: 17rem;                
            }
            .edit-button-wrapper {
                margin-top: 11rem;
                display: none;
            }
            .show-edit .edit-button-wrapper {
                display: block;
            }
            .container.not-editable .edit-button-wrapper {
                height: 0rem;
                overflow: hidden;
            }
            .edit-button {
                width: 100%;
                width: 100%;
                text-align: center;
                font-size: 13rem;
                padding: 7rem 0rem;
                border-radius: 5rem;
                height: auto;
                line-height: inherit;
                border: 1rem solid #D5D9D9;
                background-color: #ffffff;
                color: #565656;
                box-shadow: 0 2rem 5rem 0 rgb(213 217 217 / 50%);            
                position: relative;
                transition: border-color 0.2s;
            }

            .edit-button.hover,.edit-button.touchdown {
                border-color: var(--color-light-blue);               
            }
            .thumb-sale-notice {
                position: absolute;
                right: 6rem;
                top: 6rem;
                box-shadow: 0rem 0rem 5rem 1rem rgba(255,255,255,0.75);
                display: none;
            }
            .thumb-sale-notice-inner {
                border: 1rem solid var(--color-light-blue);
                background-color: #fff;
                font-size: 14rem;
                padding: 1rem 6rem;
                color: var(--color-light-blue);
                font-weight: 600;
                border-radius: 0rem;                
            }
            .container.has-thumb-sales-notice .thumb-sale-notice {
                display: block;
            }
            .quantity-sales-notice {
                width: 100%;
                background-color: #3e3e3e;
                color: #fff;
                margin-top: 7rem;
                font-size: 11rem;
                font-weight: 600;
                text-align: center;
                padding: 5rem 0rem;
                margin-bottom: -5rem;     
                display: none;
            }
            .container.has-quantity-sales-notice .quantity-sales-notice {
                display: block;
            }
            .quantity-not-editable {
                text-align: center;
                font-size: 15rem;
                color: #007185;
                border: 1rem solid #D5D9D9;
                border-radius: 8rem;
                height: 32rem;
                line-height: 29rem;
                background-color: #fff;
                display: none;
                margin-top: 11rem;
                margin-bottom: 0rem;            
            }
            .container.not-quantity-editable .quantity-not-editable {
                display: block;
            }
            .container.not-quantity-editable .quantity-control {
                display: none;
            }
            .price-free {
                font-size: 18rem;
            }
            `}</style>
        </>
    );
}