import { useEffect, useRef } from "react";
import { useSmartState } from "./use_smart_state";

type SubscriberCb = ((value: any) => void);

const subscribers: {[topic: string]: SubscriberCb[]} = {};

export const usePubSubSender = <T = any>(topic: string,{ equate = (a,b) => a===b }: {equate?: (a: T,b: T) => boolean} = {}) => {
    const ref = useRef<T>();
    return {
        send: (value: T) => {
            if (!equate(value,ref.current)) {
                ref.current = value;
                subscribers[topic] = subscribers[topic] || [];    
                subscribers[topic].forEach((cb) => cb(value));    
            }
        }
    };
};

export const usePubSubReceiver = <T = any>(topic: string,initialValue: T) => {
    const ret = useSmartState<T>(initialValue);
    useEffect(() => {
        subscribers[topic] = subscribers[topic] || [];
        const cb = (value: T) => {
            ret.value = value;
        };
        subscribers[topic].push(cb);
        return () => {
            const index = subscribers[topic].indexOf(cb);
            if (index!==-1) {
                subscribers[topic].splice(index,1);
            }
        };
    },[]);
    return ret;
};