import { useCssStateManager } from "../../hooks/use_css_state_manager";
import { misc } from "../../lib/misc";
import { prebuildData } from "../../lib/prebuild-data";
import { Image } from "../common/scaled-next-image";
import { EventPane } from "../core/event_pane";

export type ClearCartProps = {
    onClear?: () => void;
    setConfirm?: (value: boolean) => void;
    confirm: boolean;
};

function Button({text,onClick = () => {}}:{text: string;onClick?: () => void}) {
    return (
        <>
            <div className="container">
                <div className="frame">
                    {text}
                    <EventPane onClick={onClick}/>
                </div>
            </div>
            <style jsx>{`
            .container {
                display: flex;                
                color: #FFF;
                flex-direction: column;
                justify-content: center;
            }
            .frame {
                position: relative;
                background-color: #333;
                --height: 30rem;
                height: var(--height);
                line-height: var(--height);
                padding: 0rem 0rem;
                width: 46rem;
                text-align: center;
                border-radius: 4rem;
                font-size: 12rem;
                opacity: 1;
                transition: opacity 0.3s;
            }
            .frame.hover {
                opacity: 0.9;
            }
            `}</style>
        </>
    );
}

function Spacer({width}:{width: number}) {
    return <div style={{width: `${width}rem`}}></div>;
}

export default function ClearCart({onClear = () => {},setConfirm = () => {},confirm}:ClearCartProps)
{
    const cssStateManager = useCssStateManager(["container"]);
    cssStateManager.useProperty(confirm,"confirm");

    const binSvg = misc.createImageDimensions(242,256,15);

    const clearClickHandler = () => {
        setConfirm(true);
    };

    const yesClickHandler = () => {
        onClear();
    };

    const noClickHandler = () => {
        setConfirm(false);
    };

    return (
        <>
            <div className={cssStateManager.getClassNames()}>
                <div className="clear-button">
                    <div className="clear-button-wrapper">
                        <div className="bin-wrapper">
                            <Image src={prebuildData.bustCache("/images/side_cart/bin.svg")} width={binSvg.width} height={binSvg.height} />
                        </div>
                        Clear Cart
                        <EventPane onClick={clearClickHandler} />
                    </div>
                </div>
                <div className="confirm-form">
                    Clear the cart? <Spacer width={12}/><Button text="Yes" onClick={yesClickHandler} /><Spacer width={8}/><Button text="No" onClick={noClickHandler} />
                </div>
            </div>
            <style jsx>{`
            .container {
                --duration: 0.3s;
                --container-height: 44.52rem;
                position: relative;
                border-bottom: 1rem solid var(--line-color);                
                font-size: 14rem;
                color: #777;                                
                height: var(--container-height);
            }
            .clear-button {
                position: absolute;
                left: 0rem;
                right: 0rem;
                top: 0rem;
                bottom: 0rem;
                text-align: center;
                padding: 0rem 0rem;
                line-height: var(--container-height);
                opacity: 1;
                visibility: visible;
                transition: opacity var(--duration),visibility var(--duration);
            }
            .confirm .clear-button {
                opacity: 0;
                visibility: hidden;
            }
            .clear-button-wrapper {
                position: relative;
                width: 100%;
                height: 100%;
                opacity: 1;
                transition: opacity 0.3s;
            }
            .clear-button-wrapper.hover {
                opacity: 0.7;
            }
            .container.confirm .clear-button {
                opacity: 0;
            }
            .bin-wrapper {
                display: inline-block;
                padding-right: 5rem;
                position: relative;
                top: 1.5rem;
                opacity: 0.5;
            }
            .confirm-form {
                position: absolute;
                left: 0rem;
                right: 0rem;
                top: 0rem;
                bottom: 0rem;
                opacity: 0;
                visibility: hidden;
                transition: opacity var(--duration),visibility var(--duration);

                display: flex;
                justify-content: center;
                align-items: center;
            }
            .confirm .confirm-form {
                opacity: 1;
                visibility: visible;
            }
            `}</style>
        </>
    );
}