import { useRouter } from "next/router";
import { MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { DimensionsStateContext } from "../../contexts/dimensions_state";
import { LayoutStateContext } from "../../contexts/layout_state";
import { useCurrentCollection } from "../../hooks/use_current_collection";
import { env } from "../../lib/env";
import { prebuildData } from "../../lib/prebuild-data";
import { Icon } from "../core/icon";
import { VerticalCenter } from "../core/vertical_center";
import SpacerRow from "../spacer_row";
import MenuItem from "./menu_item";

export type MenuProps = {
    menuItems: ({handle: string; caption: string})[];
};

export function Menu({menuItems}:MenuProps)
{
    const { handle: selectedCollectionHandle } = useCurrentCollection();

    const { mobileDetect } = useContext(LayoutStateContext);
    const { mobileSmallScreenCutoff: mobileSmallWidthCutoff  } = mobileDetect;
    const [scrolling,setScrolling] = useState(false);
    const [scrollLeft,setScrollLeft] = useState(0);
    const scrollLeftRef = useRef(scrollLeft);
    scrollLeftRef.current = scrollLeft;
    const scrollerRef = useRef<HTMLDivElement>(null);
    const itemsRef = useRef<HTMLDivElement>(null);
    const [showLeftScroller,setShowLeftScroller] = useState(false);
    const [showRightScroller,setShowRightScroller] = useState(false);

    const updateScrollerVisibility = () => 
    {
        const { current: scroller } = scrollerRef;
        const { current: items } = itemsRef;        
        const { current: scrollLeft } = scrollLeftRef;
        if (scroller && items)
        {
            const scrollMargin = Math.max(0,items.offsetWidth-scroller.offsetWidth);
            setShowLeftScroller(scrollLeft>0);
            setShowRightScroller(scrollLeft<scrollMargin);
        }
    };

    const ensureDesktopScrollerLimits = () => 
    {
        const { current: scroller } = scrollerRef;
        const { current: items } = itemsRef;
        const { current: scrollLeft } = scrollLeftRef;
        
        if (env.isDesktop() && scroller && items)
        {
            const scrollMargin = Math.max(0,items.offsetWidth-scroller.offsetWidth);
            if (scrollLeft>scrollMargin)
            {
                setScrollLeft(scrollMargin);
            }
        }
    };

    useEffect(() => 
    {
        const listener = () => 
        {
            const { current: scroller } = scrollerRef;
            const { current: items } = itemsRef;
            if ((scroller) && (items))
            {
                setScrolling(items.offsetWidth>scroller.offsetWidth);
                updateScrollerVisibility();
                ensureDesktopScrollerLimits();
            }
        };
        window.addEventListener("resize",listener);
        listener();
        return () => window.removeEventListener("resize",listener);
    },[]);

    const speed = 0.2; // duration per 100rem
    const scrollDuration = 0.5;

    const leftScrollerClickHandler = () => 
    {
        const { current: scroller } = scrollerRef;
        const { current: items } = itemsRef;
        const { current: scrollLeft } = scrollLeftRef;
        if (scroller && items)
        {
            if (scrollLeft>0)
            {
                const newScrollLeft = scrollLeft-Math.min(scrollLeft,scroller.offsetWidth*0.75);
                setScrollLeft(newScrollLeft);
                scrollLeftRef.current = newScrollLeft;
                updateScrollerVisibility();
            }
        }
    };

    const rightScrollerClickHandler = () => 
    {
        const { current: scroller } = scrollerRef;
        const { current: items } = itemsRef;
        const { current: scrollLeft } = scrollLeftRef;
        if ((scroller) && (items))
        {
            const scrollMargin = Math.max(0,items.offsetWidth-scroller.offsetWidth);
            if (scrollLeft<scrollMargin)
            {
                const newScrollLeft = scrollLeft+Math.min(scroller.offsetWidth*0.75,scrollMargin-scrollLeft);
                setScrollLeft(newScrollLeft);
                scrollLeftRef.current = newScrollLeft;
                updateScrollerVisibility();
            }
        }
    };

    const { collectionMenuBar: { backgroundColor,bottomBorderColor } } = prebuildData.getActiveTheme();

    const scrollerArrowHeight = 15;

    return (
        <>
            <div className={`frame${scrolling?" scrolling":""}`}>
                <div className={`side-scroller left${(showLeftScroller && scrolling)?" show":""}`} onClick={leftScrollerClickHandler}>
                    <VerticalCenter frameStyle={{width: 34,alignItems: "center"}}>
                        <Icon name="navbar-left-arrow" size={scrollerArrowHeight} />
                    </VerticalCenter>
                </div>
                <div className={`side-scroller right${(showRightScroller && scrolling)?" show":""}`} onClick={rightScrollerClickHandler}>
                    <VerticalCenter frameStyle={{
                            width: 34,
                            alignItems: "center",
                        }}>
                        <Icon name="navbar-right-arrow" size={scrollerArrowHeight} />
                    </VerticalCenter>
                </div>
                <div ref={scrollerRef} className="scroller">
                    <div ref={itemsRef} className="items"
                            style={{
                                transform: `translateX(${scrolling?-scrollLeft:0}rem)`
                            }}
                        >
                        <SpacerRow size={6} />
                        {menuItems.map(({handle,caption}) => (<MenuItem key={handle} handle={handle} caption={caption} selected={selectedCollectionHandle===handle} />))}
                        <SpacerRow size={6} />
                    </div>
                </div>
            </div>
            <style jsx>{`
                .side-scroller {
                    position: absolute;
                    top: 0rem;
                    height: 100%;
                    width: 120rem;
                    /* background-color: var(--color-collection-menu); */
                    z-index: 10;
                    color: #777;
                    cursor: pointer;
                    visibility: hidden;
                    opacity: 0;
                    --duration: 0s;
                    transition: visibility var(--duration),opacity var(--duration);
                }

                .side-scroller.show {
                    visibility: visible;
                    opacity: 1;
                }

                .side-scroller.left {
                    left: 0rem;
                    background: linear-gradient(90deg, var(--color-collection-menu) 23%, var(--color-collection-menu-zero-alpha) 100%);
                }

                .side-scroller.right {
                    right: 0rem;
                    background: linear-gradient(90deg, var(--color-collection-menu-zero-alpha) 0%, var(--color-collection-menu) 77%);
                    flex-direction: row;
                    display: flex;
                    justify-content: flex-end;
                }

                .side-scroller.right .icon {
                    position: absolute;
                    top: 0rem;
                    right: 0rem;
                    bottom: 0rem;
                }

                @media (hover: none) {
                    :global(html) :global(body) .frame .side-scroller {
                        display: none;
                    }
                }

                .frame,.items {
                    height: 48rem;
                }

                .frame {
                    background-color: ${backgroundColor};
                    border-bottom: 1rem solid ${bottomBorderColor};
                    overflow: hidden;
                    position: relative;
                }

                .scroller {
                    width: 100%;                    
                    height: calc(100% + 30rem);
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    overflow: hidden;                    
                }

                .frame.scrolling .scroller {
                    justify-content: flex-start;                    
                }

                @media (hover: none) {
                    :global(html) .frame.scrolling .scroller {
                        overflow-x: scroll;
                    }
                }

                .items {
                    display: flex;
                    flex-direction: row;                    
                }

                .frame.scrolling .items {
                    transition: transform 0.5s;
                }

                @media screen and (max-width: ${mobileSmallWidthCutoff}px)
                {
                    :global(html) .frame,:global(html) .items {
                        height: 42rem;
                    }
                }
            `}</style>
        </>
    )
}