import { useEffect, useRef } from "react";
import { ResizeObserverCallbackUpdate, ResizeObserverEntry } from "./types";

let ro: ResizeObserver;
let entries: ResizeObserverEntry[] = [];

if (globalThis.document)
{
    ro = new ResizeObserver((_entries) => 
    {
        _entries.forEach(({target: _target}) => 
        {
            entries.forEach(({target,cb}) => 
            {
                if (_target===target) cb();
            });
        });
    });
}

export const useResizeObserver = <T extends Element = HTMLDivElement>(cb: (update: ResizeObserverCallbackUpdate<T>) => void,{ disabled = false,initialCall = false }: { disabled?: boolean; initialCall?: boolean; } = {}) => 
{
    if (!globalThis.document) return;
    const ref = useRef<T>(null);
    if (!disabled)
    {
        useEffect(() => 
        {
            if (ref.current)
            {
                const target = ref.current;
                const entry = {
                    target,
                    cb: () => 
                    {
                        const { left,right,top,bottom,width,height } = target.getBoundingClientRect();
                        cb({
                            target,
                            left,
                            right,
                            top,
                            bottom,
                            width,
                            height
                        });                    
                    }
                };
                entries.push(entry);
                if (initialCall) entry.cb();
                ro.observe(target);
                return () => 
                {
                    entries = entries.filter((_entry) => _entry!==entry);
                    ro.unobserve(target);
                };
            }
        },[]);    
    }
    return ref;
};