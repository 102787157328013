import { useContext, useEffect, useRef } from "react";
import { LayoutStateContext } from "../contexts/layout_state";
import { useCssStateManager } from "./use_css_state_manager";
import { useSmartState } from "./use_smart_state";

export type ErrorSlideShowProps = {
    message: string;
};

export default function ErrorSlideShow({message}:ErrorSlideShowProps)
{
    const cssStateManager = useCssStateManager(["error-slideshow"]);
    cssStateManager.useProperty(message!=="","has-message");
    const { scalePixelToRem } = useContext(LayoutStateContext);
    
    const height = useSmartState(0);

    const inlineRef = useRef<HTMLDivElement>();
    const marginBottom = 4;

    useEffect(() => {
        if (message.length>0) {
            setTimeout(() => {
                if (inlineRef.current) {
                    const { current: inlineElem } = inlineRef;
                    height.value = scalePixelToRem(inlineElem.offsetHeight)+marginBottom;
                }
            },100);
        }
        else {
            height.value = 0;
        }
    },[message.length>0]);
    
    return (
        <>
            <div className={cssStateManager.getClassNames()} style={{
                    height: `${height.value}rem`
                }}>
                <div ref={inlineRef} >
                    {message}
                </div>
            </div>
            <style jsx>{`
            .error-slideshow {
                --duration: 0.3s;
                transition: height var(--duration);
                overflow: hidden;
                text-align: center;
                color: #f00;
                font-size: 14rem;
            }
            `}</style>
        </>
    );
}

export const useErrorSlideShow = () => {    
    const message = useSmartState("");

    const actions = {
        show(messageText: string) {            
            message.value = messageText;
        },
        close() {
            message.value = "";
        }
    };

    const component = <ErrorSlideShow message={message.value} />;

    return {
        actions,
        component
    };
};