import { useContext } from "react";
import { LayoutStateContext } from "../../contexts/layout_state";

export type TextProps = {
    children: React.ReactNode;
};

export function Text({children}: TextProps)
{
    const { font } = useContext(LayoutStateContext);

    return (
        <>
            <span className="text" style={{
                visibility: font.ready?"visible":"hidden",
                opacity: font.ready?1:0
            }}>{children}</span>
            <style jsx>{`
            .text {
                --duration: 0.8s;
                transition: visibility var(--duration),opacity var(--duration);
            }
            `}</style>
        </>
    );
}