import { CSSProperties, useRef } from "react";
import { prebuildData } from "../lib/prebuild-data";
import { EventPane } from "./core/event_pane";
import { Icon, IconsLoadedWrapper } from "./core/icon";
import { Text } from "./core/text";

type TProps = {
    name: string;
    caption: string;
    wholeShift?: number;
    iconShift?: number;
    iconText?: string;
    iconTextShift?: number;
    scale?: number;
    disabled?: boolean;
    widthPlaceholder?: number;
    onClick?: () => void;
    onTouchOrMouseDown?: () => void;
    fixedWidth?: number;
    verticalPadding?: number;
};

export const useIconButton = (iconColor: string,textColor: string) => {
    const IconButtonRef = useRef(function IconButton({name,caption,wholeShift,iconShift,iconText,iconTextShift,scale,disabled,widthPlaceholder,onClick,onTouchOrMouseDown,fixedWidth,verticalPadding = 0}: TProps)
    {
        iconShift = iconShift || 0;
        iconTextShift = iconTextShift || 0;
        scale = scale || 1;
        wholeShift = wholeShift || 0;
        disabled = disabled || false;
        widthPlaceholder = widthPlaceholder || 0;
    
        const imageStyles: CSSProperties = {
            color: iconColor
        };
    
        const textStyles: CSSProperties = {
            color: textColor
        };
    
        return (
            <>
                <div className={`icon${disabled?" disabled":""}`}
                        style={{
                            top: `${wholeShift}rem`,
                            width: (fixedWidth!==undefined)?`${fixedWidth}rem`:"auto",
                            padding: `${verticalPadding}rem 0rem`
                        }}
                    >
                    {(widthPlaceholder>0)?(
                    <div style={{height: "0rem",width: `${widthPlaceholder}rem`}}></div>):null}
                    <div className="icon-image" style={imageStyles}>
                        <IconsLoadedWrapper>
                            <Text>
                                <Icon name={name} size={30*scale} shiftLeft={iconShift} />
                            </Text>
                        </IconsLoadedWrapper>                
                    </div>
                    {iconText?<div className="text" style={{top: `${14+verticalPadding+iconTextShift}rem`,fontSize: `${12*scale}rem`}}><Text>{iconText}</Text></div>:""}
                    <div className="caption" style={textStyles}>
                        <Text>{caption}</Text>
                    </div>      
                    <EventPane onClick={onClick} onTouchOrMouseDown={onTouchOrMouseDown} />
                </div>
                <style jsx>{`
                .icon
                {
                    color: var(--color-collection-menu);
                    text-align: center;
                    cursor: pointer;
                    position: relative;
                    opacity: 1;
                    transition: opacity 0.3s,background-color var(--touch-duration);
                }
    
                .icon.touchdown {
                    background-color: rgba(255,255,255,0.07);
                }
    
                .icon.disabled {
                    color: #3d3d3d;
                    cursor: default;
                }
    
                .icon.hover {
                    opacity: 0.85;
                }
    
                .caption {
                    font-size: 11rem;
                    margin-top: -2rem;
                    font-weight: 600;
                }
    
                .text {
                    position: absolute;
                    left: 0rem;
                    top: 0rem;
                    width: 100%;
                    color: black;
                    font-weight: 600;
                }
                `}</style>
            </>
        );
    });  
    return IconButtonRef.current;
};