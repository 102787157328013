export type SideMenuSectionTitleProps = {
    caption: string;
};

export function SideMenuSectionTitle({caption}:SideMenuSectionTitleProps)
{
    return (
        <>
            <div className="container">
                <div className="frame">
                    {caption}
                </div>
            </div>
            <style jsx>{`
            .container {
                width: 100%;
            }

            .frame {
                color: var(--color-menu-item);
                --height: 54rem;
                height: var(--height);
                line-height: var(--height);
                font-size: 18rem;
                font-weight: 600;
                padding-right: 16rem;
                text-align: right;
                width: 100%;
            }
            `}</style>
        </>
    );
}