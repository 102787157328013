import { useRouter } from "next/router";
import { useContext, useReducer, useRef } from "react";
import { routerTransition } from "../components/core/router_transition";
import { LayoutStateContext } from "../contexts/layout_state";
import { misc } from "../lib/misc";

export type BeforePushSubscribeCallback = (cb: () => void) => boolean;
const beforePushSubscriptions: BeforePushSubscribeCallback[] = [];

let routerLocked = false;

const processBeforePushSubscriptions = async(cb: () => void) => {
    await new Promise<void>((resolve) => {
        let counting = 0;
        const waitCount = beforePushSubscriptions.filter((subscriptionCb) => subscriptionCb(() => {
            setTimeout(() => {
                counting++;
                if (counting===waitCount) resolve();    
            },0);
        })).length;
        if (waitCount===0) resolve();
    });
    cb();
};

export const useCustomRouter = () => {
    const reactRouter = useRouter();
    const reactRouterRef = useRef(reactRouter);
    reactRouterRef.current = reactRouter;

    const { page,linearTopWorkingIndicatorRunning,routerIsLocked } = useContext(LayoutStateContext);

    return {
        reactRouter,
        async runLocked(cb: () => Promise<void>,withLinearTopWorkingIndicator = true) {
            if (routerLocked) return;
            routerLocked = true;
            routerIsLocked.value = routerLocked;
            if (withLinearTopWorkingIndicator) linearTopWorkingIndicatorRunning.value = true;            
            await cb();
            routerLocked = false;
            routerIsLocked.value = routerLocked;
            if (withLinearTopWorkingIndicator) linearTopWorkingIndicatorRunning.value = false;
        },
        async push(url: string,force = false) {
            if (routerLocked) return;
            await processBeforePushSubscriptions(() => {
                const { current: reactRouter } = reactRouterRef;
                const pid = misc.getParameterByName("pid");
                if (pid) url = misc.addQueryParamToUrl(url,"pid",pid);
                if ((reactRouter.asPath!==url) || (force)) {
                    routerTransition.start();
                    setTimeout(() => 
                    {
                        page.lockScroll();
                        reactRouter.push(url);    
                    },0);
                }                
                else {
                    page.scrollTo({top: 0,behavior: "smooth"});
                }    
            });
        },
        beforePushSubscribe(cb: BeforePushSubscribeCallback) {
            beforePushSubscriptions.push(cb);

            return () => {
                const tmp = beforePushSubscriptions.filter((_cb) => _cb!==cb);    
                beforePushSubscriptions.length = 0;
                beforePushSubscriptions.push(...tmp);
            };
        }
    };
};