import { useCssStateManager } from "../../hooks/use_css_state_manager";
import { SmartState } from "../../hooks/use_smart_state";
import { prebuildData } from "../../lib/prebuild-data";

export type StarRatingProps = {
    rating: number;
    hoverSmartState?: SmartState<boolean>;
    scale?: number;
};

export default function StarRating({rating,hoverSmartState = {value: false},scale = 1}:StarRatingProps)
{
    rating = Math.min(Math.max(Math.round(rating*2)/2,0),5);    
    const stars = Array.from(Array(5)).map(() => 
    {
        if (rating>=1)
        {
            rating--;
            return "star";
        }
        else if (rating===0.5)
        {
            rating = 0;
            return "star-half";
        }
        else
        {
            return "star-empty";
        }
    });

    const cssStateManager = useCssStateManager(["stars"]);
    cssStateManager.useProperty(hoverSmartState.value,"hover");

    const { general: { starsColor,starsHoverColor } } = prebuildData.getActiveTheme();

    return (
        <>
            <span className={cssStateManager.getClassNames()}>
                {stars.map((type,index) => <i key={index} className={`icon-collection-item-review-${type}`} />)}
            </span>
            <style jsx>{`
            .stars {
                color: ${starsColor};
                letter-spacing: ${3*scale}rem;
                font-size: ${18*scale}rem;
                transition: color 0.2s;
            }
            .stars.hover {
                color: ${starsHoverColor};
            }
            `}</style>
        </>
    );
}