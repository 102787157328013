import { infiniteFetch } from "../infinite-fetch";
import { isSimpleObject } from "../shared/options/snapshot/misc";
import { prebuildData } from "../prebuild-data";

export const sendStorefrontQuery = async ({ query, variables }: {query: string; variables: any}) => {
    const storefront = prebuildData.getStorefront();
    // console.log("query",query);

    const result = await infiniteFetch(
        storefront.graphqlUrl,{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-Shopify-Storefront-Access-Token": storefront.token,
            },
            body: JSON.stringify({ query, variables }),
        },
    );

    // console.log("result",result);
    
    if (!result.ok) {
        console.error(result);
        return false;
    }
    
    const res = await result.json();
    // console.log("res",res);
    // console.log(JSON.stringify(res,null,2));
    const { data } = res;    
    
    return data;    
};

export const createCart = async () => {
    const data = await sendStorefrontQuery({
        query: `
          mutation CreateCart {
            cartCreate {
              cart {
                checkoutUrl
                id
              }
            }
          }
        `,
        variables: {},
    });

    return {
        cartId: data.cartCreate?.cart?.id as string,
        checkoutUrl: data.cartCreate?.cart?.checkoutUrl as string,
    };
};

export const splitObject = (value: any) => {
    const ret: {[name: string]: string} = {};
    const process = (prefix: string,value: any) => {
        if (["string","number","boolean"].indexOf(typeof value)!==-1) {
            ret[prefix] = value.toString();
        }
        else if (value===null) {
            ret[prefix] = "null";
        }
        else if (Array.isArray(value)) {
            ret[`${(prefix!=="")?`${prefix}.`:""}length`] = value.length.toString();
            value.forEach((value,index) => {
                process(`${(prefix!=="")?`${prefix}.`:""}${index}`,value);
            });
        }
        else if (isSimpleObject(value)) {
            Object.keys(value).forEach((key) => {
                process(`${(prefix!=="")?`${prefix}.`:""}${key}`,value[key]);
            });
        }
    };
    process("",value);
    return ret;
}

export type LineItemAttribute = {
    key: string;
    value: string;
};

export type LineItem = {
    attributes: LineItemAttribute[];
    quantity: number;
    variantId: string;
};

export const createCheckout = async (customAttributes: {[key: string]: string},lineItems: LineItem[],note = "") => {
    const query = `
        mutation {
            checkoutCreate(input: {
                customAttributes: [${Object.keys(customAttributes).map((key) => `{key:${JSON.stringify(key)},value:${JSON.stringify(customAttributes[key])}}`).join(",")}],
                lineItems: [
                    ${lineItems.map(({attributes,quantity,variantId}) => `{
                        customAttributes: [
                            ${attributes.map(({key,value}) => `{
                                key: ${JSON.stringify(key)},
                                value: ${JSON.stringify(value)}
                            }`).join(`,
                            `)}
                        ],
                        quantity: ${quantity},
                        variantId: "gid://shopify/ProductVariant/${variantId}"
                    }`).join(`,
                    `)}],
                note: ${JSON.stringify(note)}
            }) {
                checkout {
                    id
                    webUrl
                }
                checkoutUserErrors {
                    code
                    field
                    message
                }
            }
        }
    `;
    console.log("query",query);
    const data = await sendStorefrontQuery({
        query,
        variables: {},
    });

    return {
        data
    };
};

export const checkoutLineItemsAdd = async (checkoutId: string,{attributes,quantity,variantId}: LineItem) => {
    const query = `
        mutation {
            checkoutLineItemsAdd(checkoutId: "${checkoutId}",lineItems: [{
                customAttributes: [
                    ${attributes.map(({key,value}) => `{
                        key: ${JSON.stringify(key)},
                        value: ${JSON.stringify(value)}
                    }`).join(`,
                    `)}
                ],
                quantity: ${quantity},
                variantId: "gid://shopify/ProductVariant/${variantId}"
            }]) {
                checkout {
                    id
                    webUrl
                }
                checkoutUserErrors {
                    code
                    field
                    message
                }
            }
        }
    `;
    const data = await sendStorefrontQuery({
        query,
        variables: {},
    });

    return {
        data
    };
};