import React, { useRef, useState } from 'react'

export type TSettableValue<T> = {
    value: T;
    set: (value: T) => void;
};

export type TDimensionsState = {
    navbarHeight: TSettableValue<number>;
    bannerHeight: TSettableValue<number>;
    miniNavbarHeight: TSettableValue<number>;
    collectionGridHeight: TSettableValue<number>;
    filterBarControlsHeight: TSettableValue<number>;
    scrollBottomPadding: TSettableValue<number>;
};

export const DimensionsStateContext = React.createContext<TDimensionsState>(null);

export const useSettableValue = <T>(initialValue: T,onBeforeSet: ((fromValue: T,toValue: T) => void) = () =>{}): TSettableValue<T> => 
{
    const [value,setValue] = useState(initialValue);
    const refPerm = useRef<TSettableValue<T>>({value,set: (value) => setValue(value)});
    refPerm.current.value = value;
    refPerm.current.set = (value) => 
    {
        onBeforeSet(refPerm.current.value,value);
        setValue(value);
    };
    return refPerm.current;
};